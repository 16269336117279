import styled from 'styled-components'

import IconMarker from '@components/atoms/Icons/IconMarker'
import { TextParagraphStyles } from '@components/atoms/Text/Text.styles'

import StudioFinderResultsList from '../StudioFinderResultsList'

export const StyledIconMarker = styled(IconMarker)`
  position: absolute;
  top: 0.4375rem;
  left: 0.625rem;
  path {
    fill: ${(props) => props.theme.palette.default.grey};
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => props.theme.margin.default};
  max-width: 100%;
  width: 100%;
  z-index: 99;
  outline: none;
  overflow: visible;
  height: 42px;
  &:hover {
    ${StyledIconMarker} {
      path {
        fill: ${(props) => props.theme.palette.default.black};
      }
    }
  }
`

interface SearchProps {
  open: boolean
}

export const Search = styled.div<SearchProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.palette.default.black};
  background: ${(props) => props.theme.palette.default.white};
  border-radius: ${(props) => (props.open ? '1rem 1rem 0 0' : '1rem')};
  border-top: 0.0625rem solid
    ${(props) =>
      props.open
        ? props.theme.palette.default.black
        : props.theme.palette.default.grey};
  border-right: 0.0625rem solid
    ${(props) =>
      props.open
        ? props.theme.palette.default.black
        : props.theme.palette.default.grey};
  border-bottom: 0.0625rem solid
    ${(props) =>
      props.open
        ? props.theme.palette.default.black
        : props.theme.palette.default.grey};
  border-left: 0.0625rem solid
    ${(props) =>
      props.open
        ? props.theme.palette.default.black
        : props.theme.palette.default.grey};
  &:focus,
  &:hover,
  &:active {
    border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    outline: none;
    ${StyledIconMarker} {
      path {
        fill: ${(props) => props.theme.palette.default.black};
      }
    }
  }
  ::placeholder {
    color: ${(props) => props.theme.palette.default.grey};
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 3rem;
  background: none;
  ${() => TextParagraphStyles()}
`

export const StyledStudioFinderResultsList = styled(StudioFinderResultsList)`
  width: 100%;
  height: 25rem;
  position: absolute;
  top: 41px;
  left: 0;
  background: ${(props) => props.theme.palette.default.white};
  border-left: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  border-bottom: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  border-right: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  border-top: none;
  border-radius: 0 0 1rem 1rem;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    border-left: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    border-bottom: 0.0625rem solid
      ${(props) => props.theme.palette.default.black};
    border-right: 0.0625rem solid
      ${(props) => props.theme.palette.default.black};
  }
`
