import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageSliderSlideStoryblok } from '@definitions/types/storyblokTypes'

import {
  StyledImage,
  StyledSwiperSlide,
} from './StoryblokImageSliderSlide.styles'

interface Props extends PropsWithClassName {
  blok: ImageSliderSlideStoryblok
}

const StoryblokImageSliderSlide: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  console.log(JSON.stringify(blok))
  return (
    <StyledSwiperSlide
      className={props.className}
      data-testid={'storyblok-image-slider-slide-root'}
    >
      <StyledImage
        priority
        src={blok.Image.filename}
        alt={blok.Image.alt ?? 'No Alt Text'}
        fill
      />
    </StyledSwiperSlide>
  )
}

export { StoryblokImageSliderSlide }
