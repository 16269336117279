import useStudioSearch from '@hooks/useStudioSearch'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { StudioWithDistance } from '@definitions/customTypes/StudioWithDistance'
import { Studio } from '@definitions/types/symfonyTypesd'

import {
  Root,
  Search,
  SearchBox,
  StyledHeadline,
  StyledStudioFinderSearchfield,
  ThemeKey,
} from './StudioSearchBox.styles'

interface Props extends PropsWithClassName {
  onChange: (term: string) => void
  setStudio: (studio: Studio) => void
  studios: StudioWithDistance[]
  onlyStudiosWithCoupons?: boolean
}

const StudioSearchBox: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { onChange, setStudio, onlyStudiosWithCoupons } = props
  const {
    handleSearch,
    studioArray,
    search: searchStudioTerm,
  } = useStudioSearch(null, false, onlyStudiosWithCoupons ?? false)
  const theme: any = useTheme()
  return (
    <Root className={props.className} data-testid={'studio-search-box-root'}>
      <SearchBox>
        <Row>
          <Col xs={12} sm={12} mdOffset={1} md={10} lgOffset={1} lg={10}>
            <Search>
              <StyledHeadline importance={HeadlineImportances.h5}>
                {theme.key === 'sunpoint' && (
                  <ThemeKey>
                    SUNPOINT&nbsp;
                    <br />
                    in der Nähe:
                  </ThemeKey>
                )}
                {theme.key === 'wellmaxx' && (
                  <ThemeKey>
                    Wellmaxx&nbsp;
                    <br />
                    in der Nähe:
                  </ThemeKey>
                )}
              </StyledHeadline>
              <StyledStudioFinderSearchfield
                onChange={(term) => handleSearch(term)}
                setStudio={(studio) => setStudio(studio)}
                studios={studioArray}
              />
            </Search>
          </Col>
        </Row>
      </SearchBox>
    </Root>
  )
}

export { StudioSearchBox }
