import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.device.tablet}) {
    width: 100%;
    height: auto;
    max-width: 76rem;
  }
`
