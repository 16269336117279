import { FieldError } from 'react-hook-form'
import styled from 'styled-components'

import IconArrowLeft from '@components/atoms/Icons/IconArrowLeft'
import Text from '@components/atoms/Text'

import InputLabel from '../InputLabel'

export const Root = styled.div`
  display: block;
  position: relative;
  overflow-y: initial;
  min-width: 15.9375rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: ${(props) => props.theme.margin.default};
`
interface SelectProps {
  readonly error: boolean | FieldError
  readonly disabled: boolean
  readonly isOpen: boolean
}

interface StyledIconArrowLeftProps {
  readonly isOpen: boolean
}

export const StyledIconArrowLeft = styled(
  IconArrowLeft
)<StyledIconArrowLeftProps>`
  position: absolute;
  right: 0.625rem;
  top: 0.5rem;
  transform: rotate(90deg)
    ${(props) => (props.isOpen ? 'rotateY(0deg)' : 'rotateY(180deg)')};
  transform-origin: 50% 50%;
  transition: 200ms ease-in-out;
  path {
    fill: ${(props) => props.theme.palette.default.grey};
  }
`

export const Prefix = styled(Text)`
  opacity: 1;
  color: ${(props) => props.theme.palette.default.greyLight};
  margin-right: 0.625rem;
  margin-bottom: 0;
`
export const CurrentValue = styled(Text)`
  opacity: 1;
  color: ${(props) => props.theme.palette.default.black};
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  span {
    margin-right: 0.3125rem;
    &:last-child {
      margin-right: 0;
      font-weight: normal;
    }
  }
`

export const Placeholder = styled.div`
  opacity: 0.5;
  color: ${(props) => props.theme.palette.default.white};
`

export const Select = styled.div<SelectProps>`
  border-radius: ${(props) => props.theme.borderRadius.input};
  border: 0.0625rem solid
    ${(props) =>
      props.error
        ? props.theme.palette.default.error
        : props.theme.palette.default.grey};
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  width: 100%;
  cursor: pointer;
  outline: none;
  position: relative;
  display: flex;
  ::placeholder {
    color: ${(props) => props.theme.palette.default.grey};
  }
  :focus {
    outline: none !important;
  }
  &:hover,
  &:focus {
    border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    ${StyledIconArrowLeft} {
      path {
        fill: ${(props) => props.theme.palette.default.black};
      }
    }
  }
`
export const SelectWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

interface OptionsProps {
  readonly isOpen: boolean
}

export const Options = styled.div<OptionsProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  flex-direction: column;
  position: absolute;
  top: calc(100% + ${(props) => props.theme.margin.small});
  left: 0;
  width: 100%;
  margin: 0;
  background: ${(props) => props.theme.palette.default.white};
  border-radius: ${(props) => props.theme.borderRadius.input};
  border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  z-index: 9999 !important;
  max-height: 18.75rem;
  min-width: 15.625rem;
  overflow-y: scroll;
  white-space: nowrap;
  span {
    font-weight: bold;
    &:last-child {
      font-weight: normal;
      margin-left: 0.3125rem;
    }
  }
`

interface OptionProps {
  readonly active: boolean
}

export const Option = styled.div<OptionProps>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: ${(props) =>
    props.active
      ? props.theme.palette.default.black
      : props.theme.palette.default.black};
  background: ${(props) =>
    props.active
      ? props.theme.palette.default.primary
      : props.theme.palette.default.white};
  transition: all 200ms ease-in-out;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  &:hover {
    color: ${(props) => props.theme.palette.default.black};
    background: ${(props) => props.theme.palette.default.greyLight};
  }
`

export const OptionText = styled(Text)`
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
`

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.margin.extrasmall};
`
