import Link from 'next/link'
import styled from 'styled-components'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: ${(props) => props.theme.palette.default.black};
    &:hover,
    &:visited {
      color: ${(props) => props.theme.palette.default.black};
    }
  }
`

export const PostContent = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const StyledButton = styled(Link)`
  text-decoration: none;
`
