import styled from 'styled-components'

import Col from '@components/atoms/Grid/Col'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const StyledCol = styled(Col)`
  padding-top: ${(props) => props.theme.margin.default};
`
