import { AppTheme } from 'styled-components'

interface DeviceBreakpoints {
  [key: string]: number
}

export const devices: DeviceBreakpoints = {
  smartphoneportrait: 320,
  smartphonelandscape: 480,
  tabletportrait: 768,
  tabletlandscape: 1024,
  desktop: 1200,
  desktoplarge: 1440,
}

export const themeSunpoint: AppTheme = {
  key: 'sunpoint',
  borderRadius: {
    button: '1.5rem',
    icon: '50%',
    box: '0.625rem',
    input: '1rem',
  },
  boxShadow: '0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.05)',
  device: {
    smartphone: '480px',
    mobile: '768px',
    tablet: '1024px',
    desktop: '1200px',
  },
  row: {
    maxWidth: '90rem',
    padding: '4.375rem',
  },
  font: {
    primaryFamily: '"Interstate", sans-serif',
    headline: {
      hero: {
        desktop: {
          fontSize: '3rem',
          fontWeight: 200,
          lineHeight: '3.5rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '3rem',
          fontWeight: 200,
          lineHeight: '3.5rem',
          letterSpacing: '0',
        },
      },
      one: {
        desktop: {
          fontSize: '3.25rem',
          fontWeight: 400,
          lineHeight: '3.875rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: '2.5rem',
          letterSpacing: '0',
        },
      },
      two: {
        desktop: {
          fontSize: '2.8125rem',
          fontWeight: 400,
          lineHeight: '3.4375rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.75rem',
          fontWeight: 400,
          lineHeight: '2.125rem',
          letterSpacing: '0',
        },
      },
      three: {
        desktop: {
          fontSize: '2.5rem',
          fontWeight: 400,
          lineHeight: '3.125rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.625rem',
          fontWeight: 400,
          lineHeight: '2.125rem',
          letterSpacing: '0',
        },
      },
      four: {
        desktop: {
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: '2.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.5rem',
          fontWeight: 400,
          lineHeight: '2rem',
          letterSpacing: '0',
        },
      },
      five: {
        desktop: {
          fontSize: '1.5rem',
          fontWeight: 700,
          lineHeight: '2rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.375rem',
          fontWeight: 700,
          lineHeight: '1.75rem',
          letterSpacing: '0',
        },
      },
    },
    text: {
      lead: {
        desktop: {
          fontSize: '1.25rem',
          fontWeight: 300,
          lineHeight: '1.75rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.125rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
      paragraph: {
        desktop: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
      meta: {
        desktop: {
          fontSize: '0.875rem',
          fontWeight: 300,
          lineHeight: '1.125rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '0.875rem',
          fontWeight: 300,
          lineHeight: '1.125rem',
          letterSpacing: '0',
        },
      },
      link: {
        desktop: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
    },
  },
  margin: {
    extrasmall: '0.5rem',
    small: '1rem',
    default: '1.5rem',
    large: '2rem',
    big: '4rem',
  },
  padding: {
    extrasmall: '0.5rem',
    small: '1rem',
    default: '1.5rem',
    large: '4rem',
    big: '8rem',
  },
  palette: {
    background: {
      header: '#f1fcff',
      footer: '#1F2937',
    },
    link: {
      primary: '#8A2163',
    },
    button: {
      primary: {
        background: '#292929',
        border: '#292929',
        text: '#FFFFFF',
      },
      secondary: {
        background: '#EFCD00',
        border: '#EFCD00',
        text: '#292929',
      },
      tertiary: {
        background: 'transparent',
        border: '#292929',
        text: '#292929',
      },
      danger: {
        background: '#E22F2F',
        border: 'none',
        text: '#FFFFFF',
      },
    },
    default: {
      primary: '#F6C500',
      secondary: '#981858',
      tertiary: '#e55300',
      white: '#ffffff',
      greyLight: '#f8f8f8',
      grey: '#d8d8d8',
      greyDark: '#889396',
      black: '#292929',
      error: '#E30613',
      success: '#00ff00',
      warning: '#ffff00',
    },
  },
}

export const themeWellmaxx: AppTheme = {
  key: 'wellmaxx',
  borderRadius: {
    button: '1.5rem',
    icon: '50%',
    box: '0.625rem',
    input: '1rem',
  },
  boxShadow: '0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.05)',
  device: {
    smartphone: '480px',
    mobile: '768px',
    tablet: '1024px',
    desktop: '1200px',
  },
  row: {
    maxWidth: '90rem',
    padding: '4.375rem',
  },
  font: {
    primaryFamily: '"Interstate", sans-serif',
    headline: {
      hero: {
        desktop: {
          fontSize: '3rem',
          fontWeight: 200,
          lineHeight: '3.5rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '3rem',
          fontWeight: 200,
          lineHeight: '3.5rem',
          letterSpacing: '0',
        },
      },
      one: {
        desktop: {
          fontSize: '3.25rem',
          fontWeight: 400,
          lineHeight: '3.875rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: '2.5rem',
          letterSpacing: '0',
        },
      },
      two: {
        desktop: {
          fontSize: '2.8125rem',
          fontWeight: 400,
          lineHeight: '3.4375rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.75rem',
          fontWeight: 400,
          lineHeight: '2.125rem',
          letterSpacing: '0',
        },
      },
      three: {
        desktop: {
          fontSize: '2.5rem',
          fontWeight: 400,
          lineHeight: '3.125rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.625rem',
          fontWeight: 400,
          lineHeight: '2.125rem',
          letterSpacing: '0',
        },
      },
      four: {
        desktop: {
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: '2.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.5rem',
          fontWeight: 400,
          lineHeight: '2rem',
          letterSpacing: '0',
        },
      },
      five: {
        desktop: {
          fontSize: '1.5rem',
          fontWeight: 700,
          lineHeight: '2rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.375rem',
          fontWeight: 700,
          lineHeight: '1.75rem',
          letterSpacing: '0',
        },
      },
    },
    text: {
      lead: {
        desktop: {
          fontSize: '1.25rem',
          fontWeight: 300,
          lineHeight: '1.75rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1.125rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
      paragraph: {
        desktop: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
      meta: {
        desktop: {
          fontSize: '0.875rem',
          fontWeight: 300,
          lineHeight: '1.125rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '0.875rem',
          fontWeight: 300,
          lineHeight: '1.125rem',
          letterSpacing: '0',
        },
      },
      link: {
        desktop: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
        mobile: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '1.625rem',
          letterSpacing: '0',
        },
      },
    },
  },
  margin: {
    extrasmall: '0.5rem',
    small: '1rem',
    default: '1.5rem',
    large: '2rem',
    big: '4rem',
  },
  padding: {
    extrasmall: '0.5rem',
    small: '1rem',
    default: '1.5rem',
    large: '2rem',
    big: '4rem',
  },
  palette: {
    background: {
      header: '#f1fcff',
      footer: '#1F2937',
    },
    link: {
      primary: '#8A2163',
    },
    button: {
      primary: {
        background: '#292929',
        border: '#292929',
        text: '#FFFFFF',
      },
      secondary: {
        background: '#EFCD00',
        border: '#EFCD00',
        text: '#292929',
      },
      tertiary: {
        background: 'transparent',
        border: '#292929',
        text: '#292929',
      },
      danger: {
        background: '#E22F2F',
        border: 'none',
        text: '#FFFFFF',
      },
    },
    default: {
      primary: '#981858',
      secondary: '#F6C500',
      tertiary: '#CCD441',
      white: '#ffffff',
      greyLight: '#f8f8f8',
      grey: '#d8d8d8',
      greyDark: '#889396',
      black: '#292929',
      error: '#E30613',
      success: '#00ff00',
      warning: '#ffff00',
    },
  },
}
