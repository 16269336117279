import parse from 'html-react-parser'
import React, { PropsWithChildren } from 'react'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import IconCircleCheck from '@components/atoms/Icons/IconCircleCheck'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import UnorderedList from '@components/atoms/UnorderedList'

import { formatEuro } from '@helper/currency'
import { parseOptions } from '@helper/parseOptions'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Contract as ContractType } from '@definitions/types/symfonyTypesd'

import {
  BoxBody,
  BoxBodyHeadline,
  BoxHead,
  BoxHeadHeadline,
  Content,
  Root,
  Stoerer,
  StyledBox,
  StyledButton,
  StyledListItem,
} from './Contract.styles'

export interface Affiliation {
  id: number
}

export interface Cost {
  id: number
}
export interface ContractCols {
  xs: number
  sm: number
  md: number
  lg: number
}

interface Props extends PropsWithClassName {
  contract: ContractType
  handleSelectContract?: (contract: ContractType) => void
  button?: boolean
  cols: ContractCols
}

const Contract: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { contract, handleSelectContract, button, cols } = props

  const handleClick = (contract: ContractType) => {
    if (handleSelectContract !== undefined) {
      handleSelectContract(contract)
    }
  }

  const savings: number =
    100 - Math.round((contract.weeklyFee / contract.insteadPrice) * 100)
  return (
    <Root
      className={props.className}
      dataTestId={'contract-root'}
      xs={cols.xs}
      sm={cols.sm}
      md={cols.md}
      lg={cols.lg}
    >
      <StyledBox>
        {contract.insteadPrice > 0 && (
          <Stoerer>
            <b>{savings}%</b>
            <br />
            sparen
          </Stoerer>
        )}
        <Content>
          <BoxHead>
            <BoxHeadHeadline importance={HeadlineImportances.h5}>
              {contract.title}
            </BoxHeadHeadline>
          </BoxHead>
          <BoxBody button={button ? button : false}>
            <BoxBodyHeadline importance={HeadlineImportances.h4}>
              {formatEuro(contract.weeklyFee)}/Woche
            </BoxBodyHeadline>
            {contract.insteadPrice > 0 && (
              <Text variant={TextVariants.lead}>
                statt {formatEuro(contract.insteadPrice)}/Woche
              </Text>
            )}
            {contract.conditions && (
              <>{parse(contract.conditions, parseOptions)}</>
            )}
            <UnorderedList>
              <StyledListItem icon={<IconCircleCheck active />}>
                {`${contract.duration}`}
              </StyledListItem>
              {contract.weeklyFeeDescription && (
                <StyledListItem icon={<IconCircleCheck active />}>
                  {`${contract.weeklyFeeDescription}`}
                </StyledListItem>
              )}
            </UnorderedList>
          </BoxBody>
        </Content>
        {button && (
          <StyledButton
            disabled={false}
            buttonType={ButtonTypes.BUTTON}
            modifier={ButtonModifier.PRIMARY}
            iconAlign={IconAligns.RIGHT}
            icon={<IconArrowRight />}
            loading={false}
            onClick={() => handleClick(contract)}
          >
            Auswählen
          </StyledButton>
        )}
      </StyledBox>
    </Root>
  )
}

export { Contract }
