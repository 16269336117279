import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { ButtonModifier } from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { CtaLeftAlignStoryblok } from '@definitions/types/storyblokTypes'

import {
  ContentContainer,
  Root,
  StyledBox,
  StyledHeadline,
  StyledLinkButton,
} from './StoryblokCtaLeftAlign.styles'

interface Props extends PropsWithClassName {
  blok: CtaLeftAlignStoryblok
}

const StoryblokCtaLeftAlign: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { image, headline, button, content } = blok

  const theme: any = useTheme()
  const url = getUrlFromLink(blok.link, theme)
  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-cta-left-align-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          {url && (
            <StyledBox backgroundImage={image.filename}>
              <StyledHeadline importance={HeadlineImportances.h3}>
                {headline}
              </StyledHeadline>
              <ContentContainer>
                <StoryblokRichText
                  content={content}
                  options={{ defaultColor: 'white' }}
                />
              </ContentContainer>
              <StyledLinkButton
                label={button}
                href={url}
                loading={false}
                modifier={
                  blok.buttonStyle
                    ? (blok.buttonStyle as ButtonModifier)
                    : ButtonModifier.PRIMARY
                }
                disabled={false}
              >
                {button}
              </StyledLinkButton>
            </StyledBox>
          )}
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokCtaLeftAlign }
