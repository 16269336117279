import { useQuery } from '@apollo/client'
import { GET_CATEGORIES } from '@graphql/queries/getCategories'
import React from 'react'

import { Category, CategoryEdge } from '@definitions/types/symfonyTypesd'

export interface GroupedCategory {
  parent: Category
  children: Category[]
}

export const useCategories = (
  service: boolean,
  affiliationNames: string[] = []
): {
  loading: boolean
  error: any
  data: Category[]
  groupedData: GroupedCategory[]
} => {
  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    variables: { service },
  })

  const filteredData = React.useMemo(() => {
    if (!data || !data.categories) {
      return []
    }

    const filteredCategories = data.categories.edges.filter(
      (edge: CategoryEdge) => {
        if (!edge.node) return false
        const node: Category = edge.node
        const affiliationName = node.affiliation?.name
        return affiliationName
          ? affiliationNames.includes(affiliationName)
          : false
      }
    )

    const groupedCategories = filteredCategories.reduce(
      (acc: Record<string, GroupedCategory>, { node }: { node: Category }) => {
        if (!node || !node.parent) return acc

        const parentName = node.parent.name
        if (!acc[parentName]) {
          acc[parentName] = { parent: node.parent, children: [] }
        }
        acc[parentName].children.push(node)
        return acc
      },
      {} as Record<string, GroupedCategory>
    )

    return Object.values(groupedCategories) as GroupedCategory[]
  }, [data, affiliationNames])
  return { loading, error, data: data, groupedData: filteredData }
}
