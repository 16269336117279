import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconPlay.styles'

interface Props extends PropsWithClassName {}

const IconPlay: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      width="41"
      height="41"
      fill="none"
      className={props.className}
      data-testid={'icon-play-root'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89367 5.8586C5.84982 5.98771 5.82715 6.12396 5.82715 6.26144V35.4114C5.82715 36.1018 6.38654 36.6614 7.07715 36.6614C7.28341 36.6614 7.4869 36.6104 7.66851 36.5129L34.8736 21.9379C35.4816 21.6123 35.7105 20.8538 35.3849 20.2474C35.267 20.0284 35.0888 19.8501 34.8735 19.7349L7.66745 5.15937C7.11047 4.86028 6.42755 5.02758 6.06558 5.52675L5.97515 5.67101L5.89367 5.8586Z"
        fill="white"
      />
    </Root>
  )
}

export { IconPlay }
