import React from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokBulletListItem.styles'

interface Props extends PropsWithClassName {
  children: any
  index?: number
}

const StoryblokBulletListItem = (props: Props) => {
  const { children, index } = props

  return (
    <Root
      className={props.className}
      dataTestId={'storyblok-bullet-list-item-root'}
    >
      {children}
    </Root>
  )
}

export { StoryblokBulletListItem }
