import { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse'
import { useQuery } from '@apollo/client'
import {
  GET_CONTRACT,
  GET_CONTRACTS_BY_STUDIO,
} from '@graphql/queries/getContracts'
import useGoogleTracking from '@hooks/useGoogleTracking'
import useStudioSearch from '@hooks/useStudioSearch'
import useUserCentrics from '@hooks/useUserCentrics'
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import Box from '@components/atoms/Box'
import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import Contracts from '@components/molecules/Contracts'
import FormUserData from '@components/molecules/Forms/FormUserData'
import { FormUserDataType } from '@components/molecules/Forms/FormUserData/FormUserData'
import GoogleMap from '@components/molecules/GoogleMap'
import Payment from '@components/molecules/Payment'
import { FormPaymentType } from '@components/molecules/Payment/Payment'
import StudioFinderPagination from '@components/molecules/StudioFinder/StudioFinderPagination'
import StudioFinderSearchfield from '@components/molecules/StudioFinder/StudioFinderSearchfield'

import { useUserData } from '@helper/context/UserDataContext'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { StudioWithDistance } from '@definitions/customTypes/StudioWithDistance'
import { Contract, Studio } from '@definitions/types/symfonyTypesd'

import useHandleRegistration from '../../../hooks/useHandleRegistration'
import {
  Map,
  Pagination,
  Root,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  StyledHeadline,
} from './StudioFinder.styles'

export type StepsType = 1 | 2 | 3 | 4 | 5

export interface OpeningHour {
  id: string
  weekend: string
  saturday: string
  sundayAndPublicHoliday: string
  lastEntrance: string
  title: string
}

interface Props extends PropsWithClassName {
  studio?: Studio
  studios?: Studio[]
  contract?: Contract
  contracts?: Contract[]
  formData?: FormUserDataType
}

const StudioFinder: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { userData, setUserData } = useUserData()
  const [step, setStep] = useState<StepsType>(1)
  const [studio, setStudio] = useState<Studio | undefined>(undefined)
  const [contract, setContract] = useState<Contract | undefined>(undefined)
  const [newContractId, setNewContractId] = useState<string | undefined>(
    undefined
  )
  const [formData, setFormData] = useState<FormUserDataType | undefined>(
    undefined
  )
  const [studioFinderLoading, setStudioFinderLoading] = useState<boolean>(true)

  const { consentGoogleMaps, consentPaypal } = useUserCentrics()

  const {
    trackAddPaymentInfo,
    trackPurchase,
    trackAddToCart,
    trackStudioSelection,
    trackViewContractList,
    trackAddShippingInfo,
  } = useGoogleTracking()

  const theme: any = useTheme()

  const {
    handleSearch,
    loadingStudios,
    studioArray,
    errorStudios,
    foundPlace,
  } = useStudioSearch(null)

  //set new contract set by voucher
  useQuery(GET_CONTRACT, {
    skip: newContractId === undefined,
    variables: {
      id: newContractId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.contract) {
        setContract(data.contract)
      }
    },
  })

  const { data: dataStandardContracts } = useQuery(GET_CONTRACTS_BY_STUDIO, {
    fetchPolicy: 'network-only',
    skip: !studio,
    variables: {
      containsStudio: [studio?.id ?? ''],
      affiliation: theme.key,
    },
  })

  const {
    handleRegistration,
    handleRegistrationError,
    error: registrationError,
  } = useHandleRegistration(
    (transactionId) => {
      if (contract && studio) {
        trackPurchase(
          contract,
          studio,
          formData?.gutschein ?? '',
          transactionId,
          formData?.email ?? 'unknown email'
        )
      }

      setStep(5)
      setStudio(undefined)
      setContract(undefined)
      setFormData(undefined)
      setNewContractId(undefined)
      scrollToTarget()
    },
    () => {
      console.info('payment failed')
    }
  )

  const paginationRef = useRef<HTMLDivElement>(null)

  const handleSelectContract = (contract: Contract) => {
    setUserData({
      studio: userData.studio,
      contract: contract,
      formData: userData.formData,
    })
    setContract(contract)
    setStep(3)
    scrollToTarget()
  }

  const handleSelectStudio = (studio: Studio) => {
    setUserData({
      studio: studio,
      contract: userData.contract,
      formData: userData.formData,
    })
    setStudio(studio)
    setStep(2)
    scrollToTarget()
  }

  const handleSubmitForm = (
    data: FormUserDataType,
    currentNewContractId: string | null
  ): void => {
    if (currentNewContractId) {
      setNewContractId(currentNewContractId)
    }
    setUserData({
      studio: userData.studio,
      contract: userData.contract,
      formData: data,
    })
    setFormData(data)
    setStep(4)
    scrollToTarget()
  }

  const handlePayment = async (
    data: FormPaymentType,
    paymentSession: CreateCheckoutSessionResponse | null
  ): Promise<void> => {
    await handleRegistration(
      formData as FormUserDataType,
      data,
      studio as Studio,
      contract as Contract,
      paymentSession
    )
  }

  const handlePaginationClick = (newStep: StepsType): void => {
    if (step === 5) {
      return
    }
    if (newStep === 1) {
      if (!studio) {
        return
      }
    }
    if (newStep === 2) {
      if (!studio) {
        return
      }
    }
    if (newStep === 3) {
      if (!contract || !studio) {
        return
      }
    }
    if (newStep === 4) {
      if (!contract || !studio || !formData) {
        return
      }
    }
    setStep(newStep)
    scrollToTarget()
  }

  const scrollToTarget = () => {
    if (paginationRef.current) {
      paginationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const standardContractArray: Contract[] = useMemo(() => {
    return dataStandardContracts
      ? dataStandardContracts.contracts.edges.map(
          (edge: { node: Contract }) => edge.node
        )
      : []
  }, [dataStandardContracts])

  useEffect(() => {
    switch (step) {
      case 1:
        trackStudioSelection()
        break
      case 2:
        if (standardContractArray && standardContractArray.length > 0) {
          trackViewContractList(standardContractArray)
        }
        break
      case 3:
        if (contract && studio) {
          trackAddToCart(contract, studio)
        }
        break
      case 4:
        if (contract && studio) {
          trackAddShippingInfo(contract, studio)
        }
        break
      case 5:
        if (contract && studio) {
          trackAddPaymentInfo(contract, studio)
        }
        return
    }
  }, [
    step,
    standardContractArray,
    contract,
    studio,
    trackStudioSelection,
    trackViewContractList,
    trackAddToCart,
    trackAddShippingInfo,
    trackAddPaymentInfo,
  ])

  useEffect(() => {
    if (userData.studio) {
      setStudio(userData.studio ?? props.studio)
    }
    if (userData.contract) {
      setContract(userData.contract ?? props.contract)
    }
    if (userData.formData) {
      setFormData(userData.formData ?? props.formData)
    }
    if (userData.studio && !userData.contract && !userData.formData) {
      setStep(2)
    }
    if (userData.studio && userData.contract && !userData.formData) {
      setStep(3)
    }
    if (userData.studio && userData.contract && userData.formData) {
      setStep(4)
    }
    setStudioFinderLoading(false)
  }, [userData, props.contract, props.studio, props.formData])

  const studiosWithContracts = studioArray.filter(
    (studio: StudioWithDistance) =>
      studio.contracts &&
      studio.contracts?.edges?.length !== undefined &&
      studio.contracts?.edges?.length > 0
  )

  return (
    <Root className={props.className} data-testid={'studio-finder-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledHeadline importance={HeadlineImportances.h1}>
            {theme.key.toUpperCase()} Clubmitglied werden
          </StyledHeadline>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Pagination ref={paginationRef}>
            <StudioFinderPagination
              step={step}
              handlePaginationClick={(step: StepsType) =>
                handlePaginationClick(step)
              }
            />
          </Pagination>
        </Col>
      </Row>
      <Step1>
        {step === 1 && !studioFinderLoading && consentGoogleMaps && (
          <Step1>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Map>
                  <StyledHeadline importance={HeadlineImportances.h2}>
                    Wähle dein Stamm-Studio.
                  </StyledHeadline>
                  <StyledHeadline importance={HeadlineImportances.h4}>
                    Die Club-Mitgliedschaft ist in allen teilnehmenden Studios
                    gültig.
                  </StyledHeadline>
                  <StudioFinderSearchfield
                    onChange={(term) => handleSearch(term)}
                    setStudio={(studio: Studio) => handleSelectStudio(studio)}
                    studios={props.studios ?? studiosWithContracts}
                  />
                  <GoogleMap
                    loading={loadingStudios}
                    error={errorStudios}
                    setStudio={handleSelectStudio}
                    studios={props.studios ?? studiosWithContracts}
                    center={foundPlace ?? undefined}
                  />
                </Map>
              </Col>
            </Row>
          </Step1>
        )}
        {step === 1 && !studioFinderLoading && !consentGoogleMaps && (
          <Step1>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Map>
                  <StyledHeadline importance={HeadlineImportances.h2}>
                    Wähle dein Stamm-Studio.
                    <br />
                    Die Club-Mitgliedschaft ist in allen teilnehmenden Studios
                    gültig.
                  </StyledHeadline>
                  <StudioFinderSearchfield
                    onChange={(term) => handleSearch(term)}
                    setStudio={(studio: Studio) => handleSelectStudio(studio)}
                    studios={props.studios ?? studioArray}
                  />
                </Map>
              </Col>
            </Row>
          </Step1>
        )}
        {step === 2 && (
          <Step2>
            <StyledHeadline importance={HeadlineImportances.h2}>
              Mitgliedschaft
            </StyledHeadline>
            <Contracts
              handleSelectContract={handleSelectContract}
              contracts={props.contracts ?? standardContractArray}
              studio={studio}
              showStudioButton={true}
            />
          </Step2>
        )}
        {step === 3 && (
          <Step3>
            <StyledHeadline importance={HeadlineImportances.h2}>
              Persönliche Daten
            </StyledHeadline>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormUserData
                  contract={contract as Contract}
                  formData={formData}
                  studio={studio}
                  handleSubmitForm={handleSubmitForm}
                />
              </Col>
            </Row>
          </Step3>
        )}
        {step === 4 && consentPaypal && (
          <Step4>
            <StyledHeadline importance={HeadlineImportances.h2}>
              Zahlung
            </StyledHeadline>
            {studio && contract && formData && (
              <Payment
                handlePayment={(data, paymentSession) =>
                  handlePayment(data, paymentSession)
                }
                studio={studio}
                contract={contract}
                formData={formData}
                registrationError={registrationError}
              />
            )}
          </Step4>
        )}
        {step === 5 && (
          <Step5>
            {theme.key === 'sunpoint' && (
              <Row>
                <Col xs={12} sm={12} md={10} mdOffset={1} lg={10} lgOffset={1}>
                  <StyledHeadline importance={HeadlineImportances.h2}>
                    Willkommen im SUNPOINT Club
                  </StyledHeadline>
                  <Box background={BoxBackgrounds.PRIMARY}>
                    <Text variant={TextVariants.paragraph}>
                      Vielen Dank für Deine Anfrage für den Abschluss einer
                      SUNPOINT Clubmitgliedschaft in einem unserer SUNPOINT
                      Beauty- & Tanning-Studios!
                    </Text>
                    <Text variant={TextVariants.paragraph}>
                      Als Mitglied sonnst Du exklusiv und günstig, auf Wunsch
                      täglich, Deinem Hauttyp entsprechend, jeweils bis zu 30
                      Minuten auf Deiner Lieblingssonnenbank.
                    </Text>
                    <Text variant={TextVariants.paragraph}>
                      Bei weiteren Fragen kannst Du Dich selbstverständlich bei
                      unserem Club Service melden.
                    </Text>
                    <Text variant={TextVariants.paragraph}>
                      Wir freuen uns auf Deinen Besuch bei SUNPOINT!
                    </Text>
                  </Box>
                </Col>
              </Row>
            )}
            {theme.key === 'wellmaxx' && (
              <Row>
                <Col xs={12} sm={12} md={10} mdOffset={1} lg={10} lgOffset={1}>
                  <StyledHeadline importance={HeadlineImportances.h2}>
                    Willkommen im WELLMAXX Club!
                  </StyledHeadline>
                  <Box background={BoxBackgrounds.PRIMARY}>
                    <Text variant={TextVariants.paragraph}>
                      Vielen Dank für Deine Anfrage für den Abschluss einer
                      WELLMAXX Clubmitgliedschaft in einem unserer WELLMAXX
                      bodyforming Studios!
                    </Text>
                    <Text variant={TextVariants.paragraph}>
                      Als Mitglied im WELLMAXX bodystyle Club kannst Du 2 x pro
                      Woche bodystyle und 2 x pro Woche bodyshape (nur in
                      teilnehmenden Studios) nutzen. Im WELLMAXX bodyslim Club
                      kannst Du zusätzlich 2 x pro Monat WELLMAXX bodyslim V2
                      nutzen.
                    </Text>
                    <Text variant={TextVariants.paragraph}>
                      Bei weiteren Fragen kannst Du Dich selbstverständlich bei
                      unserem Club Service melden. Wir freuen uns auf Deinen
                      Besuch im WELLMAXX bodyforming Studio bei SUNPOINT!
                    </Text>
                  </Box>
                </Col>
              </Row>
            )}
          </Step5>
        )}
      </Step1>
    </Root>
  )
}

export { StudioFinder }
