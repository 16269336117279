import React, { createContext, ReactNode, useContext, useState } from 'react'

import { FormUserDataType } from '@components/molecules/Forms/FormUserData/FormUserData'

import { Contract, Studio } from '@definitions/types/symfonyTypesd'

export interface UserData {
  contract: Contract | null
  studio: Studio | null
  formData: FormUserDataType | null
}
interface UserDataContextProps {
  userData: UserData
  setUserData: React.Dispatch<React.SetStateAction<UserData>>
}

const defaultUserData: UserData = {
  contract: null,
  studio: null,
  formData: null,
}

const UserDataContext = createContext<UserDataContextProps>({
  userData: defaultUserData,
  setUserData: () => {},
})

export const useUserData = () => useContext(UserDataContext)

export const UserDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData>(defaultUserData)

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  )
}
