import styled from 'styled-components'

import InputLabel from '../Form/InputLabel'

export const Root = styled.div``

export const StyledRemoveButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-top: -0.25rem;
  color: ${(props) => props.color ?? props.theme.palette.default.tertiary};
  text-decoration: underline;
  cursor: pointer;
`
export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 0;
`
