import { BannerStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokBanner } from './StoryblokBanner'

export const dummyBannerBlok: BannerStoryblok = {
  _uid: '3266c231-d661-4e70-8f23-7070127cfd6b',
  buttons: [
    {
      _uid: '5f13f8ff-9af1-43c0-967f-5cbe81ec9e88',
      link: {
        id: '',
        url: '',
        linktype: 'story',
        fieldtype: 'multilink',
        cached_url: '',
      },
      size: 'medium',
      label: 'Button label',
      style: 'default',
      component: 'button',
      button_color: 'primary',
      _editable:
        '<!--#storyblok#{"name": "button", "space": "228071", "uid": "5f13f8ff-9af1-43c0-967f-5cbe81ec9e88", "id": "300939517"}-->',
    },
  ],
  headline: 'Banner Headline',
  component: 'banner',
  full_width: false,
  text_color: 'dark',
  subheadline:
    'Ex eiusmod ut esse nostrud occaecat dolore consequat Lorem tempor.',
  background_blur: {
    value: 0,
    plugin: 'storyblok-slider',
  },
  pattern_overlay: false,
  background_image: {
    id: 8908003,
    alt: '',
    name: '',
    focus: '',
    title: '',
    filename:
      'https://a.storyblok.com/f/228071/1920x1080/a17bd8776b/development.jpeg',
    copyright: '',
    fieldtype: 'asset',
    is_external_url: false,
  },
  background_brightness: {
    value: 0,
    plugin: 'storyblok-slider',
  },
  _editable:
    '<!--#storyblok#{"name": "banner", "space": "228071", "uid": "3266c231-d661-4e70-8f23-7070127cfd6b", "id": "300939517"}-->',
}

export default StoryblokBanner
