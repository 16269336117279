import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Icon, Root } from './ListItem.styles'

interface Props extends PropsWithClassName {
  children: string | any
  icon?: React.ReactNode
  onClick?: () => void
}

const ListItem: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, icon, onClick, dataTestId } = props
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  const hasIcon = props.icon !== undefined && props.icon !== null
  return (
    <Root
      onClick={() => handleClick()}
      className={props.className}
      data-testid={dataTestId ?? 'list-item-root'}
      hasIcon={hasIcon}
    >
      {hasIcon && <Icon>{icon}</Icon>}
      {children}
    </Root>
  )
}

export { ListItem }
