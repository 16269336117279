import styled from 'styled-components'

import Box from '@components/atoms/Box'

export const Root = styled(Box)`
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    height: 100%;
  }
`
