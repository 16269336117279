import { StoryblokComponent } from '@storyblok/react'
import React, { PropsWithChildren, useRef } from 'react'
import SwiperCore, {
  A11y,
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperRef } from 'swiper/react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowLeft from '@components/atoms/Icons/IconArrowLeft'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageSliderSlideStoryblok } from '@definitions/types/storyblokTypes'

import {
  Next,
  Prev,
  Root,
  StyledSwiper,
  StyledSwiperSlide,
} from './ImageSlider.styles'

interface Props extends PropsWithClassName {
  data: ImageSliderSlideStoryblok[]
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow])

const ImageSlider: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { data } = props

  const swiperRef = useRef<SwiperRef | null>(null)

  const handleSlideClick = (index: number) => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) {
      return
    }
    swiper.slideToLoop(index)
  }

  const handleClickPrev = (): void => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) {
      return
    }
    if (swiper.realIndex - 1 >= 0) {
      swiper.slideToLoop(swiper.realIndex - 1)
    } else {
      swiper.slideToLoop(swiper.slides.length - 1)
    }
  }

  const handleClickNext = (): void => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) {
      return
    }
    if (swiper.realIndex + 1 === swiper.slides.length) {
      swiper.slideToLoop(0)
    } else {
      swiper.slideToLoop(swiper.realIndex + 1)
    }
  }

  if (data === undefined) {
    return <>No data</>
  }

  return (
    <Root className={props.className} data-testid={'image-slider-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledSwiper
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 800,
              modifier: 2,
              slideShadows: false,
            }}
            height={500}
            slidesPerView={'auto'}
            centeredSlides
            effect="coverflow"
            speed={600}
            pagination={{ clickable: true }}
            ref={swiperRef}
          >
            {data.map((image, index) => {
              return (
                <StyledSwiperSlide
                  onClick={() => handleSlideClick(index)}
                  key={'image-slider-' + index}
                  style={{
                    background: `url(${image.Image.filename}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: '50% 50%',
                  }}
                  aria-description={image.Image.alt}
                />
              )
            })}
            <Prev onClick={() => handleClickPrev()}>
              <IconArrowLeft />
            </Prev>
            <Next onClick={() => handleClickNext()}>
              <IconArrowRight />
            </Next>
          </StyledSwiper>
        </Col>
      </Row>
    </Root>
  )
}

export { ImageSlider }
