import React, { PropsWithChildren } from 'react'

import { shouldUseWhiteText } from '@helper/color'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconCircleX.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconCircleX: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'

  return (
    <Root
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'icon-circle-x-root'}
    >
      <ellipse cx="10.3648" cy="10" rx="10.0313" ry="10" fill={currentColor} />
      <path
        d="M15.3908 13.1165C13.911 11.5081 12.3833 10.3998 10.8383 9.08143C11.4849 8.38793 12.1366 7.6988 12.805 7.02588C13.2848 6.54255 13.75 6.86061 14.1807 6.33487C14.4586 5.99623 14.9289 5.33765 14.6043 4.88488C14.4145 4.62045 14.0918 4.60735 13.8855 4.79071C13.8765 4.79632 13.8676 4.80256 13.858 4.80755C13.7851 4.83124 13.7276 4.8855 13.6695 4.93352C13.6407 4.95348 13.5666 5.00711 13.5532 5.01647C13.4516 5.08881 13.359 5.16303 13.2676 5.2466C12.9756 5.51414 12.6906 5.78855 12.3954 6.05298C11.8191 6.56874 11.2613 7.11007 10.7124 7.65452C10.4728 7.89213 10.2338 8.11789 9.99486 8.33679C9.75589 8.11789 9.51629 7.89213 9.27668 7.65452C8.72782 7.11007 8.17065 6.56936 7.59431 6.05298C7.29911 5.78855 7.01414 5.51414 6.72214 5.2466C6.63076 5.16303 6.53812 5.08881 6.43652 5.01647C6.4231 5.00711 6.34899 4.95348 6.32023 4.93352C6.26209 4.8855 6.20458 4.83124 6.13174 4.80755C6.12216 4.80256 6.11321 4.79632 6.10427 4.79071C5.89788 4.60673 5.57521 4.61983 5.38544 4.88488C5.06085 5.33765 5.53112 5.99623 5.80907 6.33487C6.24036 6.86061 6.70488 6.54255 7.18474 7.02588C7.85309 7.6988 8.50482 8.38856 9.15144 9.08143C7.60645 10.3998 6.07871 11.5081 4.59889 13.1165C4.26152 13.4832 4.22638 14.0401 4.59889 14.4037C4.93817 14.7348 5.58607 14.7741 5.91769 14.4037C6.82692 13.3871 7.75277 12.3849 8.68884 11.3914C9.12652 10.9262 9.56165 10.4578 9.99614 9.98947C10.4306 10.4578 10.8651 10.9262 11.3034 11.3914C12.2389 12.3849 13.1647 13.3871 14.0746 14.4037C14.4062 14.7741 15.0541 14.7348 15.3934 14.4037C15.7659 14.0401 15.7308 13.4832 15.3934 13.1165H15.3908Z"
        fill={shouldUseWhiteText(currentColor) ? '#fff' : '#292929'}
      />
    </Root>
  )
}

export { IconCircleX }
