import { ImageSliderSlideStoryblok } from '@definitions/types/storyblokTypes'

import { ImageSlider } from './ImageSlider'

export default ImageSlider

export const imageSliderTestData: ImageSliderSlideStoryblok[] = [
  {
    _uid: '74d9214c-e358-45ba-8b36-e8aa82611ca5',
    Image: {
      id: 13912736,
      alt: 'dummy_slider_769x466_04',
      name: '',
      focus: '',
      title: 'dummy_slider_769x466_04',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/769x466/64824f0b67/dummy_slider_769x466_04.jpeg',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_private: '',
      is_external_url: false,
    },
    component: 'image-slider-slide',
    _editable:
      '<!--#storyblok#{"name": "image-slider-slide", "space": "235453", "uid": "74d9214c-e358-45ba-8b36-e8aa82611ca5", "id": "424177377"}-->',
  },
  {
    _uid: 'bff10506-f6ca-4d5d-b32c-8d83e727c80a',
    Image: {
      id: 13912735,
      alt: 'dummy_slider_769x466_06',
      name: '',
      focus: '',
      title: 'dummy_slider_769x466_06',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/769x466/f2c3e78d93/dummy_slider_769x466_06.jpeg',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_private: '',
      is_external_url: false,
    },
    component: 'image-slider-slide',
    _editable:
      '<!--#storyblok#{"name": "image-slider-slide", "space": "235453", "uid": "bff10506-f6ca-4d5d-b32c-8d83e727c80a", "id": "424177377"}-->',
  },
  {
    _uid: '8eaa9331-3393-455a-86df-046f3336ff51',
    Image: {
      id: 13912734,
      alt: 'dummy_slider_769x466_05',
      name: '',
      focus: '',
      title: 'dummy_slider_769x466_05',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/769x466/f6b5705fc8/dummy_slider_769x466_05.jpeg',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_private: '',
      is_external_url: false,
    },
    component: 'image-slider-slide',
    _editable:
      '<!--#storyblok#{"name": "image-slider-slide", "space": "235453", "uid": "8eaa9331-3393-455a-86df-046f3336ff51", "id": "424177377"}-->',
  },
  {
    _uid: 'f8467eb3-223b-473e-953a-9745adde1726',
    Image: {
      id: 13912326,
      alt: 'dummy_slider_769x466_01',
      name: '',
      focus: '',
      title: 'dummy_slider_769x466_01',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/769x466/dc9bf8d3e3/dummy_slider_769x466_01.jpeg',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_private: '',
      is_external_url: false,
    },
    component: 'image-slider-slide',
    _editable:
      '<!--#storyblok#{"name": "image-slider-slide", "space": "235453", "uid": "f8467eb3-223b-473e-953a-9745adde1726", "id": "424177377"}-->',
  },
]
