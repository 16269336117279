import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { BoxBackgrounds } from '../Box/Box'
import { HeadlineImportances } from '../Headline/Headline'
import IconSun from '../Icons/IconSun'
import ListItem from '../ListItem'
import {
  MessageText,
  Root,
  StyledHeadline,
  StyledUnorderedList,
} from './Message.styles'

export enum MessageTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface Props extends PropsWithClassName {
  headline: string
  type: MessageTypes
  icon?: React.ReactNode
  items?: string[]
}

const Message = (props: PropsWithChildren<Props>): React.ReactElement => {
  const { headline, items, type, icon, children } = props

  const getBackground = (type: MessageTypes) => {
    switch (type) {
      case MessageTypes.ERROR:
        return BoxBackgrounds.ERROR
      case MessageTypes.INFO:
        return BoxBackgrounds.WHITE
      case MessageTypes.SUCCESS:
        return BoxBackgrounds.SUCCESS
      case MessageTypes.WARNING:
        return BoxBackgrounds.WARNING
      default:
        return BoxBackgrounds.WHITE
    }
  }
  return (
    <Root
      icon={icon ?? <IconSun />}
      background={getBackground(type)}
      className={props.className}
      dataTestId={'Message-root'}
    >
      <MessageText>
        <StyledHeadline importance={HeadlineImportances.h5}>
          {headline}
        </StyledHeadline>
        {items && items.length > 0 && (
          <StyledUnorderedList>
            {items?.map((item, index) => {
              return <ListItem key={item + index}>{item}</ListItem>
            })}
          </StyledUnorderedList>
        )}
        {children}
      </MessageText>
    </Root>
  )
}

export { Message }
