import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query GetCategories($service: Boolean) {
    categories(service: $service) {
      edges {
        node {
          id
          name
          link
          service
          affiliation {
            id
            name
          }
          parent {
            id
            name
          }
        }
      }
    }
  }
`
