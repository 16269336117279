import styled from 'styled-components'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledIframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
`
