import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { PersonalizedSectionStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokPersonalizedSection.styles'

interface Props extends PropsWithClassName {
  blok: PersonalizedSectionStoryblok
}

const StoryblokPersonalizedSection = (props: Props) => {
  const { blok } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-testid="storyblok-personalized-section-root"
    >
      {blok.preview === 'new_visitor' &&
        blok.new_visitor_blocks?.map((newVisitorBlock) => {
          return (
            <StoryblokComponent
              key={newVisitorBlock._uid}
              blok={newVisitorBlock}
            />
          )
        })}
      {blok.preview === 'returning_visitor' &&
        blok.returning_visitor_blocks?.map((returningVisitorBlock) => {
          return (
            <StoryblokComponent
              key={returningVisitorBlock._uid}
              blok={returningVisitorBlock}
            />
          )
        })}
    </Root>
  )
}

export { StoryblokPersonalizedSection }
