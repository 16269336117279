import { useMutation } from '@apollo/client'
import { CREATE_SEND_MAIL_MUTATION } from '@graphql/mutations/createSendMail'
import { useCallback, useState } from 'react'

import { CreateAttachmentInput } from '@definitions/types/symfonyTypesd'

export default function useSendMail(): {
  sendMail: (
    subject: string,
    variables: Record<string, any>,
    template: string,
    attachments: CreateAttachmentInput[],
    sender?: string,
    data?: any
  ) => Promise<void>
  sendFormDataMail: (
    subject: string,
    formData: any,
    template: string,
    attachments: CreateAttachmentInput[],
    sender?: string,
    data?: any
  ) => Promise<void>
  isLoading: boolean
  result: boolean | null
} {
  const [createSendMailMutation, { loading: isLoading }] = useMutation(
    CREATE_SEND_MAIL_MUTATION
  )

  const [result, setResult] = useState<boolean | null>(null)

  const sendMail = useCallback(
    async (
      subject: string,
      variables: Record<string, any>,
      template: string,
      attachments: CreateAttachmentInput[],
      sender?: string,
      data?: any
    ) => {
      try {
        const input: any = {
          subject,
          variables,
          template,
          attachments,
        }

        if (sender) {
          input.sender = sender
        }
        if (data) {
          input.data = data
        }

        await createSendMailMutation({
          onCompleted: (data) => {
            setResult(data.createSendMail.sendMail.success)
          },
          onError: (error) => {
            console.error(error)
            setResult(false)
          },
          variables: {
            input,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
    [createSendMailMutation]
  )

  const sendFormDataMail = useCallback(
    async (
      subject: string,
      formData: any,
      template: string,
      attachments: CreateAttachmentInput[],
      sender?: string,
      data?: any
    ) => {
      await sendMail(subject, { formData }, template, attachments, sender, data)
    },
    [sendMail]
  )

  return { sendMail, sendFormDataMail, isLoading, result }
}
