import { StoryblokImageTextBlock } from './StoryblokImageTextBlock'

export default StoryblokImageTextBlock

export const dummyImageTextBlockStoryblok = {
  _uid: 'b62e9688-2760-414d-9ed1-019060a9b053',
  image: {
    id: 10961639,
    alt: 'test',
    name: '',
    focus: '',
    title: 'test',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/384x289/5a6c981a2f/30-jahre-storer.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    is_external_url: false,
  },
  content: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            text: 'Seit über 30 Jahren steht SUNPOINT für ...',
            type: 'text',
            marks: [
              {
                type: 'textStyle',
                attrs: {
                  color: '',
                },
              },
            ],
          },
        ],
      },
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'Beste Beratung',
                    type: 'text',
                    marks: [
                      {
                        type: 'textStyle',
                        attrs: {
                          color: '',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'Exklusive Sonnenbänke',
                    type: 'text',
                    marks: [
                      {
                        type: 'textStyle',
                        attrs: {
                          color: '',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'Perfekter Service',
                    type: 'text',
                    marks: [
                      {
                        type: 'textStyle',
                        attrs: {
                          color: '',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  headline: 'Deutschlands Sonnenstudiokette Nr.1!',
  component: 'image-text-block',
  spaceBelow: false,
  imagesRight: false,
  secondaryImage: {
    id: null,
    alt: null,
    name: '',
    focus: null,
    title: null,
    source: null,
    filename: '',
    copyright: null,
    fieldtype: 'asset',
    meta_data: {},
  },
  blackBackground: false,
  _editable:
    '<!--#storyblok#{"name": "image-text-block", "space": "235453", "uid": "b62e9688-2760-414d-9ed1-019060a9b053", "id": "322787899"}-->',
}
