import { ImageWithOptionalTextStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokImageWithOptionalText } from './StoryblokImageWithOptionalText'

export default StoryblokImageWithOptionalText

export const dummyImageWithOptionalTextStoryblok: ImageWithOptionalTextStoryblok =
  {
    _uid: 'e4daf73b-71f4-46a3-8d25-0d401bf01c7c',
    image: {
      id: 9757937,
      alt: '',
      name: '',
      focus: '',
      title: '',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/1920x445/0868c8320d/filiale.jpg',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_external_url: false,
    },
    subline: 'subline asdsadsd asdsda asdsasd asdsa',
    headline: 'headline',
    component: 'image-with-optional-text',
    _editable:
      '<!--#storyblok#{"name": "image-with-optional-text", "space": "235453", "uid": "e4daf73b-71f4-46a3-8d25-0d401bf01c7c", "id": "322787899"}-->',
  }
