import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Box from '@components/atoms/Box'
import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import FormFranchise from '@components/molecules/Forms/FormFranchise'
import { FormFranchiseType } from '@components/molecules/Forms/FormFranchise/FormFranchise'
import { Root } from '@components/storyblok/molecules/Forms/StoryblokFranchiseForm/StoryblokFranchiseForm.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ContactFormStoryblok } from '@definitions/types/storyblokTypes'

interface Props extends PropsWithClassName {
  blok: ContactFormStoryblok
  userData?: FormFranchiseType
  dataTestId?: string
}

const StoryblokFranchiseForm: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok, userData, dataTestId } = props
  const { headline, subline } = blok

  const onSubmit = useCallback(async (data: object) => {}, [])

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={dataTestId ?? 'storyblok-franchise-form-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <BlockPreContent headline={headline} subline={subline} />
        </Col>
        <Col xs={12} sm={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
          <Box background={BoxBackgrounds.WHITE}>
            <FormFranchise
              formId="contact"
              userData={userData}
              handleSubmitForm={() => console.log('handleSubmitForm')}
            />
          </Box>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokFranchiseForm }
