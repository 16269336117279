import useGoogleTracking from '@hooks/useGoogleTracking'
import useStudioSearch from '@hooks/useStudioSearch'
import parse from 'html-react-parser'
import React, { PropsWithChildren, useRef, useState } from 'react'

import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import { Link } from '@components/atoms/Link/Link'
import { TextParagraph } from '@components/atoms/Text/Text.styles'
import FormApplication from '@components/molecules/Forms/FormApplication'
import StudioFinderSearchfield from '@components/molecules/StudioFinder/StudioFinderSearchfield'

import { parseOptions } from '@helper/parseOptions'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { JobApplicationStoryblok } from '@definitions/types/storyblokTypes'
import { Studio } from '@definitions/types/symfonyTypesd'

import { Root, StyledBox } from './StoryblokJobApplication.styles'

interface StoryblokHeroStartpageProps extends JobApplicationStoryblok {}

interface Props extends PropsWithClassName {
  blok: StoryblokHeroStartpageProps
}

interface StudioFinderSearchfieldWrappedProps {
  handleSelectStudio: (studio: Studio) => void
}

const StudioFinderSearchfieldWrapped = (
  props: StudioFinderSearchfieldWrappedProps
) => {
  const { handleSearch, studioArray } = useStudioSearch(null)

  return (
    <StudioFinderSearchfield
      onChange={(term) => handleSearch(term)}
      setStudio={(studio: Studio) => props.handleSelectStudio(studio)}
      studios={studioArray}
    />
  )
}

const StoryblokJobApplication: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const [studio, setStudio] = useState<Studio | undefined>(undefined)
  const headlineRef = useRef<HTMLDivElement>(null)

  const handleSelectStudio = (studio: Studio) => {
    setStudio(studio)
  }

  const handleSubmitted = () => {
    if (headlineRef.current) {
      headlineRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <Root
      className={props.className}
      data-testid={'storyblok-job-application-root'}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Headline importance={HeadlineImportances.h5}>
            Jetzt Bewerbungsformular ausfüllen
          </Headline>

          {!studio && (
            <>
              <TextParagraph>Schritt 1 von 2</TextParagraph>

              <TextParagraph>Wähle ein Studio aus</TextParagraph>
              <StudioFinderSearchfieldWrapped
                handleSelectStudio={handleSelectStudio}
              />
            </>
          )}

          {studio && (
            <>
              <TextParagraph>Schritt 2 von 2</TextParagraph>

              <StyledBox background={BoxBackgrounds.PRIMARY}>
                <Headline importance={HeadlineImportances.h5}>
                  Ausgewähltes Studio
                </Headline>
                <TextParagraph>{studio?.title}</TextParagraph>
                {parse(studio?.address ?? '', parseOptions)}
                <TextParagraph>Telefon: {studio?.phone}</TextParagraph>
                <Link href="#" onClick={() => setStudio(undefined)}>
                  Auswahl ändern
                </Link>
              </StyledBox>

              <FormApplication
                formId={'application'}
                jobName={blok.jobName ?? ''}
                studioName={studio?.title ?? ''}
                handleSubmitted={handleSubmitted}
              />
            </>
          )}
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokJobApplication }
