import styled from 'styled-components'

import { ButtonTypes } from '@components/atoms/Button/Button'
import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'
import StudioSearchBox from '@components/molecules/StudioSearchBox'

import { userSelectNone } from '@helper/mixins'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin-bottom: 100px;
  min-height: 650px;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    width: auto;
    min-height: 500px;
  }
`
export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface PictureProps {
  backgroundImage: string
}

export const Desktop = styled.div<PictureProps>`
  width: 100%;
  height: 100%;
  display: none;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
`
export const Mobile = styled.div<PictureProps>`
  width: 100%;
  height: 100%;
  display: flex;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.padding.default};
  padding-bottom: ${(props) => props.theme.padding.big};
  overflow: hidden;
`

export const StyledLinkButton = styled(LinkButton)`
  display: flex;
  justify-self: center;
  align-self: center;
  margin: 0 auto ${(props) => props.theme.margin.default} auto;
`

export const StyledHeadline = styled(Headline)`
  width: 90%;
  font-weight: 800;
  margin: 0 auto ${(props) => props.theme.margin.default} auto;
  color: ${(props) => props.theme.palette.default.white};
  text-shadow: 1px 1px 10px black;
  text-align: center;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    width: 80%;
  }
  ${userSelectNone()}
`

export const StyledStudioSearchBox = styled(StudioSearchBox)`
  position: absolute;
  width: 100%;
  bottom: -42px;
  left: 0;
  display: flex;
`

export const Content = styled.div`
  z-index: 30;
  position: absolute;
  width: 100%;
  bottom: 120px;
  left: 0;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    bottom: 60px;
  }
`
