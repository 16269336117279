import styled from 'styled-components'

import { shouldUseWhiteText } from '@helper/color'

interface IconCheckProps {
  active?: boolean
}

export const Root = styled.svg``
export const Ellipse = styled.ellipse<IconCheckProps>`
  fill: ${(props) =>
    props.active
      ? props.theme.palette.default.primary
      : props.theme.palette.default.grey};
`

export const Check = styled.path<IconCheckProps>`
  fill: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
