import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconCheckmark.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconCheckmark: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#000'
  const currentSize = props.size ?? '14'

  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={currentSize}
      height={currentSize}
      viewBox="0 0 14 14"
      fill="none"
      className={props.className}
      data-testid={'icon-checkmark-root'}
    >
      <path
        fill={currentColor}
        d="M13.217 1.366a.857.857 0 0 0 .199.233l-.018.018a.367.367 0 0 1-.006.006c-.083.129-.236.273-.46.432-.223.16-.399.289-.528.387a.87.87 0 0 0-.204.205.892.892 0 0 1-.205.206c-.136.105-.266.22-.39.342-.126.121-.241.25-.347.387-.12.113-.242.23-.363.347-.121.117-.242.233-.363.347a7.123 7.123 0 0 1-.647.757c-.235.24-.451.492-.648.758l-.635.888a46.246 46.246 0 0 0-1.817 2.735c-.158.259-.315.522-.47.791-.155.27-.302.534-.437.792-.054.099-.1.201-.137.307a.816.816 0 0 1-.159.273.336.336 0 0 1-.176.12.981.981 0 0 0-.21.097c-.114.076-.23.157-.352.245-.121.088-.242.173-.363.257a2.121 2.121 0 0 0-.33.302.715.715 0 0 1-.363.233v-.045a.265.265 0 0 1-.045-.148.506.506 0 0 0-.034-.171 4.924 4.924 0 0 0-.108-.37 10.396 10.396 0 0 0-.13-.371 13.39 13.39 0 0 0-.5-1.145c-.183-.369-.383-.72-.602-1.054a5.607 5.607 0 0 0-.392-.502c-.14-.16-.281-.311-.425-.456a1.733 1.733 0 0 0-.222-.183.391.391 0 0 1-.164-.227c.09-.068.172-.15.244-.245.071-.095.149-.177.232-.245.084-.06.165-.112.244-.154.08-.042.169-.085.267-.131a.348.348 0 0 0 .068-.051.435.435 0 0 1 .067-.052.909.909 0 0 1 .62.012c.185.075.335.155.448.239.326.235.57.556.732.962.163.407.324.815.483 1.225h.057c.159-.288.333-.57.522-.843.19-.273.382-.541.58-.803.196-.262.397-.522.601-.78.204-.259.405-.517.602-.775.105-.144.224-.281.357-.41.133-.13.252-.266.358-.41.053-.054.105-.103.159-.149.053-.045.105-.095.158-.147.106-.144.225-.277.358-.399a12.55 12.55 0 0 0 .732-.73c.114-.12.239-.234.375-.341a2.19 2.19 0 0 0 .358-.347c.11-.134.228-.25.357-.347.296-.228.585-.46.868-.696.284-.235.581-.462.891-.683a21.553 21.553 0 0 1 .762-.502.838.838 0 0 1 .176-.085.279.279 0 0 0 .142-.108.456.456 0 0 1 .232.2l.001.002Z"
      />
    </Root>
  )
}

export { IconCheckmark }
