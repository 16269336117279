import styled from 'styled-components'

interface RootProps {
  active: boolean
}

export const Root = styled.button<RootProps>`
  align-items: center;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.default.black
      : props.theme.palette.default.white};
  border-radius: 30px;
  border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
  color: ${(props) =>
    props.active
      ? props.theme.palette.default.white
      : props.theme.palette.default.black};
  cursor: pointer;
  display: inline-flex;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0rem;
  line-height: 1.25rem;
  padding: 1rem 1.5rem 0.6875rem 1.5rem;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.palette.default.black};
    color: ${({ theme }) => theme.palette.default.white};
  }
`
