import React, { PropsWithChildren } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'

import { InputRadioGroup } from '@components/atoms/Form/InputRadioGroup/InputRadioGroup'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  IndividualWorkTimes,
  Root,
  StyledInputCheckbox,
  StyledInputSwitch,
  Weekday,
  WeekdayLabel,
  WeekdayName,
  WeekdayTimes,
  WeekdayWholeDay,
} from './FormWorkTimes.styles'

interface Props extends PropsWithClassName {
  register: (a: any) => UseFormRegisterReturn
  setValue(value: string, data: any): void
  watch: (a: string) => any
  weekdays: string[]
  times: string[]
}

const FormWorkTimes: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const theme: any = useTheme()

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.device.tablet})`,
  })

  const workTime = props.watch('workTime')

  return (
    <Root data-testid="form-work-times-root">
      <InputRadioGroup
        required={true}
        label="Zu welchen Tages- und Wochenzeiten können Sie arbeiten?"
        radios={[
          {
            label: 'Ich bin täglich flexibel einsetzbar',
            forId: 'flexible',
            value: 'flexible',
          },
          {
            label: 'Zeiten individuell einstellen',
            forId: 'individual',
            value: 'individual',
          },
        ]}
        name="type"
        forId="type"
        register={props.register('workTime.type')}
        onChange={(e) => props.setValue('workTime.type', e)}
      />
      {workTime?.type === 'individual' && (
        <IndividualWorkTimes>
          {props.weekdays.map((weekday, weekdayIndex) => (
            <Weekday key={weekday}>
              <WeekdayName>
                <WeekdayLabel variant={TextVariants.lead}>
                  {weekday}
                </WeekdayLabel>
              </WeekdayName>
              <WeekdayWholeDay>
                <StyledInputSwitch
                  onChange={(e) => {
                    props.setValue(`workTime.times.${weekdayIndex}`, [e, e, e])
                  }}
                  error={false}
                  required={false}
                  disabled={false}
                  forId={weekday}
                  label="ganztags"
                  checked={workTime.times?.[weekdayIndex]?.every(
                    (t: boolean) => t
                  )}
                />
              </WeekdayWholeDay>
              <WeekdayTimes>
                {props.times.map((time, timeIndex) => (
                  <StyledInputCheckbox
                    key={`${weekdayIndex}.${timeIndex}`}
                    forId={`${weekday}.${time}`}
                    label={time}
                    vertical={isTablet}
                    register={props.register(
                      `workTime.times.${weekdayIndex}.${timeIndex}`
                    )}
                    disabled={workTime.times?.[weekdayIndex]?.every(
                      (e: boolean) => e
                    )}
                  />
                ))}
              </WeekdayTimes>
            </Weekday>
          ))}
        </IndividualWorkTimes>
      )}
    </Root>
  )
}

export { FormWorkTimes }
