import React, { type CSSProperties, type PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconAccordionArrow.styles'

interface Props extends PropsWithClassName {
  size?: string
  style?: CSSProperties
}

const IconAccordionArrow: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentSize = props.size ?? '32'

  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={currentSize}
      height={currentSize}
      fill="none"
      className={props.className}
      data-testid={'icon-accordion-arrow-root'}
      style={props.style ?? {}}
    >
      <path
        fill="#292929"
        fillRule="evenodd"
        d="m12 14.646-6.97-6.97a.75.75 0 0 0-1.06 1.061l7.146 7.146a1.25 1.25 0 0 0 1.769 0l7.145-7.146a.75.75 0 0 0-1.06-1.06L12 14.646Z"
        clipRule="evenodd"
      />
    </Root>
  )
}

export { IconAccordionArrow }
