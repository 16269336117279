import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Col from '@components/atoms/Grid/Col'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 8.75rem);
  align-content: center;
  justify-content: center;
`
export const StyledCol = styled(Col)`
  margin-bottom: 0;
`

export const StyledBox = styled(Box)`
  margin-bottom: 0;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  justify-content: space-between;
`
