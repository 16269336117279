import Image from 'next/image'
import styled from 'styled-components'

import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.margin.default};
  background: '#E4E9F0';
`
export const ColLeft = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 1.875rem;
`
export const StyledHeadline = styled(Headline)`
  display: flex;
  width: 100%;
  align-self: center;
`
export const StyledText = styled(Text)`
  display: flex;
  width: 100%;
  align-self: center;
`
export const ColRight = styled(Col)`
  display: flex;
  flex-direction: column;
`

export const StyledImage = styled(Image)`
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 0.3125rem 0.625rem 1.875rem rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0.3125rem 0.625rem 1.875rem rgba(0, 0, 0, 0.1);
  }
`
