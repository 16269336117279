import React, { PropsWithChildren } from 'react'

import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import IconLoadingSpinner from '@components/atoms/Icons/IconLoadingSpinner'

import { Farben } from '@helper/enums/storyblok/Farben'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { RoutesSunpointDefault } from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'

import { BoxBackgrounds } from '../Box/Box'
import {
  IconLeft,
  IconRight,
  Root,
  Span,
  StyledA,
  StyledLink,
} from './LinkButton.styles'

export interface LinkButtonProps extends PropsWithClassName {
  disabled: boolean
  children: string | React.ReactNode
  modifier: ButtonModifier
  loading: boolean
  icon?: JSX.Element
  iconAlign?: IconAligns
  href: RoutesSunpoint | RoutesSunpointDefault | string
  target?: '_blank' | '_self' | '_parent' | '_top'
  label: string
  background?: BoxBackgrounds | Farben | string
}

const LinkButton: React.FC<PropsWithChildren<LinkButtonProps>> = (
  props: PropsWithChildren<LinkButtonProps>
): React.ReactElement => {
  const {
    disabled,
    background,
    children,
    href,
    dataTestId,
    modifier,
    icon,
    iconAlign,
    loading,
    target,
    label,
  } = props
  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'link-button-root'}
    >
      <StyledLink href={href} passHref legacyBehavior>
        <StyledA
          aria-label={label}
          disabled={disabled}
          modifier={modifier}
          target={target ?? '_self'}
          rel="noopener noreferrer"
          background={background}
        >
          {loading && iconAlign === IconAligns.LEFT && (
            <IconLeft background={background}>
              <IconLoadingSpinner />
            </IconLeft>
          )}
          {icon && !loading && iconAlign === IconAligns.LEFT && (
            <IconLeft background={background}>{icon}</IconLeft>
          )}
          <Span background={background}>{children}</Span>
          {icon && !loading && iconAlign === IconAligns.RIGHT && (
            <IconRight background={background}>{icon}</IconRight>
          )}
          {loading && iconAlign === IconAligns.RIGHT && (
            <IconRight background={background}>
              <IconLoadingSpinner />
            </IconRight>
          )}
          {loading && iconAlign === undefined && (
            <IconRight background={background}>
              <IconLoadingSpinner />
            </IconRight>
          )}
        </StyledA>
      </StyledLink>
    </Root>
  )
}

export { LinkButton }
