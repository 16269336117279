import styled from 'styled-components'

import Button from '@components/atoms/Button'

export const Root = styled.div`
  position: relative;
  z-index: 20;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const StyledButton = styled(Button)`
  margin: 0;
  justify-self: flex-end;
`
