import { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse'
import { ApolloError, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useTheme } from 'styled-components'

import { FormUserDataType } from '@components/molecules/Forms/FormUserData/FormUserData'
import {
  FormPaymentType,
  PaymentMethod,
} from '@components/molecules/Payment/Payment'

import { Contract, Studio } from '@definitions/types/symfonyTypesd'

import { CREATE_REGISTRATION_MUTATION } from '../graphql/mutations/createRegistration'

type callbackSuccessType = (transactionId: string) => void
type callbackErrprType = () => void

interface useHandleRegistrationReturnType {
  handleRegistration: (
    formUserData: FormUserDataType,
    formPaymentData: FormPaymentType,
    studio: Studio,
    contract: Contract,
    paymentSession: CreateCheckoutSessionResponse | null
  ) => Promise<void>
  handleRegistrationError: ApolloError | null
  error: string | null
}

const useHandleRegistration = (
  onCompleteCallback: callbackSuccessType | null,
  onErrorCallback: callbackErrprType | null
): useHandleRegistrationReturnType => {
  const [handleRegistrationError, setHandleRegistrationError] =
    useState<ApolloError | null>(null)
  const [error, setError] = useState<string | null>(null)

  const theme: any = useTheme()

  const [createRegistrationMutation] = useMutation(
    CREATE_REGISTRATION_MUTATION,
    {
      onCompleted: (data) => {
        if (data && onCompleteCallback) {
          onCompleteCallback(data.createRegistration.registration.id)
        }
      },
      onError: (error) => {
        setHandleRegistrationError(error)
        if (onErrorCallback) {
          onErrorCallback()
        }

        if (
          error.message.indexOf(
            'This is not a valid International Bank Account Number'
          ) >= 0
        ) {
          setError('Die IBAN ist nicht gültig')
        } else {
          setError('Es ist Fehler aufgetreten')
        }

        console.error(error)
      },
    }
  )

  const handleRegistration = async (
    formUserData: FormUserDataType,
    formPaymentData: FormPaymentType,
    studio: Studio,
    contract: Contract,
    paymentSession: CreateCheckoutSessionResponse | null
  ): Promise<void> => {
    const variables: any = {
      firstname: formUserData.vorname,
      lastname: formUserData.nachname,
      street: formUserData.strasse,
      streetNumber: formUserData.nr,
      city: formUserData.ort,
      zipcode: formUserData.zip,
      salutation: formUserData.anrede,
      birthday: formUserData.geburtstag,
      email: formUserData.email,
      phone: formUserData.telefon,
      vouchercode: formUserData.gutschein,
      clubCardReceive: formUserData.recievevia,
      debitIban:
        formPaymentData.paymentmethod === PaymentMethod.SEPA
          ? formPaymentData?.iban ?? null
          : null,
      debitAccountOwner: formPaymentData?.sameperson
        ? `${formUserData.vorname} ${formUserData.nachname}`
        : formPaymentData?.name ?? null,
      debitDirectmandate: formPaymentData?.directdebitmandate ?? false,
      debitSameperson: formPaymentData?.sameperson ?? false,
      paymentTime: formPaymentData?.debitdate ?? 'first',
      paymentType: formPaymentData.paymentmethod,
      contract: contract.id,
      studio: studio.id,
      advertisingPermitted: formUserData.newsletter,
      affiliationName: theme.key,
    }

    setError(null)

    if (paymentSession) {
      variables['paymentReference'] = paymentSession.reference
      variables['shopperReference'] = paymentSession.shopperReference
      variables['adyenMerchantAccount'] = paymentSession.merchantAccount
    }

    await createRegistrationMutation({
      variables: {
        input: {
          ...variables,
        },
      },
    })
  }

  return {
    handleRegistration,
    handleRegistrationError,
    error,
  }
}

export default useHandleRegistration
