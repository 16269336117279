import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageTextSectionStoryblok } from '@definitions/types/storyblokTypes'

import {
  ColLeft,
  ColRight,
  Root,
  StyledHeadline,
  StyledImage,
  StyledText,
} from './StoryblokImageTextSection.styles'

interface Props extends PropsWithClassName {
  blok: ImageTextSectionStoryblok
}

const StoryblokImageTextSection = (props: Props): React.ReactElement => {
  const { blok } = props

  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-image-text-section-root'}
    >
      <Row>
        <ColLeft xs={12} sm={12} md={6} lg={6}>
          <StyledHeadline importance={HeadlineImportances.h2}>
            {blok.headline?.toString()}
          </StyledHeadline>
          {blok.text?.content?.map((content: any, index: number) => {
            if (content.type === 'paragraph') {
              return content.content.map(
                (contentcontent: any, indexindex: number) => {
                  return (
                    <StyledText
                      variant={TextVariants.paragraph}
                      key={indexindex + contentcontent}
                    >
                      {contentcontent.text}
                    </StyledText>
                  )
                }
              )
            }
          })}
        </ColLeft>
        <ColRight xs={12} sm={12} md={6} lg={6}>
          {blok.image && (
            <StyledImage
              width="0"
              height="0"
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
                position: 'relative',
                zIndex: '5',
                maxWidth: '100%',
              }}
              alt={blok.image.alt ? blok.image.alt : blok.image.filename}
              src={blok.image.filename}
              priority
            />
          )}
        </ColRight>
      </Row>
    </Root>
  )
}

export { StoryblokImageTextSection }
