import { Accordion, AccordionElement } from './Accordion'

export const AccordioneDummyData: AccordionElement[] = [
  {
    trigger: 'Sint ad id est laborum eiusmod.',
    content:
      'Voluptate pariatur adipisicing laborum veniam laborum excepteur. Exercitation amet culpa dolor ut deserunt sit eiusmod in non duis ipsum nostrud laborum. Et ullamco cupidatat culpa sunt reprehenderit. Lorem ex duis quis commodo elit aute nisi officia proident mollit cillum voluptate ipsum. Nostrud mollit fugiat eiusmod mollit culpa elit. Laboris in consectetur consectetur sint aliquip proident amet.',
  },
  {
    trigger:
      'Consequat ipsum exercitation non nulla duis in reprehenderit ipsum velit enim occaecat duis.',
    content:
      'Sunt labore qui culpa mollit esse excepteur ipsum ex anim. Enim esse laborum sit eu reprehenderit. Nulla adipisicing veniam pariatur Lorem labore mollit. Aliqua deserunt aute incididunt irure ea ut sit fugiat. Esse tempor magna est fugiat laborum reprehenderit elit aliquip nostrud dolore ad. Lorem adipisicing adipisicing minim id duis sit consectetur excepteur velit amet cillum exercitation.',
  },
  {
    trigger: 'Elit culpa laborum eiusmod laborum Lorem non Lorem Lorem.',
    content:
      'Laborum aute est laborum mollit. Adipisicing nisi commodo elit officia fugiat in. Duis voluptate consequat ea tempor veniam officia sint tempor id ex dolor culpa consequat.',
  },
]

export default Accordion
