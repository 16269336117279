import { OfferLocationFormStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokOfferLocationForm } from './StoryblokOfferLocationForm'

export default StoryblokOfferLocationForm

export const dummyBlokOfferLocationFormStoryblok: OfferLocationFormStoryblok = {
  _uid: 'eded1a5d-4551-4606-ab40-12f58d7905ef',
  subline: 'SUNRL-174',
  headline: 'Standort anbieeten',
  subjects: 'Expansion - Standort für SUNPOINT anbieten',
  component: 'offer-location-form',
  _editable:
    '<!--#storyblok#{"name": "offer-location-form", "space": "235453", "uid": "eded1a5d-4551-4606-ab40-12f58d7905ef", "id": "367795855"}-->',
}
