import React from 'react'

import ErrorText from '@components/atoms/ErrorText'

import FormField from '@helper/FormField'

import { Checkbox, Input, Root, StyledInputLabel } from './InputCheckbox.styles'

interface Props extends FormField {
  onChange?: (checked: boolean) => void
  checked?: boolean
  inverted?: boolean
  name?: string
  vertical?: boolean
}

const InputCheckbox = (props: Props): React.ReactElement => {
  const {
    label,
    forId,
    error,
    errorText,
    dataTestId,
    register,
    disabled,
    required,
    inverted,
    name,
    vertical,
  } = props

  const testId = dataTestId ? dataTestId : 'InputCheckbox'
  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : testId + '-root'}
      inverted={inverted ?? false}
      vertical={vertical ?? false}
      disabled={disabled ?? false}
    >
      <Checkbox>
        <Input
          error={error ?? false}
          inverted={inverted ?? false}
          id={forId}
          type="checkbox"
          name={name ?? forId}
          disabled={disabled ?? false}
          {...register}
        />
        {label && (
          <StyledInputLabel
            disabled={disabled ?? false}
            error={error ?? false}
            required={required ?? false}
            forId={forId}
            dataTestId={testId + '-label'}
          >
            {label}
          </StyledInputLabel>
        )}
      </Checkbox>
      {error && (
        <ErrorText dataTestId={testId + '-error'}>{errorText}</ErrorText>
      )}
    </Root>
  )
}

export { InputCheckbox }
