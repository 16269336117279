export enum UsercentricServices {
  Typo3 = 'RjoygBzf',
  ConsentManagementPlatform = 'H1Vl5NidjWX',
  Gstatic = 'rJJjcVouoZ7',
  Paypal = 'JiFH1ubU',
  Sentry = 'rH1vNPCFR',
  reCAPTCHA = 'Hko_qNsui-Q',
  GoogleMaps = 'S1pcEj_jZX',
  YouTube = 'BJz7qNsdj-7',
  FacebookSocialPlugins = 'XYQZBUojc',
  GoogleAnalyticsEventTracking = 'ge3hQ3zM',
  GoogleAnalyticsStatistics = 'b8FM5DHv-',
  GoogleAnalyticsYoutubeEventTracking = 'QPYQDujT',
  GoogleTagManagerService = 'BJ59EidsWQ',
  GoogleAnalyticsService = 'HkocEodjb7',
  GoogleFontsService = 'HkPBYFofN',
  AmazonWebServices = 'J39GyuWQq',
  GoogleTranslateService = 'qvkzz7qPe',
  jQueryService = 'qvkzz7qPe',
  GoogleGlobalSiteTag = 'BJMh5NodoZQ',
  GoogleAnalyticsAdvertising = 'ABsocZLBQ',
  GoogleAdsConversions = '8ScDZDmU',
  MailchimpService = 'SJggqNsOi-m',
  GoogleOptimizeService = 'gkEBFID-V',
  GoogleAdsRemarketingService = 'B1Hk_zoTX',
  FacebookPixelService = 'ko1w5PpFl',
  GoogleAnalyticsAudiences = 'Skr99EiujbX',
  DoubleClickAd = '9V8bg4D63',
  TaboolaService = 'HkMucNoOjWX',
  Matterport = '7bFNv7DLf',
}
