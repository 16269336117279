import styled from 'styled-components'

export const Root = styled.blockquote`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.mobile.lineHeight};
  font-weight: 300;
  letter-spacing: ${(props) =>
    props.theme.font.text.paragraph.mobile.letterSpacing};
  color: ${(props) => props.theme.palette.default.black};
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  margin-bottom: ${(props) => props.theme.margin.default};
  a {
    text-decoration: underline;
    &:link {
      color: ${(props) => props.theme.palette.default.black};
    }
    &:active {
      color: ${(props) => props.theme.palette.default.black};
    }
    &:visited {
      color: ${(props) => props.theme.palette.default.black};
    }
  }
  b,
  strong {
    font-weight: 700;
  }
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-size: ${(props) => props.theme.font.text.paragraph.desktop.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.paragraph.desktop.lineHeight};
    letter-spacing: ${(props) => props.theme.font.text.paragraph.letterSpacing};
  }
  border-left: 0.1875rem solid ${(props) => props.theme.palette.default.primary};
  padding: ${(props) => props.theme.padding.small};
`
