import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Trigger = styled.div<{
  titleAlign?: 'left' | 'center' | 'right'
}>`
  align-items: center;
  color: ${(props) => props.theme.palette.default.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: ${(props) => props.titleAlign ?? 'left'};
  padding-bottom: 1rem;
`

export const Content = styled.div<{
  isOpen: boolean
}>`
  color: ${(props) => props.theme.palette.default.black};
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  text-align: left;
  width: 100%;
`
