import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import FileUpload from '@components/atoms/FileUpload'
import FileUploadSimple from '@components/atoms/FileUploadSimple'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputDate from '@components/atoms/Form/InputDate'
import InputRadioGroup from '@components/atoms/Form/InputRadioGroup'
import { RadioButtonDirection } from '@components/atoms/Form/InputRadioGroup/InputRadioGroup'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'
import { CreateAttachmentInput } from '@definitions/types/symfonyTypesd'

import { ButtonCol, Root, StyledButton } from './FormCancellation.styles'

export interface FormCancellationType {
  firstname: string
  lastname: string
  street: string
  number: string
  city: string
  zip: string
  birthdate: string
  contract: string
  email: string
  option: string
  message: string
  privacy: boolean
  cancel: boolean
  reasonForTermination: string
}

interface Props extends PropsWithClassName {
  userData?: FormCancellationType
  handleSubmitForm: (data: FormCancellationType) => void
  formId: string
  handleSubmitted?: () => void
}

const FormCancellation: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSend, setFormSend] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<CreateAttachmentInput[]>([])
  const { handleSubmitForm, userData, formId, dataTestId, handleSubmitted } =
    props

  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { register, handleSubmit, formState, setValue } =
    useForm<FormCancellationType>({
      resolver: yupResolver(
        yup.object().shape({
          firstname: yup.string().required('Dies ist ein Pflichtfeld.'),
          lastname: yup.string().required('Dies ist ein Pflichtfeld.'),
          street: yup.string().required('Dies ist ein Pflichtfeld.'),
          number: yup.string().required('Dies ist ein Pflichtfeld.'),
          zip: yup.string().required('Dies ist ein Pflichtfeld.'),
          city: yup.string().required('Dies ist ein Pflichtfeld.'),
          birthdate: yup
            .string()
            .test(
              'is-date',
              'Bitte trage dein Geburtsdatum im Format Tag. Monat Jahr ein.',
              (value) => {
                if (!value) return false
                return yup.date().isValidSync(value)
              }
            )
            .test('is-over-18', 'Du musst über 18 Jahre alt sein.', (value) => {
              if (!value) return false
              const birthdate = new Date(value)
              const today = new Date()
              const age = today.getFullYear() - birthdate.getFullYear()
              return age >= 18
            }),
          contract: yup.string().required('Dies ist ein Pflichtfeld.'),
          email: yup
            .string()
            .trim()
            .email(
              'Bitte trage deine E-Mail korrekt ein. Achte darauf, dass keine Leerzeichen und keine Umlaute enthalten sind und verwende keine Auto-Fill-Funktion.'
            )
            .required(
              'Bitte trage deine E-Mail korrekt ein. Achte darauf, dass keine Leerzeichen und keine Umlaute enthalten sind und verwende keine Auto-Fill-Funktion.'
            )
            .test(
              'is-valid-email',
              'Dies ist keine valide E-Mail-Adresse',
              (value) => {
                if (!value) return false
                return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
              }
            ),
          option: yup
            .string()
            .required('Bitte fülle mindestens eines dieser Felder aus.'),
          reasonForTermination: yup
            .string()
            .required('Bitte fülle mindestens eines dieser Felder aus.'),
          message: yup.string(),
          privacy: yup
            .boolean()
            .oneOf(
              [true],
              'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
            ),
          cancel: yup
            .boolean()
            .oneOf(
              [true],
              'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
            ),
        })
      ) as Resolver<FormCancellationType>,
      defaultValues: userData,
    })

  const onSubmit = async (data: FormCancellationType) => {
    setLoading(true)
    await sendFormDataMail('Kündigung', data, 'cancellation', attachments)
    await sendFormDataMail(
      'Eingangsbestätigung Kündigung Club-Mitgliedschaft',
      data,
      'cancellation-confirm',
      attachments
    )
    setLoading(false)
    setFormSend(true)
    if (props.handleSubmitted) {
      props.handleSubmitted()
    }
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'form-cancellation-root'}
    >
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('firstname')}
                forId={`${formId}-firstname`}
                label="Vorname"
                required={true}
                error={formState.errors.firstname ?? false}
                errorText={formState.errors?.firstname?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('lastname')}
                forId={`${formId}-lastname`}
                label="Nachname"
                required={true}
                error={formState.errors.lastname ?? false}
                errorText={formState.errors?.lastname?.message}
              />
            </Col>
            <Col xs={12} sm={10} md={4} lg={4}>
              <InputText
                register={register('street')}
                forId={`${formId}-street`}
                label="Straße"
                required={true}
                error={formState.errors.street ?? false}
                errorText={formState.errors?.street?.message}
              />
            </Col>
            <Col xs={12} sm={2} md={2} lg={2}>
              <InputText
                register={register('number')}
                forId={`${formId}-number`}
                label="Nr."
                required={true}
                error={formState.errors.number ?? false}
                errorText={formState.errors?.number?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('zip')}
                forId={`${formId}-zip`}
                label="PLZ"
                required={true}
                error={formState.errors.zip ?? false}
                errorText={formState.errors?.zip?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputDate
                value=""
                register={register('birthdate')}
                forId={`${formId}-birthdate`}
                label="Geburtsdatum"
                required={false}
                error={formState.errors.birthdate ?? false}
                errorText={formState.errors?.birthdate?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('city')}
                forId={`${formId}-city`}
                label="Stadt"
                required={true}
                error={formState.errors.city ?? false}
                errorText={formState.errors?.city?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="E-Mail-Adresse"
                required={true}
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('contract')}
                forId={`${formId}-contract`}
                label="Clubkarten- oder Vertragsnummer"
                required={true}
                error={formState.errors.contract ?? false}
                errorText={formState.errors?.contract?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputRadioGroup
                label="Kündigungszeitpunkt"
                direction={RadioButtonDirection.column}
                radios={[
                  // If changes are made, apply the adjustments here as well
                  // symfony/templates/emails/cancellation_confirm.html.twig
                  {
                    label: 'Fristgerecht zum nächstmöglichen Zeitpunkt',
                    value: 'normal',
                    forId: `${formId}-option-normal`,
                  },
                  {
                    label: 'Sonderkündigung (Umzug/Krankheit)',
                    value: 'special',
                    forId: `${formId}-option-special`,
                  },
                  {
                    label: 'Später als fristgerechter, möglicher Zeitpunkt',
                    value: 'later',
                    forId: `${formId}-option-later`,
                  },
                ]}
                forId={`${formId}-option`}
                name={`${formId}-option`}
                error={formState.errors.option ?? false}
                errorText={formState.errors?.option?.message}
                required={false}
                onChange={(value) => {
                  setValue('option', value)
                }}
                infoTextTitle="Kündigungsfrist Info"
                infoTextContent="Es gelten die gesetzlichen Kündigungsfristen."
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputRadioGroup
                label="Kündigungsgrund"
                direction={RadioButtonDirection.column}
                radios={[
                  // If changes are made, apply the adjustments here as well
                  // symfony/templates/emails/cancellation_confirm.html.twig
                  {
                    label: 'Kein Bedarf',
                    value: 'keinbedarf',
                    forId: `${formId}-reasonForTermination-kein-bedarf`,
                  },
                  {
                    label: 'Unzufrieden',
                    value: 'unzufrieden',
                    forId: `${formId}-reasonForTermination-unzufrieden`,
                  },
                  {
                    label: 'Umzug',
                    value: 'umzug',
                    forId: `${formId}-reasonForTermination-umzug`,
                  },
                  {
                    label: 'Krankheit',
                    value: 'krankheit',
                    forId: `${formId}-reasonForTermination-krankheit`,
                  },
                  {
                    label: 'Anderer Grund',
                    value: 'anderergrund',
                    forId: `${formId}-reasonForTermination-anderer-grund`,
                  },
                ]}
                required={false}
                forId={`${formId}-reasonForTermination`}
                name={`${formId}-reasonForTermination`}
                error={formState.errors.reasonForTermination ?? false}
                errorText={formState.errors?.reasonForTermination?.message}
                onChange={(value) => {
                  setValue('reasonForTermination', value)
                }}
                infoTextTitle="Kündigungsgrund Info"
                infoTextContent="Bei Umzug ist ein Anhang mit einer Bescheinigung des Einwohnermeldeamtes oder einer Kopie des Personalausweises mit neuer, korrigierter Adresse erforderlich.<br/><br/>Bei Krankheit ist ein Anhang mit einem ärztlichen Attest erforderlich."
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                register={register('message')}
                forId={`${formId}-message`}
                label="Bemerkung"
                error={formState.errors.message ?? false}
                errorText={formState.errors?.message?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FileUploadSimple
                forId="fileupload"
                label="Anhang (Max. 10 MB. Format: PNG, JPG, GIF, PDF, ZIP)"
                maxFiles={3}
                onChange={(attachments) => {
                  setAttachments(attachments)
                }}
                acceptedFileTypes={[
                  'image/png',
                  'image/jpeg',
                  'application/pdf',
                ]}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required={true}
                label={
                  <>
                    Ja, ich habe die&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />
                    &nbsp;gelesen
                  </>
                }
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('cancel')}
                forId={`${formId}-cancel`}
                required={true}
                error={formState.errors.cancel ?? false}
                errorText={formState.errors?.cancel?.message}
                label={
                  <>
                    Hiermit verzichte ich auf meine Club-Vorteile und kündige
                    meinen Vertrag mit den von mir korrekt eingetragenen Daten.
                  </>
                }
              />
            </Col>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Jetzt kündigen
              </StyledButton>
            </ButtonCol>
          </Row>
        </form>
      )}
      {formSend && (
        <>
          <Text variant={TextVariants.lead}>
            <b>
              {resultSendMail === true && (
                <>
                  Schade, dass du kündigen möchtest.
                  <br />
                  Wir werden dein Anliegen umgehend bearbeiten.
                </>
              )}
              {resultSendMail === false && 'Es ist ein Fehler aufgetreten'}
            </b>
          </Text>
        </>
      )}
    </Root>
  )
}

export { FormCancellation }
