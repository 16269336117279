import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './LoadingSpinner.styles'

interface Props extends PropsWithClassName {}

const LoadingSpinner: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      role="status"
      aria-live="polite"
      aria-label="Loading"
      className={props.className}
      data-testid={'loading-spinner-root'}
    />
  )
}

export { LoadingSpinner }
