import Image from 'next/image'
import styled from 'styled-components'

import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'

interface RootProps {
  blackBackground: boolean | undefined
}

export const Root = styled.div<RootProps>`
  margin-bottom: ${(props) => props.theme.margin.big};
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  background-color: ${(props) =>
    props.blackBackground
      ? props.theme.palette.default.black
      : props.theme.palette.default.white};
  color: ${(props) =>
    !props.blackBackground
      ? props.theme.palette.default.black
      : props.theme.palette.default.white};

  img {
    border-radius: 1rem;
    overflow: hidden;
  }
`

export const StyledHeadline = styled(Headline)<any>`
  width: auto;
  color: ${(props) =>
    !props.whiteColor
      ? props.theme.palette.default.black
      : props.theme.palette.default.white};
`

export const ImageCol = styled(Col)`
  display: flex;
  order: 1;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    order: 0;
  }
`
export const ContentCol = styled(Col)`
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.padding.small};
  padding-bottom: ${(props) => props.theme.padding.small};
  order: 2;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    order: 0;
    padding-top: ${(props) => props.theme.padding.large};
    padding-bottom: ${(props) => props.theme.padding.large};
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: ${(props) => props.theme.padding.small};
  padding-bottom: 5rem;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    padding-bottom: ${(props) => props.theme.padding.large};
    padding-top: ${(props) => props.theme.padding.large};
  }
`

export const PrimaryImageContainer = styled.div`
  border-radius: 1rem;
`

interface SecondaryImageContainerProps {
  imagesRight?: boolean
}

export const SecondaryImageContainer = styled.div<SecondaryImageContainerProps>`
  border-radius: 1rem;
  position: absolute;
  bottom: 1rem;
  right: ${(props) =>
    props.imagesRight ? '-' + props.theme.padding.default : 'auto'};
  left: ${(props) =>
    props.imagesRight ? 'auto' : '-' + props.theme.padding.default};
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    bottom: ${(props) => props.theme.padding.default};
  }
`

export const PrimaryImage = styled(Image)`
  border-radius: 1rem;
`
export const SecondaryImage = styled(Image)`
  border-radius: 1rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const StyledLinkButton = styled(LinkButton)`
  margin: 0;
  margin-right: ${(props) => props.theme.margin.default};
`
