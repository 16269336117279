import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'

export const Root = styled.div`
  padding: 6.25rem 0 6.25rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => props.theme.margin.large};
`
export const Filter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.125rem);
  z-index: 1;
`

export const StyledHeadline = styled(Headline)`
  margin: 0;
  position: relative;
  z-index: 20;
`
export const StyledBox = styled(Box)`
  margin: 0;
  position: relative;
  z-index: 20;
`
export const StyledCol = styled(Col)`
  margin-bottom: 0;
  position: relative;
  z-index: 20;
`
