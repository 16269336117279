import {
  countries,
  Countries,
  deutschlandBundeslaender,
  FederalStateCodeDE,
  FederalStateDE,
} from '@helper/federalStates'

import { FormUserData, FormUserDataType } from './FormUserData'

export const DummyFormUserData: FormUserDataType = {
  vorname: 'John',
  nachname: 'Doe',
  strasse: '123 Main Street',
  nr: 'Apt 4B',
  zip: '12345',
  ort: 'Anytown',
  email: 'johndoe@example.com',
  emailConfirm: 'johndoe@example.com',
  telefon: '555-123-4567',
  newsletter: true,
  land: countries.DEUTSCHLAND,
  bundesland: FederalStateDE.MECKLENBURG_VORPOMMERN,
  gutschein: 'ABC123',
  agb: true,
  anrede: 'herr',
  recievevia: 'post',
  geburtstag: '1966-05-06',
}

export default FormUserData
