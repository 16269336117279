import { useCallback, useContext, useRef, useState } from 'react'

import GoogleMapApiContext from '@helper/context/GoogleMapApiContext'

interface useGoogleApiReturnType {
  googleApiLoaded: boolean
  findPlaceFromText: (placeText: string) => void
  foundPlace: LatLng | null
}

export interface LatLng {
  lat: number
  lng: number
}

const useGoogleApi = (): useGoogleApiReturnType => {
  const [foundPlace, setFoundPlace] = useState<LatLng | null>(null)
  const { googleApiLoaded } = useContext(GoogleMapApiContext)
  const placesService = useRef<google.maps.places.PlacesService | null>()

  const findPlaceFromText = useCallback(
    (placeText: string): void => {
      if (placeText.trim() === '') {
        setFoundPlace(null)

        return
      }
      const request = {
        query: placeText,
        fields: ['geometry.location'],
      }

      function placesSearchCallback(results: any, status: any) {
        if (!googleApiLoaded) {
          console.warn('google map api not loaded')
          setFoundPlace(null)

          return
        }
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          console.error(status)
          console.error(results)

          return
        }
        if (results[0]?.geometry?.location) {
          const searchLat = results[0].geometry.location.lat()
          const searchLng = results[0].geometry.location.lng()

          setFoundPlace({ lat: searchLat, lng: searchLng })

          return
        }

        setFoundPlace(null)
      }

      if (!placesService.current) {
        placesService.current = new google.maps.places.PlacesService(
          document.createElement('div')
        )
      }
      placesService.current.findPlaceFromQuery(request, placesSearchCallback)
    },
    [googleApiLoaded]
  )

  return { googleApiLoaded: googleApiLoaded, findPlaceFromText, foundPlace }
}

export default useGoogleApi
