import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { BannerStoryblok } from '@definitions/types/storyblokTypes'

import {
  Filter,
  Root,
  StyledBox,
  StyledCol,
  StyledHeadline,
} from './StoryblokBanner.styles'

interface StoryblokBannerProps extends BannerStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokBannerProps
}

const StoryblokBanner = (props: Props) => {
  const { blok } = props

  const styles = {
    backgroundImage: `url(${blok.background_image?.filename})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }

  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-banner-root'}
      style={styles}
      role="img"
      aria-label={
        blok.background_image?.alt
          ? blok.background_image?.alt
          : blok.headline?.toString()
      }
      title={blok.headline?.toString()}
    >
      <Row>
        <StyledCol xs={12} sm={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
          <StyledBox>
            <Headline importance={HeadlineImportances.h1}>
              {blok.headline?.toString()}
            </Headline>
            <StyledHeadline importance={HeadlineImportances.h2}>
              {blok.subheadline?.toString()}
            </StyledHeadline>
          </StyledBox>
        </StyledCol>
      </Row>
      <Filter />
    </Root>
  )
}

export { StoryblokBanner }
