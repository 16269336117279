import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Circle, Root, Spinner } from './IconLoadingSpinner.styles'

interface Props extends PropsWithClassName {}

const IconLoadingSpinner: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={props.className}
      data-testid={'icon-loading-spinner-root'}
    >
      <Spinner stroke="#292929">
        <Circle
          cx="12"
          cy="12"
          r="9.5"
          fill="none"
          stroke-width="3"
          stroke="#292929"
        />
      </Spinner>
    </Root>
  )
}

export { IconLoadingSpinner }
