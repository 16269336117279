import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const StyledBox = styled(Box)`
  margin-bottom: 0;
  &::before {
    display: none !important;
  }
`

export const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.palette.default.white};
  margin-bottom: 1rem;
`
export const ContentContainer = styled.div`
  color: ${(props) => props.theme.palette.default.white};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.lead.desktop.fontSize};
  line-height: ${(props) => props.theme.font.text.lead.desktop.lineHeight};
  margin-bottom: 2rem;
  font-weight: 300;
`

export const StyledLinkButton = styled(LinkButton)`
  text-decoration: none;
  margin: 0;
`
