import { yupResolver } from '@hookform/resolvers/yup'
import useGoogleTracking from '@hooks/useGoogleTracking'
import axios from 'axios'
import Link from 'next/link'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputText from '@components/atoms/Form/InputText'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import { ButtonContainer, Root, StyledButton } from './FormNewsletter.styles'

const privacyRoutes: Record<string, string> = {
  sunpoint: RoutesSunpoint.SUNPOINT__DATENSCHUTZ,
  wellmaxx: RoutesWellmaxx.WELLMAXX__DATENSCHUTZ,
}

export interface FormNewsletterType {
  email: string
  privacy: boolean
}

interface Props extends PropsWithClassName {
  userData?: FormNewsletterType
  handleSubmitForm: (data: FormNewsletterType) => void
  formId: string
  list: string
  inverted?: boolean
}

const FormNewsletter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<string | null>(null)
  const { handleSubmitForm, userData, formId, dataTestId } = props
  const { trackNewsletterRegistration } = useGoogleTracking()

  const { register, handleSubmit, formState } = useForm<FormNewsletterType>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .email('Dies ist keine valide E-Mail-Adresse')
          .required('Dies ist ein Pflichtfeld.')
          .test(
            'is-valid-email',
            'Dies ist keine valide E-Mail-Adresse',
            (value) => {
              if (!value) return false
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
            }
          ),
        privacy: yup
          .boolean()
          .oneOf(
            [true],
            'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
          ),
      })
    ) as Resolver<FormNewsletterType>,
    defaultValues: userData,
  })

  const onSubmit = async (data: FormNewsletterType) => {
    setLoading(true)
    const listData = { ...data, list: props.list }
    try {
      const response = await axios.post('/api/subscribeMailchimp', listData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setResult(response.data.status)
      handleSubmitForm(data)
      trackNewsletterRegistration()
    } catch (error: any) {
      if (error.response?.data) {
        console.error('Failed to subscribe:', error.response.data)
      }
      setResult(error.response?.data?.status ?? 'error')
    } finally {
      setLoading(false)
    }
  }

  function renderResult(status: string) {
    if (status === 'subscribed') {
      return (
        <>
          <Text variant={TextVariants.lead}>
            <b>Erfolgreich angemeldet</b>
          </Text>
          <Text variant={TextVariants.paragraph}>
            Bitte bestätige deine Anmeldung über den Link in der E-Mail, die wir
            dir gerade geschickt haben.
          </Text>
        </>
      )
    } else if (status === 'already_subscribed') {
      return (
        <>
          <Text variant={TextVariants.lead}>
            <b>Fehler</b>
          </Text>
          <Text variant={TextVariants.paragraph}>
            Du bist bereits für den Newsletter angemeldet.
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text variant={TextVariants.lead}>
            <b>Fehler</b>
          </Text>
          <Text variant={TextVariants.paragraph}>
            Es ist ein Fehler bei der Anmeldung aufgetreten.
          </Text>
        </>
      )
    }
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'form-newsletter-root'}
    >
      {!result && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            register={register('email')}
            forId={`${formId}-email`}
            label="E-Mail-Adresse"
            required
            error={formState.errors.email ?? false}
            errorText={formState.errors?.email?.message}
          />
          <InputCheckbox
            register={register('privacy')}
            forId={`${formId}-privacy`}
            error={formState.errors.privacy ?? false}
            errorText={formState.errors?.privacy?.message}
            required
            inverted={props.inverted ?? false}
            label={
              <>
                Ja, ich habe die&nbsp;
                <ThemeBasedLink
                  linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                  linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                  text="Datenschutzerklärung"
                />
                &nbsp;gelesen
              </>
            }
          />
          <ButtonContainer>
            <StyledButton
              modifier={ButtonModifier.PRIMARY}
              buttonType={ButtonTypes.SUBMIT}
              disabled={formState.isSubmitting}
              icon={<IconArrowRight />}
              iconAlign={IconAligns.RIGHT}
              loading={loading}
            >
              Anmelden
            </StyledButton>
          </ButtonContainer>
        </form>
      )}
      {result && renderResult(result)}
    </Root>
  )
}

export { FormNewsletter }
