import { ImageSliderSlideStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokImageSliderSlide } from './StoryblokImageSliderSlide'

export const dummyStoryblokImageSliderSlide: ImageSliderSlideStoryblok = {
  Image: {
    alt: 'A serene landscape with mountains in the background',
    copyright: 'John Doe Photography',
    id: 1,
    filename:
      'https://a.storyblok.com/f/235453/769x466/dc9bf8d3e3/dummy_slider_769x466_01.jpeg',
    name: 'Serene Landscape',
    title: 'The Serenity of Nature',
    focus: 'center',
    // Add additional keys here if needed
  },
  _uid: 'unique_string_identifier_123',
  component: 'image-slider-slide',
  // You can add more keys here to match the `[k: string]: any` pattern
}
export default StoryblokImageSliderSlide
