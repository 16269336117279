import React, { PropsWithChildren } from 'react'

import {
  CloseContainer,
  StyledPopup,
} from '@components/atoms/FullPopup/FullPopup.styles'
import IconCircleXNotFilled from '@components/atoms/Icons/IconCircleXNotFilled'

import { PropsWithClassName } from '@helper/PropsWithClassName'

interface Props extends PropsWithClassName {
  trigger?: React.JSX.Element | ((isOpen: boolean) => React.JSX.Element)
}

const FullPopup: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const inner = (close: any): any => (
    <div>
      <CloseContainer onClick={close}>
        <IconCircleXNotFilled />
      </CloseContainer>
      {props.children}
    </div>
  )

  const innerFunc: any = inner

  return (
    <StyledPopup trigger={props.trigger} modal closeOnDocumentClick>
      {innerFunc}
    </StyledPopup>
  )
}

export { FullPopup }
