import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import LogoPaypal from '@components/atoms/Logos/LogoPaypal'
import LogoSepa from '@components/atoms/Logos/LogoSepa'
import Text from '@components/atoms/Text'

export const Root = styled.div``
export const Form = styled.form``

export const StyledMetaText = styled(Text)`
  margin-bottom: 0;
`
export const StyledParagraphText = styled(Text)`
  margin-bottom: 0;
`
export const Kleingedrucktes = styled(Text)`
  max-width: 100%;
`
export const StyledButton = styled(Button)`
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 0;
  & > span {
    white-space: normal;
  }
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    & > span {
      white-space: nowrap;
    }
  }
`

export const Hint = styled(Text)`
  margin: 0;
`
export const StyledCol = styled(Col)`
  display: flex;
  flex: 1;
  align-items: stretch;
`

export const PaymentBox = styled(Box)``

export const StyledBox = styled(Box)`
  margin-bottom: 0;
`

export const ButtonCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

export const StyledLogoPaypal = styled(LogoPaypal)`
  height: 3.125rem;
  width: auto;
  margin-bottom: ${(props) => props.theme.margin.default};
`

export const StyledLogoSepa = styled(LogoSepa)`
  height: 3.125rem;
  width: auto;
  margin-bottom: ${(props) => props.theme.margin.default};
`

export const StyledLogoPaypalMini = styled(LogoPaypal)`
  height: 1rem;
  width: auto;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
`

export const StyledLogoSepaMini = styled(LogoSepa)`
  height: 1rem;
  width: auto;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
`

export const FinalizePaymentBox = styled.div`
  background: ${(props) => props.theme.palette.default.primary};
  border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
  color: ${(props) =>
    props.theme.key === 'wellmaxx'
      ? props.theme.palette.default.white
      : props.theme.palette.default.black};
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  background-image: ${(props) =>
    props.theme.key === 'wellmaxx' ? 'none' : "url('/img/sun-background.svg')"};
  background-position: top -20px right -80px;
  background-repeat: no-repeat;
`

export const FinalizeInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

export const FinalizeCostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`
export const FinalizeCostsRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.key === 'wellmaxx'
        ? props.theme.palette.default.white
        : props.theme.palette.default.black};
  margin-bottom: 0.5rem;
`
export const StyledMetaFinalizeCostsText = styled(Text)`
  margin-left: 0.5rem;
  margin-right: auto;
  margin-bottom: 0.5rem;
`
export const PaypalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 2rem;
`

export const PaypalInnerContainer = styled.div``
