import React, { PropsWithChildren } from 'react'

import ErrorText from '@components/atoms/ErrorText'

import FormField from '@helper/FormField'

import { Input, Root, StyledInputLabel } from './InputText.styles'

export enum InputTextType {
  text = 'text',
  email = 'email',
}

interface Props extends FormField {
  label: string
  mulitlines?: number
  type?: InputTextType
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props extends FormField {}

const InputText: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    dataTestId,
    errorText,
    error,
    disabled,
    required,
    label,
    placeholder,
    forId,
    register,
    onChange,
  } = props

  return (
    <Root className={props.className} data-testid={'input-text-root'}>
      <StyledInputLabel
        error={error ?? false}
        disabled={disabled ?? false}
        required={required ?? false}
        forId={forId}
      >
        {label}
      </StyledInputLabel>
      <Input
        error={error ? error : false}
        type="text"
        placeholder={placeholder}
        id={forId}
        {...register}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
      />
      {error && (
        <ErrorText dataTestId={dataTestId + '-error'}>{errorText}</ErrorText>
      )}
    </Root>
  )
}

export { InputText }
