import styled from 'styled-components'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin-bottom: 100px;
  min-height: 650px;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    width: auto;
    min-height: 500px;
  }
`

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface PictureProps {
  backgroundImage: string
}

export const Desktop = styled.div<PictureProps>`
  width: 100%;
  height: 100%;
  display: none;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
`
export const Mobile = styled.div<PictureProps>`
  width: 100%;
  height: 100%;
  display: flex;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`
