import styled from 'styled-components'

import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'

export const Root = styled.div``

export const StyledButton = styled(Button)`
  margin: 0;
`

export const ButtonCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const StyledRow = styled(Row)`
  margin-bottom: 1rem;
`
