import { FieldError } from 'react-hook-form'
import styled, { css } from 'styled-components'

import InputLabel from '../InputLabel'
import { RadioButtonDirection } from './InputRadioGroup'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  align-content: center;
  flex-direction: column;
`

export const InputRadio = styled.div<Radiosprops>`
  display: flex;
  align-content: center;
  flex-direction: row;
  margin-right: ${(props) =>
    props.direction === RadioButtonDirection.column
      ? 0
      : props.theme.margin.default};
  margin-bottom: ${(props) =>
    props.direction === RadioButtonDirection.column
      ? props.theme.margin.small
      : props.theme.margin.default};
`
interface Radiosprops {
  direction: RadioButtonDirection
}
export const Radios = styled.div<Radiosprops>`
  display: flex;
  align-content: center;
  flex-direction: ${(props) => props.direction};
`

interface InputProps {
  readonly disabled: boolean
  readonly error: boolean | FieldError
  readonly inverted: boolean
}

export const getBorderStyles = (
  error: boolean | FieldError,
  inverted: boolean
) => {
  if (error) {
    return css`
      border: 0.0625rem solid ${(props) => props.theme.palette.default.error};
    `
  }
  if (inverted) {
    return css`
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    `
  }
  return css`
    border: 0.0625rem solid ${(props) => props.theme.palette.default.grey};
  `
}

export const Input = styled.input<InputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  margin-right: ${(props) => props.theme.margin.half};
  &::before {
    position: absolute;
    left: 0;
    content: ' ';
    border-radius: 100%;
    top: 0;
    width: 1.375rem;
    height: 1.375rem;
    ${(props) => getBorderStyles(props.error, props.inverted)}
  }
  &:checked::before {
    border: 0.0625rem solid
      ${(props) =>
        props.inverted
          ? props.theme.palette.default.black
          : props.theme.palette.default.primary};
  }
  &:checked::after {
    position: absolute;
    content: ' ';
    background: ${(props) =>
      props.inverted
        ? props.theme.palette.default.black
        : props.theme.palette.default.primary};
    border-radius: 100%;
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem;
  }
  &:disabled::before {
    background: ${(props) => props.theme.palette.default.grey};
    cursor: not-allowed;
  }
  &:hover,
  &:active {
    outline: none;
    &::before {
      border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    }
  }
`
export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.margin.extrasmall};
`

export const StyledRadioLabel = styled(InputLabel)`
  display: flex;
  margin-bottom: 0;
  padding-left: 0.9375rem;
`
