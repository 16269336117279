import React from 'react'

type RealEnvironmentContextProps = {
  isRealEnvironment: boolean | undefined
}

const RealEnvironmentContext = React.createContext<RealEnvironmentContextProps>(
  {} as RealEnvironmentContextProps
)

export default RealEnvironmentContext
