import styled from 'styled-components'

import Text from '../Text'
import { TextParagraphStyles } from '../Text/Text.styles'

interface RootProps {
  hasIcon?: boolean
}

export const Root = styled.li<RootProps>`
  margin-bottom: ${(props) => props.theme.margin.half};
  position: relative;
  padding-left: 2.2rem;
  padding-top: 5px;
  ${TextParagraphStyles()}
  text-decoration: none;
  counter-increment: list-item-counter;
  list-style-type: none !important;
  text-align: left;
  &::before {
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    left: 0px;
    top: 3px;
    text-align: center;
    min-width: 24px;
    height: 24px;
    display: ${(props) => (props.hasIcon ? 'none' : 'flex')};
  }
  p {
    margin: 0;
  }
`

export const Icon = styled.div`
  position: absolute;
  left: 2px;
  top: 5px;
`
export const StyledText = styled(Text)`
  margin: 0;
`
