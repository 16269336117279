import { ImageTextColStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokImageTextCol } from './StoryblokImageTextCol'

export default StoryblokImageTextCol

export const dummyImageTextColStoryblok: ImageTextColStoryblok = {
  _uid: 'ce5baf00-f0b1-43d1-818a-d87cb2116a31',
  link: {
    id: '589e3df3-f7df-4728-989e-8cbdf37e6209',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: 'sunpoint/braeune/solarium',
  },
  image: {
    id: 10414769,
    alt: 'Sonnenbank',
    name: '',
    focus: '',
    title: 'Sonnenbank',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/384x288/01074d2bf9/sonnenbank.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    is_external_url: false,
  },
  content: {
    type: 'doc',
    content: [
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'sadsdasdd',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'sdadsdd',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'sdadsddds',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  headline: 'ssdsadsdads',
  component: 'image-text-col',
  _editable:
    '<!--#storyblok#{"name": "image-text-col", "space": "235453", "uid": "ce5baf00-f0b1-43d1-818a-d87cb2116a31", "id": "322787899"}-->',
}
