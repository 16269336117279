import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './LogoLiftmee.styles'

interface Props extends PropsWithClassName {}

const LogoLiftmee: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      width="46"
      height="33"
      viewBox="0 0 46 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'logo-liftmee-root'}
    >
      <g clipPath="url(#clip0_1722_119306)">
        <path
          d="M0.151223 32.0698C0.151223 31.9435 0.151223 31.8593 0.151223 31.775C0.151223 30.2329 0.14602 28.6856 0.16163 27.1435C0.177241 25.9961 0.307332 24.8593 0.890137 23.8435C1.39489 22.9593 2.11299 22.2908 3.05484 21.8856C4.58991 21.2277 6.151 21.2698 7.71208 21.7119C8.43018 21.9172 9.04941 22.3277 9.54896 22.9014C9.57498 22.9329 9.59059 22.9803 9.6114 23.0277C9.86638 22.7961 10.0797 22.6066 10.2931 22.4172C10.8759 21.8961 11.5784 21.6172 12.3225 21.4645C13.306 21.2593 14.3051 21.2645 15.2938 21.4487C17.2451 21.8119 18.3795 22.9698 18.827 24.8698C19.0039 25.6277 19.0508 26.3908 19.0456 27.1645C19.0404 28.6908 19.0456 30.2172 19.0456 31.7435C19.0456 32.0698 19.03 32.0856 18.7125 32.0908C18.1297 32.0908 17.5521 32.0908 16.9693 32.0908C16.8913 32.0908 16.8184 32.075 16.6987 32.0645C16.6987 31.7908 16.6987 31.5277 16.6987 31.2698C16.7143 29.4119 16.7404 27.554 16.7352 25.6961C16.7352 25.0014 16.5843 24.3224 16.2668 23.6908C15.9598 23.0698 15.4551 22.6856 14.8046 22.5277C14.0449 22.3435 13.28 22.3487 12.5254 22.6172C11.6981 22.9119 11.2401 23.5435 10.9956 24.3487C10.7978 25.0066 10.751 25.6856 10.7562 26.375C10.7718 28.1645 10.7666 29.954 10.7666 31.7435C10.7666 31.8382 10.7562 31.9329 10.751 32.0593C9.98086 32.0593 9.22634 32.0593 8.4614 32.0593C8.4614 31.9224 8.4614 31.8119 8.4614 31.6961C8.4614 29.7435 8.48742 27.7856 8.4562 25.8329C8.44579 25.054 8.28448 24.2803 7.8786 23.5908C7.47792 22.9066 6.83267 22.5908 6.08855 22.5329C5.59421 22.4961 5.08425 22.5382 4.59511 22.6172C3.64285 22.7698 3.07566 23.4014 2.76344 24.2856C2.5553 24.8856 2.48245 25.5119 2.48245 26.1487C2.48765 27.9908 2.48245 29.8329 2.48245 31.675C2.48245 32.0803 2.47204 32.0908 2.07656 32.0908C1.54579 32.0908 1.02023 32.0908 0.489459 32.0908C0.400997 32.0961 0.302128 32.0803 0.151223 32.0698Z"
          fill="#292929"
        />
        <path
          d="M0.21808 0.517578C1.30043 0.517578 2.33595 0.517578 3.4079 0.517578C3.41831 0.659683 3.43392 0.785999 3.43392 0.912315C3.43392 4.90179 3.43392 8.89126 3.43392 12.8807C3.43392 13.4439 3.45993 13.9965 3.64206 14.5386C3.99591 15.5702 4.68799 16.2228 5.7131 16.5492C6.24908 16.7228 6.80066 16.7544 7.35745 16.7597C8.85609 16.7649 10.3547 16.7597 11.8586 16.7597C11.9731 16.7597 12.0823 16.7597 12.228 16.7597C12.2332 16.8281 12.2488 16.9123 12.2488 16.9913C12.2488 17.4123 12.2436 17.8334 12.2436 18.2544C12.2436 18.5649 12.2384 18.5702 11.921 18.5702C10.5265 18.5702 9.13188 18.5597 7.73211 18.5755C6.5717 18.586 5.4269 18.5071 4.31853 18.1439C2.24749 17.4649 0.899754 16.0913 0.395003 13.9123C0.233691 13.2123 0.202469 12.5123 0.202469 11.8018C0.202469 8.18074 0.197266 4.55968 0.197266 0.938631C0.197266 0.807052 0.207673 0.680736 0.21808 0.517578Z"
          fill="#292929"
        />
        <path
          d="M39.8227 2.72767C39.8123 2.86978 39.8019 2.95399 39.8019 3.0382C39.8227 4.69609 39.8435 6.35399 39.8696 8.00662C39.8852 9.04873 39.9008 10.0908 39.9216 11.1382C39.9684 13.4803 40.0205 15.8171 40.0673 18.1593C40.0777 18.5698 40.0725 18.5698 39.6718 18.5698C38.9954 18.5698 38.3189 18.5698 37.6424 18.5698C37.3302 18.5698 37.3198 18.5435 37.3354 18.2435C37.3458 18.0171 37.3458 17.7856 37.351 17.5593C37.3718 15.8961 37.3874 14.2277 37.4187 12.5645C37.4447 11.1961 37.4863 9.83294 37.5175 8.46452C37.5383 7.51715 37.554 6.56978 37.5696 5.62767C37.5852 4.80662 37.606 3.9803 37.6216 3.15925C37.6216 3.0382 37.6112 2.91715 37.606 2.76978C37.4915 2.75925 37.3926 2.7382 37.2938 2.7382C35.9096 2.75399 34.5202 2.77504 33.1361 2.80136C32.6834 2.80662 32.2359 2.82241 31.7831 2.84346C31.575 2.85399 31.4813 2.7803 31.4865 2.55925C31.4969 2.14873 31.4865 1.73294 31.4865 1.32241C31.4865 1.02767 31.5178 0.996094 31.8196 0.996094C33.9166 0.996094 36.0137 0.996094 38.1159 0.996094C40.5981 0.996094 43.075 0.996094 45.5571 0.996094C45.8693 0.996094 45.8954 1.01715 45.8954 1.34346C45.8954 1.72767 45.8954 2.11715 45.8954 2.50136C45.8954 2.81715 45.8693 2.84346 45.5519 2.8382C43.7671 2.80662 41.9822 2.77504 40.1974 2.7382C40.0777 2.72767 39.9684 2.72767 39.8227 2.72767Z"
          fill="#292929"
        />
        <path
          d="M23.8269 9.61205C23.9466 9.61205 24.0507 9.61205 24.1495 9.61205C26.0124 9.61205 27.8753 9.61205 29.7434 9.61205C30.1077 9.61205 30.1181 9.62784 30.1233 10.0015C30.1233 10.2384 30.1233 10.4699 30.1233 10.7699C29.0305 10.7226 27.9898 10.6752 26.9491 10.6384C25.9136 10.6015 24.878 10.5647 23.8217 10.5278C23.8165 10.6542 23.8061 10.7489 23.8061 10.8436C23.8165 12.6226 23.8269 14.4015 23.8321 16.1805C23.8373 16.8647 23.8321 17.5489 23.8321 18.2331C23.8321 18.5436 23.8061 18.5699 23.5095 18.5699C22.781 18.5699 22.0525 18.5699 21.324 18.5752C21.1522 18.5752 21.043 18.5384 21.043 18.3278C21.0586 16.891 21.069 15.4542 21.0742 14.012C21.0794 12.4489 21.0638 10.891 21.0846 9.32784C21.095 8.22257 21.0898 7.11731 21.1991 6.01731C21.3396 4.56994 21.9692 3.3331 23.1088 2.40152C24.2172 1.50152 25.5129 1.09626 26.9127 1.02784C27.8233 0.980469 28.7339 0.990995 29.6393 0.980469C29.733 0.980469 29.8267 0.990995 29.962 1.00152C29.962 1.43836 29.9724 1.85415 29.9464 2.26994C29.9412 2.32257 29.7642 2.40678 29.6654 2.40678C28.9212 2.41731 28.1719 2.391 27.4278 2.42257C26.5848 2.45942 25.7887 2.691 25.1122 3.23836C24.4826 3.74889 24.0975 4.41205 23.9622 5.20678C23.8841 5.68573 23.8477 6.18047 23.8373 6.66468C23.8113 7.6331 23.8269 8.59626 23.8269 9.61205Z"
          fill="#292929"
        />
        <path
          d="M35.6611 27.37C35.734 27.7805 35.7756 28.1384 35.8641 28.4805C36.1138 29.4015 36.5457 30.1963 37.3783 30.7278C37.9039 31.0647 38.4763 31.2015 39.0747 31.2436C40.0217 31.3121 40.9168 31.0752 41.7702 30.6752C42.1396 30.5015 42.5039 30.3226 42.9098 30.1278C43.0034 30.291 43.0919 30.4489 43.2012 30.6278C43.0919 30.7121 43.0034 30.7805 42.915 30.8384C41.9991 31.4647 41.0208 31.9542 39.9281 32.1384C37.6697 32.5173 35.4374 31.7331 34.2405 29.6963C33.3611 28.2015 33.1686 26.5963 33.7514 24.97C34.2249 23.6489 35.1095 22.6173 36.3896 21.9857C38.0079 21.191 39.6523 21.1752 41.2394 22.0015C42.9358 22.8857 43.8256 24.3542 43.9297 26.291C43.9453 26.57 43.9245 26.8489 43.9349 27.1331C43.9401 27.3278 43.8516 27.4068 43.6695 27.4015C43.4717 27.391 43.2688 27.3752 43.0711 27.3752C42.2073 27.3805 41.3383 27.4015 40.4745 27.3963C39.3557 27.3963 38.2421 27.3805 37.1233 27.37C36.6654 27.3647 36.2075 27.37 35.6611 27.37ZM41.7337 26.1647C41.6401 25.6963 41.5724 25.2752 41.4788 24.8594C41.2082 23.67 40.386 22.6384 38.9914 22.491C38.1276 22.4015 37.3783 22.6278 36.7799 23.2857C36.1711 23.9542 35.8953 24.7752 35.76 25.6594C35.734 25.8173 35.7236 25.9805 35.7027 26.17C37.7165 26.1647 39.6887 26.1647 41.7337 26.1647Z"
          fill="#292929"
        />
        <path
          d="M30.9664 30.6383C30.8312 30.7331 30.7219 30.812 30.6074 30.8857C29.6812 31.512 28.6873 32.0015 27.5841 32.1594C24.514 32.591 21.9798 30.9173 21.3502 27.9699C21.0536 26.5699 21.2565 25.2278 21.985 24.0173C22.9008 22.491 24.2642 21.6173 26.049 21.4436C26.9128 21.3594 27.7454 21.4436 28.5468 21.7699C29.8581 22.3015 30.7895 23.2225 31.3359 24.5489C31.6689 25.3594 31.7782 26.2015 31.747 27.0699C31.7366 27.2962 31.6585 27.3699 31.4348 27.3699C30.4565 27.3699 29.4782 27.3962 28.4999 27.3962C27.4384 27.3962 26.3717 27.3752 25.3101 27.3699C24.8158 27.3647 24.3214 27.3647 23.8323 27.3699C23.723 27.3699 23.6189 27.4015 23.4784 27.4173C23.4993 27.6173 23.5097 27.791 23.5409 27.9594C23.7022 28.8331 23.9936 29.6436 24.6388 30.2962C25.2217 30.8857 25.9345 31.1647 26.7307 31.2331C27.9484 31.3331 29.0567 30.9647 30.1131 30.3962C30.2588 30.3173 30.3993 30.2331 30.5554 30.1804C30.623 30.1594 30.7427 30.1962 30.7895 30.2541C30.8624 30.3541 30.8988 30.4857 30.9664 30.6383ZM23.468 26.1647C25.5026 26.1647 27.48 26.1647 29.5094 26.1647C29.4314 25.7173 29.395 25.2962 29.2805 24.8962C28.9735 23.7962 28.4063 22.9331 27.2355 22.5857C26.2155 22.2857 25.0916 22.5962 24.4203 23.4331C23.9676 24.0015 23.7282 24.6647 23.5773 25.3594C23.5305 25.6068 23.5097 25.8699 23.468 26.1647Z"
          fill="#292929"
        />
        <path
          d="M17.6727 6.79662C17.6831 6.92294 17.6935 7.00715 17.6935 7.09136C17.6935 10.7808 17.6935 14.4756 17.6935 18.165C17.6935 18.5703 17.6935 18.5703 17.2876 18.5703C16.6111 18.5703 15.9346 18.5703 15.2582 18.5703C14.9668 18.5703 14.9408 18.544 14.9408 18.2598C14.9408 16.0966 14.9356 13.9282 14.946 11.765C14.9512 10.2124 14.9772 8.66504 14.9876 7.11241C14.9928 6.76504 15.0136 6.75451 15.3622 6.76504C16.0023 6.78083 16.6475 6.7703 17.2876 6.77557C17.3969 6.7703 17.5165 6.78609 17.6727 6.79662Z"
          fill="#292929"
        />
        <path
          d="M16.1992 4.57586C15.2678 4.56533 14.4924 3.73902 14.508 2.7706C14.5237 1.8127 15.3354 0.986386 16.2513 1.00218C17.3128 1.02323 18.0517 1.86007 18.0309 2.81797C18.0101 3.82849 17.0942 4.59165 16.1992 4.57586Z"
          fill="#292929"
        />
      </g>
      <defs>
        <clipPath id="clip0_1722_119306">
          <rect
            width="46"
            height="32"
            fill="white"
            transform="translate(0 0.328125)"
          />
        </clipPath>
      </defs>
    </Root>
  )
}

export { LogoLiftmee }
