import AdyenCheckout from '@adyen/adyen-web'
import PaypalElement from '@adyen/adyen-web/dist/types/components/PayPal'
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse'
import axios from 'axios'
import { useState } from 'react'

import { Contract, Studio } from '@definitions/types/symfonyTypesd'

interface useHandlePaymentReturnType {
  initPayment: () => Promise<CreateCheckoutSessionResponse | null>
  paymentSession: CreateCheckoutSessionResponse | null
  loadPaypalButton: (
    paymentSession: CreateCheckoutSessionResponse,
    buttonContainer: HTMLElement,
    paymentCompletedCallback: (data: any, actions: any) => void,
    paymentErrorCallback: (data: any, actions: any) => void
  ) => Promise<PaypalElement | null>
}

const useHandlePayment = (
  studio: Studio,
  contract: Contract,
  shopperEmail: string
): useHandlePaymentReturnType => {
  const [paymentSession, setPaymentSession] =
    useState<CreateCheckoutSessionResponse | null>(null)

  const initPayment =
    async (): Promise<CreateCheckoutSessionResponse | null> => {
      try {
        const payload = {
          studio,
          contract,
          shopperEmail,
        }

        const response = await axios.post('/api/initPayment', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        setPaymentSession(response.data)

        return response.data as CreateCheckoutSessionResponse
      } catch (error: any) {
        console.error('Payment init failed:', error)
      }

      return null
    }

  const loadPaypalButton = async (
    paymentSession: CreateCheckoutSessionResponse,
    buttonContainer: HTMLElement,
    paymentCompletedCallback: (data: any, actions: any) => void,
    paymentErrorCallback: (data: any, actions: any) => void
  ): Promise<PaypalElement | null> => {
    const paypalConfiguration: CoreOptions = {
      showPayButton: true,
      environment: process.env.adyenEnv,
      clientKey: process.env.adyenClientKey,
      session: paymentSession,
      onPaymentCompleted: (data: any, actions: any) => {
        if (data.resultCode === 'Authorised') {
          paymentCompletedCallback(data, actions)
        } else {
          paymentErrorCallback(data, actions)
          console.error('payment failed', data)
        }
      },
    }

    const checkout = await AdyenCheckout(paypalConfiguration)

    return checkout
      .create('paypal', {
        intent: 'authorize',
        environment: process.env.adyenEnv,
        blockPayPalPayLaterButton: true,
      })
      .mount(buttonContainer)
  }

  return { initPayment, paymentSession, loadPaypalButton }
}

export default useHandlePayment
