import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import Link from '@components/atoms/Link'
import LinkButton from '@components/atoms/LinkButton'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'
import {
  Container,
  Content,
  StyledHeadline,
  StyledImage,
} from '@components/storyblok/molecules/StoryblokImageTextCol/StoryblokImageTextCol.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { ImageTextColStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokImageTextCol.styles'

interface StoryblokButtonProps extends ImageTextColStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokButtonProps
}

interface Props extends PropsWithClassName {}

const StoryblokImageTextCol: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, image, content, link, linkName } = blok
  const theme: any = useTheme()
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-image-text-col-root'}
    >
      <Container>
        <StyledImage src={image?.filename ?? ''} alt={image?.alt ?? ''} />

        {headline && (
          <StyledHeadline importance={HeadlineImportances.h5}>
            {headline}
          </StyledHeadline>
        )}

        <Content>
          <StoryblokRichText content={content} />
        </Content>

        {link && linkName != '' && (
          <LinkButton
            label={linkName ?? 'Mehr erfahren'}
            disabled={false}
            loading={false}
            modifier={ButtonModifier.MINIMAL}
            href={getUrlFromLink(link, theme) ?? ''}
            icon={<IconArrowRight />}
            iconAlign={IconAligns.RIGHT}
          >
            {linkName ?? 'Mehr erfahren'}
          </LinkButton>
        )}
      </Container>
    </Root>
  )
}

export { StoryblokImageTextCol }
