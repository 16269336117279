import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import Link from 'next/link'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputDate from '@components/atoms/Form/InputDate'
import InputRadioGroup from '@components/atoms/Form/InputRadioGroup'
import { RadioButtonDirection } from '@components/atoms/Form/InputRadioGroup/InputRadioGroup'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { AllMetaLinks } from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import { ButtonCol, Feedback, Root, StyledButton } from './FormFranchise.styles'

export interface FormFranchiseType {
  name: string
  anschrift: string
  telefon: string
  email: string
  birthdate: string
  bundesland: string
  suchgebiet: string
  start: string
  ausbildung: string
  beruflicheTaetigkeit: string
  erfahrungKaufmann: string
  erfahrungWellness: string
  selbstaendig: string
  selberFuehren: string
  grundSelbstaendig: string
  warumFranchise: string
  groessteVorteile: string
  einkommensVorstellungen: string
  eigenKapital: string
  wieAufmerksam: string
  nachricht: string
  privacy: boolean
}

interface Props extends PropsWithClassName {
  userData?: FormFranchiseType
  handleSubmitForm: (data: FormFranchiseType) => void
  formId: string
}

const FormFranchise: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [formSend, setFormSend] = useState<boolean>(false)
  const { handleSubmitForm, userData, formId, dataTestId } = props
  const [loading, setLoading] = useState<boolean>(false)
  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { register, handleSubmit, formState } = useForm<FormFranchiseType>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Dies ist ein Pflichtfeld.'),
        anschrift: yup.string(),
        telefon: yup.string(),
        email: yup
          .string()
          .trim()
          .email('Dies ist keine valide E-Mail-Adresse')
          .required('Dies ist ein Pflichtfeld.')
          .test(
            'is-valid-email',
            'Dies ist keine valide E-Mail-Adresse',
            (value) => {
              if (!value) return false
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
            }
          ),
        birthdate: yup.string(),
        bundesland: yup.string(),
        suchgebiet: yup.string(),
        start: yup.string(),
        ausbildung: yup.string(),
        beruflicheTaetigkeit: yup.string(),
        erfahrungKaufmann: yup.string(),
        erfahrungWellness: yup.string(),
        selbstaendig: yup.string(),
        selberFuehren: yup.string(),
        grundSelbstaendig: yup.string(),
        warumFranchise: yup.string(),
        groessteVorteile: yup.string(),
        einkommensVorstellungen: yup.string(),
        eigenKapital: yup.string(),
        wieAufmerksam: yup.string(),
        nachricht: yup.string(),
        privacy: yup
          .boolean()
          .oneOf(
            [true],
            'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
          ),
      })
    ) as Resolver<FormFranchiseType>,
    defaultValues: userData,
  })

  const onSubmit = async (data: FormFranchiseType) => {
    setLoading(true)
    await sendFormDataMail('Franchise-Anfrage', data, 'franchise', [])
    setLoading(false)
    setFormSend(true)
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ?? 'form-franchise-root'}
    >
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputText
                register={register('name')}
                forId={`${formId}-name`}
                label="Vollständiger Name"
                error={formState.errors.name ?? false}
                errorText={formState.errors?.name?.message}
                required
              />
              <InputText
                register={register('anschrift')}
                forId={`${formId}-anschrift`}
                label="Anschrift"
                error={formState.errors.anschrift ?? false}
                errorText={formState.errors?.anschrift?.message}
              />
              <InputDate
                value=""
                register={register('birthdate')}
                forId="birthdate"
                label="Geburstdatum"
                error={formState.errors.birthdate ?? false}
                errorText={formState.errors?.birthdate?.message}
              />
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="E-Mailadresse"
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
                required
              />
              <InputText
                register={register('telefon')}
                forId={`${formId}-telefon`}
                label="Telefon"
                error={formState.errors.telefon ?? false}
                errorText={formState.errors?.telefon?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h4}>
                Suchgebiet
              </Headline>
              <InputTextarea
                rows={3}
                label="In welchem (Bundes-) Land möchten Sie ein SUNPOINT Studio eröffnen?"
                forId={`${formId}-bundesland`}
                register={register('bundesland')}
                error={formState.errors.bundesland ?? false}
                errorText={formState.errors?.bundesland?.message}
              />
              <InputTextarea
                rows={3}
                label="Ist Ihr Suchgebiet auf bestimmte Städte beschränkt?"
                forId={`${formId}-suchgebiet`}
                register={register('suchgebiet')}
                error={formState.errors.suchgebiet ?? false}
                errorText={formState.errors?.suchgebiet?.message}
              />
              <InputDate
                value=""
                register={register('start')}
                forId="start"
                label="Ab wann können Sie SUNPOINT zur Verfügung stehen?"
                error={formState.errors.start ?? false}
                errorText={formState.errors?.start?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h4}>
                Schule und Beruf
              </Headline>
              <InputTextarea
                rows={3}
                label="Schul- und Berufsausbild eintragen"
                forId={`${formId}-ausbildung`}
                register={register('ausbildung')}
                error={formState.errors.ausbildung ?? false}
                errorText={formState.errors?.ausbildung?.message}
              />
              <InputTextarea
                rows={3}
                label="Berufliche Tätigkeit eintragen"
                forId={`${formId}-beruflicheTaetigkeit`}
                register={register('beruflicheTaetigkeit')}
                error={formState.errors.beruflicheTaetigkeit ?? false}
                errorText={formState.errors?.beruflicheTaetigkeit?.message}
              />
              <InputTextarea
                rows={3}
                label="Welche kaufmännischen Erfahrungen haben Sie?"
                forId={`${formId}-erfahrungKaufmann`}
                register={register('erfahrungKaufmann')}
                error={formState.errors.erfahrungKaufmann ?? false}
                errorText={formState.errors?.erfahrungKaufmann?.message}
              />
              <InputTextarea
                rows={3}
                label="Sind besondere Erfahrungen/Kenntnisse im Bereich Beauty/Wellness vorhanden?"
                forId={`${formId}-erfahrungWellness`}
                register={register('erfahrungWellness')}
                error={formState.errors.erfahrungWellness ?? false}
                errorText={formState.errors?.erfahrungWellness?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h4}>
                Angaben zur Selbstständigkeit
              </Headline>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputRadioGroup
                required={true}
                disabled={false}
                label="Waren Sie bereits selbstständig?"
                name="selbstaendig"
                forId="selbstaendig"
                error={formState.errors.selbstaendig ?? false}
                errorText={formState.errors?.selbstaendig?.message}
                direction={RadioButtonDirection.row}
                radios={[
                  {
                    label: 'Ja',
                    forId: 'anrede-ja',
                    value: 'ja',
                  },
                  {
                    label: 'Nein',
                    forId: 'anrede-nein',
                    value: 'nein',
                  },
                ]}
                register={register('selbstaendig')}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputRadioGroup
                required={true}
                disabled={false}
                label="Wollen Sie das SUNPOINT Studio selbst führen?"
                name="selberFuehren"
                forId="selberFuehren"
                error={formState.errors.selberFuehren ?? false}
                errorText={formState.errors?.selberFuehren?.message}
                direction={RadioButtonDirection.row}
                radios={[
                  {
                    label: 'Ja',
                    forId: 'selberFuehren-ja',
                    value: 'ja',
                  },
                  {
                    label: 'Nein',
                    forId: 'selberFuehren-nein',
                    value: 'nein',
                  },
                ]}
                register={register('selberFuehren')}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={3}
                label="Warum möchten Sie sich selbstständig machen?"
                forId={`${formId}-grundSelbstaendig`}
                register={register('grundSelbstaendig')}
                error={formState.errors.grundSelbstaendig ?? false}
                errorText={formState.errors?.grundSelbstaendig?.message}
              />
              <InputTextarea
                rows={3}
                label="Warum interessieren Sie sich für unser Franchise-Konzept?"
                forId={`${formId}-warumFranchise`}
                register={register('warumFranchise')}
                error={formState.errors.warumFranchise ?? false}
                errorText={formState.errors?.warumFranchise?.message}
              />
              <InputTextarea
                rows={3}
                label="Was glauben Sie, sind die größten Vorteile, bei SUNPOINT Franchise-Partner zu werden?"
                forId={`${formId}-groessteVorteile`}
                register={register('groessteVorteile')}
                error={formState.errors.groessteVorteile ?? false}
                errorText={formState.errors?.groessteVorteile?.message}
              />
              <InputTextarea
                rows={3}
                label="Welche Einkommensvorstellungen haben Sie (brutto im Jahr)?"
                forId={`${formId}-einkommensVorstellungen`}
                register={register('einkommensVorstellungen')}
                error={formState.errors.einkommensVorstellungen ?? false}
                errorText={formState.errors?.einkommensVorstellungen?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={3}
                label="Wie viel frei verfügbares Eigenkapital können Sie zur Verwirklichung Ihrer Selbstständigkeit aufbringen?"
                forId={`${formId}-eigenKapital`}
                register={register('eigenKapital')}
                error={formState.errors.eigenKapital ?? false}
                errorText={formState.errors?.eigenKapital?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Headline importance={HeadlineImportances.h4}>Weiteres</Headline>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={3}
                label="Wie sind Sie auf uns aufmerksam geworden?"
                forId={`${formId}-wieAufmerksam`}
                register={register('wieAufmerksam')}
                error={formState.errors.wieAufmerksam ?? false}
                errorText={formState.errors?.wieAufmerksam?.message}
              />
              <InputTextarea
                rows={3}
                label="Ihre Nachricht an uns"
                forId={`${formId}-nachricht`}
                register={register('nachricht')}
                error={formState.errors.nachricht ?? false}
                errorText={formState.errors?.nachricht?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required
                label={
                  <>
                    Ich willige ein, dass meine gemachten Angaben durch die WM
                    BEAUTYSYSTEMS AG & Co. KG im Rahmen der{' '}
                    <ThemeBasedLink
                      linkSunpoint={AllMetaLinks.sunpoint.privacy}
                      linkWellmaxx={AllMetaLinks.wellmaxx.privacy}
                      text={'Datenschutzerklärung'}
                    />{' '}
                    bearbeitet werden. Zu Angaben die ich im Zuge eines
                    Standortangebotes gemacht habe willige ich ein, dass diese
                    im Rahmen der{' '}
                    <ThemeBasedLink
                      linkSunpoint={AllMetaLinks.sunpoint.privacy}
                      linkWellmaxx={AllMetaLinks.wellmaxx.privacy}
                      text={'Datenschutzerklärung'}
                    />{' '}
                    verarbeitet und an die SUNPOINT AG & Co. KG zum Zwecke der
                    weiteren Bearbeitung weitergeleitet werden. Dieser
                    Einwilligung kann ich jederzeit elektronisch (
                    <Link href={'mailto:datenschutz@wm-beautysystems.com'}>
                      datenschutz@wm-beautysystems.com
                    </Link>
                    ) oder schriftlich widerrufen.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Absenden
              </StyledButton>
            </ButtonCol>
          </Row>
        </form>
      )}
      {formSend && (
        <>
          {resultSendMail && (
            <>
              <Headline importance={HeadlineImportances.h3}>
                Ihre Anfrage wurde versendet.
              </Headline>
              <Feedback>
                <Text variant={TextVariants.lead}>
                  <b>Sie bekommen umgehend eine Rückantwort.</b>
                </Text>
              </Feedback>
            </>
          )}
          {!resultSendMail && (
            <>
              <Headline importance={HeadlineImportances.h3}>Fehler</Headline>
              <Feedback>
                <Text variant={TextVariants.lead}>
                  <b>Es ist leider ein Fehler aufgetreten.</b>
                </Text>
              </Feedback>
            </>
          )}
        </>
      )}
    </Root>
  )
}

export { FormFranchise }
