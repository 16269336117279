import styled from 'styled-components'

export const Root = styled.div`
  padding: ${(props) => props.theme.padding.big} 2rem;
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
