import { gql } from '@apollo/client'

export const CREATE_SEND_MAIL_MUTATION = gql`
  mutation CreateSendMail($input: createSendMailInput!) {
    createSendMail(input: $input) {
      sendMail {
        success
        error
      }
    }
  }
`
