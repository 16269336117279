import { NewsletterFormularStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokNewsletter } from './StoryblokNewsletter'

export default StoryblokNewsletter

export const testBlok: NewsletterFormularStoryblok = {
  _uid: '842b50f8-55c1-4472-888f-6dbaec690c1a',
  list: 'sunpoint',
  component: 'newsletter-formular',
  _editable:
    '<!--#storyblok#{"name": "newsletter-formular", "space": "235453", "uid": "842b50f8-55c1-4472-888f-6dbaec690c1a", "id": "382433939"}-->',
}
