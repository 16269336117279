import styled from 'styled-components'

import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  overflow-y: scroll;
  p {
    margin-bottom: 0;
  }
`

export const Error = styled.div``

export const Result = styled(Row)`
  padding: 0.625rem 0.625rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.palette.default.grey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  max-width: 100%;
  &:last-child {
    border: none;
  }
  &:hover {
    background: ${(props) => props.theme.palette.default.greyLight};
  }
`

export const Info = styled(Col)`
  display: flex;
  flex-direction: column;
  display: flex;
  flex: 1;
  text-align: center;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    text-align: left;
  }
`

export const Distance = styled.div`
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: ${(props) => props.theme.margin.default};
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`

export const Control = styled(Col)`
  justify-content: center;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    justify-content: flex-end;
  }
`

export const StyledText = styled(Text)`
  margin: 0;
`

export const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.margin.extrasmall} 0 0 0;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    margin: 0;
  }
`
