import React, { PropsWithChildren } from 'react'

import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import FormField from '@helper/FormField'

import { Input, Root, StyledInputLabel, Switch } from './InputSwitch.styles'

interface Props extends FormField {
  onChange: (newValue: boolean) => void
  checked?: boolean
}

const InputSwitch: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    dataTestId,
    errorText,
    label,
    error,
    required,
    disabled,
    forId,
    onChange,
  } = props

  return (
    <Root className={props.className} data-testid={'input-switch-root'}>
      <Input
        onChange={(event) => {
          onChange(event.target.checked)
        }}
        id={forId}
        type="checkbox"
        checked={props.checked}
      />
      <Switch />
      <StyledInputLabel
        forId={forId}
        error={error ?? false}
        required={required ?? false}
        disabled={disabled ?? false}
      >
        {label}
      </StyledInputLabel>
      {error && (
        <Text variant={TextVariants.meta} dataTestId={dataTestId + '-error'}>
          {errorText}
        </Text>
      )}
    </Root>
  )
}

export { InputSwitch }
