import React, { PropsWithChildren } from 'react'

import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { StyledLink } from '@components/atoms/Link/Link.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { themeSunpoint } from '@helper/theme'

interface Props extends PropsWithClassName {
  children: string | React.ReactNode | number
  href: string
  onClick?: () => void
  fontSize?: string
  fontColor?: string
  fontWeight?: number
}

const Link: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { href, children, dataTestId, fontSize, fontWeight, onClick } = props

  return (
    <StyledLink
      href={href}
      data-testid={dataTestId ? dataTestId : 'link-root'}
      fontSize={fontSize ?? themeSunpoint.font.text.lead.mobile.fontSize}
      fontWeight={fontWeight ?? 300}
      onClick={onClick ? onClick : () => {}}
    >
      <span>{children}</span>
      <IconArrowRight />
    </StyledLink>
  )
}

export { Link }
