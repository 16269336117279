import styled from 'styled-components'

import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputSwitch from '@components/atoms/Form/InputSwitch'
import Text from '@components/atoms/Text'

export const Root = styled.div``

export const IndividualWorkTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
`

export const Weekday = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.default.white};
  gap: 0 0.25rem;
  border-radius: 1rem;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    flex-direction: row;
  }
`

export const WeekdayCol = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`

export const WeekdayName = styled(WeekdayCol)`
  flex-basis: 20%;
  background-color: ${(props) => props.theme.palette.default.white};
  padding: 0.75rem;
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    padding-bottom: 0.25rem;
  }
`

export const WeekdayWholeDay = styled(WeekdayCol)`
  flex-basis: 20%;
  background-color: ${(props) => props.theme.palette.default.white};
  padding: 0.5rem 0;
`

export const WeekdayTimes = styled(WeekdayCol)`
  flex-grow: 1;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    padding: 0.5rem 0;
  }
`

export const WeekdayLabel = styled(Text)`
  font-size: ${(props) => props.theme.font.headline.four.mobile.fontSize};
  margin: 0;
`

export const StyledInputSwitch = styled(InputSwitch)`
  margin: 0;
`

export const StyledInputCheckbox = styled(InputCheckbox)`
  margin: 0;
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    flex-direction: column;
  }
`
