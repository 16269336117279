import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import Link from 'next/link'
import React, { type PropsWithChildren, useState } from 'react'
import { type Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import { FormContactType } from '@components/molecules/Forms/FormContact/FormContact'

import { type PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { AllMetaLinks } from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import { ButtonCol, Root, StyledButton } from './FormOfferLocation.styles'

export interface FormOfferLocationType {
  name: string
  email: string
  message: string
  phone: string
  privacy: boolean
}

interface Props extends PropsWithClassName {
  userData?: FormOfferLocationType
  handleSubmitForm: (data: FormOfferLocationType) => void
  formId: string
}

const FormOfferLocation: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSend, setFormSend] = useState<boolean>(false)
  const { handleSubmitForm, userData, formId } = props

  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { register, handleSubmit, formState } = useForm<FormOfferLocationType>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Dies ist ein Pflichtfeld.'),
        email: yup
          .string()
          .trim()
          .email('Dies ist keine valide E-Mail-Adresse')
          .required('Dies ist ein Pflichtfeld.')
          .test(
            'is-valid-email',
            'Dies ist keine valide E-Mail-Adresse',
            (value) => {
              if (!value) return false
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
            }
          ),
        message: yup.string().required('Dies ist ein Pflichtfeld.'),
        phone: yup.string().required('Dies ist ein Pflichtfeld.'),
        privacy: yup
          .boolean()
          .oneOf(
            [true],
            'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
          ),
      })
    ) as Resolver<FormOfferLocationType>,
    defaultValues: userData,
  })

  const onSubmit = async (data: FormContactType) => {
    setLoading(true)
    await sendFormDataMail('Standort Angebot', data, 'offer_location', [])
    setLoading(false)
    setFormSend(true)
  }

  return (
    <Root className={props.className} data-testid={'form-offer-location-root'}>
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('name')}
                forId={`${formId}-name`}
                label="Vor- und Zuname"
                error={formState.errors.name ?? false}
                errorText={formState.errors?.name?.message}
                required
              />
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="Ihre E-Mailadresse"
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('phone')}
                forId={`${formId}-phone`}
                label="Ihre Rückrufnummer"
                error={formState.errors.phone ?? false}
                errorText={formState.errors?.phone?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                register={register('message')}
                forId={`${formId}-message`}
                label="Nachricht an uns"
                error={formState.errors.message ?? false}
                errorText={formState.errors?.message?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required
                label={
                  <>
                    Ich willige ein, dass meine gemachten Angaben durch die WM
                    BEAUTYSYSTEMS AG & Co. KG im Rahmen der{' '}
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />{' '}
                    bearbeitet werden und an die SUNPOINT Deutschland AG & Co.
                    KG zum Zwecke der weiteren Bearbeitung weitergeleitet
                    werden. Dieser Einwilligung kann ich jederzeit elektronisch
                    (
                    <Link href={'mailto:datenschutz@sunpoint.de'}>
                      datenschutz@sunpoint.de
                    </Link>
                    ) oder schriftlich widerrufen.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Absenden
              </StyledButton>
            </ButtonCol>
          </Row>
        </form>
      )}
      {formSend && (
        <>
          <Text variant={TextVariants.lead}>
            <b>
              {resultSendMail === true && (
                <>
                  Vielen Dank für Ihre Kontaktaufnahme.
                  <br />
                  Wir werden uns in Kürze bei Ihnen melden.
                </>
              )}
              {resultSendMail === false && 'Es ist ein Fehler aufgetreten'}
            </b>
          </Text>
        </>
      )}
    </Root>
  )
}

export { FormOfferLocation }
