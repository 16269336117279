import { SingleOption } from '@components/atoms/Form/InputSelect/InputSelect'

export enum countries {
  DEUTSCHLAND = 'Deutschland',
  OESTERREICH = 'Österreich',
  LUXEMBURG = 'Luxemburg',
}

export enum countryCode {
  DEUTSCHLAND = 'DE',
  OESTERREICH = 'AR',
  LUXEMBURG = 'LU',
}

export const Countries: SingleOption[] = [
  {
    value: '',
    label: 'Bitte wählen Sie ein Land',
  },
  {
    value: countryCode.DEUTSCHLAND,
    label: countries.DEUTSCHLAND,
  },
  {
    value: countryCode.OESTERREICH,
    label: countries.OESTERREICH,
  },
  {
    value: countryCode.LUXEMBURG,
    label: countries.LUXEMBURG,
  },
]

export enum FederalStateCodeDE {
  BADEN_WUERTTEMBERG = 'BW',
  BAYERN = 'BY',
  BERLIN = 'BE',
  BRANDENBURG = 'BB',
  BREMEN = 'HB',
  HAMBURG = 'HH',
  HESSEN = 'HE',
  MECKLENBURG_VORPOMMERN = 'MV',
  NIEDERSACHSEN = 'NI',
  NORDRHEIN_WESTFALEN = 'NW',
  RHEINLAND_PFALZ = 'RP',
  SAARLAND = 'SL',
  SACHSEN = 'SN',
  SACHSEN_ANHALT = 'ST',
  SCHLESWIG_HOLSTEIN = 'SH',
  THUERINGEN = 'TH',
}

export enum FederalStateDE {
  BADEN_WUERTTEMBERG = 'Baden-Württemberg',
  BAYERN = 'Bayern',
  BERLIN = 'Berlin',
  BRANDENBURG = 'Brandenburg',
  BREMEN = 'Bremen',
  HAMBURG = 'Hamburg',
  HESSEN = 'Hessen',
  MECKLENBURG_VORPOMMERN = 'Mecklenburg-Vorpommern',
  NIEDERSACHSEN = 'Niedersachsen',
  NORDRHEIN_WESTFALEN = 'Nordrhein-Westfalen',
  RHEINLAND_PFALZ = 'Rheinland-Pfalz',
  SAARLAND = 'Saarland',
  SACHSEN = 'Sachsen',
  SACHSEN_ANHALT = 'Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN = 'Schleswig-Holstein',
  THUERINGEN = 'Thüringen',
}

export const deutschlandBundeslaender: SingleOption[] = [
  { value: '', label: 'Bitte wählen Sie ein Bundesland' },
  {
    value: FederalStateCodeDE.BADEN_WUERTTEMBERG,
    label: FederalStateDE.BADEN_WUERTTEMBERG,
  },
  { value: FederalStateCodeDE.BAYERN, label: FederalStateDE.BAYERN },
  { value: FederalStateCodeDE.BERLIN, label: FederalStateDE.BERLIN },
  { value: FederalStateCodeDE.BRANDENBURG, label: FederalStateDE.BRANDENBURG },
  { value: FederalStateCodeDE.BREMEN, label: FederalStateDE.BREMEN },
  { value: FederalStateCodeDE.HAMBURG, label: FederalStateDE.HAMBURG },
  { value: FederalStateCodeDE.HESSEN, label: FederalStateDE.HESSEN },
  {
    value: FederalStateCodeDE.MECKLENBURG_VORPOMMERN,
    label: FederalStateDE.MECKLENBURG_VORPOMMERN,
  },
  {
    value: FederalStateCodeDE.NIEDERSACHSEN,
    label: FederalStateDE.NIEDERSACHSEN,
  },
  {
    value: FederalStateCodeDE.NORDRHEIN_WESTFALEN,
    label: FederalStateDE.NORDRHEIN_WESTFALEN,
  },
  {
    value: FederalStateCodeDE.RHEINLAND_PFALZ,
    label: FederalStateDE.RHEINLAND_PFALZ,
  },
  { value: FederalStateCodeDE.SAARLAND, label: FederalStateDE.SAARLAND },
  { value: FederalStateCodeDE.SACHSEN, label: FederalStateDE.SACHSEN },
  {
    value: FederalStateCodeDE.SACHSEN_ANHALT,
    label: FederalStateDE.SACHSEN_ANHALT,
  },
  {
    value: FederalStateCodeDE.SCHLESWIG_HOLSTEIN,
    label: FederalStateDE.SCHLESWIG_HOLSTEIN,
  },
  { value: FederalStateCodeDE.THUERINGEN, label: FederalStateDE.THUERINGEN },
]

export enum FederalStateCodeAT {
  BURGENLAND = 'BU',
  KAERNTEN = 'KA',
  NIEDEROESTERREICH = 'NO',
  OBEROESTERREICH = 'OO',
  SALZBURG = 'SA',
  STEIERMARK = 'ST',
  TIROL = 'TI',
  VORARLBERG = 'VO',
  WIEN = 'WI',
}

export enum FederalStateAT {
  BURGENLAND = 'Burgenland',
  KAERNTEN = 'Kärnten',
  NIEDEROESTERREICH = 'Niederösterreich',
  OBEROESTERREICH = 'Oberösterreich',
  SALZBURG = 'Salzburg',
  STEIERMARK = 'Steiermark',
  TIROL = 'Tirol',
  VORARLBERG = 'Vorarlberg',
  WIEN = 'Wien',
}

export const oesterreichBundeslaender: SingleOption[] = [
  { value: '', label: 'Bitte wählen Sie ein Bundesland' },
  { value: FederalStateCodeAT.BURGENLAND, label: FederalStateAT.BURGENLAND },
  { value: FederalStateCodeAT.KAERNTEN, label: FederalStateAT.KAERNTEN },
  {
    value: FederalStateCodeAT.NIEDEROESTERREICH,
    label: FederalStateAT.NIEDEROESTERREICH,
  },
  {
    value: FederalStateCodeAT.OBEROESTERREICH,
    label: FederalStateAT.OBEROESTERREICH,
  },
  { value: FederalStateCodeAT.SALZBURG, label: FederalStateAT.SALZBURG },
  { value: FederalStateCodeAT.STEIERMARK, label: FederalStateAT.STEIERMARK },
  { value: FederalStateCodeAT.TIROL, label: FederalStateAT.TIROL },
  { value: FederalStateCodeAT.VORARLBERG, label: FederalStateAT.VORARLBERG },
  { value: FederalStateCodeAT.WIEN, label: FederalStateAT.WIEN },
]

export enum FederalStateCodeLU {
  DIEKIRCH = 'DI',
  GREVENMACHER = 'GR',
  LUXEMBURG = 'LU',
}

export enum FederalStateLU {
  DIEKIRCH = 'Diekirch',
  GREVENMACHER = 'Grevenmacher',
  LUXEMBURG = 'Luxemburg',
}

export const luxemburgDistrikte: SingleOption[] = [
  { value: '', label: 'Bitte wählen Sie einen Distrikt' },
  { value: FederalStateCodeLU.DIEKIRCH, label: FederalStateLU.DIEKIRCH },
  {
    value: FederalStateCodeLU.GREVENMACHER,
    label: FederalStateLU.GREVENMACHER,
  },
  { value: FederalStateCodeLU.LUXEMBURG, label: FederalStateLU.LUXEMBURG },
]
