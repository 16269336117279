import styled from 'styled-components'

interface HeadlineProps {}

export const H1 = styled.h1<HeadlineProps>`
  word-wrap: break-word;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin.default};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.one.mobile.fontSize};
  line-height: ${(props) => props.theme.font.headline.one.mobile.lineHeight};
  font-weight: ${(props) => props.theme.font.headline.one.mobile.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.one.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.one.desktop.fontSize};
    line-height: ${(props) => props.theme.font.headline.one.desktop.lineHeight};
    font-weight: ${(props) => props.theme.font.headline.one.desktop.fontWeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.one.desktop.letterSpacing};
  }
`
export const H2 = styled.h2<HeadlineProps>`
  word-wrap: break-word;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin.default};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.two.mobile.fontSize};
  line-height: ${(props) => props.theme.font.headline.two.mobile.lineHeight};
  font-weight: ${(props) => props.theme.font.headline.two.mobile.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.two.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.two.desktop.fontSize};
    line-height: ${(props) => props.theme.font.headline.two.desktop.lineHeight};
    font-weight: ${(props) => props.theme.font.headline.two.desktop.fontWeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.two.desktop.letterSpacing};
  }
`
export const H3 = styled.h3<HeadlineProps>`
  word-wrap: break-word;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin.default};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.three.mobile.fontSize};
  line-height: ${(props) => props.theme.font.headline.three.mobile.lineHeight};
  font-weight: ${(props) => props.theme.font.headline.three.mobile.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.three.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.three.desktop.fontSize};
    line-height: ${(props) =>
      props.theme.font.headline.three.desktop.lineHeight};
    font-weight: ${(props) =>
      props.theme.font.headline.three.desktop.fontWeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.three.desktop.letterSpacing};
  }
`
export const H4 = styled.h4<HeadlineProps>`
  word-wrap: break-word;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin.default};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.four.mobile.fontSize};
  line-height: ${(props) => props.theme.font.headline.four.mobile.lineHeight};
  font-weight: ${(props) => props.theme.font.headline.four.mobile.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.four.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.four.desktop.fontSize};
    line-height: ${(props) =>
      props.theme.font.headline.four.desktop.lineHeight};
    font-weight: ${(props) =>
      props.theme.font.headline.four.desktop.fontWeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.four.desktop.letterSpacing};
  }
`
export const H5 = styled.h5<HeadlineProps>`
  word-wrap: break-word;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin.default};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.headline.five.mobile.fontSize};
  line-height: ${(props) => props.theme.font.headline.five.mobile.lineHeight};
  font-weight: ${(props) => props.theme.font.headline.five.mobile.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.font.headline.five.mobile.letterSpacing};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.headline.five.desktop.fontSize};
    line-height: ${(props) =>
      props.theme.font.headline.five.desktop.lineHeight};
    font-weight: ${(props) =>
      props.theme.font.headline.five.desktop.fontWeight};
    letter-spacing: ${(props) =>
      props.theme.font.headline.five.desktop.letterSpacing};
  }
`
