import React from 'react'
import Popup from 'reactjs-popup'
import styled from 'styled-components'

export const StyledPopup = styled(Popup)`
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  &-content {
    margin: auto;
    background: rgb(255, 255, 255);
    min-height: 200px;
    padding: 2rem 3.5rem 2rem 2rem;
    border-radius: 1rem;
    position: relative;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`

export const CloseContainer = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
`
