import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import { ButtonModifier, ButtonTypes } from '@components/atoms/Button/Button'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ButtonStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokButton.styles'

interface StoryblokButtonProps extends ButtonStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokButtonProps
  buttonType: ButtonTypes
  disabled: boolean
  modifier: ButtonModifier
}

const StoryblokButton = (props: Props): React.ReactNode => {
  const { blok, disabled, buttonType, modifier } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      dataTestId={'storyblok-button-root'}
      disabled={disabled}
      loading={false}
      modifier={ButtonModifier.PRIMARY}
      buttonType={ButtonTypes.BUTTON}
    >
      {blok.label}
    </Root>
  )
}

export { StoryblokButton }
