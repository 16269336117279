import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { BoxBackgrounds } from '@components/atoms/Box/Box'
import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import IconBody from '@components/atoms/Icons/IconBody'
import IconCosmetics from '@components/atoms/Icons/IconCosmetics'
import IconSun from '@components/atoms/Icons/IconSun'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'
import {
  Content,
  StyledBox,
  StyledHeadline,
  StyledSubline,
} from '@components/storyblok/molecules/StoryblokTextIconCol/StoryblokTextIconCol.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { TextIconColStoryblok } from '@definitions/types/storyblokTypes'

import { Root, StyledLinkButton } from './StoryblokTextIconCol.styles'

interface Props extends PropsWithClassName {
  blok: TextIconColStoryblok
}

const StoryblokTextIconCol: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const {
    headline,
    subline,
    content,
    link,
    linkName,
    icon,
    blockBackground,
    backgroundImage,
  } = blok
  const theme: any = useTheme()
  const linkUrl = getUrlFromLink(link, theme)
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'sun':
        return <IconSun />
      case 'body':
        return <IconBody />
      case 'cosmetics':
        return <IconCosmetics />
      default:
        break
    }
  }

  const background =
    blockBackground !== undefined && blockBackground.length >= 1
      ? blockBackground[0].toString()
      : '#ffffff'

  return (
    <Root
      className={props.className}
      data-testid={'storyblok-text-icon-col-root'}
      aria-label={headline?.toString()}
      title={headline?.toString()}
      {...storyblokEditable(blok)}
    >
      <StyledBox
        icon={renderIcon(icon)}
        backgroundImage={backgroundImage}
        background={background}
        backgroundTransparency={true}
      >
        <Content>
          {headline && (
            <StyledHeadline importance={HeadlineImportances.h4}>
              {headline}
            </StyledHeadline>
          )}
          {subline && (
            <StyledSubline importance={HeadlineImportances.h5}>
              {subline}
            </StyledSubline>
          )}
          <StoryblokRichText content={content} />
        </Content>
        {linkUrl && linkName && (
          <StyledLinkButton
            loading={false}
            disabled={false}
            label={'Label'}
            modifier={ButtonModifier.MINIMAL}
            href={linkUrl}
            icon={<IconArrowRight />}
            iconAlign={IconAligns.RIGHT}
            background={background}
          >
            {linkName ?? 'Mehr erfahren'}
          </StyledLinkButton>
        )}
      </StyledBox>
    </Root>
  )
}

export { StoryblokTextIconCol }
