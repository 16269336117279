import {
  TextSectionStoryblok,
  VideoWithOptionalTextStoryblok,
} from '@definitions/types/storyblokTypes'

import { StoryblokVideoWithOptionalText } from './StoryblokVideoWithOptionalText'

export default StoryblokVideoWithOptionalText

export const dummyVideoWithOptionalTextStoryblok: VideoWithOptionalTextStoryblok =
  {
    _uid: 'e3ac569b-5187-473f-80b3-3c89a58fde15',
    video: {
      id: 10354892,
      alt: 'sdasddssd',
      name: '',
      focus: '',
      title: 'saasddds',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/x/6213947d4c/samplevideo_720x480_1mb.mp4',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_external_url: false,
    },
    subline: 'dsaasd',
    headline: 'asdasdsd',
    component: 'video-with-optional-text',
    _editable:
      '<!--#storyblok#{"name": "video-with-optional-text", "space": "235453", "uid": "e3ac569b-5187-473f-80b3-3c89a58fde15", "id": "322787899"}-->',
  }

export const dummyYoutubeWithOptionalTextStoryblok: VideoWithOptionalTextStoryblok =
  {
    _uid: 'e3ac569b-5187-473f-80b3-3c89a58fde15',
    video: undefined,
    youtube: 'https://www.youtube.com/watch?v=tgbNymZ7vqY',
    subline: 'dsaasd',
    headline: 'asdasdsd',
    component: 'video-with-optional-text',
    _editable:
      '<!--#storyblok#{"name": "video-with-optional-text", "space": "235453", "uid": "e3ac569b-5187-473f-80b3-3c89a58fde15", "id": "322787899"}-->',
  }
