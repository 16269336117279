import styled from 'styled-components'

export const Root = styled.div``

export const Content = styled.div`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.mobile.lineHeight};
  font-weight: 300;
  margin-bottom: 3.5rem;
  > :nth-child(3) {
    display: none;
  }
`
