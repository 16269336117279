import { CancellationFormStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokCancellationForm } from './StoryblokCancellationForm'

export default StoryblokCancellationForm

export const dummyBlokCancellationFormStoryblok: CancellationFormStoryblok = {
  _uid: '6c0f21a6-af4b-4bb9-8062-794f0d48cf01',
  subline: 'Subline',
  headline: 'Headline',
  component: 'cancellation-form',
  _editable:
    '<!--#storyblok#{"name": "cancellation-form", "space": "235453", "uid": "6c0f21a6-af4b-4bb9-8062-794f0d48cf01", "id": "322564270"}-->',
}
