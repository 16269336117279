import { FieldError } from 'react-hook-form'
import styled from 'styled-components'

import { InputStyles } from '@helper/mixins'

import InputLabel from '../InputLabel'

interface InputProps {
  error: boolean | FieldError
}

export const Input = styled.input<InputProps>`
  ${(props) => InputStyles(false, props.error)}
`

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.margin.extrasmall};
`

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
`
