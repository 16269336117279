import { BoxBackgrounds } from '@components/atoms/Box/Box'

import { GridSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokGridSection } from './StoryblokGridSection'

export const dummyGridSectionBlok: GridSectionStoryblok = {
  _uid: '18c2604f-4815-4447-b580-70b6250c9e64',
  cols: '3',
  lead: '',
  cards: [
    {
      _uid: '6858dbc6-05f6-4350-8c4c-069481025880',
      icon: {
        id: 678345,
        alt: 'null',
        name: '',
        focus: 'null',
        title: 'null',
        source: null,
        filename: '',
        copyright: 'null',
        fieldtype: 'asset',
        meta_data: {},
      },
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'qwer',
                type: 'text',
              },
            ],
          },
        ],
      },
      label: 'asdf',
      button: [
        {
          _uid: '110aa19f-6a68-4063-8f37-c759c276b14b',
          link: {
            id: '',
            url: '',
            linktype: 'story',
            fieldtype: 'multilink',
            cached_url: '',
          },
          size: 'medium',
          label: 'Button label',
          style: 'default',
          component: 'button',
          button_color: 'primary',
          _editable:
            '<!--#storyblok#{"name": "button", "space": "235453", "uid": "110aa19f-6a68-4063-8f37-c759c276b14b", "id": "322564270"}-->',
        },
      ],
      component: 'grid-card',
      background_color: BoxBackgrounds.WHITE,
      _editable:
        '<!--#storyblok#{"name": "grid-card", "space": "235453", "uid": "6858dbc6-05f6-4350-8c4c-069481025880", "id": "322564270"}-->',
    },
    {
      _uid: 'dc9ac645-0e69-4843-88e9-e7730de2cd6d',
      icon: {
        id: 1234,
        alt: 'null',
        name: '',
        focus: 'null',
        title: 'null',
        source: null,
        filename: '',
        copyright: 'null',
        fieldtype: 'asset',
        meta_data: {},
      },
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'qwer',
                type: 'text',
              },
            ],
          },
        ],
      },
      label: 'qwer',
      button: [
        {
          _uid: 'a91b8d4a-cd32-4101-adf6-3090bd73e2d3',
          link: {
            id: '',
            url: '',
            linktype: 'story',
            fieldtype: 'multilink',
            cached_url: '',
          },
          size: 'medium',
          label: 'Button label',
          style: 'default',
          component: 'button',
          button_color: 'primary',
          _editable:
            '<!--#storyblok#{"name": "button", "space": "235453", "uid": "a91b8d4a-cd32-4101-adf6-3090bd73e2d3", "id": "322564270"}-->',
        },
      ],
      component: 'grid-card',
      background_color: BoxBackgrounds.SECONDARY,
      _editable:
        '<!--#storyblok#{"name": "grid-card", "space": "235453", "uid": "dc9ac645-0e69-4843-88e9-e7730de2cd6d", "id": "322564270"}-->',
    },
    {
      _uid: 'b67a300a-5273-4529-82fe-15f320c539ca',
      icon: {
        id: 2345,
        alt: '§',
        name: '',
        focus: 'null',
        title: 'null',
        source: null,
        filename: '',
        copyright: 'null',
        fieldtype: 'asset',
        meta_data: {},
      },
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'asdf',
                type: 'text',
              },
            ],
          },
        ],
      },
      label: 'asdf',
      button: [
        {
          _uid: 'e58568ba-5de8-44e9-85a8-81dd3bd9d84f',
          link: {
            id: '',
            url: '',
            linktype: 'story',
            fieldtype: 'multilink',
            cached_url: '',
          },
          size: 'medium',
          label: 'Button label',
          style: 'default',
          component: 'button',
          button_color: 'primary',
          _editable:
            '<!--#storyblok#{"name": "button", "space": "235453", "uid": "e58568ba-5de8-44e9-85a8-81dd3bd9d84f", "id": "322564270"}-->',
        },
      ],
      component: 'grid-card',
      background_color: BoxBackgrounds.GREY,
      _editable:
        '<!--#storyblok#{"name": "grid-card", "space": "235453", "uid": "b67a300a-5273-4529-82fe-15f320c539ca", "id": "322564270"}-->',
    },
  ],
  headline: '',
  component: 'grid-section',
  _editable:
    '<!--#storyblok#{"name": "grid-section", "space": "235453", "uid": "18c2604f-4815-4447-b580-70b6250c9e64", "id": "322564270"}-->',
}

export default StoryblokGridSection
