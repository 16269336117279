import React from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './Row.styles'

interface Props extends PropsWithClassName {
  children?: JSX.Element[] | JSX.Element
  dataTestId?: string
}

const Row = (props: Props): React.ReactElement => {
  const { children, dataTestId } = props
  return (
    <Root
      className={props.className + ' row'}
      data-testid={dataTestId ? dataTestId : 'Row-root'}
    >
      {children}
    </Root>
  )
}

export { Row }
