import React, { RefObject, useRef, useState } from 'react'

import ErrorText from '@components/atoms/ErrorText'
import { TextVariants } from '@components/atoms/Text/Text'

import FormField from '@helper/FormField'

import {
  CurrentValue,
  Option,
  Options,
  OptionText,
  Placeholder,
  Prefix,
  Root,
  Select,
  SelectWrap,
  StyledIconArrowLeft,
  StyledInputLabel,
} from './InputSelect.styles'

export interface SingleOption {
  readonly value: string
  readonly label: string
  readonly info?: string
}

interface Props extends FormField {
  value?: SingleOption
  options: SingleOption[]
  onChange: (newValue: SingleOption) => void
  open?: boolean
  valuePrefix?: string
}

const InputSelect = (props: Props): React.ReactElement => {
  const {
    label,
    forId,
    onChange,
    options,
    placeholder,
    value,
    required,
    error,
    disabled,
    errorText,
    dataTestId,
    open,
    valuePrefix,
    register,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(open ? open : false)
  const [currentValue, setCurrentValue] = useState<SingleOption>(
    value ? value : options[0]
  )
  const optionsRefs: RefObject<HTMLDivElement>[] = options.map(() =>
    React.createRef()
  )
  const optionsContainerRef: RefObject<HTMLDivElement> = useRef(null)

  const handleSelectClick = (): void => {
    if (disabled) {
      return
    }
    setIsOpen(!isOpen)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        selectPreviousOption()
        break
      case 'ArrowDown':
        event.preventDefault()
        selectNextOption()
        break
      case 'Enter':
      case ' ':
        event.preventDefault()
        if (isOpen) {
          setIsOpen(false)
          onChange(currentValue)
        } else {
          setIsOpen(true)
        }
        break
      case 'Escape':
        event.preventDefault()
        setIsOpen(false)
        break
    }
  }

  const selectNextOption = (): void => {
    if (currentValue === undefined) {
      return
    }
    setIsOpen(true)
    const arrayPos = options.indexOf(currentValue)
    if (arrayPos + 1 < options.length) {
      setCurrentValue(options[options.indexOf(currentValue) + 1])
      scrollToSelectedOption(options.indexOf(currentValue) + 1)
    } else {
      setCurrentValue(options[0])
      scrollToSelectedOption(0)
    }
  }

  const selectPreviousOption = (): void => {
    if (currentValue === undefined) {
      return
    }
    setIsOpen(true)
    const arrayPos = options.indexOf(currentValue)
    const newArrayPos = arrayPos - 1
    if (newArrayPos < 0) {
      setCurrentValue(options[options.length - 1])
      scrollToSelectedOption(options.length - 1)
    } else {
      setCurrentValue(options[newArrayPos])
      scrollToSelectedOption(newArrayPos)
    }
  }

  const scrollToSelectedOption = (index: number): void => {
    const ref = optionsRefs[index].current
    const optionsContainer = optionsContainerRef.current

    if (optionsContainer !== null && ref !== null) {
      const boundingClientRect = ref.getBoundingClientRect()
      optionsContainer.scrollTo({
        top: index * boundingClientRect.height,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const handleBlur = (): void => {
    setIsOpen(false)
  }

  const handleOptionClick = (option: SingleOption, index: number): void => {
    setCurrentValue(option)
    onChange(option)
    setIsOpen(false)
    scrollToSelectedOption(index)
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'InputSelect-root'}
      onBlur={() => setTimeout(handleBlur, 500)}
    >
      {label && (
        <StyledInputLabel
          disabled={disabled ?? false}
          error={error ?? false}
          required={required ?? false}
          forId={forId}
        >
          {label}
        </StyledInputLabel>
      )}
      <SelectWrap>
        <Select
          disabled={disabled ?? false}
          error={error ?? false}
          isOpen={isOpen}
          onClick={() => handleSelectClick()}
          tabIndex={disabled ? -1 : 0}
          aria-controls={forId}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
            handleKeyDown(event)
          }
        >
          {valuePrefix && (
            <Prefix variant={TextVariants.lead}>{valuePrefix}</Prefix>
          )}
          {currentValue && (
            <CurrentValue variant={TextVariants.lead}>
              {currentValue.label}
            </CurrentValue>
          )}
          {!currentValue && <Placeholder>{placeholder}</Placeholder>}

          <StyledIconArrowLeft isOpen={isOpen} />
        </Select>
        <Options ref={optionsContainerRef} isOpen={isOpen}>
          {options.map((option, index) => (
            <Option
              ref={optionsRefs[index]}
              key={option.value + index}
              active={option.value === currentValue?.value}
              aria-selected={option.value === currentValue?.value}
              onClick={() => handleOptionClick(option, index)}
            >
              <OptionText variant={TextVariants.lead}>
                {option.label}
              </OptionText>
              {option.info && <span>({option.info})</span>}
            </Option>
          ))}
        </Options>
        <input id={forId} type="hidden" value={currentValue.value} />
      </SelectWrap>

      {error && (
        <ErrorText dataTestId={dataTestId + '-error'}>{errorText}</ErrorText>
      )}
    </Root>
  )
}

export { InputSelect }
