import { StudioTourStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokStudioTour } from './StoryblokStudioTour'

export default StoryblokStudioTour

export const testBlok: StudioTourStoryblok = {
  _uid: '81440099-889b-473a-a42d-78d2aa3e1aa9',
  subline:
    'Machen Sie einen virtuellen Rundgang durch ein SUNPOINT Beauty- \u0026 Tanning-Studio',
  headline: 'Virtueller Studiorungang',
  component: 'studio-tour',
  placeholderImage: {
    id: 0,
    name: 'placeholder',
    alt: 'Studio Tour Placeholder',
    filename: '',
  },
  _editable:
    '\u003c!--#storyblok#{"name": "studio-tour", "space": "235453", "uid": "81440099-889b-473a-a42d-78d2aa3e1aa9", "id": "418757635"}--\u003e',
}
