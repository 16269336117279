import React, { type FC, type PropsWithChildren } from 'react'

import { joinConjunction } from '@helper/i18n'
import { prepareStudios } from '@helper/prepareStudios'
import { type PropsWithClassName } from '@helper/PropsWithClassName'

import { type Operator, Studio } from '@definitions/types/symfonyTypesd'

interface Props extends PropsWithClassName {
  operator: Operator
}

const OperatorStudios: FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { operator } = props

  const operatorStudioCount = operator.studios?.totalCount ?? 0

  function getCrossStudioText(): string {
    if (operatorStudioCount <= 1 || !operator.crossStudio) {
      return 'Bei diesem Vertragspartner ist eine studioübergreifende Clubkartennutzung nicht möglich'
    } else if (operatorStudioCount > 20) {
      return 'Bei diesem Vertragspartner ist eine studioübergreifende Clubkartennutzung möglich.'
    } else {
      return `Bei diesem Vertragspartner ist eine studioübergreifende Clubkartennutzung in ${joinConjunction(
        prepareStudios(operator.studios).map((studio) => studio.title)
      )} möglich.`
    }
  }

  return (
    <span data-testid={'operator-studios-root'}>
      Betreiber/in: {operator.name}, {operator.street}, {operator.location}
      <br />
      {getCrossStudioText()}
    </span>
  )
}

export { OperatorStudios }
