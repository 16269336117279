import { useRouter } from 'next/router'
import React, { PropsWithChildren, useMemo } from 'react'
import { useTheme } from 'styled-components'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import Message from '@components/atoms/Message'
import { MessageTypes } from '@components/atoms/Message/Message'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  Contract as ContractType,
  Studio,
} from '@definitions/types/symfonyTypesd'

import { Contract } from '../Contract/Contract'
import { Root, StyledButton } from './Contracts.styles'

export interface Affiliation {
  id: number
}

export interface Cost {
  id: number
}

interface Props extends PropsWithClassName {
  contracts: ContractType[]
  handleSelectContract?: (contract: ContractType) => void
  studio: Studio | undefined
  showStudioButton: boolean
}

const Contracts: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { contracts, handleSelectContract, studio, showStudioButton } = props
  const theme: any = useTheme()
  const router = useRouter()

  const contractsFiltered = useMemo(
    () =>
      contracts
        .filter((contract) => {
          return (
            contract.standard &&
            (contract.affiliations?.edges ?? []).filter(
              (e) => e?.node?.name.toLowerCase() === theme.key
            ).length > 0
          )
        })
        .sort((a, b) => a.weeklyFee - b.weeklyFee),
    [contracts, theme.key]
  )

  const handleStudioClick = (studio: Studio) => {
    let theme: string | null = null
    if (router.pathname.startsWith('/sunpoint')) {
      theme = 'sunpoint'
    } else if (router.pathname.startsWith('/wellmaxx')) {
      theme = 'wellmaxx'
    }
    if (theme === undefined) {
      theme = 'sunpoint'
    }
    router.push(`/${theme}/studio/${studio.slug}`)
  }

  return (
    <Root className={props.className} dataTestId={'contracts-root'}>
      {contractsFiltered.length > 0 ? (
        contractsFiltered.map((contract, index) => {
          return (
            <Contract
              cols={{ xs: 12, sm: 12, md: 6, lg: 6 }}
              handleSelectContract={handleSelectContract}
              key={contract.id + index}
              contract={contract}
              button
            />
          )
        })
      ) : (
        <Row>
          <Col xs={12} smOffset={1} sm={10}>
            <Message
              type={MessageTypes.INFO}
              headline="Angebote im Studio vor Ort"
            >
              <p>
                Für dieses Studio kann eine Mitgliedschaft nicht online
                geschlossen werden. Jetzt attraktive Angebote im Studio vor Ort
                abschließen!
              </p>
              <div>
                {showStudioButton && studio !== undefined && (
                  <StyledButton
                    disabled={false}
                    buttonType={ButtonTypes.BUTTON}
                    modifier={ButtonModifier.PRIMARY}
                    iconAlign={IconAligns.RIGHT}
                    icon={<IconArrowRight />}
                    loading={false}
                    onClick={() => handleStudioClick(studio)}
                  >
                    Informationen zum Studio
                  </StyledButton>
                )}
              </div>
            </Message>
          </Col>
        </Row>
      )}
    </Root>
  )
}

export { Contracts }
