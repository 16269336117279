import Link from 'next/link'
import styled from 'styled-components'

export const StyledLink = styled(Link)<any>`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.theme.font.text.lead.mobile.lineHeight};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    font-size: ${(props) =>
      props.fontSize ?? props.theme.font.text.lead.desktop.fontSize};
    line-height: ${(props) => props.theme.font.text.lead.desktop.lineHeight};
    letter-spacing: ${(props) => props.theme.font.text.lead.letterSpacing};
  }

  font-weight: ${(props) => props.fontWeight};
  display: flex;
  align-items: initial;
  text-decoration: none;
  gap: 0.5rem;
  color: ${(props) => props.color ?? props.theme.palette.default.tertiary};
  span {
    color: ${(props) => props.color ?? props.theme.palette.default.tertiary};
  }
  svg {
    path {
      fill: ${(props) => props.color ?? props.theme.palette.default.tertiary};
    }
  }
`
