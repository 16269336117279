export function rgbToHex(rgb: number[]): string {
  return (
    '#' +
    ((1 << 24) | (rgb[0] << 16) | (rgb[1] << 8) | rgb[2])
      .toString(16)
      .slice(1)
      .toUpperCase()
  )
}

export function hexToRgb(hex: string): number[] {
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)
  return [r, g, b]
}

export function isValidHexColor(hex: string): boolean {
  const pattern = /^#[0-9A-Fa-f]{6}$/
  return pattern.test(hex)
}

export function getColorsBetweenColors(
  startColor: string,
  endColor: string,
  colorCount: number
): string[] {
  let s = hexToRgb(startColor)
  let e = hexToRgb(endColor)
  let rStep = (e[0] - s[0]) / (colorCount + 1)
  let gStep = (e[1] - s[1]) / (colorCount + 1)
  let bStep = (e[2] - s[2]) / (colorCount + 1)
  let colors = []
  for (let i = 1; i <= colorCount; i++) {
    let rgb = [
      Math.round(s[0] + i * rStep),
      Math.round(s[1] + i * gStep),
      Math.round(s[2] + i * bStep),
    ]
    colors.push(rgbToHex(rgb))
  }
  return colors
}

export function calculateRelativeLuminance(rgb: number[]): number {
  const rsrgb = rgb[0] / 255
  const gsrgb = rgb[1] / 255
  const bsrgb = rgb[2] / 255
  const rLuminance =
    rsrgb <= 0.03928 ? rsrgb / 12.92 : Math.pow((rsrgb + 0.055) / 1.055, 2.4)
  const gLuminance =
    gsrgb <= 0.03928 ? gsrgb / 12.92 : Math.pow((gsrgb + 0.055) / 1.055, 2.4)
  const bLuminance =
    bsrgb <= 0.03928 ? bsrgb / 12.92 : Math.pow((bsrgb + 0.055) / 1.055, 2.4)
  return 0.2126 * rLuminance + 0.7152 * gLuminance + 0.0722 * bLuminance
}

export function shouldUseWhiteText(hexColor: string) {
  const contrastWithBlack = calculateContrast(hexColor, '#000000')
  return contrastWithBlack < 7
}

export function calculateContrast(
  hexColor1: string,
  hexColor2: string
): number {
  const rgb1 = hexToRgb(hexColor1)
  const rgb2 = hexToRgb(hexColor2)
  const luminance1 = calculateRelativeLuminance(rgb1)
  const luminance2 = calculateRelativeLuminance(rgb2)
  const contrast =
    (Math.max(luminance1, luminance2) + 0.05) /
    (Math.min(luminance1, luminance2) + 0.05)
  return contrast
}
