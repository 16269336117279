export enum RoutesWellmaxx {
  WELLMAXX__GEWICHTSREDUKTION = '/wellmaxx/gewichtsreduktion/',
  WELLMAXX__GEWICHTSREDUKTION__ABNEHMENMITVITAMIND = '/wellmaxx/gewichtsreduktion/abnehmen-mit-vitamin-d',
  WELLMAXX__GEWICHTSREDUKTION__PROBLEMZONENBEKAEMPFEN = '/wellmaxx/gewichtsreduktion/problemzonen-bekaempfen',
  WELLMAXX__AGB = '/wellmaxx/agb',
  WELLMAXX = '/wellmaxx/',
  WELLMAXX__JOBS = '/wellmaxx/jobs/',
  WELLMAXX__GEWICHTSREDUKTION__FETTVERBRENNUNGOHNESPORT = '/wellmaxx/gewichtsreduktion/fettverbrennung-ohne-sport',
  WELLMAXX__WIDERRUFSBELEHRUNG = '/wellmaxx/widerrufsbelehrung',
  WELLMAXX__JOBS__BEWERBUNGSTUDIOMITARBEITERIN = '/wellmaxx/jobs/bewerbung-studiomitarbeiterin',
  WELLMAXX__JOBS__BEWERBUNGSTUDIOLEITERIN = '/wellmaxx/jobs/bewerbung-studioleiterin',
  WELLMAXX__KONZEPT = '/wellmaxx/konzept/',
  WELLMAXX__GEWICHTSREDUKTION__WELLMAXXBODYSLIMV2 = '/wellmaxx/gewichtsreduktion/wellmaxx-bodyslim-v2',
  WELLMAXX__DEV = '/wellmaxx/dev',
  WELLMAXX__JOBS__BEWERBUNGREINIGUNGSKRAFT = '/wellmaxx/jobs/bewerbung-reinigungskraft',
  WELLMAXX__KONZEPT__STUDIOFINDER = '/wellmaxx/konzept/studiofinder',
  WELLMAXX__JOBS__JOBS_ALT = '/wellmaxx/jobs/jobs_alt',
  WELLMAXX__KOERPERSTRAFFUNG = '/wellmaxx/koerperstraffung/',
  WELLMAXX__GEWICHTSREDUKTION__ABNEHMENOHNEDIAET = '/wellmaxx/gewichtsreduktion/abnehmen-ohne-diaet',
  WELLMAXX__KOERPERSTRAFFUNG__WELLMAXXBODYSTYLE = '/wellmaxx/koerperstraffung/wellmaxx-bodystyle',
  WELLMAXX__KONZEPT__WELLMAXXBODYFORMING = '/wellmaxx/konzept/wellmaxx-bodyforming',
  WELLMAXX__KONZEPT__ERFAHRUNGSBERICHTE = '/wellmaxx/konzept/erfahrungsberichte',
  WELLMAXX__KOOPERATIONENINFLUENCER = '/wellmaxx/kooperationen-influencer',
  WELLMAXX__KOERPERSTRAFFUNG__MASSAGENGEGENCELLULITE = '/wellmaxx/koerperstraffung/massagen-gegen-cellulite',
  WELLMAXX__KOERPERSTRAFFUNG__LYMPHDRAINAGE = '/wellmaxx/koerperstraffung/lymphdrainage',
  WELLMAXX__KONZEPT__ERNAEHRUNGSTIPPS = '/wellmaxx/konzept/ernaehrungstipps',
  WELLMAXX__NEWSLETTER = '/wellmaxx/newsletter',
  WELLMAXX__KOERPERSTRAFFUNG__OBERSCHENKELUNDPOWIRKUNGSVOLLSTRAFFEN = '/wellmaxx/koerperstraffung/oberschenkel-und-po-wirkungsvoll-straffen',
  WELLMAXX__VIBRATIONSTRAINING = '/wellmaxx/vibrationstraining/',
  WELLMAXX__KONZEPT__CLUB = '/wellmaxx/konzept/club',
  WELLMAXX__HAENDLERANFRAGEN = '/wellmaxx/haendleranfragen',
  WELLMAXX__CLUBFAQS = '/wellmaxx/club-faqs',
  WELLMAXX__KOERPERSTRAFFUNG__SCHNELLEHILFEBEIMUSKELKATERUNDSCHWERENBEINEN = '/wellmaxx/koerperstraffung/schnelle-hilfe-bei-muskelkater-und-schweren-beinen',
  WELLMAXX__KOSMETIK = '/wellmaxx/kosmetik/',
  WELLMAXX__CLUBVERTRAGKUENDIGEN = '/wellmaxx/clubvertrag-kuendigen',
  WELLMAXX__PRESSE = '/wellmaxx/presse',
  WELLMAXX__DATENSCHUTZ = '/wellmaxx/datenschutz',
  WELLMAXX__COOKIEEINSTELLUNGEN = '/wellmaxx/cookie-einstellungen',
  WELLMAXX__IMPRESSUM = '/wellmaxx/impressum',
  WELLMAXX__KONTAKT = '/wellmaxx/kontakt',
  WELLMAXX__CLUBMITGLIEDWERDEN = '/wellmaxx/clubmitglied-werden',
  WELLMAXX__COUPONS = '/wellmaxx/coupons',
}
