import React, { PropsWithChildren } from 'react'

import { shouldUseWhiteText } from '@helper/color'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconCircleXNotFilled.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconCircleXNotFilled: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'

  return (
    <Root
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'icon-circle-x-not-filled-root'}
    >
      <g id="icon / ico_arrow_right">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 15.9985C32 7.16225 24.8363 -0.00146484 16 -0.00146484C7.16372 -0.00146484 0 7.16225 0 15.9985C0 24.8348 7.16372 31.9985 16 31.9985C24.8363 31.9985 32 24.8348 32 15.9985ZM2 15.9985C2 8.26682 8.26828 1.99854 16 1.99854C23.7317 1.99854 30 8.26682 30 15.9985C30 23.7303 23.7317 29.9985 16 29.9985C8.26828 29.9985 2 23.7303 2 15.9985ZM17.4142 15.9985L22.7071 10.7056C23.0976 10.3151 23.0976 9.68195 22.7071 9.29143C22.3166 8.9009 21.6834 8.9009 21.2929 9.29143L16 14.5843L10.7071 9.29143C10.3166 8.9009 9.68342 8.9009 9.29289 9.29143C8.90237 9.68195 8.90237 10.3151 9.29289 10.7056L14.5858 15.9985L9.29289 21.2914C8.90237 21.682 8.90237 22.3151 9.29289 22.7056C9.68342 23.0962 10.3166 23.0962 10.7071 22.7056L16 17.4127L21.2929 22.7056C21.6834 23.0962 22.3166 23.0962 22.7071 22.7056C23.0976 22.3151 23.0976 21.682 22.7071 21.2914L17.4142 15.9985Z"
          fill="black"
        />
      </g>
    </Root>
  )
}

export { IconCircleXNotFilled }
