import { TextIconColStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextIconCol } from './StoryblokTextIconCol'

export default StoryblokTextIconCol

export const dummyBlokTextIconColStoryblok: TextIconColStoryblok = {
  _uid: '0928ebed-bb61-4066-9536-e61230ab1bf6',
  icon: 'sun',
  link: {
    id: 'aa50ff89-3062-426b-9c44-9ad8ec699036',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: 'sunpoint/braeune/',
  },
  content: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            text: 'Solarium, Bräunungsdusche oder Collagen-Sonnenbänke:',
            type: 'text',
          },
          {
            type: 'hard_break',
          },
          {
            text: 'Für die perfekte Bräune!',
            type: 'text',
          },
        ],
      },
    ],
  },
  subline: '',
  headline: 'Bräune',
  linkName: 'Mehr zu Bräune',
  component: 'text-icon-col',
  blockBackground: ['primary'],
  _editable:
    '<!--#storyblok#{"name": "text-icon-col", "space": "235453", "uid": "0928ebed-bb61-4066-9536-e61230ab1bf6", "id": "322564270"}-->',
}
