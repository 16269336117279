import { TextOnImageColStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextOnImageCol } from './StoryblokTextOnImageCol'

export default StoryblokTextOnImageCol

export const dummyBlokTextOnImageColStoryblok: TextOnImageColStoryblok = {
  _uid: '7236e357-76c6-45fe-bab9-e4d1c7c8ecc6',
  link: {
    id: 'a9c26fc2-0022-4313-8963-c083c435d947',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: 'sunpoint/braeune/hauttypen-test',
  },
  image: {
    id: 10944283,
    alt: 'test',
    name: '',
    focus: '452x149:453x150',
    title: 'test',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/592x322/404a45832c/shutterstock_2127851798-1.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    content_type: 'png',
    is_external_url: false,
  },
  content: {
    type: 'doc',
    content: [
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'Erfahre, welcher Hauttyp du bist',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'Spare Zeit bei der Anmeldung im ',
                    type: 'text',
                  },
                ],
              },
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'SUNPOINT Studio',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  headline: 'Hauttypen-Test\n',
  linkName: 'Zum Hauttypen-Test',
  component: 'text-on-image-col',
  logoImage: {
    id: 123,
    alt: 'null',
    name: '',
    focus: 'null',
    title: 'null',
    source: null,
    filename: '',
    copyright: 'null',
    fieldtype: 'asset',
    meta_data: {},
  },
  rightAlign: false,
  _editable:
    '<!--#storyblok#{"name": "text-on-image-col", "space": "235453", "uid": "7236e357-76c6-45fe-bab9-e4d1c7c8ecc6", "id": "322564270"}-->',
}
