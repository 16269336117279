import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'

import ListItem from '@components/atoms/ListItem'

import FormField from '@helper/FormField'

import { CreateAttachmentInput } from '@definitions/types/symfonyTypesd'

import { Button, ButtonModifier, ButtonTypes } from '../Button/Button'
import {
  Root,
  StyledInputLabel,
  StyledRemoveButton,
} from '../FileUpload/FileUpload.styles'
import { IconCircleCheck } from '../Icons/IconCircleCheck/IconCircleCheck'
import Text from '../Text'
import { TextVariants } from '../Text/Text'
import { UnorderedList } from '../UnorderedList/UnorderedList'

export const getReadableFileSizeString = (fileSizeInBytes: number) => {
  let i = -1
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  } while (fileSizeInBytes > 1024)
  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

interface Props extends FormField {
  onChange: (attachments: CreateAttachmentInput[]) => void
  maxFileSize?: number | undefined
  acceptedFileTypes: string[]
  maxFiles?: number | undefined
}

const FileUploadSimple: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    onChange,
    maxFileSize,
    acceptedFileTypes,
    maxFiles,
    label,
    error,
    required,
    disabled,
    forId,
  } = props
  const [attachments, setAttachments] = useState<CreateAttachmentInput[]>([])
  const [warning, setWarning] = useState<any | null>(null)
  const fileInput = useRef<HTMLInputElement>(null)

  const removeMediaObject = useCallback(
    async (deletAattachment: CreateAttachmentInput) => {
      const newAttachments = attachments.filter(
        (attachment) => attachment.filename !== deletAattachment.filename
      )
      setAttachments(newAttachments)
      onChange(newAttachments)
    },
    [attachments]
  )

  const onFileChange = useCallback(
    async (event: any) => {
      const inputFile: File = event.target.files[0]

      if (inputFile === undefined) {
        return
      }

      setWarning(null)

      if (maxFileSize !== undefined && inputFile.size > maxFileSize) {
        setWarning(
          `Datei mit ungültiger Dateigröße. Maximal ${getReadableFileSizeString(
            maxFileSize
          )} erlaubt.`
        )
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        const newAttachment: CreateAttachmentInput = {
          filename: inputFile.name,
          dataUri: e.target?.result as string,
        }
        const newAttachments = [...attachments, newAttachment]

        setAttachments(newAttachments)
        onChange(newAttachments)
      }

      reader.readAsDataURL(inputFile)
    },
    [maxFileSize, attachments]
  )

  const chooseFile = useCallback(() => {
    if (!fileInput?.current) {
      return
    }
    fileInput.current.click()
  }, [fileInput])

  return (
    <Root className={props.className} data-testid={'file-upload-root'}>
      <StyledInputLabel
        error={error ?? false}
        disabled={disabled ?? false}
        required={required ?? false}
        forId={forId}
      >
        {label}
      </StyledInputLabel>
      {warning && <Text variant={TextVariants.paragraph}>{warning}</Text>}
      {attachments.length > 0 && (
        <UnorderedList>
          {attachments.map((attachment) => (
            <ListItem
              icon={<IconCircleCheck active />}
              key={attachment.filename}
            >
              {attachment?.filename}
              <StyledRemoveButton
                onClick={() => removeMediaObject(attachment)}
                type={'button'}
              >
                löschen
              </StyledRemoveButton>
            </ListItem>
          ))}
        </UnorderedList>
      )}
      {attachments.length === 0 && (
        <Text variant={TextVariants.paragraph}>Keine ausgewählt</Text>
      )}
      {(!maxFiles || attachments.length < maxFiles) && (
        <Button
          modifier={ButtonModifier.SECONDARY}
          buttonType={ButtonTypes.BUTTON}
          disabled={false}
          loading={false}
          onClick={chooseFile}
        >
          Datei auswählen
        </Button>
      )}
      <input
        id={forId}
        type="file"
        onChange={onFileChange}
        accept={acceptedFileTypes.join(', ')}
        ref={fileInput}
        style={{ display: 'none' }}
      />
    </Root>
  )
}

export { FileUploadSimple }
