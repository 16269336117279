import styled, { keyframes } from 'styled-components'

import Box from '@components/atoms/Box'
import { Icon } from '@components/atoms/Box/Box.styles'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import IconLargeSun from '@components/atoms/Icons/IconLargeSun'
import Text from '@components/atoms/Text'
import UnorderedList from '@components/atoms/UnorderedList'

import { shouldUseWhiteText } from '@helper/color'

export const Root = styled.div`
  padding-top: ${(props) => props.theme.padding.default};
`
export const Questions = styled.div``
interface Questionprops {
  visible: boolean
}
export const Question = styled(Box)<Questionprops>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
`
export const Questionhead = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${(props) => props.theme.margin.default};
`
export const Questionheadline = styled(Headline)`
  margin-bottom: 0;
`
export const QuestionId = styled(Text)`
  margin-bottom: 0;
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
export const AnswerRow = styled(Row)``
export const Answers = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

interface AnswerProps {
  delay: number
}

export const Answer = styled(Box)<AnswerProps>`
  display: flex;
  background: ${(props) => props.theme.palette.default.white};
  border: 0.4375rem solid ${(props) => props.theme.palette.default.white};
  cursor: pointer;
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.white) ? 'white' : 'black'};
  &:hover {
    background: ${(props) => props.theme.palette.default.primary};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.primary)
        ? 'white'
        : 'black'};
    border: 0.4375rem solid ${(props) => props.theme.palette.default.primary};
    ${Icon} {
      color: ${(props) =>
        shouldUseWhiteText(props.theme.palette.default.primary)
          ? 'white'
          : 'black'};
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.4375rem solid ${(props) => props.theme.palette.default.primary};
    }
    &:active {
      border: 0.4375rem solid ${(props) => props.theme.palette.default.white};
      ${Icon} {
        border: 0.4375rem solid ${(props) => props.theme.palette.default.white};
      }
    }
  }
`
export const AnswerText = styled(Text)`
  margin: 0;
`

export const SameHeightRow = styled(Row)`
  flex: 1;
`

export const SameHeightCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
`

export const SkinBox = styled(Box)``
interface SkinBoxUnorderedListProps {
  background: string
}
export const SkinBoxUnorderedList = styled(
  UnorderedList
)<SkinBoxUnorderedListProps>`
  svg {
    path {
      fill: ${(props) =>
        shouldUseWhiteText(props.background) ? 'white' : 'black'};
    }
  }
`
export const SkinBoxHeadline = styled(Headline)`
  width: 80%;
`

export const SkinBoxListItemText = styled(Text)`
  margin-bottom: 0;
`
export const SkinBoxText = styled(Text)``
export const Test = styled.div``

export const ResultBox = styled(Box)`
  position: relative;
  overflow: hidden;
  text-align: center;
  align-items: center;
`
export const Result = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
interface IconLargeSunProps {
  fill: string
}
export const SmallIconLargeSun = styled(IconLargeSun)<IconLargeSunProps>`
  position: absolute;
  top: -4.6875rem;
  left: 3.125rem;
  bottom: auto;
  right: auto;
  opacity: 1;
  z-index: 1;
  height: 10.9375rem;
  width: auto;
  path {
    fill: ${(props) => props.theme.palette.default.primary};
  }
`
export const BigIconLargeSun = styled(IconLargeSun)<IconLargeSunProps>`
  position: absolute;
  top: auto;
  left: auto;
  bottom: -9.375rem;
  right: -0.9375rem;
  opacity: 1;
  z-index: 1;
  height: 21.875rem;
  width: auto;
  path {
    fill: ${(props) => props.theme.palette.default.primary};
  }
`
