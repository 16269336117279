import { FieldError } from 'react-hook-form'
import styled from 'styled-components'

import { InfoSymbol } from './InfoText.styles'

interface LabelProps {
  readonly error: boolean | FieldError
  readonly disabled: boolean
}

export const Root = styled.label<LabelProps>`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.mobile.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.text.paragraph.mobile.letterSpacing};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  color: ${(props) =>
    props.error
      ? props.theme.palette.default.error
      : props.theme.palette.default.black};

  margin-bottom: ${(props) => props.theme.margin.small};
  user-select: none;
  display: block;
  span:not(${InfoSymbol}),
  a {
    display: inline-block;
  }
`
