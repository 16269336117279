import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { HeroSectionStoryblok } from '@definitions/types/storyblokTypes'

import {
  Buttons,
  Root,
  StyledBox,
  StyledCol,
} from './StoryblokHeroSection.styles'

interface StoryblokButtonProps extends HeroSectionStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokButtonProps
}

const StoryblokHeroSection = (props: Props): React.ReactElement => {
  const { blok } = props

  const styles = {
    backgroundImage: `url(${blok.background_image?.filename})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-hero-section-root'}
      style={styles}
      role="img"
      aria-label={
        blok.background_image?.alt
          ? blok.background_image?.alt
          : blok.headline?.toString()
      }
      title={blok.headline?.toString()}
    >
      <Row>
        <StyledCol xs={12} sm={12} md={6} mdOffset={3} lg={8} lgOffset={2}>
          <StyledBox>
            <Headline importance={HeadlineImportances.h1}>
              {blok.headline?.toString()}
            </Headline>
            <Headline importance={HeadlineImportances.h2}>
              {blok.subheadline?.toString()}
            </Headline>
            <Buttons>
              {blok.buttons &&
                blok.buttons.map((button: any) => {
                  return <StoryblokComponent key={button._uid} blok={button} />
                })}
            </Buttons>
          </StyledBox>
        </StyledCol>
      </Row>
    </Root>
  )
}

export { StoryblokHeroSection }
