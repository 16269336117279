import useStudioSearch from '@hooks/useStudioSearch'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import BlockPreContent from '@components/atoms/BlockPreContent'
import StudioSearchBox from '@components/molecules/StudioSearchBox'

import { useUserData } from '@helper/context/UserDataContext'
import { Searchdestinations } from '@helper/enums/storyblok/Searchdestinations'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  RoutesSunpointDefault,
  RoutesWellmaxxDefault,
} from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'
import { StudioSearchFieldStoryblok } from '@definitions/types/storyblokTypes'
import { Studio } from '@definitions/types/symfonyTypesd'

import { Root } from './StoryblokSearchfield.styles'

interface Props extends PropsWithClassName {
  blok: StudioSearchFieldStoryblok
}

const StoryblokSearchfield: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, subline, searchLink } = blok
  const { userData, setUserData } = useUserData()
  const router = useRouter()
  const theme: any = useTheme()
  const { studioArray, handleSearch } = useStudioSearch(null)

  const handleStudioSelect = (studio: Studio): void => {
    setUserData({
      studio: studio,
      contract: userData.contract,
      formData: userData.formData,
    })
    switch (searchLink) {
      case Searchdestinations.STUDIODETAILCOUPONS:
        if (theme.key === 'sunpoint') {
          router.push(
            RoutesSunpointDefault.STUDIOLIST + '/' + studio.slug + '#coupons'
          )
        }
        if (theme.key === 'wellmaxx') {
          router.push(
            RoutesWellmaxxDefault.STUDIOLIST + '/' + studio.slug + '#coupons'
          )
        }
        break
      case Searchdestinations.STUDIODETAIL:
        if (theme.key === 'sunpoint') {
          router.push(RoutesSunpointDefault.STUDIOLIST + '/' + studio.slug)
        }
        if (theme.key === 'wellmaxx') {
          router.push(RoutesWellmaxxDefault.STUDIOLIST + '/' + studio.slug)
        }
        break
      case Searchdestinations.CLUBMITGLIEDWERDEN:
        if (theme.key === 'sunpoint') {
          router.push(RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN)
        }
        if (theme.key === 'wellmaxx') {
          router.push(RoutesWellmaxx.WELLMAXX__CLUBMITGLIEDWERDEN)
        }
        break
      default:
        router.push(RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN)
        break
    }
  }

  return (
    <Root
      className={props.className}
      data-testid={'storyblok-searchfield-root'}
    >
      {(headline || subline) && (
        <BlockPreContent headline={headline} subline={subline} />
      )}
      <StudioSearchBox
        onChange={(term) => handleSearch(term)}
        setStudio={(studio) => handleStudioSelect(studio)}
        studios={studioArray}
        onlyStudiosWithCoupons={
          Searchdestinations.STUDIODETAILCOUPONS === searchLink
        }
      />
    </Root>
  )
}

export { StoryblokSearchfield }
