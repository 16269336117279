import { FeaturedArticlesSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokFeaturedArticleSection } from './StoryblokFeaturedArticleSection'

export const dummyFeaturedArticlesSectionBlok: FeaturedArticlesSectionStoryblok =
  {
    _uid: '0d47d12a-f794-403b-a2cd-8f44f50a9632',
    cols: '3',
    lead: 'Deserunt minim aliquip excepteur aute laborum ea anim laborum tempor quis aliquip.',
    articles: [
      '3b6202f6-f475-4ad3-be43-90f2ff096483',
      '5de53282-b18e-4f15-8f3d-3b053dfc5ecf',
      '5c92a78f-e27a-4d76-9012-f575ef141792',
    ],
    headline: 'Featured Articles Section',
    component: 'featured-articles-section',
    background_color: 'white',
    _editable:
      '<!--#storyblok#{"name": "featured-articles-section", "space": "228071", "uid": "0d47d12a-f794-403b-a2cd-8f44f50a9632", "id": "300939517"}-->',
  }

export default StoryblokFeaturedArticleSection
