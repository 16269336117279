import useUserCentrics from '@hooks/useUserCentrics'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Content, Root } from './StoryblokJobPlugin.styles'

interface Props extends PropsWithClassName {}

const StoryblokJobPlugin: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const [loaded, setLoaded] = useState(false)
  const { consentToAll } = useUserCentrics()

  useEffect(() => {
    if (!consentToAll || loaded) {
      return
    }

    setLoaded(true)

    const script = document.createElement('script')
    script.setAttribute('data-widget', 'mpWidget')
    script.setAttribute('data-id', '1476762e2833538e7d9.57062947')
    script.setAttribute('src', 'https://cdn.messengerpeople.com/widget.cube.js')
    contentRef.current?.appendChild(script)
  }, [consentToAll, loaded])

  return (
    <Root className={props.className} data-testid={'storyblok-job-plugin-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Content ref={contentRef}>
            <a href="https://www.messengerpeople.com/"></a>
          </Content>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokJobPlugin }
