import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Accordion from '@components/molecules/Accordion'
import { AccordionElement } from '@components/molecules/Accordion/Accordion'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { AccordionStoryblok } from '@definitions/types/storyblokTypes'

import { Container, Root } from './StoryblokAccordion.styles'

interface StoryblokTextColContainerProps extends AccordionStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokTextColContainerProps
}

const StoryblokAccordion: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, items } = blok

  const accordionData: AccordionElement[] =
    items?.map((item) => {
      return { trigger: item.headline, content: item.content }
    }) ?? []

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-accordion-root'}
      aria-label={headline?.toString()}
      title={headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} mdOffset={0} lgOffset={0}>
          <Container>
            <Accordion
              elements={accordionData}
              headline={headline ?? ''}
              contentRenderer={(content) => (
                <StoryblokRichText content={content} />
              )}
            />
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokAccordion }
