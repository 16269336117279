import { gql } from '@apollo/client'

import { FRAGMENT_REGISTRATION_BASE } from '../fragments/registrationFragment'

export const CREATE_REGISTRATION_MUTATION = gql`
  mutation CreateRegistration($input: createRegistrationInput!) {
    createRegistration(input: $input) {
      registration {
        ...RegistrationBase
      }
    }
  }
  ${FRAGMENT_REGISTRATION_BASE}
`
