import React, { PropsWithChildren, useState } from 'react'

import SkinTypeTest from '@components/organisms/SkinTypeTest'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokSkinTypeTest.styles'

interface Props extends PropsWithClassName {}

const StoryblokSkinTypeTest: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-skin-type-test-root'}
    >
      <SkinTypeTest />
    </Root>
  )
}

export { StoryblokSkinTypeTest }
