import React from 'react'

type GoogleMapApiContextProps = {
  googleApiLoaded: boolean
  setGoogleApiLoaded: (value: boolean) => void
}

const GoogleMapApiContext = React.createContext<GoogleMapApiContextProps>(
  {} as GoogleMapApiContextProps
)

export default GoogleMapApiContext
