import { CtaCenterAlignStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokCtaCenterAlign } from './StoryblokCtaCenterAlign'

export default StoryblokCtaCenterAlign

export const dummyBlokCtaCenterAlignPrimaryStoryblok: CtaCenterAlignStoryblok =
  {
    _uid: 'c2ebffe0-9fcb-42d0-b332-9c576d8d3ba2',
    link: {
      id: 'e8c991b7-c7f9-49dd-af80-6bb3c53967e1',
      url: '',
      linktype: 'story',
      fieldtype: 'multilink',
      cached_url: 'sunpoint/jobs/',
    },
    image: {
      id: 10343902,
      alt: 'Suns',
      name: '',
      focus: '',
      title: 'Suns',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/1216x303/4032f39ca5/suns_background.png',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_external_url: false,
    },
    button: 'sdasdadssdd',
    content: 'sdadadads\ndssdsdsd\nsdadssd',
    headline: 'asdsddddddsad',
    component: 'cta-center-align',
    calltoaction: 'Jetzt oder nie',
    buttonStyle: 'primary',
    _editable:
      '<!--#storyblok#{"name": "cta-center-align", "space": "235453", "uid": "c2ebffe0-9fcb-42d0-b332-9c576d8d3ba2", "id": "322787899"}-->',
  }

export const dummyBlokCtaCenterAlignSecondaryStoryblok: CtaCenterAlignStoryblok =
  {
    ...dummyBlokCtaCenterAlignPrimaryStoryblok,
    buttonStyle: 'secondary',
  }
