import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './LogoSunmaxx.styles'

interface Props extends PropsWithClassName {}

const LogoSunmaxx: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      width="131"
      height="23"
      viewBox="0 0 131 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'logo-sunmaxx-root'}
    >
      <g clipPath="url(#clip0_1722_119287)">
        <path
          d="M12.9517 1.55247C11.6509 1.07114 10.1424 0.799805 8.69357 0.799805C4.28804 0.800471 1.09426 3.38847 1.09426 6.97047C1.09426 7.90314 1.36019 8.74647 1.86323 9.46847C2.33614 10.1605 2.95773 10.7025 3.7267 11.0331C4.64305 11.4845 5.11661 11.6651 8.07328 12.5078C11.3856 13.5011 12.302 14.2838 12.302 16.1198C12.302 18.2565 10.4391 19.6711 7.60037 19.6711C6.06243 19.6711 4.55462 19.1898 3.43064 18.3471C2.69115 17.8051 2.36627 17.4138 1.7152 16.3905L0.148438 19.4005C2.15929 20.9051 4.82055 21.7485 7.54077 21.7485C12.6268 21.7485 16.3531 18.7385 16.3531 14.6151C16.3531 13.6218 16.0872 12.6885 15.6431 11.8758C14.8741 10.5818 13.8098 9.88914 11.2965 9.13647C8.19184 8.1738 7.71827 8.02314 6.89035 7.57114C6.00283 7.11981 5.47097 6.30714 5.47097 5.40447C5.47097 3.92914 6.94996 2.8758 9.01976 2.8758C11.0011 2.8758 12.6871 3.74847 13.8104 5.37381L15.2593 2.72514C14.2532 2.09447 13.8392 1.8838 12.9517 1.55247Z"
          fill="#292929"
        />
        <path
          d="M12.9517 1.55247C11.6509 1.07114 10.1424 0.799805 8.69357 0.799805C4.28804 0.800471 1.09426 3.38847 1.09426 6.97047C1.09426 7.90314 1.36019 8.74647 1.86323 9.46847C2.33614 10.1605 2.95773 10.7025 3.7267 11.0331C4.64305 11.4845 5.11661 11.6651 8.07328 12.5078C11.3856 13.5011 12.302 14.2838 12.302 16.1198C12.302 18.2565 10.4391 19.6711 7.60037 19.6711C6.06243 19.6711 4.55462 19.1898 3.43064 18.3471C2.69115 17.8051 2.36627 17.4138 1.7152 16.3905L0.148438 19.4005C2.15929 20.9051 4.82055 21.7485 7.54077 21.7485C12.6268 21.7485 16.3531 18.7385 16.3531 14.6151C16.3531 13.6218 16.0872 12.6885 15.6431 11.8758C14.8741 10.5818 13.8098 9.88914 11.2965 9.13647C8.19184 8.1738 7.71827 8.02314 6.89035 7.57114C6.00283 7.11981 5.47097 6.30714 5.47097 5.40447C5.47097 3.92914 6.94996 2.8758 9.01976 2.8758C11.0011 2.8758 12.6871 3.74847 13.8104 5.37381L15.2593 2.72514C14.2532 2.09447 13.8392 1.8838 12.9517 1.55247Z"
          fill="#292929"
        />
        <path
          d="M19.0234 14.8557C19.0234 16.963 19.2009 17.8357 19.7924 18.829C20.8863 20.605 23.4001 21.749 26.3575 21.749C28.5458 21.749 30.5567 21.177 31.9165 20.1537C33.3954 19.0397 33.8094 17.7757 33.8094 14.3143V1.22168H31.2366V14.2243C31.2366 16.1203 31.1776 16.6623 30.9405 17.445C30.4971 18.8297 29.0187 19.6723 27.0963 19.6723C25.9134 19.6723 24.9374 19.341 24.2569 18.7397C23.4584 18.0477 23.2816 17.265 23.2816 14.4957V1.22168H19.0234V14.8557Z"
          fill="#292929"
        />
        <path
          d="M19.0234 14.8557C19.0234 16.963 19.2009 17.8357 19.7924 18.829C20.8863 20.605 23.4001 21.749 26.3575 21.749C28.5458 21.749 30.5567 21.177 31.9165 20.1537C33.3954 19.0397 33.8094 17.7757 33.8094 14.3143V1.22168H31.2366V14.2243C31.2366 16.1203 31.1776 16.6623 30.9405 17.445C30.4971 18.8297 29.0187 19.6723 27.0963 19.6723C25.9134 19.6723 24.9374 19.341 24.2569 18.7397C23.4584 18.0477 23.2816 17.265 23.2816 14.4957V1.22168H19.0234V14.8557Z"
          fill="#292929"
        />
        <path
          d="M37.748 21.2247H40.3504V4.70141L49.9317 21.2247H52.5039V1.14941H49.9317L49.9258 13.7647L42.5682 1.14941H37.748V21.2247Z"
          fill="#292929"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.748 21.2247H40.3504V4.70141L49.9317 21.2247H52.5039V1.14941H49.9317L49.9258 13.7647L42.5682 1.14941H37.748V21.2247Z"
          fill="#292929"
        />
        <path
          d="M56.4434 21.297H59.0457V4.23101L64.8712 17.5343L70.8442 4.11101V21.297H75.0735V1.22168H69.8099L65.7581 10.4017L61.8255 1.22168H56.4434V21.297Z"
          fill="#292929"
        />
        <path
          d="M56.4434 21.297H59.0457V4.23101L64.8712 17.5343L70.8442 4.11101V21.297H75.0735V1.22168H69.8099L65.7581 10.4017L61.8255 1.22168H56.4434V21.297Z"
          fill="#292929"
        />
        <path
          d="M90.3555 21.2967H94.8802L88.2614 1.14941L83.7308 1.25141L76.7812 21.2967H79.4726L81.6898 14.6747H88.3138L90.3555 21.2967ZM82.3114 12.6887L85.1201 4.05075L87.723 12.6887H82.3114Z"
          fill="#292929"
        />
        <path
          d="M90.3555 21.2967H94.8802L88.2614 1.14941L83.7308 1.25141L76.7812 21.2967H79.4726L81.6898 14.6747H88.3138L90.3555 21.2967ZM82.3114 12.6887L85.1201 4.05075L87.723 12.6887H82.3114Z"
          fill="#292929"
        />
        <path
          d="M100.892 10.793L94.416 21.297H97.4329L102.253 13.1103L106.54 21.297H111.301L104.825 9.82968L110.03 1.22168H107.073L103.494 7.48168L100.123 1.22168H95.3618L100.892 10.793Z"
          fill="#292929"
        />
        <path
          d="M100.892 10.793L94.416 21.297H97.4329L102.253 13.1103L106.54 21.297H111.301L104.825 9.82968L110.03 1.22168H107.073L103.494 7.48168L100.123 1.22168H95.3618L100.892 10.793Z"
          fill="#292929"
        />
        <path
          d="M117.39 10.793L110.914 21.297H113.93L118.75 13.1103L123.038 21.297H127.799L121.323 9.82968L126.528 1.22168H123.571L119.992 7.48168L116.621 1.22168H111.86L117.39 10.793Z"
          fill="#292929"
        />
        <path
          d="M117.39 10.793L110.914 21.297H113.93L118.75 13.1103L123.038 21.297H127.799L121.323 9.82968L126.528 1.22168H123.571L119.992 7.48168L116.621 1.22168H111.86L117.39 10.793Z"
          fill="#292929"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.306 1.57684H128.854C129.213 1.57684 129.374 1.73084 129.374 2.0195C129.374 2.28884 129.156 2.39484 128.854 2.39484H128.306V1.57684ZM126.529 2.6155C126.529 3.8655 127.541 4.90484 128.779 4.90484C130.017 4.90484 131.028 3.86617 131.028 2.6155C131.028 1.36484 130.017 0.326172 128.779 0.326172C127.541 0.326839 126.529 1.36551 126.529 2.6155ZM126.812 2.6155C126.812 1.52884 127.7 0.614839 128.778 0.614839C129.855 0.614839 130.744 1.52884 130.744 2.6155C130.744 3.70217 129.855 4.61617 128.778 4.61617C127.701 4.61617 126.812 3.70217 126.812 2.6155ZM128.306 3.85684V2.74084H128.825L129.354 3.85684H129.855L129.307 2.70284C129.638 2.61617 129.865 2.35684 129.865 2.00084C129.865 1.55817 129.572 1.23151 128.844 1.23151H127.852V3.85684H128.306Z"
          fill="#292929"
        />
      </g>
      <defs>
        <clipPath id="clip0_1722_119287">
          <rect
            width="131"
            height="22"
            fill="white"
            transform="translate(0 0.328125)"
          />
        </clipPath>
      </defs>
    </Root>
  )
}

export { LogoSunmaxx }
