import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { H1, H2, H3, H4, H5 } from './Headline.styles'

export enum HeadlineImportances {
  h1 = 1,
  h2 = 2,
  h3 = 3,
  h4 = 4,
  h5 = 5,
}

interface Props extends PropsWithClassName {
  children: string | React.ReactNode
  importance: HeadlineImportances
}

const Headline: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, className, importance } = props

  function renderHeadline(importance: number): JSX.Element {
    switch (importance) {
      case HeadlineImportances.h1:
        return (
          <H1 data-testid={'Headline-root'} className={className}>
            {children}
          </H1>
        )
      case HeadlineImportances.h2:
        return (
          <H2 data-testid={'Headline-root'} className={className}>
            {children}
          </H2>
        )
      case HeadlineImportances.h3:
        return (
          <H3 data-testid={'Headline-root'} className={className}>
            {children}
          </H3>
        )
      case HeadlineImportances.h4:
        return (
          <H4 data-testid={'Headline-root'} className={className}>
            {children}
          </H4>
        )
      case HeadlineImportances.h5:
        return (
          <H5 data-testid={'Headline-root'} className={className}>
            {children}
          </H5>
        )
      default:
        return (
          <H1 data-testid={'Headline-root'} className={className}>
            {children}
          </H1>
        )
    }
  }

  return renderHeadline(importance)
}

export { Headline }
