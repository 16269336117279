import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TextLead, TextMeta, TextParagraph } from './Text.styles'

export enum TextVariants {
  lead = 'lead',
  paragraph = 'paragraph',
  meta = 'meta',
}

interface Props extends PropsWithClassName {
  children: string | React.ReactNode | number
  variant: TextVariants
}

const Text: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, dataTestId, variant, className } = props

  const renderText = (variant: TextVariants): JSX.Element => {
    switch (variant) {
      case TextVariants.lead:
        return (
          <TextLead
            data-testid={dataTestId ? dataTestId : 'text-root'}
            className={className}
            variant={variant}
          >
            {children}
          </TextLead>
        )
      case TextVariants.paragraph:
        return (
          <TextParagraph
            data-testid={dataTestId ? dataTestId : 'text-root'}
            className={className}
            variant={variant}
          >
            {children}
          </TextParagraph>
        )
      case TextVariants.meta:
        return (
          <TextMeta
            data-testid={dataTestId ? dataTestId : 'text-root'}
            className={className}
            variant={variant}
          >
            {children}
          </TextMeta>
        )
      default:
        return (
          <TextLead
            data-testid={dataTestId ? dataTestId : 'text-root'}
            className={className}
            variant={variant}
          >
            {children}
          </TextLead>
        )
    }
  }

  return renderText(variant)
}

export { Text }
