import React, { PropsWithChildren } from 'react'

import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root, StyledHeadline, StyledSubline } from './BlockPreContent.styles'

interface Props extends PropsWithClassName {
  headline?: string
  subline?: string
}

const BlockPreContent: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { headline, subline } = props

  return (
    <Root data-testid={'block-pre-content-root'}>
      {headline && (
        <StyledHeadline importance={HeadlineImportances.h2}>
          {headline}
        </StyledHeadline>
      )}
      {subline && (
        <StyledSubline variant={TextVariants.lead}>{subline}</StyledSubline>
      )}
    </Root>
  )
}

export { BlockPreContent }
