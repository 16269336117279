import { useQuery } from '@apollo/client'
import { CHECK_VOUCHER } from '@graphql/queries/checkVoucher'
import { yupResolver } from '@hookform/resolvers/yup'
import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import Box from '@components/atoms/Box'
import { BoxBackgrounds } from '@components/atoms/Box/Box'
import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import ErrorText from '@components/atoms/ErrorText'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputDate from '@components/atoms/Form/InputDate'
import InputRadioGroup from '@components/atoms/Form/InputRadioGroup'
import {
  Radio,
  RadioButtonDirection,
} from '@components/atoms/Form/InputRadioGroup/InputRadioGroup'
import InputSelect from '@components/atoms/Form/InputSelect'
import { SingleOption } from '@components/atoms/Form/InputSelect/InputSelect'
import InputText from '@components/atoms/Form/InputText'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'
import OperatorStudios from '@components/molecules/StudioDetail/OperatorStudios'

import {
  countries,
  Countries,
  countryCode,
  deutschlandBundeslaender,
  FederalStateAT,
  FederalStateDE,
  FederalStateLU,
  luxemburgDistrikte,
  oesterreichBundeslaender,
} from '@helper/federalStates'
import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'
import { Contract, Studio } from '@definitions/types/symfonyTypesd'

import {
  ButtonCol,
  ButtonStatus,
  Root,
  StyledButton,
  StyledInputText,
  StyledText,
} from './FormUserData.styles'

export interface FormUserDataType {
  anrede: string
  vorname: string
  nachname: string
  strasse: string
  nr: string
  zip: string
  ort: string
  bundesland: FederalStateDE | FederalStateAT | FederalStateLU
  land: countries
  email: string
  emailConfirm: string
  telefon: string
  geburtstag: string
  recievevia: string
  newsletter?: boolean
  gutschein?: string
  agb: boolean
}

interface Props extends PropsWithClassName {
  formData?: FormUserDataType
  studio?: Studio
  contract: Contract
  handleSubmitForm: (
    data: FormUserDataType,
    newContractId: string | null
  ) => void
}

let formUserDataVoucherValue: string | null = null

const createValidationSchema = (voucherValid: boolean | null) => {
  return {
    anrede: yup.string().required('Dies ist ein Pflichtfeld.'),
    vorname: yup.string().required('Dies ist ein Pflichtfeld.'),
    nachname: yup.string().required('Dies ist ein Pflichtfeld.'),
    strasse: yup.string().required('Dies ist ein Pflichtfeld.'),
    nr: yup.string().required('Dies ist ein Pflichtfeld.'),
    zip: yup
      .string()
      .test(
        'len',
        'Darf nicht länger als 5 Zeichen sein',
        (val) => (val ?? '').length <= 5
      )
      .required('Dies ist ein Pflichtfeld.'),
    ort: yup.string().required('Dies ist ein Pflichtfeld.'),
    bundesland: yup.string().required('Dies ist ein Pflichtfeld.'),
    land: yup.string().required('Dies ist ein Pflichtfeld.'),
    email: yup
      .string()
      .trim()
      .email('Dies ist keine valide E-Mail-Adresse')
      .required('Dies ist ein Pflichtfeld.')
      .test(
        'is-valid-email',
        'Dies ist keine valide E-Mail-Adresse',
        (value) => {
          if (!value) return false
          return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
        }
      ),
    emailConfirm: yup
      .string()
      .trim()
      .email('Dies ist keine valide E-Mail-Adresse')
      .oneOf([yup.ref('email')], 'E-Mail Adressen müssen übereinstimmen.')
      .required('Dies ist ein Pflichtfeld.'),
    telefon: yup.string().required('Dies ist ein Pflichtfeld.'),
    geburtstag: yup
      .string()
      .test('is-date', 'Dies ist ein Pflichtfeld.', (value) => {
        if (!value) return false
        return yup.date().isValidSync(value)
      })
      .test('is-over-18', 'Du musst mindestens 18 Jahre alt sein.', (value) => {
        if (!value) return false
        const birthdate = new Date(value)
        const today = new Date()
        const age = today.getFullYear() - birthdate.getFullYear()
        return age >= 18
      }),
    recievevia: yup.string().required('Dies ist ein Pflichtfeld.'),
    newsletter: yup.boolean(),
    gutschein: yup
      .string()
      .test('is-checked', 'Bitte löse den Gutschein ein.', (value) => {
        return voucherValid != null || !formUserDataVoucherValue
      })
      // error message for voucher already handled by button, so we leave it empty here
      .test('is-valid-voucher', '', (value) => {
        if (!value || value.trim() === '') {
          return true
        }
        return voucherValid === true
      }),
    agb: yup
      .boolean()
      .oneOf([true], 'Bitte akzeptiere unsere AGB um fortzufahren.'),
  }
}

const FormUserData: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [country, setCountry] = useState<SingleOption | null>(null)
  const [federalState, setFederalState] = useState<SingleOption | null>(null)
  const { handleSubmitForm, formData, contract } = props
  const [voucherValid, setVoucherValid] = useState<boolean | null>(null)
  const [voucherCode, setVoucherCode] = useState<string | null>(null)
  const [hasVoucher, setHasVoucher] = useState<boolean>(false)

  const { data: checkVoucherData, loading: voucherLoading } = useQuery(
    CHECK_VOUCHER,
    {
      skip: voucherCode === null,
      variables: {
        code: voucherCode,
        contractId: contract?.id ?? null,
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    setVoucherValid(checkVoucherData?.voucherCheck?.valid ?? null)
  }, [checkVoucherData])

  const { register, handleSubmit, formState, setValue } =
    useForm<FormUserDataType>({
      resolver: yupResolver(
        yup.object().shape(createValidationSchema(voucherValid))
      ) as Resolver<FormUserDataType>,
      defaultValues: formData,
    })

  const onSubmit = (data: FormUserDataType) => {
    setLoading(true)
    handleSubmitForm(
      data,
      checkVoucherData?.voucherCheck?.newContract.id ?? null
    )
  }

  const getOptions = (value: SingleOption | null): SingleOption[] => {
    const defaultOption: SingleOption[] = [
      { value: '', label: 'Bitte wählen Sie ein Bundesland / Distrikt' },
    ]
    if (value === null) {
      return defaultOption
    }
    switch (value.value) {
      case countryCode.DEUTSCHLAND:
        return deutschlandBundeslaender
      case countryCode.OESTERREICH:
        return oesterreichBundeslaender
      case countryCode.LUXEMBURG:
        return luxemburgDistrikte
      case null:
        return defaultOption
      default:
        return defaultOption
    }
  }

  const recieveviaOptions = useMemo((): Radio[] => {
    const options: Radio[] = [
      {
        label: 'Im Studio (Nach 3 Werktagen)',
        forId: 'recievevia-studio',
        value: 'studio',
      },
    ]

    if (props.studio?.sisyCashbox) {
      options.push({
        label: 'Per Post (Dauert ca. 10 Werktage)',
        forId: 'recievevia-post',
        value: 'post',
      })
    }

    return options
  }, [props.studio])

  return (
    <Root className={props.className} data-testid={'form-user-data-root'}>
      <form
        onError={(error) => console.log(error)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box background={BoxBackgrounds.WHITE}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputRadioGroup
                required={true}
                disabled={false}
                label="Anrede"
                name="anrede"
                forId="anrede"
                error={formState.errors.anrede ?? false}
                errorText={formState.errors?.anrede?.message}
                direction={RadioButtonDirection.row}
                radios={[
                  {
                    label: 'Frau',
                    forId: 'anrede-frau',
                    value: 'frau',
                  },
                  {
                    label: 'Herr',
                    forId: 'anrede-herr',
                    value: 'herr',
                  },
                  {
                    label: 'Divers',
                    forId: 'anrede-divers',
                    value: 'divers',
                  },
                ]}
                register={register('anrede')}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('vorname')}
                forId="vorname"
                label="Vorname"
                error={formState.errors.vorname ?? false}
                errorText={formState.errors?.vorname?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('nachname')}
                forId="nachname"
                label="Nachname"
                required
                error={formState.errors.nachname ?? false}
                errorText={formState.errors?.nachname?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <InputText
                register={register('strasse')}
                forId="strasse"
                label="Straße"
                required
                error={formState.errors.strasse ?? false}
                errorText={formState.errors?.strasse?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <InputText
                register={register('nr')}
                forId="nr"
                label="Nr"
                required
                error={formState.errors.nr ?? false}
                errorText={formState.errors?.nr?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('zip')}
                forId="zip"
                label="PLZ"
                required
                error={formState.errors.zip ?? false}
                errorText={formState.errors?.zip?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('ort')}
                forId="ort"
                label="Ort"
                required
                error={formState.errors.ort ?? false}
                errorText={formState.errors?.ort?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputSelect
                register={register('land')}
                placeholder="Land"
                options={Countries}
                onChange={(value) => {
                  setValue('land', value.value as any)
                  setCountry(value)
                }}
                label="Land"
                forId="land"
                error={formState.errors.land ?? false}
                errorText={formState.errors?.land?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputSelect
                key={country?.value}
                register={register('bundesland')}
                placeholder="Bundesland"
                options={getOptions(country)}
                onChange={(value) => {
                  setValue('bundesland', value.value as any)
                  setFederalState(value)
                }}
                label="Bundesland"
                forId="bundesland"
                error={formState.errors.bundesland ?? false}
                errorText={formState.errors?.bundesland?.message}
                required
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <InputDate
                value=""
                register={register('geburtstag')}
                forId="geburtsdatum"
                label="Geburtsdatum"
                placeholder="Geburtsdatum"
                error={formState.errors.geburtstag ?? false}
                errorText={formState.errors?.geburtstag?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('email')}
                forId="email"
                label="E-Mail"
                required
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('emailConfirm')}
                forId="emailConfirm"
                label="E-Mail bestätigen"
                required
                error={formState.errors.emailConfirm ?? false}
                errorText={formState.errors?.emailConfirm?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputText
                register={register('telefon')}
                forId="telefon"
                label="Telefon"
                required
                error={formState.errors.telefon ?? false}
                errorText={formState.errors?.telefon?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('newsletter')}
                error={formState.errors.newsletter ?? false}
                errorText={formState.errors?.newsletter?.message}
                forId="newsletter"
                onChange={(value) => console.log(value)}
                label={
                  'Ich erkläre mich einverstanden, dass meine persönlichen Daten für an mich gerichtete Werbung (z.B. Informationen über Sonderangebote, Rabattaktionen) per Post, E-Mail und SMS sowie zu Zwecken der Marktforschung durch den Beauty- und Tanning-Studio Betreiber sowie die Fa. WM BEAUTYSYSTEMS AG & Co. KG gespeichert und genutzt werden.'
                }
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputRadioGroup
                required
                disabled={false}
                label="Clubkarte erhalten"
                name="recievevia"
                forId="recievevia"
                direction={RadioButtonDirection.row}
                error={formState.errors.recievevia ?? false}
                errorText={formState.errors?.recievevia?.message}
                radios={recieveviaOptions}
                register={register('recievevia')}
                defaultValue={'studio'}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <StyledInputText
                register={register('gutschein')}
                forId="gutschein"
                label="Gutschein einlösen"
                error={formState.errors.gutschein ?? false}
                errorText={formState.errors?.gutschein?.message}
                onChange={(value) => {
                  formUserDataVoucherValue = value.target.value.trim()
                  setHasVoucher(formUserDataVoucherValue.length > 0)
                  setVoucherCode(null)
                  setVoucherValid(null)
                }}
              />
              <ButtonStatus>
                <StyledButton
                  modifier={ButtonModifier.SMALL}
                  buttonType={ButtonTypes.BUTTON}
                  disabled={voucherValid != null || !hasVoucher}
                  loading={voucherLoading}
                  onClick={() => setVoucherCode(formUserDataVoucherValue)}
                >
                  Einlösen
                </StyledButton>
                <div>
                  {voucherValid === true && (
                    <StyledText variant={TextVariants.paragraph}>
                      Der Gutschein wurde erfolgreich eingelöst.
                      <br />
                      Die Zusammenfassung siehst du im nächsten Schritt.
                    </StyledText>
                  )}
                  {voucherValid === false && (
                    <ErrorText>Dies ist kein gültiger Gutschein.</ErrorText>
                  )}
                </div>
              </ButtonStatus>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('agb')}
                forId="agb"
                error={formState.errors.agb ?? false}
                errorText={formState.errors?.agb?.message}
                required
                label={
                  <>
                    Ich stimme den&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__AGB}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__AGB}
                      text="Allgemeinen Geschäftsbedingungen"
                    />
                    &nbsp; zu. Die&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__WIDERRUFSBELEHRUNG}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__WIDERRUFSBELEHRUNG}
                      text="Widerrufsbelehrung"
                    />
                    &nbsp;(inkl. Muster-Widerrufsformular) und die&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />
                    &nbsp;habe ich zur Kenntnis genommen.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Text variant={TextVariants.paragraph}>
                {props.studio?.operator && (
                  <OperatorStudios operator={props.studio.operator} />
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Weiter zur Zahlung
              </StyledButton>
            </ButtonCol>
          </Row>
        </Box>
      </form>
    </Root>
  )
}

export { FormUserData }
