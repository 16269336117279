import {
  acceptService as _acceptService,
  areAllConsentsAccepted,
  getServicesFullInfo,
} from '@s-group/react-usercentrics'
import { useContext, useEffect, useState } from 'react'

import RealEnvironmentContext from '@helper/context/RealEnviromentContext'
import { UsercentricServices } from '@helper/enums/UsercentricServices'

interface useUsercentricsReturnType {
  consentGoogleAnalytics: boolean
  consentGoogleMaps: boolean
  consentYoutube: boolean
  consentPaypal: boolean
  consentSentry: boolean
  consentMatterport: boolean
  consentToAll: boolean
  acceptService: (service: UsercentricServices) => Promise<void>
  showServiceDetails: (service: UsercentricServices) => void
}

const services = [
  UsercentricServices.GoogleAnalyticsService,
  UsercentricServices.GoogleMaps,
  UsercentricServices.YouTube,
  UsercentricServices.Paypal,
  UsercentricServices.Sentry,
  UsercentricServices.Matterport,
]

const useUserCentrics = (): useUsercentricsReturnType => {
  const { isRealEnvironment: usercentricsAvailable } = useContext(
    RealEnvironmentContext
  )

  const [usercentricsInitialized, setUsercentricsInitialized] =
    useState<number>(0)

  const [consentGoogleAnalytics, setConsentGoogleAnalytics] =
    useState<boolean>(false)
  const [consentGoogleMaps, setConsentGoogleMaps] = useState<boolean>(false)
  const [consentYoutube, setConsentYoutube] = useState<boolean>(false)
  const [consentPaypal, setConsentPaypal] = useState<boolean>(false)
  const [consentSentry, setConsentSentry] = useState<boolean>(false)
  const [consentMatterport, setConsentMatterport] = useState<boolean>(false)
  const [consentToAll, setConsentToAll] = useState<boolean>(false)

  useEffect(() => {
    if (!usercentricsAvailable) {
      setConsentGoogleAnalytics(true)
      setConsentGoogleMaps(true)
      setConsentYoutube(true)
      setConsentPaypal(true)
      setConsentSentry(true)
      setConsentMatterport(true)
      setConsentToAll(true)

      return
    }
    const reloadInfos = async () => {
      const serviceInfos = await getServicesFullInfo()
      for (const serviceInfo of serviceInfos) {
        switch (serviceInfo.id) {
          case UsercentricServices.GoogleAnalyticsService:
            setConsentGoogleAnalytics(serviceInfo.consent.status)
            break
          case UsercentricServices.GoogleMaps:
            setConsentGoogleMaps(serviceInfo.consent.status)
            break
          case UsercentricServices.YouTube:
            setConsentYoutube(serviceInfo.consent.status)
            break
          case UsercentricServices.Paypal:
            setConsentPaypal(serviceInfo.consent.status)
            break
          case UsercentricServices.Sentry:
            setConsentSentry(serviceInfo.consent.status)
            break
          case UsercentricServices.Matterport:
            setConsentMatterport(serviceInfo.consent.status)
            break
        }
      }

      setConsentToAll(areAllConsentsAccepted())
    }
    reloadInfos()
  }, [usercentricsInitialized, usercentricsAvailable])

  const acceptService = async (service: UsercentricServices) => {
    await _acceptService(service)
    setUsercentricsInitialized((usercentricsInitialized) => {
      return usercentricsInitialized + 1
    })
  }

  useEffect(() => {
    const updateHandler = (event: any) => {
      if (['ACCEPT_ALL', 'SAVE', 'DENY_ALL'].includes(event?.detail?.type)) {
        setUsercentricsInitialized((usercentricsInitialized) => {
          return usercentricsInitialized + 1
        })
      }
    }
    window.addEventListener('UC_UI_CMP_EVENT', updateHandler)
    return () => window.removeEventListener('UC_UI_CMP_EVENT', updateHandler)
  }, [])

  useEffect(() => {
    const updateHandler = (event: any) => {
      setUsercentricsInitialized((usercentricsInitialized) => {
        return usercentricsInitialized + 1
      })
    }
    window.addEventListener('UC_UI_INITIALIZED', updateHandler)
    return () => window.removeEventListener('UC_UI_INITIALIZED', updateHandler)
  }, [])

  const getUsercentricsUi = (): any => ('UC_UI' in window ? window.UC_UI : null)

  const showServiceDetails = (service: UsercentricServices) => {
    getUsercentricsUi()?.showSecondLayer(service)
  }

  return {
    consentGoogleAnalytics,
    consentGoogleMaps,
    consentYoutube,
    consentPaypal,
    consentSentry,
    consentMatterport,
    consentToAll,
    acceptService,
    showServiceDetails,
  }
}

export default useUserCentrics
