import styled, { css } from 'styled-components'

import { TextVariants } from './Text'

interface TextProps {
  variant?: TextVariants
  className?: string
}

export const TextLeadStyles = () => {
  return css`
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.lead.mobile.fontSize};
    line-height: ${(props) => props.theme.font.text.lead.mobile.lineHeight};
    font-weight: 300;
    letter-spacing: ${(props) =>
      props.theme.font.text.lead.mobile.letterSpacing};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.primary};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.primary};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.primary};
      }
    }
    b,
    strong {
      font-weight: 700;
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) => props.theme.font.text.lead.desktop.fontSize};
      line-height: ${(props) => props.theme.font.text.lead.desktop.lineHeight};
      letter-spacing: ${(props) => props.theme.font.text.lead.letterSpacing};
    }
  `
}
export const TextParagraphStyles = () => {
  return css`
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
    line-height: ${(props) =>
      props.theme.font.text.paragraph.mobile.lineHeight};
    font-weight: 300;
    letter-spacing: ${(props) =>
      props.theme.font.text.paragraph.mobile.letterSpacing};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.black};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.black};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.black};
      }
    }
    b,
    strong {
      font-weight: 700;
    }
    sup {
      font-size: smaller;
      vertical-align: super;
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) => props.theme.font.text.paragraph.desktop.fontSize};
      line-height: ${(props) =>
        props.theme.font.text.paragraph.desktop.lineHeight};
      letter-spacing: ${(props) =>
        props.theme.font.text.paragraph.letterSpacing};
    }
  `
}
export const TextMetaStyles = () => {
  return css`
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.meta.mobile.fontSize};
    line-height: ${(props) => props.theme.font.text.meta.mobile.lineHeight};
    letter-spacing: ${(props) =>
      props.theme.font.text.meta.mobile.letterSpacing};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    a {
      text-decoration: underline;
      &:link {
        color: ${(props) => props.theme.palette.default.black};
      }
      &:active {
        color: ${(props) => props.theme.palette.default.black};
      }
      &:visited {
        color: ${(props) => props.theme.palette.default.black};
      }
    }
    b,
    strong {
      font-weight: 700;
    }
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      font-size: ${(props) => props.theme.font.text.meta.desktop.fontSize};
      line-height: ${(props) => props.theme.font.text.meta.desktop.lineHeight};
      letter-spacing: ${(props) => props.theme.font.text.meta.letterSpacing};
    }
  `
}

export const getTextStyles = (variant?: TextVariants) => {
  switch (variant) {
    case TextVariants.lead:
      return TextLeadStyles()
    case TextVariants.paragraph:
      return TextParagraphStyles()
    case TextVariants.meta:
      return TextMetaStyles()
    default:
      return TextParagraphStyles()
  }
}

export const TextLead = styled.p<TextProps>`
  margin-bottom: ${(props) => props.theme.margin.default};
  max-width: 700px;
  ${(props) => getTextStyles(props.variant)}
`
export const TextParagraph = styled.p<TextProps>`
  margin-bottom: ${(props) => props.theme.margin.default};
  max-width: 700px;
  ${(props) => getTextStyles(props.variant)}
`
export const TextMeta = styled.p<TextProps>`
  margin-bottom: ${(props) => props.theme.margin.default};
  max-width: 700px;
  ${(props) => getTextStyles(props.variant)}
`
