import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './UnorderedList.styles'

export const ListItems = ['Lorem', 'Ipsum', 'dolor']

interface Props extends PropsWithClassName {
  onMouseLeave?: () => void
}

const UnorderedList: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, dataTestId, onMouseLeave } = props
  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave()
    }
  }
  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'unordered-list-root'}
      onMouseLeave={() => handleMouseLeave()}
    >
      {children}
    </Root>
  )
}

export { UnorderedList }
