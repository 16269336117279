import useStudioSearch from '@hooks/useStudioSearch'
import { storyblokEditable } from '@storyblok/react'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useState } from 'react'
import { useTheme } from 'styled-components'

import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'

import { useUserData } from '@helper/context/UserDataContext'
import { Searchdestinations } from '@helper/enums/storyblok/Searchdestinations'
import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import {
  RoutesSunpointDefault,
  RoutesWellmaxxDefault,
} from '@definitions/routes/Routes'
import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'
import { HeroStartpageStoryblok } from '@definitions/types/storyblokTypes'
import { type Studio } from '@definitions/types/symfonyTypesd'

import {
  BackgroundImage,
  Content,
  Desktop,
  Mobile,
  Root,
  StyledHeadline,
  StyledLinkButton,
  StyledStudioSearchBox,
} from './StoryblokHeroStartpage.styles'

interface Props extends PropsWithClassName {
  blok: HeroStartpageStoryblok
}

const StoryblokHeroStartpage: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { image, image_mobile, buttonText, buttonLink, searchLink } = blok
  const router = useRouter()
  const { studioArray, handleSearch } = useStudioSearch(null)
  const theme: any = useTheme()

  const buttonUrl = getUrlFromLink(buttonLink, theme)

  const { userData, setUserData } = useUserData()

  const handleStudioSelect = (studio: Studio): void => {
    setUserData({
      studio: studio,
      contract: userData.contract,
      formData: userData.formData,
    })
    switch (searchLink) {
      case Searchdestinations.STUDIODETAILCOUPONS:
        if (theme.key === 'sunpoint') {
          router.push(
            RoutesSunpointDefault.STUDIOLIST + '/' + studio.slug + '#coupons'
          )
        }
        if (theme.key === 'wellmaxx') {
          router.push(
            RoutesWellmaxxDefault.STUDIOLIST + '/' + studio.slug + '#coupons'
          )
        }
        break
      case Searchdestinations.STUDIODETAIL:
        if (theme.key === 'sunpoint') {
          router.push(RoutesSunpointDefault.STUDIOLIST + '/' + studio.slug)
        }
        if (theme.key === 'wellmaxx') {
          router.push(RoutesWellmaxxDefault.STUDIOLIST + '/' + studio.slug)
        }
        break
      case Searchdestinations.CLUBMITGLIEDWERDEN:
        if (theme.key === 'sunpoint') {
          router.push(RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN)
        }
        if (theme.key === 'wellmaxx') {
          router.push(RoutesWellmaxx.WELLMAXX__CLUBMITGLIEDWERDEN)
        }
        break
      default:
        router.push(RoutesSunpoint.SUNPOINT__CLUBMITGLIEDWERDEN)
        break
    }
  }

  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-hero-startpage-root'}
      role="img"
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <BackgroundImage>
        <Mobile
          backgroundImage={image_mobile.filename ?? image.filename}
        ></Mobile>
        <Desktop backgroundImage={image.filename}></Desktop>
      </BackgroundImage>
      <Content>
        <StyledHeadline importance={HeadlineImportances.h1}>
          {blok.headline}
        </StyledHeadline>
        <StyledHeadline importance={HeadlineImportances.h4}>
          {blok.subline}
        </StyledHeadline>
        {buttonUrl && buttonText && (
          <StyledLinkButton
            modifier={ButtonModifier.TERTIARY}
            disabled={false}
            icon={<IconArrowRight />}
            iconAlign={IconAligns.RIGHT}
            loading={false}
            href={buttonUrl}
            label={buttonText}
            background={ButtonModifier.TERTIARY}
          >
            {buttonText}
          </StyledLinkButton>
        )}
      </Content>
      <StyledStudioSearchBox
        onChange={(term) => handleSearch(term)}
        setStudio={(studio) => handleStudioSelect(studio)}
        studios={studioArray}
      />
    </Root>
  )
}

export { StoryblokHeroStartpage }
