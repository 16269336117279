import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import ImageSlider from '@components/molecules/ImageSlider'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageSliderStoryblok } from '@definitions/types/storyblokTypes'

import { Container, Root } from './StoryblokImageSlider.styles'

interface Props extends PropsWithClassName {
  blok: ImageSliderStoryblok
}

const StoryblokImageSlider: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, subline, slides } = blok
  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-image-slider-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Container>
            <BlockPreContent headline={headline} subline={subline} />
            <ImageSlider data={slides} />
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokImageSlider }
