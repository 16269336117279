import styled from 'styled-components'

import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'

export const Root = styled.div`
  text-align: center;
`

interface ContainerProps {
  image: string
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${(props) => 'url(' + props.image + ')'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: ${(props) => props.theme.padding.large};
  min-height: 350px;
  padding-bottom: ${(props) => props.theme.padding.large};
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-bottom: 0;
`

export const StyledHeadline = styled(Headline)`
  margin-bottom: 1rem;
  width: auto;
`

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: 350px;
  padding-bottom: ${(props) => props.theme.padding.large};
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-bottom: 0;
`

export const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentContainer = styled.div`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.lead.desktop.fontSize};
  line-height: ${(props) => props.theme.font.text.lead.desktop.lineHeight};
  font-weight: 300;
`

export const CallToActionText = styled.div`
  margin-top: 2rem;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.desktop.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.desktop.lineHeight};
  font-weight: 400;
`
