import styled from 'styled-components'

import Button from '@components/atoms/Button'
import LoadingSpinner from '@components/atoms/LoadingSpinner'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  border-radius: 1rem;
  overflow: hidden;
`
export const DummyMap = styled.div`
  width: 100%;
  height: 28.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledInfoWindow = styled.div`
  padding: 1.25rem;
`

export const ParsedAddress = styled.div``

export const StyledButton = styled(Button)`
  margin: 0;
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${(props) => props.theme.margin.default};
`

export const LoadingText = styled(Text)`
  color: ${(props) => props.theme.palette.default.white};
`
