import { FormFranchise, FormFranchiseType } from './FormFranchise'

export const dummyFormFranchiseData: FormFranchiseType = {
  name: 'Max Mustermann',
  anschrift: 'Musterstraße 1, 12345 Musterstadt',
  telefon: '+49 123 456789',
  email: 'max.mustermann@example.com',
  birthdate: '1980-01-01',
  bundesland: 'Nordrhein-Westfalen',
  suchgebiet: 'Rhein-Ruhr',
  start: '2024-05-01',
  ausbildung: 'Betriebswirtschaftslehre',
  beruflicheTaetigkeit: '10 Jahre im Marketing',
  erfahrungKaufmann: '5 Jahre als Kaufmann',
  erfahrungWellness: 'Keine',
  selbstaendig: 'Ja',
  selberFuehren: 'Ja',
  grundSelbstaendig: 'Mehr Unabhängigkeit',
  warumFranchise: 'Geringeres Risiko und erprobtes Geschäftsmodell',
  groessteVorteile: 'Markenbekanntheit und Unterstützung',
  einkommensVorstellungen: '50.000 - 70.000 Euro jährlich',
  eigenKapital: '30.000 Euro',
  wieAufmerksam: 'Internetrecherche',
  nachricht:
    'Ich bin sehr interessiert an Ihrem Franchise-Modell und würde gerne mehr erfahren.',
  privacy: true,
}

export default FormFranchise
