import { ContactFormStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokContactForm } from './StoryblokContactForm'

export default StoryblokContactForm

export const dummyBlokContactFormStoryblok: ContactFormStoryblok = {
  _uid: 'eded1a5d-4551-4606-ab40-12f58d7905ef',
  subline: 'SUNRL-117',
  headline: 'Kontakformular',
  subjects:
    'Fristgerecht zum nächstmöglichen Zeitpunkt\nSonderkündigung (Umzug/Krankheit)\nSpäter als fristgerechter, möglicher Zeitpunkt',
  component: 'contact-form',
  _editable:
    '<!--#storyblok#{"name": "contact-form", "space": "235453", "uid": "eded1a5d-4551-4606-ab40-12f58d7905ef", "id": "367795855"}-->',
}
