import React, { type PropsWithChildren, useCallback } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Box from '@components/atoms/Box'
import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import FormOfferLocation from '@components/molecules/Forms/FormOfferLocation'

import { type PropsWithClassName } from '@helper/PropsWithClassName'

import { type OfferLocationFormStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokOfferLocationForm.styles'

interface Props extends PropsWithClassName {
  blok: OfferLocationFormStoryblok
}

const StoryblokOfferLocationForm: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, subline } = blok

  const onSubmit = useCallback(async (data: object) => {
    console.log('handleSubmitForm', data)
  }, [])

  return (
    <Root
      className={props.className}
      data-testid={'storyblok-offer-location-form-root'}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <BlockPreContent headline={headline} subline={subline} />
        </Col>

        <Col xs={12} sm={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
          <Box background={BoxBackgrounds.WHITE}>
            <FormOfferLocation
              formId="offer-location"
              handleSubmitForm={onSubmit}
            />
          </Box>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokOfferLocationForm }
