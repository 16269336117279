import React, { ChangeEvent, PropsWithChildren } from 'react'

import ErrorText from '@components/atoms/ErrorText'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import FormField from '@helper/FormField'

import { Input, Root, StyledInputLabel } from './InputDate.styles'

interface Props extends FormField {
  placeholder?: string
  value: string
}

const InputDate: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    errorText,
    dataTestId,
    disabled,
    error,
    label,
    forId,
    required,
    register,
    placeholder,
  } = props
  return (
    <Root className={props.className} data-testid={'input-date-root'}>
      {label && (
        <StyledInputLabel
          error={error ?? false}
          disabled={disabled ?? false}
          required={required ?? false}
          forId={forId}
        >
          {label}
        </StyledInputLabel>
      )}
      <Input
        error={error ?? false}
        type="date"
        required={required}
        placeholder={placeholder}
        id={forId}
        {...register}
      />
      {error && (
        <ErrorText dataTestId={dataTestId + '-error'}>{errorText}</ErrorText>
      )}
    </Root>
  )
}

export { InputDate }
