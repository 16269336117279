import { ApolloError, useQuery } from '@apollo/client'
import {
  GET_STUDIOS,
  GET_STUDIOS_WITH_CATEGORIES,
} from '@graphql/queries/getStudios'
import useGoogleApi, { LatLng } from '@hooks/useGoogleApi'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { geoDistance } from '@helper/geoCalculation'

import { StudioWithDistance } from '@definitions/customTypes/StudioWithDistance'
import { Contract, Studio } from '@definitions/types/symfonyTypesd'

const searchRadius = 80

interface useStudioSearchReturnType {
  search: string
  handleSearch: (term: string) => void
  studioArray: StudioWithDistance[]
  loadingStudios: boolean
  errorStudios: ApolloError | undefined
  foundPlace: LatLng | null
}

const parseStudioZipcode = (studio: Studio): string => {
  const match = (studio.address ?? '').match(/\d{5}/)

  return match ? match[0] : ''
}

const useStudioSearch = (
  contract: Contract | null | undefined,
  studiosWithCategories: boolean = false,
  onlyStudiosWithCoupons: boolean = false
): useStudioSearchReturnType => {
  const [search, setSearch] = useState<string>('')
  const { googleApiLoaded, findPlaceFromText, foundPlace } = useGoogleApi()

  const theme: any = useTheme()

  const handleSearch = (term: string) => {
    setSearch(term)
    if (googleApiLoaded) {
      findPlaceFromText(term)
    }
  }

  const variables: any = { affiliation: theme.key }
  if (contract !== null) {
    variables.containsContract = [contract?.id ?? null]
  }
  if (onlyStudiosWithCoupons) {
    variables.hasAnyCoupons = true
  }

  const {
    loading: loadingStudios,
    error: errorStudios,
    data: dataStudios,
  } = useQuery(
    studiosWithCategories ? GET_STUDIOS_WITH_CATEGORIES : GET_STUDIOS,
    {
      skip: contract === undefined,
      variables: variables,
      fetchPolicy: 'network-only',
    }
  )

  const studioArray = useMemo<StudioWithDistance[]>(() => {
    let newArray: StudioWithDistance[] = dataStudios
      ? dataStudios.studios.edges.map((edge: { node: Studio }) => edge.node)
      : []

    if (foundPlace !== null) {
      newArray = newArray.map((studio) => {
        return {
          ...studio,
          distance: geoDistance(
            foundPlace.lng,
            foundPlace.lat,
            studio.longitude ?? 0,
            studio.latitude ?? 0
          ),
        }
      })
      newArray = newArray.filter(
        (studio) => (studio.distance ?? 0) <= searchRadius
      )
      newArray.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0))
    } else {
      newArray = newArray.filter(
        (studio) =>
          studio.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          parseStudioZipcode(studio).indexOf(search.toLowerCase()) === 0
      )
    }

    return newArray
  }, [dataStudios, foundPlace, search])

  return {
    search,
    handleSearch,
    studioArray,
    loadingStudios,
    errorStudios,
    foundPlace,
  }
}

export default useStudioSearch
