import React, { PropsWithChildren } from 'react'

import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import FormField from '@helper/FormField'

import { Root, StyledInputLabel, Textarea } from './InputTextarea.styles'

interface Props extends FormField {
  rows?: number
}

const InputTextarea: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    dataTestId,
    errorText,
    error,
    disabled,
    required,
    label,
    forId,
    register,
    rows,
  } = props

  return (
    <Root className={props.className} data-testid={'input-text-root'}>
      <StyledInputLabel
        error={error ?? false}
        disabled={disabled ?? false}
        required={required ?? false}
        forId={forId}
      >
        {label}
      </StyledInputLabel>
      <Textarea
        error={error ? error : false}
        rows={rows ? rows : 3}
        id={forId}
        {...register}
      />
      {error && (
        <Text variant={TextVariants.meta} dataTestId={dataTestId + '-error'}>
          {errorText}
        </Text>
      )}
    </Root>
  )
}

export { InputTextarea }
