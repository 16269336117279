import React from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './Col.styles'

interface Props extends PropsWithClassName {
  xs?: number
  xsOffset?: number
  sm?: number
  smOffset?: number
  md?: number
  mdOffset?: number
  lg?: number
  lgOffset?: number
  children?: any
}

const Col = (props: Props): React.ReactElement => {
  const {
    xs,
    xsOffset,
    sm,
    smOffset,
    md,
    mdOffset,
    lg,
    lgOffset,
    children,
    dataTestId,
  } = props
  function renderClassNameString(): string {
    const classNameString = ` col-xs-${xs} col-xs-offset-${xsOffset} col-sm-${sm} col-sm-offset-${smOffset} col-md-${md} col-md-offset-${mdOffset} col-lg-${lg} col-lg-offset-${lgOffset}`
    return classNameString
  }
  return (
    <Root
      className={props.className + renderClassNameString()}
      data-testid={dataTestId ?? 'Col-root'}
    >
      {children}
    </Root>
  )
}

export { Col }
