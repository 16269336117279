import React, { PropsWithChildren } from 'react'

import { Farben } from '@helper/enums/storyblok/Farben'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Children, Icon, Root } from './Box.styles'

export enum BoxBackgrounds {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WHITE = 'white',
  BLACK = 'black',
  GREYLIGHT = 'greyLight',
  GREY = 'grey',
  GREYDARK = 'greyDark',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  TRANSPARENT = 'transparent',
}

interface Props extends PropsWithClassName {
  children: React.ReactNode
  background?: BoxBackgrounds | Farben | string
  backgroundIcon?: BoxBackgrounds | Farben | string
  icon?: React.ReactNode
  iconBackgroundColor?: BoxBackgrounds | Farben | string
  backgroundImage?: string
  backgroundTransparency?: boolean
  backgroundImageOffset?: string
  onClick?: () => void
}

const Box: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const {
    children,
    dataTestId,
    background,
    backgroundIcon,
    icon,
    backgroundImage,
    backgroundImageOffset,
    backgroundTransparency,
    onClick,
  } = props
  const handleClick = (): void => {
    if (!onClick) {
      return
    }
    onClick()
  }
  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'box-root'}
      background={background ? background : BoxBackgrounds.WHITE}
      backgroundIcon={backgroundIcon}
      icon={icon}
      backgroundImage={backgroundImage}
      backgroundImageOffset={backgroundImageOffset}
      backgroundTransparency={backgroundTransparency}
      onClick={() => handleClick()}
      style={{
        backgroundImage: 'url(' + props.backgroundImage + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      {icon && (
        <Icon background={background ? background : BoxBackgrounds.WHITE}>
          {icon}
        </Icon>
      )}
      <Children>{children}</Children>
    </Root>
  )
}

export { Box }
