import styled from 'styled-components'

export const Root = styled.div``
export const TabSelectors = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`

interface TabSelectorProps {
  active: boolean
}
export const TabSelector = styled.div<TabSelectorProps>`
  display: flex;
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0rem;
  text-align: left;
  padding: 1.25rem;
  border: 0.0625rem solid ${(props) => (props.active ? 'black' : 'grey')};
  cursor: pointer;
  box-shadow: 0.3125rem 0.625rem 1.875rem
    rgba(0, 0, 0, ${(props) => (props.active ? '0.5' : '0.2')});
  &:hover {
    box-shadow: 0.3125rem 0.625rem 1.875rem rgba(0, 0, 0, 0.5);
    border: 0.0625rem solid grey;
  }
`
