import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  GridCardStoryblok,
  GridSectionStoryblok,
} from '@definitions/types/storyblokTypes'

import {
  Root,
  StyledCol,
  StyledHeadline,
  StyledText,
} from './StoryblokGridSection.styles'

interface StoryblokGridSectionProps extends GridSectionStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokGridSectionProps
}

const StoryblokGridSection = (props: Props) => {
  const { blok } = props

  const getGridCols = (cols: string | undefined) => {
    switch (cols) {
      case '2':
        return 6
      case '3':
        return 4
      case '4':
        return 3
      default:
        return 4
    }
  }
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-testid="storyblok-grid-section-root"
    >
      <Row>
        <StyledCol xs={12} sm={12} md={12} lg={12}>
          <StyledHeadline importance={HeadlineImportances.h2}>
            {props.blok.headline}
            täst
          </StyledHeadline>
          <StyledText variant={TextVariants.lead}>{props.blok.lead}</StyledText>
        </StyledCol>
      </Row>
      <Row>
        {}
        {props.blok?.cards &&
          props.blok.cards.map((card: GridCardStoryblok, index: number) => (
            <Col
              key={card._uid + card.label + 'col'}
              xs={12}
              sm={12}
              md={getGridCols(props.blok.cols)}
              lg={getGridCols(props.blok.cols)}
            >
              <StoryblokComponent key={card._uid + card.label} blok={card} />
            </Col>
          ))}
      </Row>
    </Root>
  )
}

export { StoryblokGridSection }
