import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'

import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TabbedContentEntryStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokTabbedContentEntry.styles'

interface Props extends PropsWithClassName {
  blok: TabbedContentEntryStoryblok
}

const StoryblokTabbedContentEntry = (props: Props) => {
  const { blok } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      dataTestId="storyblok-tabbed-content-entry-root"
    >
      {blok.image && blok.image.filename && (
        <Image
          width="0"
          height="0"
          sizes="100vw"
          style={{
            width: '400px',
            height: 'auto',
            margin: 'auto auto 30px auto ',
          }}
          alt={blok.image.alt ? blok.image.alt : ''}
          src={blok.image.filename}
          priority
        />
      )}
      <Headline importance={HeadlineImportances.h2}>
        {blok.headline?.toString()}
      </Headline>
      <Text variant={TextVariants.paragraph}>
        {blok.description?.toString()}
      </Text>
    </Root>
  )
}

export { StoryblokTabbedContentEntry }
