import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import {
  BannerReferenceStoryblok,
  ImageTextSectionStoryblok,
} from '@definitions/types/storyblokTypes'

interface StoryblokBannerReferenceProps extends BannerReferenceStoryblok {}

const StoryblokBannerReference = ({ blok }: StoryblokBannerReferenceProps) => {
  if (blok === undefined) {
    return (
      <Text variant={TextVariants.paragraph}>
        StoryblokBannerReference No blok
      </Text>
    )
  }
  return (
    <div
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-test="image-text-section"
      style={{ textAlign: 'center', marginBottom: '20px' }}
    >
      <Row>
        <Col md={6}>
          <Headline importance={HeadlineImportances.h2}>
            {blok.headline?.toString()}
          </Headline>
        </Col>
        <Col md={6}>
          {blok.image && (
            <Image
              width="0"
              height="0"
              sizes="100vw"
              style={{ width: '400px', height: 'auto' }}
              alt={blok.image.alt}
              src={blok.image.filename}
              priority
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default StoryblokBannerReference
