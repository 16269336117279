import styled from 'styled-components'

import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'
import Text from '@components/atoms/Text'

interface RootProps {
  paddingTop: boolean
  alignment?: 'left' | 'center'
}

interface TextProps {
  alignment?: 'left' | 'center'
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.margin.big};
  padding-top: ${(props) =>
    props.paddingTop ? props.theme.padding.large : '0'};
  text-align: ${(props) => props.alignment};
`
export const StyledHeadline = styled(Headline)<TextProps>`
  text-align: ${(props) => props.alignment};
  margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
`
export const StyledText = styled(Text)<TextProps>`
  text-align: ${(props) => props.alignment};
  margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
  margin-top: ${(props) => props.theme.margin.default};
`
export const RichText = styled.div<TextProps>`
  margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
  margin-top: ${(props) => props.theme.margin.default};
  p {
    margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
    margin-bottom: ${(props) => props.theme.margin.default};
  }
  ul,
  ol {
    margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
    margin-bottom: ${(props) => props.theme.margin.default};
    li {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  }
`

export const StyledLinkButton = styled(LinkButton)<TextProps>`
  margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
  justify-self: center;
  align-self: center;
  margin-top: ${(props) => props.theme.margin.default};
  a {
    margin: ${(props) => (props.alignment === 'center' ? 'auto' : 0)};
  }
`
