import { InputRadioGroup, Radio } from './InputRadioGroup'

export const DummyRadios: Radio[] = [
  {
    label: 'Gelb',
    forId: 'InputRadioGroupoptionaRADIO',
    value: 'InputRadioGroupgelb',
  },
  {
    label: 'Grün',
    forId: 'InputRadioGroupoptionbRADIO',
    value: 'InputRadioGroupgruen',
  },
  {
    label: 'Blau',
    forId: 'InputRadioGroupoptioncRADIO',
    value: 'InputRadioGroupblau',
  },
]

export const DummyRadiosPlaces: Radio[] = [
  {
    label: 'Paris',
    forId: 'InputRadioGroupoptionaRADIOparis',
    value: 'InputRadioGroupparis',
  },
  {
    label: 'Berlin',
    forId: 'InputRadioGroupoptionbRADIOberlin',
    value: 'InputRadioGroupberlin',
  },
  {
    label: 'Prag',
    forId: 'InputRadioGroupoptioncRADIOprag',
    value: 'InputRadioGroupprag',
  },
]

export default InputRadioGroup
