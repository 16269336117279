import { HeroSubpageStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokHeroSubpage } from './StoryblokHeroSubpage'

export default StoryblokHeroSubpage

export const dummyBlokHeroSubpageStoryblok: HeroSubpageStoryblok = {
  _uid: 'c7cb1668-ca30-4a9b-8ff4-1693f08cfc3e',
  image: {
    id: 13935669,
    alt: 'Februaraktion',
    name: '',
    focus: '',
    title: 'Februaraktion',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/3000x650/19de963b71/sp-aktion-desktop_mit-text_3000x650.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    is_private: '',
    is_external_url: false,
  },
  component: 'hero-subpage',
  image_mobile: {
    id: 13935670,
    alt: 'Februaraktion',
    name: '',
    focus: '',
    title: 'Februaraktion',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/768x650/bec0e0d4c4/sp-aktion-tablet_mit-text_768x650.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    is_private: '',
    is_external_url: false,
  },
  _editable:
    '<!--#storyblok#{"name": "hero-subpage", "space": "235453", "uid": "c7cb1668-ca30-4a9b-8ff4-1693f08cfc3e", "id": "424177377"}-->',
}
