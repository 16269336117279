import { TabbedContentSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTabbedContentSection } from './StoryblokTabbedContentSection'

export const dummyTabbedContentSectionBlok: TabbedContentSectionStoryblok = {
  _uid: '0df94950-1246-4af4-88e0-161421e79088',
  lead: 'Cupidatat consequat enim adipisicing laborum et.',
  entries: [
    {
      _uid: 'f320e324-83c4-4e23-94f3-1eefb8e7c410',
      image: {
        id: 8908003,
        alt: '',
        name: '',
        focus: '',
        title: '',
        source: '',
        filename:
          'https://a.storyblok.com/f/228071/1920x1080/a17bd8776b/development.jpeg',
        copyright: '',
        fieldtype: 'asset',
        meta_data: {},
        is_external_url: false,
      },
      button: [],
      headline: 'Tabbed Content Entry',
      component: 'tabbed-content-entry',
      description:
        'Culpa amet qui consectetur dolor in fugiat ea et nulla ea cillum laborum sint.',
      _editable:
        '<!--#storyblok#{"name": "tabbed-content-entry", "space": "228071", "uid": "f320e324-83c4-4e23-94f3-1eefb8e7c410", "id": "300939517"}-->',
    },
    {
      _uid: '8685e05a-12f6-4df9-94a1-0cdfa64045fd',
      image: {
        id: 1,
        alt: 'null',
        name: '',
        focus: 'null',
        title: 'null',
        source: 'null',
        filename: '',
        copyright: 'null',
        fieldtype: 'asset',
        meta_data: {},
      },
      button: [],
      headline: 'Tabbed Content Entry',
      component: 'tabbed-content-entry',
      description: 'Non velit sunt qui sint fugiat qui Lorem.',
      _editable:
        '<!--#storyblok#{"name": "tabbed-content-entry", "space": "228071", "uid": "8685e05a-12f6-4df9-94a1-0cdfa64045fd", "id": "300939517"}-->',
    },
    {
      _uid: 'a5ed2d45-2310-451d-a8bb-2b2d1c5b0af3',
      image: {
        id: 12,
        alt: 'null',
        name: '',
        focus: 'null',
        title: 'null',
        source: 'null',
        filename: '',
        copyright: 'null',
        fieldtype: 'asset',
        meta_data: {},
      },
      button: [],
      headline: 'Tabbed Content Entry',
      component: 'tabbed-content-entry',
      description:
        'Proident incididunt commodo aliquip irure ex enim mollit deserunt sunt.',
      _editable:
        '<!--#storyblok#{"name": "tabbed-content-entry", "space": "228071", "uid": "a5ed2d45-2310-451d-a8bb-2b2d1c5b0af3", "id": "300939517"}-->',
    },
  ],
  headline: 'Tabbed Content Section',
  component: 'tabbed-content-section',
  _editable:
    '<!--#storyblok#{"name": "tabbed-content-section", "space": "228071", "uid": "0df94950-1246-4af4-88e0-161421e79088", "id": "300939517"}-->',
}

export default StoryblokTabbedContentSection
