import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './HorizontalRule.styles'

interface Props extends PropsWithClassName {}

const HorizontalRule: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root className={props.className} data-testid={'horizontal-rule-root'} />
  )
}

export { HorizontalRule }
