import useUserCentrics from '@hooks/useUserCentrics'
import React, { type PropsWithChildren } from 'react'

import { UsercentricServices } from '@helper/enums/UsercentricServices'
import { type PropsWithClassName } from '@helper/PropsWithClassName'

import { Button, ButtonModifier, ButtonTypes } from '../../atoms/Button/Button'
import { Headline, HeadlineImportances } from '../../atoms/Headline/Headline'
import { Text, TextVariants } from '../../atoms/Text/Text'
import {
  Root,
  StyledImage,
  StyledText,
  StyledUsercentricsBox,
} from './UsercentricsConsent.styles'

interface Props extends PropsWithClassName {
  placeholderImage?: {
    filename: string
    alt?: string
  }
  headline: string
  text: string
  service: UsercentricServices
}

const UsercentricsConsent: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { acceptService, showServiceDetails } = useUserCentrics()

  return (
    <Root className={props.className} data-testid={'usercentrics-consent-root'}>
      {props.placeholderImage && (
        <StyledImage
          src={props.placeholderImage.filename}
          alt={props.placeholderImage.alt}
        />
      )}
      <StyledUsercentricsBox>
        <Headline importance={HeadlineImportances.h5}>
          {props.headline}
        </Headline>
        <Text variant={TextVariants.paragraph}>{props.text}</Text>
        <Button
          modifier={ButtonModifier.MINIMAL}
          buttonType={ButtonTypes.BUTTON}
          disabled={false}
          loading={false}
          onClick={() => showServiceDetails(props.service)}
        >
          Mehr Informationen
        </Button>
        <Button
          modifier={ButtonModifier.PRIMARY}
          buttonType={ButtonTypes.SUBMIT}
          disabled={false}
          loading={false}
          onClick={() => acceptService(props.service)}
        >
          Akzeptieren
        </Button>
        <StyledText variant={TextVariants.meta}>
          powered by{' '}
          <a
            href="https://usercentrics.com/de/consent-management-platform-powered-by-usercentrics/"
            target="_blank"
          >
            Usercentrics Consent Management Platform
          </a>
        </StyledText>
      </StyledUsercentricsBox>
    </Root>
  )
}

export { UsercentricsConsent }
