import styled from 'styled-components'

import Box from '@components/atoms/Box'

import { shouldUseWhiteText } from '@helper/color'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.default.greyLight};
  padding-top: ${(props) => props.theme.padding.big};
  padding-bottom: ${(props) => props.theme.padding.big};
`

export const StyledBox = styled(Box)`
  max-width: 30rem;
  background: ${(props) => props.theme.palette.default.primary};
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
