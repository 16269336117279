import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { PropsWithClassName } from './PropsWithClassName'

interface Props extends PropsWithClassName {
  linkSunpoint: string
  linkWellmaxx: string
  text: string
}

const ThemeBasedLink: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { linkSunpoint, linkWellmaxx, text } = props
  const theme: any = useTheme()
  return (
    <Link
      target="_blank"
      href={theme.key === 'sunpoint' ? linkSunpoint : linkWellmaxx}
    >
      {text}
    </Link>
  )
}

export default ThemeBasedLink
