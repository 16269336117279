import styled from 'styled-components'

import Button from '@components/atoms/Button'
import { ButtonTypes } from '@components/atoms/Button/Button'

interface ButtonProps {
  buttonType: ButtonTypes
}
export const Root = styled(Button)<ButtonProps>`
  max-width: 18.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
