import { StoryblokTextIconColContainer } from './StoryblokTextIconColContainer'

export default StoryblokTextIconColContainer

export const dummyBlokTextIconColContainerStoryblok = {
  _uid: '9fbfcb48-6169-4b36-b00d-1f5a78f6ea36',
  subline: 'Spalten mit Icons',
  headline: 'SUNRL-104',
  component: 'text-icon-col-container',
  textIconCols: [
    {
      _uid: '87c85e9c-cddd-4fad-bf22-ec678e74dee8',
      icon: 'sun',
      link: {
        id: '50d1b16c-e98c-4255-a068-8fe147ec27db',
        url: '',
        linktype: 'story',
        fieldtype: 'multilink',
        cached_url: 'sunpoint/home',
      },
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Lorem ipsum dolor sit amet,',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'consetetur sadipscing elitr',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'tempor invidunt ut labore ',
                type: 'text',
              },
            ],
          },
        ],
      },
      subline: 'consetetur',
      headline: 'Lorem ipsum',
      linkName: 'Mehr erfahren',
      component: 'text-icon-col',
      blockBackground: true,
      _editable:
        '<!--#storyblok#{"name": "text-icon-col", "space": "235453", "uid": "87c85e9c-cddd-4fad-bf22-ec678e74dee8", "id": "322787899"}-->',
    },
    {
      _uid: 'c5289ccf-6826-47a8-95c3-465bbf92cc12',
      icon: 'body',
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Lorem ipsum dolor sit amet,',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'consetetur sadipscing elitr',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'tempor invidunt ut labore ',
                type: 'text',
              },
            ],
          },
        ],
      },
      subline: 'consetetur',
      headline: 'Lorem ipsum ',
      component: 'text-icon-col',
      blockBackground: true,
      _editable:
        '<!--#storyblok#{"name": "text-icon-col", "space": "235453", "uid": "c5289ccf-6826-47a8-95c3-465bbf92cc12", "id": "322787899"}-->',
    },
    {
      _uid: 'dbda4d11-dbb6-4688-9b49-7257a3fdb85f',
      icon: 'cosmetics',
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Lorem ipsum dolor sit amet,',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'consetetur sadipscing elitr',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'tempor invidunt ut labore ',
                type: 'text',
              },
            ],
          },
        ],
      },
      subline: 'consetetur',
      headline: 'Lorem ipsum',
      component: 'text-icon-col',
      blockBackground: true,
      _editable:
        '<!--#storyblok#{"name": "text-icon-col", "space": "235453", "uid": "dbda4d11-dbb6-4688-9b49-7257a3fdb85f", "id": "322787899"}-->',
    },
  ],
  _editable:
    '<!--#storyblok#{"name": "text-icon-col-container", "space": "235453", "uid": "9fbfcb48-6169-4b36-b00d-1f5a78f6ea36", "id": "322787899"}-->',
}
