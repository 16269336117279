import styled from 'styled-components'

export const Root = styled.ul`
  margin-bottom: ${(props) => props.theme.margin.default};
  max-width: 40rem;
  list-style-type: none;
  li::before {
    content: '•';
  }
`
