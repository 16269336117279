import React, { PropsWithChildren, useState } from 'react'

import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import LogoDevee from '@components/atoms/Logos/LogoDevee'
import LogoLiftmee from '@components/atoms/Logos/LogoLiftmee'
import LogoSunmaxx from '@components/atoms/Logos/LogoSunmaxx'
import LogoWellmaxx from '@components/atoms/Logos/LogoWellmaxx'
import { Text, TextVariants } from '@components/atoms/Text/Text'
import FormNewsletter from '@components/molecules/Forms/FormNewsletter'
import { FormNewsletterType } from '@components/molecules/Forms/FormNewsletter/FormNewsletter'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { NewsletterFormularStoryblok } from '@definitions/types/storyblokTypes'

import {
  Logos,
  Root,
  Stoerer,
  StyledBox,
  StyledIconLargeSun,
  StyledText,
} from './StoryblokNewsletter.styles'

interface StoryblokNewsletterProps extends NewsletterFormularStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokNewsletterProps
}
const StoryblokNewsletter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [subscribed, setSubscribed] = useState<boolean>(false)

  const handleSubmit = (data: FormNewsletterType) => {
    setSubscribed(true)
  }
  return (
    <Root className={props.className} data-testid={'storyblok-newsletter-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledBox background={BoxBackgrounds.WHITE}>
            <Stoerer>
              <StyledText variant={TextVariants.lead}>
                <b>5€</b>
              </StyledText>
              <StyledText variant={TextVariants.meta}>Gutschein</StyledText>
              <StyledIconLargeSun />
            </Stoerer>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Headline importance={HeadlineImportances.h3}>
                  Newsletter
                </Headline>
                {!subscribed && (
                  <>
                    <Headline importance={HeadlineImportances.h5}>
                      Exklusive Angebote & Gewinnspiele
                    </Headline>
                    <Text variant={TextVariants.paragraph}>
                      Jetzt anmelden und <b>5€ Gutschein</b> für unseren
                      Online-Shop sichern.
                    </Text>
                  </>
                )}
                <Logos>
                  <LogoSunmaxx />
                  <LogoWellmaxx height={30} />
                  <LogoLiftmee />
                  <LogoDevee />
                </Logos>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <FormNewsletter
                  formId="widget"
                  list={props.blok.list!}
                  handleSubmitForm={(data: FormNewsletterType) =>
                    handleSubmit(data)
                  }
                />
              </Col>
            </Row>
          </StyledBox>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokNewsletter }
