import { TextColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextColContainer } from './StoryblokTextColContainer'

export default StoryblokTextColContainer

export const dummyBlokTextColContainerStoryblok: TextColContainerStoryblok = {
  _uid: '0bcf8f91-4409-4cd0-a831-91112f26e7e0',
  button: 'Clubmitglied werden',
  subline: 'dsdasdsdaddsd',
  headline: 'dsadssadsda',
  textcols: [
    {
      _uid: '3caa5dca-2448-4917-ba2f-60164a72543b',
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'saddssdda saddssdadsad',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                text: 'dsasdasadsad dassad sadddsadsads sadd',
                type: 'text',
              },
            ],
          },
        ],
      },
      headline: 'sdasdsda',
      component: 'text-col',
      _editable:
        '<!--#storyblok#{"name": "text-col", "space": "235453", "uid": "3caa5dca-2448-4917-ba2f-60164a72543b", "id": "322787899"}-->',
    },
    {
      _uid: '2bd242fc-1c12-4773-b6fc-50b98ed87957',
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'dsasddsadd ',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'dsaasdd ',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsd',
                type: 'text',
              },
            ],
          },
        ],
      },
      headline: 'sdsadsdsd',
      component: 'text-col',
      _editable:
        '<!--#storyblok#{"name": "text-col", "space": "235453", "uid": "2bd242fc-1c12-4773-b6fc-50b98ed87957", "id": "322787899"}-->',
    },
    {
      _uid: '623ff9b6-2dc9-4a73-bcd9-fce0cf48b4d5',
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'dassdsad adsadsd ',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
                marks: [
                  {
                    type: 'italic',
                  },
                ],
              },
              {
                type: 'hard_break',
              },
              {
                text: 'asdsdasas dsadsasda',
                type: 'text',
              },
            ],
          },
        ],
      },
      headline: 'dsasadsdds',
      component: 'text-col',
      _editable:
        '<!--#storyblok#{"name": "text-col", "space": "235453", "uid": "623ff9b6-2dc9-4a73-bcd9-fce0cf48b4d5", "id": "322787899"}-->',
    },
  ],
  component: 'text-col-container',
  buttonLink: {
    id: '589e3df3-f7df-4728-989e-8cbdf37e6209',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: 'sunpoint/braeune/solarium',
  },
  _editable:
    '<!--#storyblok#{"name": "text-col-container", "space": "235453", "uid": "0bcf8f91-4409-4cd0-a831-91112f26e7e0", "id": "322787899"}-->',
}
