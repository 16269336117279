import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { FeaturedArticlesSectionStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokFeaturedArticleSection.styles'

interface Props extends PropsWithClassName {
  blok: FeaturedArticlesSectionStoryblok
}

const StoryblokFeaturedArticleSection = (props: Props) => {
  const { blok } = props

  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-testid="storyblok-featured-article-section-root"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Headline importance={HeadlineImportances.h2}>
            {blok.headline}
          </Headline>
          <Headline importance={HeadlineImportances.h3}>{blok.lead}</Headline>
        </Col>
      </Row>
      <Row>
        {blok.articles &&
          blok.articles.map((article) => (
            <Col key={article.toString()} xs={12} sm={12} md={4} lg={4}>
              <Text variant={TextVariants.paragraph}>
                TODO: Load Article by Id
              </Text>
              <Text variant={TextVariants.paragraph}>{article.toString()}</Text>
            </Col>
          ))}
      </Row>
    </Root>
  )
}

export { StoryblokFeaturedArticleSection }
