import { gql } from '@apollo/client'

export const FRAGMENT_CONTRACT_BASE = gql`
  fragment ContractBase on Contract {
    id
    title
    contractId
    contractValue
    duration
    durationDescription
    weeklyFee
    weeklyFeeDescription
    weeklyInterval
    monthlyFee
    monthlyFeeDescription
    insteadPrice
    promotionText
    infoInLayer
    conditions
    priceCalculationMembership
    standard
    specialOffer
    affiliations {
      edges {
        node {
          id
          name
        }
      }
    }
    costs {
      edges {
        node {
          id
          title
          costType
          amount
        }
      }
    }
  }
`
