import { gql } from '@apollo/client'

import { FRAGMENT_CONTRACT_BASE } from '../fragments/contractFragment'

export const GET_STANDARD_CONTRACTS = gql`
  query Contracts($affiliation: String) {
    contracts(standard: true, affiliation: $affiliation) {
      edges {
        node {
          ...ContractBase
        }
      }
    }
  }
  ${FRAGMENT_CONTRACT_BASE}
`

export const GET_CONTRACTS_BY_STUDIO = gql`
  query Contracts($containsStudio: Iterable, $affiliation: String) {
    contracts(
      containsStudio: $containsStudio
      affiliation: $affiliation
      standard: true
    ) {
      edges {
        node {
          ...ContractBase
        }
      }
    }
  }
  ${FRAGMENT_CONTRACT_BASE}
`

export const GET_CONTRACT = gql`
  query Contract($id: ID!) {
    contract(id: $id) {
      ...ContractBase
    }
  }
  ${FRAGMENT_CONTRACT_BASE}
`
