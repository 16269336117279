import styled from 'styled-components'

export const InfoSymbol = styled.span`
  align-items: center;
  background-color: ${(props) => props.theme.palette.default.black};
  border-radius: 100%;
  color: ${(props) => props.theme.palette.default.white};
  display: inline-flex;
  font-weight: bolder;
  height: 20px;
  justify-content: center;
  width: 20px;
`

export const InfoButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0 0 10px;
  outline: none;
  padding: 0;
`

export const Headline = styled.h1`
  color: ${(props) => props.theme.palette.default.white};
  font-size: 0.85rem;
  font-weight: bold;
  margin-bottom: 10px;
`

export const TooltipContent = styled.div`
  max-width: 300px;
  text-align: left;
`
