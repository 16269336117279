import styled from 'styled-components'

import Button from '@components/atoms/Button'

import { shouldUseWhiteText } from '@helper/color'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
`

export const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.palette.default.greyLight};
`

const COLORS: Record<string, string> = {
  Bräune: '#EFCD00',
  Bodyforming: '#8A2163',
  Kosmetik: '#869396',
  Club: '#D3E7F5',
}

interface TabProps {
  active: boolean
  variant: string
}

export const Tab = styled.div<TabProps>`
  background-color: ${(props) =>
    props.active
      ? COLORS[props.variant]
      : props.theme.palette.default.greyLight};
  border: none;
  color: ${(props) =>
    props.active && shouldUseWhiteText(COLORS[props.variant])
      ? props.theme.palette.button.primary.text
      : props.theme.palette.default.black};
  cursor: pointer;
  padding: 14px 8px;
  transition: color 0.15s ease-in-out;
  &:hover {
    background-color: ${({ variant }) => COLORS[variant]};
    color: ${({ theme, variant }) =>
      shouldUseWhiteText(COLORS[variant])
        ? theme.palette.default.white
        : theme.palette.default.black};
  }
  &:last-child {
    border-top-right-radius: 16px;
  }
  &:first-child {
    border-top-left-radius: 16px;
  }
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    padding: 14px 24px;
  }
`

export const Pills = styled.div`
  display: flex;
  gap: ${(props) => props.theme.padding.small};
  justify-content: center;
  margin: ${(props) => props.theme.margin.default} 0;
  flex-wrap: wrap;
`

interface FilterResetButtonProps {
  color: string
}

export const FilterResetButton = styled(Button)<FilterResetButtonProps>`
  margin: auto;
  margin-bottom: ${(props) => props.theme.margin.default};
  svg {
    path {
      fill: ${(props) => (shouldUseWhiteText(props.color) ? 'white' : 'black')};
    }
  }
  &:hover {
    svg {
      path {
        fill: ${(props) =>
          shouldUseWhiteText(props.color) ? 'white' : 'black'};
      }
    }
  }
`
