import useUserCentrics from '@hooks/useUserCentrics'
import { useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'
import getUuidByString from 'uuid-by-string'

import { Contract as ContractType } from '@definitions/types/symfonyTypesd'
import { Studio } from '@definitions/types/symfonyTypesd'

enum TrackingCategory {
  Ecommerce = 'Ecommerce',
}

interface useGoogleTrackingReturnType {
  trackAddToCart: (contract: ContractType, studio: Studio) => void
  trackPurchase: (
    contract: ContractType,
    studio: Studio,
    coupon: string,
    transactionId: string,
    email: string
  ) => void
  trackAddPaymentInfo: (contract: ContractType, studio: Studio) => void
  trackViewContractList: (contracts: ContractType[]) => void
  trackStudioSelection: () => void
  trackAddShippingInfo: (contract: ContractType, studio: Studio) => void
  trackNewsletterRegistration: () => void
  trackJobApplication: (studioName: string, position: string) => void
  trackDownloadCoupon: (studio: Studio, couponName: string) => void
  trackPageView: (page: string, title: string) => void
}

interface TrackContractItem {
  item_name: string
  item_id: string
  price: number
  item_brand: string
  item_category: string
  item_category_2?: string
  item_variant: string
  quantity: string
  coupon?: string
}

interface TrackContractListItem extends TrackContractItem {
  item_list_name: string
  item_list_id: string
  index: number
}

const createTrackContractItemForCheckout = (
  contract: ContractType,
  studio?: Studio,
  coupon?: string,
  email?: string
): TrackContractItem => {
  let brand = ''
  for (const affiliation of contract.affiliations?.edges ?? []) {
    brand = affiliation?.node?.name ?? ''
  }

  let gaItem: any = {
    item_name: contract.title,
    item_id: contract.id.replace('/api/contracts/', ''),
    price: contract.contractValue,
    item_brand: brand,
    item_category: 'Monats Vertrag',
    item_variant: contract.duration,
    quantity: '1',
  }

  if (studio) {
    gaItem.item_category_2 = studio.title
  }

  if (coupon) {
    gaItem.coupon = coupon
  }

  if (email) {
    gaItem.email = email
  }

  return gaItem
}

const createTrackContractListItemsForCheckout = (
  contracts: ContractType[]
): TrackContractListItem[] => {
  const items: TrackContractListItem[] = []

  let index = 1

  for (const contract of contracts) {
    const trackContractItem = createTrackContractItemForCheckout(
      contract
    ) as TrackContractListItem
    trackContractItem.item_list_name = 'Vertragsübersicht'
    trackContractItem.item_list_id = 'V1'
    trackContractItem.index = index
    items.push(trackContractItem)
    index++
  }

  return items
}

const track = (eventName: string, data?: any, category?: string) => {
  const trackingData = {
    event: eventName,
    ...data,
  }

  if (category) {
    trackingData.category = category
  }

  ReactGA.event(eventName, trackingData)
}

const trackContractEvent = (
  eventName: string,
  contract: ContractType,
  studio: Studio,
  coupon?: string,
  transactionId?: string,
  email?: string
) => {
  const gaItem = createTrackContractItemForCheckout(
    contract,
    studio,
    coupon,
    email
  )
  const data: any = {
    currency: 'EUR',
    value: contract.contractValue,
    items: [gaItem],
  }

  if (transactionId) {
    data.transaction_id = transactionId.replace('/api/registrations/', '')
  }

  track(
    eventName,
    {
      ecommerce: data,
    },
    TrackingCategory.Ecommerce
  )
}

const trackListContractsEvent = (
  eventName: string,
  contracts: ContractType[]
) => {
  track(
    eventName,
    {
      ecommerce: {
        items: createTrackContractListItemsForCheckout(contracts),
      },
    },
    TrackingCategory.Ecommerce
  )
}

const useGoogleTracking = (): useGoogleTrackingReturnType => {
  const initialized = useRef<boolean>(false)
  const alreadyTracked = useRef<string[]>([])
  const delayedTrackings = useRef<
    { event: string; trackingDataId: string; callback: () => void }[]
  >([])
  const { consentGoogleAnalytics } = useUserCentrics()

  const tryTrackEvent = (
    event: string,
    trackingDataId: string,
    callback: () => void
  ) => {
    const trackingId = `${event}#${trackingDataId}`
    if (alreadyTracked.current.includes(trackingId)) {
      return
    }

    if (!initialized.current) {
      delayedTrackings.current.push({ event, trackingDataId, callback })
      return
    }
    callback()
    alreadyTracked.current = [...alreadyTracked.current, trackingId]
  }

  useEffect(() => {
    if (
      !consentGoogleAnalytics ||
      initialized.current ||
      !process.env.googleTrackingCode
    ) {
      return
    }
    initialized.current = true

    for (const {
      event,
      trackingDataId,
      callback,
    } of delayedTrackings.current) {
      tryTrackEvent(event, trackingDataId, callback)
    }
    delayedTrackings.current = []
    ReactGA.initialize(process.env.googleTrackingCode as string)
  }, [consentGoogleAnalytics])

  const trackAddToCart = (contract: ContractType, studio: Studio): void => {
    const event = 'add_to_cart'
    tryTrackEvent(event, studio.id, () => {
      trackContractEvent(event, contract, studio, undefined)
    })
  }
  const trackPurchase = (
    contract: ContractType,
    studio: Studio,
    coupon: string,
    transactionId: string,
    email: string
  ): void => {
    const event = 'purchase'
    tryTrackEvent(
      event,
      contract.id + '#' + studio.id + '#' + coupon + '#' + transactionId,
      () => {
        trackContractEvent(
          event,
          contract,
          studio,
          coupon,
          transactionId,
          email
        )
      }
    )
  }

  const trackAddShippingInfo = (
    contract: ContractType,
    studio: Studio
  ): void => {
    const event = 'add_shipping_info'
    tryTrackEvent(event, contract.id + '#' + studio.id, () => {
      trackContractEvent(event, contract, studio, undefined)
    })
  }

  const trackAddPaymentInfo = (
    contract: ContractType,
    studio: Studio
  ): void => {
    const event = 'add_payment_info'
    tryTrackEvent(event, contract.id + '#' + studio.id, () => {
      trackContractEvent(event, contract, studio, undefined)
    })
  }

  const trackViewContractList = (contracts: ContractType[]): void => {
    const event = 'view_item_list'
    const trackingDataId = getUuidByString(contracts.map((c) => c.id).join('#'))

    tryTrackEvent(event, trackingDataId, () => {
      trackListContractsEvent(event, contracts)
    })
  }

  const trackStudioSelection = (): void => {
    const event = 'view_studioauswahl'
    tryTrackEvent(event, '', () => {
      track(event, {}, TrackingCategory.Ecommerce)
    })
  }

  const trackNewsletterRegistration = (): void => {
    const event = 'newsletter_signup'
    tryTrackEvent(event, '', () => {
      track(event)
    })
  }

  const trackJobApplication = (studioName: string, position: string): void => {
    const event = 'bewerbung_abgeschickt'
    tryTrackEvent(event, studioName + '#' + position, () => {
      track(event, {
        studio: studioName,
        position,
      })
    })
  }

  const trackDownloadCoupon = (studio: Studio, couponName: string): void => {
    const event = 'download_coupon'
    tryTrackEvent(event, couponName, () => {
      track(event, {
        coupon_name: couponName,
      })
    })
  }

  const trackPageView = (page: string, title: string): void => {
    const event = 'pageview'
    tryTrackEvent(event, page + '#' + title, () => {
      ReactGA.send({ hitType: 'pageview', page: page, title: title })
    })
  }

  return {
    trackAddToCart,
    trackPurchase,
    trackAddPaymentInfo,
    trackViewContractList,
    trackStudioSelection,
    trackAddShippingInfo,
    trackNewsletterRegistration,
    trackJobApplication,
    trackDownloadCoupon,
    trackPageView,
  }
}

export default useGoogleTracking
