import parse from 'html-react-parser'
import React, { PropsWithChildren } from 'react'

import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { TextVariants } from '@components/atoms/Text/Text'

import { parseOptions } from '@helper/parseOptions'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { StudioWithDistance } from '@definitions/customTypes/StudioWithDistance'
import { Studio } from '@definitions/types/symfonyTypesd'

import {
  Control,
  Distance,
  Error,
  Info,
  Result,
  Root,
  StyledButton,
  StyledText,
} from './StudioFinderResultsList.styles'

interface Props extends PropsWithClassName {
  studios: StudioWithDistance[]
  setStudio: (studio: Studio) => void
  hideErrorMessage?: boolean
}

const StudioFinderResultsList: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { studios, setStudio, hideErrorMessage } = props
  if (studios.length === 0 || studios === undefined) {
    return (
      <Root
        className={props.className}
        data-testid={'studio-finder-results-list-root'}
      >
        {!hideErrorMessage && (
          <Error>
            <Headline importance={HeadlineImportances.h3}>
              Es wurden keine Studios gefunden.
            </Headline>
          </Error>
        )}
      </Root>
    )
  }
  return (
    <Root
      className={props.className}
      data-testid={'studio-finder-results-list-root'}
    >
      {studios.map((studio, index) => {
        return (
          <Result key={index}>
            <Info xs={12} sm={8} md={8} lg={8}>
              <StyledText variant={TextVariants.paragraph}>
                <b>{studio.title}</b>
              </StyledText>
              {parse(studio.address, parseOptions)}
            </Info>
            <Control xs={12} sm={3} md={3} lg={3}>
              {studio.distance && (
                <Distance>
                  <StyledText variant={TextVariants.paragraph}>
                    <b>{Math.round(studio.distance)}</b> km
                  </StyledText>
                </Distance>
              )}
              <StyledButton
                onClick={() => setStudio(studio)}
                disabled={false}
                modifier={ButtonModifier.SMALL}
                icon={<IconArrowRight />}
                loading={false}
                iconAlign={IconAligns.RIGHT}
              >
                Studio wählen
              </StyledButton>
            </Control>
          </Result>
        )
      })}
    </Root>
  )
}

export { StudioFinderResultsList }
