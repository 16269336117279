import Image from 'next/image'
import React, { PropsWithChildren } from 'react'
import {
  NODE_BR,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_HR,
  NODE_IMAGE,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer'

import Blockqoute from '@components/atoms/Blockqoute'
import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import HorizontalRule from '@components/atoms/HorizontalRule'
import IconCheckmark from '@components/atoms/Icons/IconCheckmark'
import IconChevron from '@components/atoms/Icons/IconChevron'
import IconCircleCheck from '@components/atoms/Icons/IconCircleCheck'
import ListItem from '@components/atoms/ListItem'
import OrderedList from '@components/atoms/OrderedList'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import UnorderedList from '@components/atoms/UnorderedList'
import StoryblokBulletListItem from '@components/storyblok/atoms/StoryblokBulletListItem'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokRichText.styles'

const textContent = (
  elem: React.ReactElement | string
): string | React.ReactElement[] => {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }

  const children = elem.props && elem.props.children
  if (children instanceof Array) {
    return children.map((textContent, idx) => {
      return <React.Fragment key={idx}>{textContent}</React.Fragment>
    })
  }

  return textContent(children)
}

interface RenderRichTextOptions {
  defaultColor?: string
}

const richTextRenderOptions = (options?: RenderRichTextOptions): any => {
  return {
    nodeResolvers: {
      [NODE_QUOTE]: (children: any) => {
        return <Blockqoute>{children}</Blockqoute>
      },
      [NODE_IMAGE]: (children: any, attrs: any) => {
        return (
          <Image
            width="0"
            height="0"
            sizes="100vw"
            style={{
              width: '500px',
              height: 'auto',
              position: 'relative',
              zIndex: '5',
              maxWidth: '80%',
            }}
            src={attrs?.src ? attrs?.src : 'Missing src'}
            alt={attrs?.alt ? attrs?.alt : 'Missing alt'}
          />
        )
      },
      [NODE_CODEBLOCK]: (children: any, attrs: any) => {
        return (
          <pre>
            <code>{children}</code>
          </pre>
        )
      },
      [NODE_HEADING]: (children: any, { level }: any) => {
        switch (level) {
          case 1:
            return (
              <Headline importance={HeadlineImportances.h1}>
                {children}
              </Headline>
            )
          case 2:
            return (
              <Headline importance={HeadlineImportances.h2}>
                {children}
              </Headline>
            )
          case 3:
            return (
              <Headline importance={HeadlineImportances.h3}>
                {children}
              </Headline>
            )
          case 4:
            return (
              <Headline importance={HeadlineImportances.h4}>
                {children}
              </Headline>
            )
          case 5:
            return (
              <Headline importance={HeadlineImportances.h5}>
                {children}
              </Headline>
            )
          default:
            return (
              <Headline importance={HeadlineImportances.h3}>
                {children}
              </Headline>
            )
        }
      },
      [NODE_PARAGRAPH]: (children: any) => {
        return <Text variant={TextVariants.paragraph}>{children}</Text>
      },
      [NODE_UL]: (children: any) => {
        const lines: string[] = children.map((child: any) => textContent(child))
        return (
          <UnorderedList>
            {lines.map((line, index) => (
              <ListItem key={index}>{line}</ListItem>
            ))}
          </UnorderedList>
        )
      },
      [NODE_OL]: (children: any) => {
        const lines: string[] = children.map((child: any) => textContent(child))
        return (
          <OrderedList>
            {lines.map((line, index) => (
              <ListItem key={index}>{line}</ListItem>
            ))}
          </OrderedList>
        )
      },
      [NODE_LI]: (children: any) => (
        <StoryblokBulletListItem index={1}>{children}</StoryblokBulletListItem>
      ),
      [NODE_BR]: () => <br />,
      [NODE_HR]: () => <HorizontalRule />,
    }, // block elements
    //  blokResolvers: { ... }, // embedded components
    defaultBlokResolver: (name: string, props: any) => (
      <div>
        <code>Missing blok resolver for blok type &quot;{name}&ldquo;.</code>
        <pre>
          <code>{JSON.stringify(props, undefined, 2)}</code>
        </pre>
      </div>
    ),
    //  defaultStringResolver: (str) => ( ... ),
    //  textResolver: (text) => ( ... ),
  }
}

interface Props extends PropsWithClassName {
  content: any
  options?: RenderRichTextOptions
}

const StoryblokRichText: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { content, options } = props
  return (
    <Root className={props.className} data-testid={'storyblok-rich-text-root'}>
      {render(content, richTextRenderOptions(options))}
    </Root>
  )
}

export { StoryblokRichText }
