import { Studio } from '@definitions/types/symfonyTypesd'

export function prepareStudios(studios: any): Studio[] {
  if (!studios === undefined) {
    return []
  }
  return studios.edges.map((studio: { node: Studio }) => {
    return {
      ...studio.node,
    }
  })
}
