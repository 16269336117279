import styled from 'styled-components'

export const Root = styled.div``

export const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 1rem;
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
