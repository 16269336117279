import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { BoxBackgrounds } from '@components/atoms/Box/Box'
import { ButtonModifier } from '@components/atoms/Button/Button'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'
import {
  Content,
  InnerContainerBottom,
  InnerContainerTop,
  Root,
  StyledHeadline,
  StyledImage,
  StyledLinkButton,
} from '@components/storyblok/molecules/StoryblokTextOnImageCol/StoryblokTextOnImageCol.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { TextOnImageColStoryblok } from '@definitions/types/storyblokTypes'

import { StyledBox } from '../StoryblokBanner/StoryblokBanner.styles'

interface Props extends PropsWithClassName {
  blok: TextOnImageColStoryblok
}

const StoryblokTextOnImageCol: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { logoImage, headline, content, link, linkName, image, rightAlign } =
    blok
  const theme: any = useTheme()
  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-text-on-image-col-root'}
    >
      <StyledBox backgroundImage={image?.filename ?? ''}>
        <InnerContainerTop style={{ textAlign: rightAlign ? 'right' : 'left' }}>
          {logoImage && logoImage.filename !== '' && (
            <StyledImage
              src={logoImage?.filename ?? ''}
              alt={logoImage?.alt ?? ''}
            />
          )}

          {headline && (
            <StyledHeadline importance={HeadlineImportances.h5}>
              {blok.headline
                ?.toString()
                .split('\n')
                .map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {item}
                      <br />
                    </React.Fragment>
                  )
                })}
            </StyledHeadline>
          )}

          {!rightAlign && (
            <Content>
              <StoryblokRichText content={content} />
            </Content>
          )}
        </InnerContainerTop>

        <InnerContainerBottom
          style={{ justifyContent: rightAlign ? 'end' : 'start' }}
        >
          {link && (
            <StyledLinkButton
              modifier={ButtonModifier.MINIMAL}
              href={getUrlFromLink(link, theme) ?? ''}
              disabled={false}
              loading={false}
              background={BoxBackgrounds.WHITE}
              label={linkName ?? 'Mehr erfahren'}
            >
              {linkName ?? 'Mehr erfahren'}
            </StyledLinkButton>
          )}
        </InnerContainerBottom>
      </StyledBox>
    </Root>
  )
}

export { StoryblokTextOnImageCol }
