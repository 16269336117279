import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'

import { BlockPreContent } from '@components/atoms/BlockPreContent/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageWithOptionalTextStoryblok } from '@definitions/types/storyblokTypes'

import {
  Container,
  Root,
  StyledImage,
} from './StoryblokImageWithOptionalText.styles'

interface StoryblokImageWithOptionalTextStoryblokProps
  extends ImageWithOptionalTextStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokImageWithOptionalTextStoryblokProps
}

const StoryblokImageWithOptionalText: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { image, headline, subline } = blok

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-image-with-optional-text-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Container>
            <BlockPreContent headline={headline} subline={subline} />
            <StyledImage src={image?.filename ?? ''} alt={image?.alt ?? ''} />
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokImageWithOptionalText }
