import styled from 'styled-components'

import Headline from '@components/atoms/Headline'
import IconAccordionArrow from '@components/atoms/Icons/IconAccordionArrow'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  text-align: center;
  user-select: none;
  width: 100%;

  margin-bottom: ${(props) => props.theme.margin.big};
`
export const AccordionElement = styled.div`
  background: #f8f8f8;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
`

export const Trigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.palette.default.black};
  padding: 1.5rem 1.375rem 1.5rem 1.375rem;
`
export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
  text-align: left;
  user-select: none;
`

export const StyledIconAccordionArrow = styled(
  IconAccordionArrow
)<ContentProps>`
  transform: rotate(${(props) => (props.visible ? '180deg' : '0deg')});
`

interface ContentProps {
  visible: boolean
}
export const Content = styled.div<ContentProps>`
  padding: 0 1.375rem 1.5rem 1.375rem;
  color: ${(props) => props.theme.palette.default.black};
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  text-align: left;
`

export const StyledText = styled(Text)`
  margin-bottom: 0;
  text-align: left;
  user-select: none;
`
