import { TextColStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextCol } from './StoryblokTextCol'

export default StoryblokTextCol

export const dummyBlokTextColStoryblok: TextColStoryblok = {
  _uid: '3caa5dca-2448-4917-ba2f-60164a72543b',
  type: '123',
  content: {
    _uid: '3caa5dca-2448-4917-ba2f-60164a72543b123',
    type: '123',
    headline: 'Lorem ipsum',
    component: 'text-col',
  },
  headline: 'Lorem ipsum',
  component: 'text-col',
  _editable:
    '<!--#storyblok#{"name": "text-col", "space": "235453", "uid": "3caa5dca-2448-4917-ba2f-60164a72543b", "id": "322787899"}-->',
}
