import Link from 'next/link'
import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  gap: 0.375rem;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: 0.875rem;
  line-height: ${(props) => props.theme.font.text.lead.mobile.lineHeight};
  font-weight: 300;
  align-items: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.default.tertiary};
`
