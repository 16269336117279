import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './Pill.styles'

interface Props extends PropsWithClassName {
  onClick: (pill: string) => void
  children: string
  active: boolean
}

const Pill: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { onClick, children, active } = props
  return (
    <Root
      onClick={() => onClick(children)}
      className={props.className}
      data-testid={'pill-root'}
      active={active}
    >
      {children}
    </Root>
  )
}

export { Pill }
