import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Headline from '@components/atoms/Headline'
import UnorderedList from '@components/atoms/UnorderedList'

export const Root = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.margin.half};
  padding: 0.9375rem 1.0625rem;
  border-radius: 0.3125rem;
`
export const Icon = styled.div`
  margin-right: 0.625rem;
  display: flex;
  align-items: flex-start;
`
export const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.0625rem;
  text-align: left;
  line-height: 1.5;
`
export const StyledHeadline = styled(Headline)`
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  text-align: left;
`
export const StyledUnorderedList = styled(UnorderedList)`
  margin-top: 0.625rem;
  margin-bottom: 0;
`
