import styled from 'styled-components'

import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  position: relative;
  z-index: 20;
`

export const ButtonCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  margin: 0;
`

export const Feedback = styled.div`
  p {
    margin: 0;
  }
`
