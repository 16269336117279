import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useMemo } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { ImageTextColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { Root, StyledCol } from './StoryblokImageTextColContainer.styles'

interface StoryblokButtonProps extends ImageTextColContainerStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokButtonProps
}

const StoryblokImageTextColContainer: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { imageTextCols, headline, subline } = blok

  const colWidth = useMemo(
    () => 12 / ((imageTextCols ? imageTextCols?.length : null) ?? 1),
    [imageTextCols]
  )

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-image-text-col-container-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <StyledCol xs={12} sm={12} md={12} lg={12}>
          <BlockPreContent headline={headline} subline={subline} />
        </StyledCol>
      </Row>
      <Row>
        {imageTextCols?.map((imageTextCol, index) => (
          <Col xs={12} sm={12} md={colWidth} lg={colWidth} key={index}>
            <StoryblokComponent blok={imageTextCol} />
          </Col>
        ))}
      </Row>
    </Root>
  )
}

export { StoryblokImageTextColContainer }
