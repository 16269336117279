import React, { type PropsWithChildren, useState } from 'react'

import IconAccordionArrow from '@components/atoms/Icons/IconAccordionArrow'

import { type PropsWithClassName } from '@helper/PropsWithClassName'

import { Content, Root, Trigger } from './Collapsible.styles'

interface Props extends PropsWithClassName {
  title: string
  titleAlign: 'left' | 'center' | 'right'
}

const Collapsible: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, title, titleAlign } = props
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen((state) => !state)

  return (
    <Root className={props.className} data-testid={'collapsible-root'}>
      <Trigger onClick={toggleOpen} titleAlign={titleAlign}>
        {title}
        <IconAccordionArrow
          size="16"
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
          }}
        />
      </Trigger>
      <Content isOpen={isOpen}>{children}</Content>
    </Root>
  )
}

export { Collapsible }
