import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'
import { StyledA } from '@components/atoms/LinkButton/LinkButton.styles'

export const Root = styled.div`
  min-height: 100%;
  min-width: 100%;
  display: flex;
`

export const StyledBox = styled(Box)`
  justify-content: space-between;
`

export const StyledHeadline = styled(Headline)`
  width: auto;
  margin-bottom: ${(props) => props.theme.margin.extrasmall};
`

export const StyledSubline = styled(Headline)`
  width: auto;
  margin-bottom: ${(props) => props.theme.margin.small};
`

export const StyledLinkButton = styled(LinkButton)`
  ${StyledA} {
    margin: 0;
  }
  margin: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
