import styled from 'styled-components'

import Headline from '@components/atoms/Headline'
import StudioFinderSearchfield from '@components/molecules/StudioFinder/StudioFinderSearchfield'

import { userSelectNone } from '@helper/mixins'

export const Root = styled.div``

export const SearchBox = styled.div`
  height: auto;
  width: 100%;
  display: flex;
`
export const Search = styled.div`
  position: relative;
  padding: 1.5rem 3rem 1.5rem 3rem;
  background: ${(props) => props.theme.palette.default.greyLight};
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    flex-direction: row;
    text-align: left;
    gap: ${(props) => props.theme.margin.default};
  }
`

export const StyledHeadline = styled(Headline)`
  width: 100%;
  margin: 0 0 ${(props) => props.theme.margin.small} 0;
  ${userSelectNone()}
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    width: auto;
    margin: 0;
  }
`
export const StyledStudioFinderSearchfield = styled(StudioFinderSearchfield)`
  margin: 0;
`

export const ThemeKey = styled.span`
  white-space: nowrap;
  br {
    display: flex;
  }
  @media screen and (min-width: 400px) {
    br {
      display: none;
    }
  }
`
