import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

import { isValidHexColor, shouldUseWhiteText } from '@helper/color'
import { Farben } from '@helper/enums/storyblok/Farben'

import { BoxBackgrounds } from './Box'

interface BoxStyleProps {
  background: string | BoxBackgrounds | Farben
  backgroundIcon: string | null | undefined
}

export const BoxPrimaryStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.primary};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.primary)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxSecondaryStyles = (
  backgroundIcon: string | null | undefined
) => {
  return css`
    background: ${(props) => props.theme.palette.default.secondary};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.secondary};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.secondary)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.secondary};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.secondary)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxTertiaryStyles = (
  backgroundIcon: string | null | undefined
) => {
  return css`
    background: ${(props) => props.theme.palette.default.tertiary};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.tertiary};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.tertiary)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.tertiary};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.tertiary)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxWhiteStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.white};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.white};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.white)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.white};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.white)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxBlackStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.black};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.black};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.black)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.black};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.black)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxGreyStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.grey};
    border: 0.0625rem solid
      ${(props) => backgroundIcon ?? props.theme.palette.default.grey};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.grey) ? 'white' : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.grey};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.grey)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

export const BoxGreyLightStyles = (
  backgroundIcon: string | null | undefined
) => {
  return css`
    background: ${(props) => props.theme.palette.default.greyLight};
    border: 0.0625rem solid
      ${(props) => backgroundIcon ?? props.theme.palette.default.greyLight};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.greyLight)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.greyLight};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.greyLight)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}
export const BoxGreyDarkStyles = (
  backgroundIcon: string | null | undefined
) => {
  return css`
    background: ${(props) => props.theme.palette.default.greyDark};
    border: 0.0625rem solid
      ${(props) => backgroundIcon ?? props.theme.palette.default.greyDark};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.white)
        ? 'white'
        : 'black'};
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.greyDark};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.greyDark)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

export const BoxErrorStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.error};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.error};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.error)
        ? 'white'
        : 'black'};
    box-shadow: none;
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.error};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.error)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

export const BoxWarningStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.warning};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.warning};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.warning)
        ? 'white'
        : 'black'};
    box-shadow: none;
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.warning};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.warning)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

export const BoxSuccessStyles = (backgroundIcon: string | null | undefined) => {
  return css`
    background: ${(props) => props.theme.palette.default.success};
    border: 0.0625rem solid ${(props) => props.theme.palette.default.success};
    color: ${(props) =>
      shouldUseWhiteText(props.theme.palette.default.success)
        ? 'white'
        : 'black'};
    box-shadow: none;
    ${Icon} {
      background: ${(props) =>
        backgroundIcon ?? props.theme.palette.default.success};
      border: 0.4375rem solid
        ${(props) => backgroundIcon ?? props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.success)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

export const BoxTransparentStyles = (
  backgroundIcon: string | null | undefined
) => {
  return css`
    background: transparent;
    border: none;
    color: ${(props) => props.theme.palette.default.black};
    box-shadow: none;
    ${Icon} {
      background: ${(props) => props.theme.palette.default.primary};
      border: 0.4375rem solid ${(props) => props.theme.palette.default.white};
      svg {
        path,
        circle,
        rect {
          fill: ${(props) =>
            shouldUseWhiteText(props.theme.palette.default.primary)
              ? 'white'
              : 'black'};
        }
      }
    }
  `
}

const getBoxStyles = (
  props: BoxStyleProps
): FlattenInterpolation<ThemeProps<any>> => {
  const { background, backgroundIcon } = props
  if (isValidHexColor(background)) {
    return css`
      background: ${background};
      border: 0.0625rem solid ${background};
      color: ${shouldUseWhiteText(background) ? 'white' : 'black'};
      a {
        color: ${shouldUseWhiteText(background) ? 'white' : 'black'};
      }
      ${Icon} {
        background: ${background};
        border: 0.4375rem solid white;
        svg {
          path,
          circle,
          rect {
            fill: ${shouldUseWhiteText(background) ? 'white' : 'black'};
          }
        }
      }
    `
  }
  switch (background) {
    case BoxBackgrounds.BLACK:
      return BoxBlackStyles(backgroundIcon)
    case BoxBackgrounds.WHITE:
      return BoxWhiteStyles(backgroundIcon)
    case BoxBackgrounds.GREYLIGHT:
      return BoxGreyLightStyles(backgroundIcon)
    case BoxBackgrounds.GREY:
      return BoxGreyStyles(backgroundIcon)
    case BoxBackgrounds.GREYDARK:
      return BoxGreyDarkStyles(backgroundIcon)
    case BoxBackgrounds.PRIMARY:
      return BoxPrimaryStyles(backgroundIcon)
    case BoxBackgrounds.SECONDARY:
      return BoxSecondaryStyles(backgroundIcon)
    case BoxBackgrounds.TERTIARY:
      return BoxTertiaryStyles(backgroundIcon)
    case BoxBackgrounds.ERROR:
      return BoxErrorStyles(backgroundIcon)
    case BoxBackgrounds.WARNING:
      return BoxWarningStyles(backgroundIcon)
    case BoxBackgrounds.SUCCESS:
      return BoxSuccessStyles(backgroundIcon)
    case BoxBackgrounds.TRANSPARENT:
      return BoxTransparentStyles(backgroundIcon)
    default:
      return BoxWhiteStyles(backgroundIcon)
  }
}

interface RootProps {
  background: BoxBackgrounds | Farben | string
  backgroundIcon?: string
  icon?: React.ReactNode
  backgroundImage?: string
  backgroundImageOffset?: string
  backgroundTransparency?: boolean
}

export const Root = styled.div<RootProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 2rem;
  position: relative;
  max-width: 100%;
  margin: ${(props) => (props.icon ? props.theme.margin.large : 0)} 0
    ${(props) => props.theme.margin.big} 0;
  border-radius: 1rem;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(151, 151, 151, 0.15);
  z-index: 10;
  ${(props) =>
    getBoxStyles({
      background: props.background,
      backgroundIcon: props.backgroundIcon,
    })}

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    background: ${(props) => props.background};
    opacity: 0.85;
    display: ${(props) =>
      props.backgroundImage && props.backgroundTransparency ? 'block' : 'none'};
    z-index: 10;
  }
`

export const Icon = styled.div<RootProps>`
  position: absolute;
  top: -2rem;
  left: 1.5rem;
  z-index: 30;
  ${(props) =>
    getBoxStyles({
      background: props.background,
      backgroundIcon: props.backgroundIcon,
    })}
  background: none;
  border-radius: 1.6875rem;
  padding: 0.3825rem 1.125rem;
  border: 0.1875rem solid white;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Children = styled.div`
  position: relative;
  z-index: 20;
  height: 100%;
  display: flex;
  flex-direction: column;
`
