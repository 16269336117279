import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { useState } from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TabbedContentSectionStoryblok } from '@definitions/types/storyblokTypes'

import {
  Root,
  TabSelector,
  TabSelectors,
} from './StoryblokTabbedContentSection.styles'

interface Props extends PropsWithClassName {
  blok: TabbedContentSectionStoryblok
}

const StoryblokTabbedContentSection = (props: Props) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const { blok } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-testid="storyblok-tabbed-content-section-root"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Headline importance={HeadlineImportances.h2}>
            {blok.headline?.toString()}
          </Headline>
          <Headline importance={HeadlineImportances.h3}>
            {blok.lead?.toString()}
          </Headline>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <TabSelectors>
            {blok.entries &&
              blok.entries.map((entry: any, index: number) => {
                return (
                  <TabSelector
                    active={index === activeTab}
                    onClick={() => setActiveTab(index)}
                    key={entry._uid}
                  >
                    {entry.headline}
                  </TabSelector>
                )
              })}
          </TabSelectors>
          {blok.entries &&
            blok.entries.map((entry: any, index: number) => {
              if (index === activeTab) {
                return <StoryblokComponent key={entry._uid} blok={entry} />
              }
            })}
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokTabbedContentSection }
