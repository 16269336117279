import { FormSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokFormSection } from './StoryblokFormSection'

export const dummyFormSectionBlok: FormSectionStoryblok = {
  _uid: '96662925-6118-4892-aca5-e7cb9aa25060',
  form: 'contact',
  lead: 'Willkommen auf unserer Webseite! Wir freuen uns sehr über Ihr Interesse an unseren Produkten und Dienstleistungen. Um Ihnen bestmöglich helfen zu können, steht Ihnen unser Kontaktformular zur Verfügung. Bitte füllen Sie das Formular aus und teilen Sie uns mit, wie wir Ihnen weiterhelfen können. Wir werden uns so schnell wie möglich bei Ihnen melden, um Ihre Fragen zu beantworten oder Ihnen bei Problemen zu helfen. Vielen Dank für Ihr Vertrauen!',
  headline: 'Form Section Kontaktieren Sie uns',
  component: 'form-section',
  _editable:
    '<!--#storyblok#{"name": "form-section", "space": "228071", "uid": "96662925-6118-4892-aca5-e7cb9aa25060", "id": "300939517"}-->',
}

export default StoryblokFormSection
