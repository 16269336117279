import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import StoryblokTextSection from '@components/storyblok/atoms/StoryblokTextSection'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { GridCardStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokGridCard.styles'

interface Props extends PropsWithClassName {
  blok: GridCardStoryblok
}

const StoryblokGridCard = (props: Props) => {
  const { blok } = props

  return (
    <Root
      {...storyblokEditable(blok)}
      className="grid-card"
      dataTestId="storyblok-grid-card-root"
      background={blok.background_color?.toString()}
    >
      {blok.label && (
        <Headline importance={HeadlineImportances.h2}>
          {blok.label.toString()}
        </Headline>
      )}
      {blok.text && (
        <StoryblokTextSection
          key={'StoryblokGridCard' + blok.text}
          blok={blok.text}
        />
      )}
      {blok.button &&
        blok.button.map((button: any, index: number) => {
          return <StoryblokComponent key={button.text + index} blok={button} />
        })}
    </Root>
  )
}

export { StoryblokGridCard }
