import React from 'react'
import { FieldError } from 'react-hook-form'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { InfoText } from './InfoText'
import { Root } from './InputLabel.styles'

interface Props extends PropsWithClassName {
  children: string | React.ReactNode
  infoTextTitle?: string
  infoTextContent?: string
  forId?: string
  error?: boolean | FieldError
  required?: boolean
  disabled?: boolean
}

const InputLabel = (props: Props): React.ReactElement => {
  const {
    children,
    infoTextContent,
    infoTextTitle,
    forId,
    required,
    error,
    disabled,
    dataTestId,
  } = props
  const showInfoText = infoTextContent && infoTextTitle

  return (
    <Root
      disabled={disabled ?? false}
      htmlFor={forId}
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'InputLabel-root'}
      error={error ?? false}
    >
      {children}
      {required && <sup>*</sup>}
      {showInfoText ? (
        <InfoText title={infoTextTitle} content={infoTextContent} />
      ) : null}
    </Root>
  )
}

export { InputLabel }
