import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import Link from 'next/link'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import { ButtonCol, Root, StyledButton } from './FormContact.styles'

export interface FormContactType {
  name: string
  email: string
  message: string
  privacy: boolean
}

interface Props extends PropsWithClassName {
  userData?: FormContactType
  handleSubmitForm: (data: FormContactType) => void
  formId: string
}

const FormContact: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSend, setFormSend] = useState<boolean>(false)
  const { handleSubmitForm, userData, formId, dataTestId } = props

  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { register, handleSubmit, formState } = useForm<FormContactType>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Dies ist ein Pflichtfeld.'),
        email: yup
          .string()
          .trim()
          .email('Dies ist keine valide E-Mail-Adresse')
          .required('Dies ist ein Pflichtfeld.')
          .test(
            'is-valid-email',
            'Dies ist keine valide E-Mail-Adresse',
            (value) => {
              if (!value) return false
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
            }
          ),
        message: yup.string().required('Dies ist ein Pflichtfeld.'),
        privacy: yup
          .boolean()
          .oneOf(
            [true],
            'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
          ),
      })
    ) as Resolver<FormContactType>,
    defaultValues: userData,
  })

  const onSubmit = async (data: FormContactType) => {
    setLoading(true)
    await sendFormDataMail('Kontakt Anfrage', data, 'contact', [])
    setLoading(false)
    setFormSend(true)
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'form-contact-root'}
    >
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('name')}
                forId={`${formId}-name`}
                label="Name"
                error={formState.errors.name ?? false}
                errorText={formState.errors?.name?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="E-Mail-Adresse"
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                register={register('message')}
                forId={`${formId}-message`}
                label="Nachricht"
                error={formState.errors.message ?? false}
                errorText={formState.errors?.message?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required
                label={
                  <>
                    Ja, ich habe die&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />
                    &nbsp;gelesen
                  </>
                }
              />
            </Col>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Absenden
              </StyledButton>
            </ButtonCol>
          </Row>
        </form>
      )}
      {formSend && (
        <>
          <Text variant={TextVariants.lead}>
            <b>
              {resultSendMail === true && (
                <>
                  Vielen Dank für Deine Kontaktaufnahme.
                  <br />
                  Wir werden uns in Kürze bei Dir melden.
                </>
              )}
              {resultSendMail === false && 'Es ist ein Fehler aufgetreten'}
            </b>
          </Text>
        </>
      )}
    </Root>
  )
}

export { FormContact }
