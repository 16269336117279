import styled from 'styled-components'

import Headline from '@components/atoms/Headline'

export const Root = styled.div``

export const StyledImage = styled.img`
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
`

export const StyledHeadline = styled(Headline)`
  margin-bottom: 1rem;
  width: auto;
  font-weight: 700;
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  a {
    color: ${(props) => props.theme.palette.default.black};
    &:hover,
    &:visited {
      color: ${(props) => props.theme.palette.default.black};
    }
  }
`

export const Content = styled.div`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.mobile.lineHeight};
  margin-bottom: 1.5rem;
`
