import YouTube from 'react-youtube'
import styled from 'styled-components'

import Box from '@components/atoms/Box'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const StyledVideo = styled.video`
  width: 100%;
  border-radius: 1rem;
  position: relative;
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const VideoButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

export const VideoPlayIconContainer = styled.div`
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
  position: absolute;
`

export const StyledYoutube = styled(YouTube)`
  flex-grow: 1;
  .youtubeIframeElement {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    display: block;
  }
`
