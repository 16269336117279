import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconSun.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconSun: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  const currentSize = props.size ?? '32'
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={currentSize}
      height={currentSize}
      viewBox="0 0 32 32"
      fill="none"
      className={props.className}
      data-testid={'icon-sun-root'}
    >
      <circle cx="16" cy="16" r="5.333" fill="#292929" />
      <rect width="2" height="5.333" x="15" y="1.333" fill="#292929" rx="1" />
      <rect width="2" height="5.333" x="15" y="25.333" fill="#292929" rx="1" />
      <rect
        width="2"
        height="5.333"
        x="30.666"
        y="15"
        fill={currentColor}
        rx="1"
        transform="rotate(90 30.666 15)"
      />
      <rect
        width="2"
        height="5.333"
        x="6.667"
        y="15"
        fill={currentColor}
        rx="1"
        transform="rotate(90 6.667 15)"
      />
      <rect
        width="2"
        height="5.333"
        x="25.664"
        y="4.922"
        fill={currentColor}
        rx="1"
        transform="rotate(45 25.664 4.922)"
      />
      <rect
        width="2"
        height="5.333"
        x="8.693"
        y="21.893"
        fill={currentColor}
        rx="1"
        transform="rotate(45 8.693 21.893)"
      />
      <rect
        width="2"
        height="5.333"
        x="27.078"
        y="25.664"
        fill={currentColor}
        rx="1"
        transform="rotate(135 27.078 25.664)"
      />
      <rect
        width="2"
        height="5.333"
        x="10.108"
        y="8.693"
        fill={currentColor}
        rx="1"
        transform="rotate(135 10.108 8.693)"
      />
    </Root>
  )
}

export { IconSun }
