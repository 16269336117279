import { ApolloProvider } from '@apollo/client'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import { UserDataProvider } from '@helper/context/UserDataContext'
import initStoryblok from '@helper/initStoryblok'
import { themeSunpoint, themeWellmaxx } from '@helper/theme'

import client from '../../apollo-client.js'
import '../scss/styles.scss'

export const SunpointGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Interstate';
    src: url('/fonts/-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Interstate';
    src: url('/fonts/Interstate-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Interstate';
    src: url('/fonts/Interstate-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  body {
    font-family: 'Interstate', sans-serif;
  }
`

initStoryblok()

function MyApp({ Component, pageProps, router }: AppProps) {
  if (pageProps.story?.id === 0) {
    return <meta httpEquiv="refresh" content="0; URL=/404" />
  }
  let theme
  if (router.pathname.startsWith('/sunpoint')) {
    theme = themeSunpoint
  } else if (router.pathname.startsWith('/wellmaxx')) {
    theme = themeWellmaxx
  }
  if (theme === undefined) {
    theme = themeSunpoint
  }
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SunpointGlobalStyle />
        <UserDataProvider>
          <Component {...pageProps} />
        </UserDataProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default appWithTranslation(MyApp)
