import Head from 'next/head'
import React, { PropsWithChildren, useState } from 'react'

import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  AccordionElement,
  Content,
  Root,
  StyledHeadline,
  StyledIconAccordionArrow,
  StyledText,
  Trigger,
} from './Accordion.styles'

export interface AccordionElement {
  trigger: string
  content: string | any
}
interface Props extends PropsWithClassName {
  headline: string
  elements: AccordionElement[]
  contentRenderer?: (content: any) => React.ReactElement
}

const Accordion: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [activeElement, setActiveElement] = useState<number | null>(null)

  const { elements, headline, contentRenderer } = props

  const handleTriggerToggle = (index: number): void => {
    setActiveElement(index !== activeElement ? index : null)
  }

  return (
    <Root className={props.className} data-testid={'accordion-root'}>
      <Headline importance={HeadlineImportances.h2}>{headline}</Headline>
      {elements.map((element, index) => {
        return (
          <AccordionElement key={element.trigger + index}>
            <Trigger onClick={() => handleTriggerToggle(index)}>
              <StyledHeadline importance={HeadlineImportances.h5}>
                {element.trigger}
              </StyledHeadline>
              <StyledIconAccordionArrow visible={activeElement === index} />
            </Trigger>
            <Content visible={activeElement === index}>
              {contentRenderer ? (
                contentRenderer(element.content)
              ) : (
                <StyledText variant={TextVariants.paragraph}>
                  {element.content}
                </StyledText>
              )}
            </Content>
          </AccordionElement>
        )
      })}
    </Root>
  )
}

export { Accordion }
