import { AppTheme } from 'styled-components'

import { MultilinkStoryblok } from '@definitions/types/storyblokTypes'

const deslugify = (slug: string): string => {
  slug = slug.replace(/ae/g, 'ä').replace(/ue/g, 'ü').replace(/oe/g, 'ö')
  return slug.charAt(0).toUpperCase() + slug.slice(1)
}

const urlToBreadcrumbs = (url: string): { text: string; link: string }[] => {
  const breadcrumbs: { text: string; link: string }[] = []
  const urlParts = url.split('/').filter((p) => p !== '')
  const currentUrlParts: string[] = []

  for (let [index, urlPart] of urlParts.entries()) {
    currentUrlParts.push(urlPart)

    breadcrumbs.push({
      text: index === 0 ? 'Home' : deslugify(urlPart),
      link: `/${currentUrlParts.join('/')}`,
    })
  }

  return breadcrumbs
}

const getUrlFromLink = (
  link:
    | Exclude<
        MultilinkStoryblok,
        { linktype?: 'email' } | { linktype?: 'asset' }
      >
    | undefined,
  theme: AppTheme
): string | undefined => {
  if (link === undefined) {
    return undefined
  }

  const urlSunpointMain: string = 'https://www.sunpoint.de/'
  const urlWellmaxxMain: string = 'https://www.wellmaxx-bodyforming.com/'

  if (link.linktype === 'url' && link.url !== undefined) {
    return link.url
  }
  if (link.linktype === 'story') {
    // SUN -> WM
    if (link.cached_url?.includes('wellmaxx/')) {
      if (theme?.key === 'sunpoint') {
        return urlWellmaxxMain + link?.cached_url
      }
      if (theme?.key === 'wellmaxx') {
        return '/' + link.cached_url
      }
    }
    // WM -> SUN
    if (link.cached_url?.includes('sunpoint/')) {
      if (theme?.key === 'sunpoint') {
        return '/' + link.cached_url
      }
      if (theme?.key === 'wellmaxx') {
        return urlSunpointMain + link.cached_url
      }
    }
  }
  if (link.linktype === 'story') {
  }

  // Fallback
  return undefined
}

export { urlToBreadcrumbs, deslugify, getUrlFromLink }
