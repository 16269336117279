import useUserCentrics from '@hooks/useUserCentrics'
import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'

import { BlockPreContent } from '@components/atoms/BlockPreContent/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import UsercentricsConsent from '@components/molecules/UsercentricsConsent'

import { UsercentricServices } from '@helper/enums/UsercentricServices'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { StudioTourStoryblok } from '@definitions/types/storyblokTypes'

import { Container, Root, StyledIframe } from './StoryblokStudioTour.styles'

interface Props extends PropsWithClassName {
  blok: StudioTourStoryblok
}

const StoryblokStudioTour: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props

  const { consentMatterport } = useUserCentrics()

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-studio-tour-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Container>
            <BlockPreContent headline={blok.headline} subline={blok.subline} />
            {consentMatterport && (
              <StyledIframe
                allow="fullscreen"
                src="https://my.matterport.com/show/?m=M555yzEsZ4t"
              ></StyledIframe>
            )}
            {!consentMatterport && (
              <UsercentricsConsent
                placeholderImage={blok.placeholderImage}
                service={UsercentricServices.Matterport}
                headline={
                  'Wir benötigen Ihre Zustimmung, um den virtuellen Studiorundgang zu laden!'
                }
                text={`Wir verwenden einen Service eines Drittanbieters, um diesen
                      Inhalt einzubetten. Dieser Service kann Daten zu Ihren
                      Aktivitäten sammeln. Bitte lesen Sie die Details durch und
                      stimmen Sie der Nutzung des Service zu, um diesen Inhalt
                      anzusehen.`}
              />
            )}
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokStudioTour }
