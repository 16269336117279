import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { ButtonModifier } from '@components/atoms/Button/Button'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import LinkButton from '@components/atoms/LinkButton'
import { Text, TextVariants } from '@components/atoms/Text/Text'
import {
  Container,
  Root,
  StyledCol,
  StyledHeadline,
} from '@components/storyblok/molecules/StoryblokCtaCenterAlign/StoryblokCtaCenterAlign.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { CtaCenterAlignStoryblok } from '@definitions/types/storyblokTypes'

interface Props extends PropsWithClassName {
  blok: CtaCenterAlignStoryblok
}

const StoryblokCtaCenterAlign: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { image, headline, calltoaction, content } = blok

  const theme: any = useTheme()
  const url = getUrlFromLink(blok.link, theme)

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-cta-center-align-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <StyledCol xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Container image={blok.image.filename}>
            <StyledHeadline importance={HeadlineImportances.h3}>
              {headline}
            </StyledHeadline>
            <Text variant={TextVariants.paragraph}>{content}</Text>
            {url && (
              <LinkButton
                label={calltoaction ?? ''}
                disabled={false}
                loading={false}
                modifier={
                  blok.buttonStyle
                    ? (blok.buttonStyle as ButtonModifier)
                    : ButtonModifier.PRIMARY
                }
                href={url}
              >
                {calltoaction}
              </LinkButton>
            )}
          </Container>
        </StyledCol>
      </Row>
    </Root>
  )
}

export { StoryblokCtaCenterAlign }
