import {
  StoryblokComponent,
  StoryblokComponentType,
  storyblokEditable,
} from '@storyblok/react'
import React from 'react'

import { DefaultPageStoryblok } from '@definitions/types/storyblokTypes'

interface PageProps extends DefaultPageStoryblok {}

const StoryblokPage = (props: PageProps) => {
  const { blok } = props
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="page">
      {blok.body &&
        blok.body.map((item: StoryblokComponentType<any>) => {
          return <StoryblokComponent key={item._uid} blok={item} />
        })}
    </div>
  )
}

export default StoryblokPage
