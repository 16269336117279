import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import useGoogleTracking from '@hooks/useGoogleTracking'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import FileUploadSimple from '@components/atoms/FileUploadSimple'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputDate from '@components/atoms/Form/InputDate'
import { InputSelect } from '@components/atoms/Form/InputSelect/InputSelect'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'
import FormWorkTimes from '@components/molecules/Forms/FormWorkTimes'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'
import { CreateAttachmentInput } from '@definitions/types/symfonyTypesd'

import {
  ButtonCol,
  Root,
  StyledButton,
  StyledRow,
} from './FormApplication.styles'

export interface FormApplicationType {
  bewerbungUmkreis: string
  anstellungsart: string
  workTime: {
    type: 'flexible' | 'individual'
    times?: [boolean, boolean, boolean][]
  }
  gehaltsvorstellung: string
  vorname: string
  nachname: string
  geburtsdatum: string
  strasse: string
  plz: string
  stadt: string
  email: string
  telefon: string
  freitext: string
  aufmerksamGeworden: string
  privacy: boolean
  wat: string
}

interface Props extends PropsWithClassName {
  formId: string
  jobName: string
  studioName: string
  handleSubmitted?: () => void
}

const weekdayLabels = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
]
const timeLabels = ['vormittags', 'nachmittags', 'abends']

const FormApplication: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSend, setFormSend] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<CreateAttachmentInput[]>([])
  const { formId, dataTestId } = props
  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { trackJobApplication } = useGoogleTracking()
  const theme: any = useTheme()

  const { register, setValue, handleSubmit, formState, watch } =
    useForm<FormApplicationType>({
      resolver: yupResolver(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .email('Dies ist keine valide E-Mail-Adresse')
            .required('Dies ist ein Pflichtfeld.')
            .test(
              'is-valid-email',
              'Dies ist keine valide E-Mail-Adresse',
              (value) => {
                if (!value) return false
                return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
              }
            ),
          gehaltsvorstellung: yup
            .string()
            .required('Dies ist ein Pflichtfeld.'),
          bewerbungUmkreis: yup.string().required('Dies ist ein Pflichtfeld.'),
          anstellungsart: yup.string().required('Dies ist ein Pflichtfeld.'),
          aufmerksamGeworden: yup
            .string()
            .required('Dies ist ein Pflichtfeld.'),
          vorname: yup.string().required('Dies ist ein Pflichtfeld.'),
          nachname: yup.string().required('Dies ist ein Pflichtfeld.'),
          plz: yup.string().required('Dies ist ein Pflichtfeld.'),
          stadt: yup.string().required('Dies ist ein Pflichtfeld.'),
          telefon: yup.string().required('Dies ist ein Pflichtfeld.'),
          privacy: yup
            .boolean()
            .oneOf(
              [true],
              'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
            ),
        })
      ) as Resolver<any>,
    })

  const onSubmit = async (data: FormApplicationType) => {
    const extendedData: any = {
      studioName: props.studioName,
      jobName: props.jobName,
      affiliation: theme.key,
    }

    if (data.workTime.type === 'individual') {
      extendedData['arbeitszeit'] = 'Individuell'
      const times = data.workTime.times
      for (let i = 0; i < weekdayLabels.length; i++) {
        const dayLabel = weekdayLabels[i]
        const checkedTimes: string[] = []
        const timeValues = times?.[i]
        if (timeValues?.every((t) => t)) {
          checkedTimes.push('ganztags')
        } else {
          for (let j = 0; j < timeLabels.length; j++) {
            if (timeValues?.[j]) {
              checkedTimes.push(timeLabels[j])
            }
          }
        }
        extendedData[dayLabel] = checkedTimes.join(', ') || '-'
      }
    } else {
      extendedData['arbeitszeit'] = 'Flexibel'
    }

    for (const [key, value] of Object.entries(data)) {
      if (key === 'workTime') {
        continue
      }
      extendedData[key] = value
    }

    setLoading(true)
    await sendFormDataMail(
      `Bewerbung ${props.jobName} im Studio ${props.studioName}`,
      extendedData,
      'application',
      attachments,
      'jobs@sunpoint.de'
    )
    setFormSend(true)
    trackJobApplication(props.studioName, props.jobName)
    if (props.handleSubmitted) {
      props.handleSubmitted()
    }
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'form-application-root'}
    >
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputSelect
                disabled={false}
                required={true}
                register={register('bewerbungUmkreis')}
                error={formState.errors.bewerbungUmkreis ?? false}
                errorText={formState.errors?.bewerbungUmkreis?.message}
                options={[
                  {
                    value: '',
                    label: 'Bitte wählen',
                  },
                  {
                    value: 'Nur das gewählte Studio',
                    label: 'Nur das gewählte Studio',
                  },
                  {
                    value: '5 km',
                    label: '5 km',
                  },
                  {
                    value: '10 km',
                    label: '10 km',
                  },
                  {
                    value: '15 km',
                    label: '15 km',
                  },
                  {
                    value: '20 km',
                    label: '20 km',
                  },
                  {
                    value: '25 km',
                    label: '25 km',
                  },
                ]}
                onChange={(value) => {
                  setValue('bewerbungUmkreis', value.value)
                }}
                label="Bewerbung gilt auch für andere Studios im Umkreis von"
                forId="bewerbungUmkreis"
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputSelect
                disabled={false}
                required={true}
                register={register('anstellungsart')}
                error={formState.errors.anstellungsart ?? false}
                errorText={formState.errors?.anstellungsart?.message}
                placeholder="Bitte wählen"
                options={[
                  {
                    value: '',
                    label: 'Bitte wählen',
                  },
                  {
                    value: 'Vollzeit',
                    label: 'Vollzeit',
                  },
                  {
                    value: 'Teilzeit',
                    label: 'Teilzeit',
                  },
                  {
                    value: 'Gfb/Minijob',
                    label: 'Gfb/Minijob',
                  },
                ]}
                onChange={(value) => {
                  setValue('anstellungsart', value.value)
                }}
                label="Anstellungsart"
                forId="anstellungsart"
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormWorkTimes
                weekdays={weekdayLabels}
                times={timeLabels}
                register={register}
                setValue={setValue}
                watch={watch}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={5}
                label="Gehaltsvorstellung/Stundenlohn"
                forId="InputTextarea"
                register={register('gehaltsvorstellung')}
                required
                error={formState.errors.gehaltsvorstellung ?? false}
                errorText={formState.errors?.gehaltsvorstellung?.message}
              />
            </Col>
          </StyledRow>

          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('vorname')}
                forId={`${formId}-Vorname`}
                label="Vorname"
                error={formState.errors.vorname ?? false}
                errorText={formState.errors?.vorname?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('nachname')}
                forId={`${formId}-nachname`}
                label="Nachname"
                error={formState.errors.nachname ?? false}
                errorText={formState.errors?.nachname?.message}
                required
              />
            </Col>

            <Col xs={12} sm={6} md={6} lg={6}>
              <InputDate
                value=""
                register={register('geburtsdatum')}
                forId={`${formId}-geburtsdatum`}
                label="Geburtsdatum"
                error={formState.errors.geburtsdatum ?? false}
                errorText={formState.errors?.geburtsdatum?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('strasse')}
                forId={`${formId}-strasse`}
                label="Strasse, Hausnummer"
                error={formState.errors.strasse ?? false}
                errorText={formState.errors?.strasse?.message}
                required
              />
            </Col>

            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('plz')}
                forId={`${formId}-plz`}
                label="Plz"
                error={formState.errors.plz ?? false}
                errorText={formState.errors?.plz?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('stadt')}
                forId={`${formId}-stadt`}
                label="Stadt"
                error={formState.errors.stadt ?? false}
                errorText={formState.errors?.stadt?.message}
                required
              />
            </Col>

            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="E-Mail"
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <InputText
                register={register('telefon')}
                forId={`${formId}-telefon`}
                label="Telefon"
                error={formState.errors.telefon ?? false}
                errorText={formState.errors?.telefon?.message}
                required
              />
            </Col>
          </Row>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={5}
                label="Kurzbewerbung/Lebenslauf"
                forId="InputTextareaFreitext"
                register={register('freitext')}
                error={formState.errors.gehaltsvorstellung ?? false}
                errorText={formState.errors?.gehaltsvorstellung?.message}
                required
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FileUploadSimple
                forId="anhang_fileupload"
                label="Hier haben Sie die Möglichkeit, uns Ihre Bewerbungsunterlagen
                als PDF-Dateien und ein Foto als JPG-Datei hochzuladen."
                onChange={(attachments) => {
                  setAttachments(attachments)
                }}
                required
                acceptedFileTypes={[
                  'image/png',
                  'image/jpeg',
                  'application/pdf',
                ]}
                maxFiles={1}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputSelect
                disabled={false}
                error={false}
                register={register('aufmerksamGeworden')}
                placeholder="Bitte wählen"
                options={[
                  {
                    value: '',
                    label: 'Bitte wählen',
                  },
                  {
                    value: 'Sunpoint Website',
                    label: 'Sunpoint Website',
                  },
                  {
                    value: 'Im Sunpoint Studio',
                    label: 'Im Sunpoint Studio',
                  },
                  {
                    value: 'Freunde/Bekannte',
                    label: 'Freunde/Bekannte',
                  },
                  {
                    value: 'Google',
                    label: 'Google',
                  },
                  {
                    value: 'Facebook',
                    label: 'Facebook',
                  },
                  {
                    value: 'Instagram',
                    label: 'Instagram',
                  },
                  {
                    value: 'TikTok',
                    label: 'TikTok',
                  },
                  {
                    value: 'Jobmensa',
                    label: 'Jobmensa',
                  },
                  {
                    value: 'Bundesagentur für Arbeit',
                    label: 'Bundesagentur für Arbeit',
                  },
                  {
                    value: 'Indeed',
                    label: 'Indeed',
                  },
                  {
                    value: 'Monster',
                    label: 'Monster',
                  },
                  {
                    value: 'Ebay Kleinanzeigen',
                    label: 'Ebay Kleinanzeigen',
                  },
                  {
                    value: 'Zeitungsanzeige',
                    label: 'Zeitungsanzeige',
                  },
                  {
                    value: 'Sonstiges',
                    label: 'Sonstiges',
                  },
                ]}
                onChange={(value) => {
                  setValue('aufmerksamGeworden', value.value)
                }}
                label="Wie bist du auf uns aufmerksam geworden?"
                forId="aufmerksamGeworden"
                errorText={formState.errors?.aufmerksamGeworden?.message}
                required
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required
                label={
                  <>
                    Ja, ich habe die&nbsp;
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />
                    &nbsp;gelesen
                  </>
                }
              />
            </Col>

            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Absenden
              </StyledButton>
            </ButtonCol>
          </StyledRow>
        </form>
      )}
      {formSend && (
        <>
          <Text variant={TextVariants.lead}>
            <b>
              {resultSendMail === true && (
                <>
                  Vielen Dank für Deine Bewerbung.
                  <br />
                  Wir werden uns in Kürze bei Dir melden.
                </>
              )}
              {resultSendMail === false && 'Es ist ein Fehler aufgetreten'}
            </b>
          </Text>
        </>
      )}
    </Root>
  )
}

export { FormApplication }
