import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconCosmetics.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconCosmetics: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  const currentSize = props.size ?? '32'
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={currentSize}
      height={currentSize}
      viewBox="0 0 32 32"
      fill="none"
      className={props.className}
      data-testid={'icon-cosmetics-root'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={currentColor}
        d="M26.1929 24.9886C25.3824 25.9713 24.4289 26.8362 23.3582 27.5517C21.199 28.9943 18.6606 29.7644 16.0638 29.7644C13.467 29.7644 10.9286 28.9943 8.76943 27.5517C7.69874 26.8362 6.74516 25.9713 5.93468 24.9886C5.58328 24.5625 5.64382 23.9323 6.0699 23.5809C6.49597 23.2295 7.12623 23.29 7.47763 23.7161C8.16467 24.5491 8.97297 25.2823 9.88057 25.8887C11.7108 27.1116 13.8626 27.7644 16.0638 27.7644C18.265 27.7644 20.4168 27.1116 22.247 25.8887C23.1546 25.2823 23.9629 24.5491 24.65 23.7161C25.0014 23.29 25.6316 23.2295 26.0577 23.5809C26.4838 23.9323 26.5443 24.5625 26.1929 24.9886Z"
      />
      <path
        fill={currentColor}
        d="M16.0064 24.7639C14.971 24.7639 14.0112 24.5583 13.1246 24.1471C12.2381 23.736 11.4627 23.1765 10.7984 22.4665C10.1341 21.7565 9.61638 20.9245 9.24758 19.9731C8.85987 19.0383 8.66602 18.039 8.66602 16.9752C8.66602 15.7799 8.97098 14.6491 9.58091 13.5852C10.1908 12.5214 10.9048 11.448 11.7275 10.3626C12.5502 9.27966 13.3658 8.13932 14.179 6.94399C14.8274 5.99095 15.3451 4.96041 15.7344 3.85357C15.8301 3.58149 16.233 3.56398 16.3485 3.82824C16.8371 4.94567 17.4157 5.98342 18.0844 6.94399C18.9331 8.13932 19.7464 9.27966 20.5218 10.3626C21.2972 11.4456 21.9615 12.519 22.5171 13.5852C23.0726 14.6515 23.3492 15.7799 23.3492 16.9752C23.3492 18.039 23.1554 19.0383 22.7677 19.9731C22.3989 20.9245 21.8811 21.7565 21.2168 22.4665C20.5525 23.1765 19.7771 23.736 18.8906 24.1471C18.0041 24.5583 17.0443 24.7639 16.0088 24.7639H16.0064Z"
      />
    </Root>
  )
}

export { IconCosmetics }
