import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconChevron.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconChevron: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { color } = props

  return (
    <Root
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={props.className}
      data-testid={'icon-chevron-root'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18504 12.6464C4.9904 12.8417 4.9904 13.1583 5.18504 13.3536C5.37968 13.5488 5.69526 13.5488 5.8899 13.3536L10.6396 8.58866C10.9639 8.26335 10.9636 7.73519 10.6386 7.41029L5.8899 2.64645C5.69526 2.45118 5.37968 2.45118 5.18504 2.64645C4.9904 2.84171 4.9904 3.15829 5.18504 3.35355L9.81671 8L5.18504 12.6464Z"
        fill={color || 'black'}
      />
    </Root>
  )
}

export { IconChevron }
