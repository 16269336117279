import styled from 'styled-components'

export const Root = styled.hr`
  display: flex;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.0625rem solid ${(props) => props.theme.palette.default.white};
  height: 0.0625rem;
  opacity: 0.3;
  background: none;
  padding: 0;
  margin: ${(props) => props.theme.margin.large} 0
    ${(props) => props.theme.margin.large} 0;
`
