import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Box from '@components/atoms/Box'
import { BoxBackgrounds } from '@components/atoms/Box/Box'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import FormCancellation from '@components/molecules/Forms/FormCancellation'
import { Root } from '@components/storyblok/molecules/Forms/StoryblokCancellationForm/StoryblokCancellationForm.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { CancellationFormStoryblok } from '@definitions/types/storyblokTypes'

interface Props extends PropsWithClassName {
  blok: CancellationFormStoryblok
}

const StoryblokCancellationForm: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, subline } = blok

  const onSubmit = useCallback(async (data: object) => {
    ;() => console.log('onSubmit', data)
  }, [])

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-contact-form-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <BlockPreContent headline={headline} subline={subline} />
        </Col>
        <Col xs={12} sm={12} md={10} mdOffset={1} lg={10} lgOffset={1}>
          <Box background={BoxBackgrounds.WHITE}>
            <FormCancellation
              formId="cancellation"
              handleSubmitForm={onSubmit}
            />
          </Box>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokCancellationForm }
