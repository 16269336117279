import { storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import BreadCrumb from '@components/atoms/BreadCrumb'
import { ButtonModifier } from '@components/atoms/Button/Button'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import { themeSunpoint } from '@helper/theme'
import { getUrlFromLink, urlToBreadcrumbs } from '@helper/url'

import { ImageTextBlockStoryblok } from '@definitions/types/storyblokTypes'

import {
  ButtonContainer,
  ContentCol,
  ImageCol,
  ImagesContainer,
  PrimaryImage,
  PrimaryImageContainer,
  Root,
  SecondaryImage,
  SecondaryImageContainer,
  StyledHeadline,
  StyledLinkButton,
} from './StoryblokImageTextBlock.styles'

interface StoryblokImageTextBlockProps extends ImageTextBlockStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokImageTextBlockProps
}

const renderImages = (props: PropsWithChildren<Props>): React.ReactElement => {
  const { image, secondaryImage, imagesRight } = props.blok
  return (
    <ImagesContainer>
      <PrimaryImageContainer>
        <PrimaryImage
          width={400}
          height={322}
          alt={image.alt ?? ''}
          src={image.filename ?? ''}
          style={{
            objectFit: 'cover',
          }}
        />
      </PrimaryImageContainer>
      {secondaryImage?.filename && (
        <SecondaryImageContainer imagesRight={imagesRight}>
          <SecondaryImage
            width={256}
            height={192}
            alt={secondaryImage.alt ?? ''}
            src={secondaryImage.filename ?? ''}
            style={{
              objectFit: 'cover',
            }}
          />
        </SecondaryImageContainer>
      )}
    </ImagesContainer>
  )
}

const renderContent = (
  props: PropsWithChildren<Props>,
  theme?: any
): React.ReactElement => {
  const {
    headline,
    content,
    buttonName,
    buttonLink,
    breadcrumbLink,
    blackBackground,
  } = props.blok

  const buttonUrl = getUrlFromLink(buttonLink, theme)
  const breadcrumbUrl = getUrlFromLink(breadcrumbLink, theme)

  return (
    <>
      <StyledHeadline
        importance={HeadlineImportances.h3}
        whiteColor={blackBackground}
      >
        {headline}
      </StyledHeadline>
      <StoryblokRichText content={content} options={{}} />
      <ButtonContainer>
        {buttonName && buttonUrl && (
          <StyledLinkButton
            modifier={ButtonModifier.PRIMARY}
            disabled={false}
            loading={false}
            label={'buttonName'}
            href={buttonUrl ?? '#'}
          >
            {buttonName ?? 'Mehr erfahren'}
          </StyledLinkButton>
        )}
        {breadcrumbUrl && (
          <BreadCrumb
            elements={urlToBreadcrumbs(breadcrumbUrl ?? '')}
            color={themeSunpoint.palette.link.primary}
          />
        )}
      </ButtonContainer>
    </>
  )
}

const StoryblokImageTextBlock: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>,
  theme?: any
): React.ReactElement => {
  const { blok } = props
  const { imagesRight, blackBackground, spaceBelow } = blok
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-image-text-block-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
      blackBackground={blackBackground}
    >
      <Row>
        <>
          {!imagesRight && (
            <ImageCol xs={12} sm={12} md={5} lg={5}>
              {renderImages(props)}
            </ImageCol>
          )}
        </>
        <ContentCol xs={12} sm={12} md={7} lg={7}>
          {renderContent(props, theme)}
        </ContentCol>
        <>
          {imagesRight && (
            <ImageCol xs={12} sm={12} md={5} lg={5}>
              {renderImages(props)}
            </ImageCol>
          )}
        </>
      </Row>
    </Root>
  )
}

export { StoryblokImageTextBlock }
