import { gql } from '@apollo/client'

export const FRAGMENT_STUDIO_BASE = gql`
  fragment StudioBase on Studio {
    id
    title
    name
    slug
    studioType
    info
    additionalInfo
    clientStudioId
    address
    phone
    parkingAvailable
    sisyCashbox
    adyenMerchantAccount
    payPalMerchantId
    latitude
    longitude
    cidNumber
    specialOpeningHour
    specialOpeningHourInfo
    seoTitle
    seoDescription
    seoKeywords
    openingHoursWeekdays
    openingHoursSaturday
    openingHoursSunday
    openingHoursPublicHoliday
    openingHoursLastEntrance
    affiliations {
      edges {
        node {
          id
          name
        }
      }
    }
    operator {
      id
      name
      country
      location
      street
      crossStudio
      studios(first: 20) {
        totalCount
        edges {
          node {
            title
          }
        }
      }
    }
    contracts {
      edges {
        node {
          id
          title
          contractId
          contractValue
          duration
          durationDescription
          weeklyFee
          weeklyFeeDescription
          weeklyInterval
          monthlyFee
          monthlyFeeDescription
          insteadPrice
          promotionText
          infoInLayer
          conditions
          priceCalculationMembership
          standard
          affiliations {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          link
          service
          sort
          affiliation {
            id
            name
          }
          parent {
            id
            name
            sort
          }
        }
      }
    }
    coupons {
      edges {
        node {
          id
          title
          subtitle
          sort
          frontendImage {
            publicPath
          }
          affiliations {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const FRAGMENT_STUDIO_MIDDLE_BASE = gql`
  fragment StudioMiddleBase on Studio {
    id
    latitude
    longitude
    name
    info
    additionalInfo
    address
    phone
    slug
    title
    adyenMerchantAccount
    operator {
      id
      name
      country
      location
      street
    }
    contracts {
      edges {
        node {
          id
          title
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          service
          affiliation {
            id
            name
          }
          parent {
            id
            name
          }
        }
      }
    }
  }
`

export const FRAGMENT_STUDIO_SMALL_BASE = gql`
  fragment StudioSmallBase on Studio {
    id
    latitude
    longitude
    name
    info
    additionalInfo
    address
    phone
    slug
    title
    adyenMerchantAccount
    sisyCashbox
    operator {
      id
      name
      country
      location
      street
    }
    contracts {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
