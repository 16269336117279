import React, { useEffect, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import ErrorText from '@components/atoms/ErrorText'

import FormField from '@helper/FormField'

import {
  Input,
  InputRadio,
  Radios,
  Root,
  StyledInputLabel,
  StyledRadioLabel,
} from './InputRadioGroup.styles'

export interface Radio {
  label: string | React.ReactElement
  forId: string
  value: string
  error?: boolean
  checked?: boolean
}

export enum RadioButtonDirection {
  row = 'row',
  column = 'column',
}

interface Props extends FormField {
  radios: Radio[]
  direction?: RadioButtonDirection
  register?: UseFormRegisterReturn
  inverted?: boolean
  name: string
  onChange?: (value: string) => void
  infoTextTitle?: string
  infoTextContent?: string
  defaultValue?: string
}

const InputRadioGroup = (props: Props): React.ReactElement => {
  const {
    forId,
    label,
    infoTextContent,
    infoTextTitle,
    errorText,
    radios,
    required,
    error,
    disabled,
    name,
    dataTestId,
    register,
    inverted,
    direction,
    defaultValue,
  } = props
  const [activeRadioValue, setActiveRadioValue] = useState<string>(
    defaultValue ?? ''
  )

  const updateActiveRadio = (newActiveRadioValue: string) => {
    setActiveRadioValue(newActiveRadioValue)
  }

  useEffect(() => {
    if (defaultValue) {
      radios.forEach((radio) => {
        radio.checked = radio.value === defaultValue
      })

      return
    }

    radios.forEach((radio) => {
      if (radio.checked) {
        setActiveRadioValue(radio.value)
      }
    })
  }, [])

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ?? 'InputRadioGroup-root'}
    >
      <StyledInputLabel
        dataTestId="InputRadioGroup-label"
        disabled={disabled ?? false}
        error={error ?? false}
        required={required ?? false}
        forId={forId}
        infoTextTitle={infoTextTitle}
        infoTextContent={infoTextContent}
      >
        {label}
      </StyledInputLabel>
      <Radios direction={direction ?? RadioButtonDirection.column}>
        {radios.map((radio, index) => (
          <InputRadio
            direction={direction ?? RadioButtonDirection.column}
            {...(register ?? {})}
            key={radio.value + index + name}
            data-testid={`${dataTestId ?? 'InputRadioGroup'}-option-${index}`}
          >
            <Input
              onChange={(event) => {
                updateActiveRadio(event.currentTarget.value)
                if (props.onChange) {
                  props.onChange(event.currentTarget.value)
                }
              }}
              error={error ?? false}
              disabled={disabled ?? false}
              value={radio.value}
              id={radio.forId}
              type="radio"
              name={name}
              required={required ?? false}
              checked={radio.value === activeRadioValue}
              inverted={inverted ?? false}
            />
            {radio.label && (
              <StyledRadioLabel
                disabled={disabled ?? false}
                error={error ?? false}
                required={false}
                forId={radio.forId}
              >
                {radio.label}
              </StyledRadioLabel>
            )}
          </InputRadio>
        ))}
      </Radios>
      {error && (
        <ErrorText dataTestId={dataTestId + '-error'}>{errorText}</ErrorText>
      )}
    </Root>
  )
}

export { InputRadioGroup }
