import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './OrderedList.styles'

export const ListItems = ['Lorem', 'Ipsum', 'dolor']

interface Props extends PropsWithClassName {}

const OrderedList: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children } = props
  return (
    <Root className={props.className} data-testid={'ordered-list-root'}>
      {children}
    </Root>
  )
}

export { OrderedList }
