import React, { PropsWithChildren } from 'react'

import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'
import {
  Container,
  Content,
  StyledHeadline,
} from '@components/storyblok/molecules/StoryblokTextCol/StoryblokTextCol.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TextColStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokTextCol.styles'

interface StoryblokTextColProps extends TextColStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokTextColProps
}

const StoryblokTextCol: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { headline, content } = blok

  return (
    <Root className={props.className} data-testid={'storyblok-text-col-root'}>
      <Container>
        {headline && (
          <StyledHeadline importance={HeadlineImportances.h5}>
            {headline}
          </StyledHeadline>
        )}
        <Content>
          <StoryblokRichText content={content} />
        </Content>
      </Container>
    </Root>
  )
}

export { StoryblokTextCol }
