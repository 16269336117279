import { FieldError } from 'react-hook-form'
import { css } from 'styled-components'

import { TextVariants } from '@components/atoms/Text/Text'
import { getTextStyles } from '@components/atoms/Text/Text.styles'

export const InputStyles = (
  disabled: boolean,
  error: boolean | FieldError
) => css`
  ${getTextStyles(TextVariants.paragraph)}
  max-width: 100%;

  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.input};
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.palette.default.black};
  background: ${(props) =>
    disabled
      ? props.theme.palette.default.grey
      : props.theme.palette.default.white};
  outline: 1px solid
    ${(props) =>
      error
        ? props.theme.palette.default.error
        : props.theme.palette.default.grey};
  border: none;
  margin-bottom: 0;
  ::placeholder {
    color: ${(props) => props.theme.palette.default.black};
  }
  &:disabled {
    cursor: not-allowed;
    outline: none;
  }
  ::placeholder {
    color: ${(props) => props.theme.palette.default.black};
  }
  &:disabled {
    cursor: not-allowed;
    outline: none;
  }
  &:focus {
    outline: 1px solid ${(props) => props.theme.palette.default.black};
  }
  &:hover {
    outline: 1px solid ${(props) => props.theme.palette.default.black};
  }
`

export const userSelectNone = () => css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
