import styled from 'styled-components'

export const Root = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  background: linear-gradient(
    165deg,
    #efcd00 0%,
    #edaf00 40%,
    #ea9000 98%,
    #292929 100%
  );
  border-radius: 50%;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-bottom: 0 solid #ffffff05;
    box-shadow: 0 -0.625rem 1.25rem 1.25rem #ffffff40 inset,
      0 -0.3125rem 0.9375rem 0.625rem #ffffff50 inset,
      0 -0.125rem 0.3125rem #ffffff80 inset,
      0 -0.1875rem 0.125rem #ffffffbb inset, 0 0.125rem 0rem #ffffff,
      0 0.125rem 0.1875rem #ffffff, 0 0.3125rem 0.3125rem #ffffff90,
      0 0.625rem 0.9375rem #ffffff60, 0 0.625rem 1.25rem 1.25rem #ffffff40;
    filter: blur(0.1875rem);
    animation: 2s rotate linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`
