import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useMemo } from 'react'

import BlockPreContent from '@components/atoms/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import {
  Container,
  PostContent,
} from '@components/storyblok/molecules/StoryblokTextColContainer/StoryblokTextColContainer.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TextIconColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { Root, StyledCol } from './StoryblokTextIconColContainer.styles'

interface StoryblokTextIconColContainerProps
  extends TextIconColContainerStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokTextIconColContainerProps
}

const StoryblokTextIconColContainer: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { textIconCols, headline, subline } = blok

  const colWidth = useMemo(
    () => 12 / ((textIconCols ? textIconCols?.length : null) ?? 1),
    [textIconCols]
  )

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-text-icon-col-container-root'}
      aria-label={headline?.toString()}
      title={headline?.toString()}
    >
      <Row>
        <>
          {headline && subline && (
            <Col xs={12} sm={12} md={12} lg={12} mdOffset={0} lgOffset={0}>
              <Container>
                <BlockPreContent headline={headline} subline={subline} />
              </Container>
            </Col>
          )}
        </>
        <>
          {textIconCols?.map((textCol, index) => (
            <StyledCol
              xs={12}
              sm={12}
              md={colWidth}
              lg={colWidth}
              mdOffset={0}
              lgOffset={0}
              key={index}
            >
              <StoryblokComponent blok={textCol} />
            </StyledCol>
          ))}
        </>
      </Row>
    </Root>
  )
}

export { StoryblokTextIconColContainer }
