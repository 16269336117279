import { gql } from '@apollo/client'

import {
  FRAGMENT_STUDIO_BASE,
  FRAGMENT_STUDIO_MIDDLE_BASE,
  FRAGMENT_STUDIO_SMALL_BASE,
} from '../fragments/studioFragment'

export const GET_STUDIOS = gql`
  query GetStudios(
    $containsContract: Iterable
    $affiliation: String
    $hasAnyCoupons: Boolean
  ) {
    studios(
      containsContract: $containsContract
      affiliation: $affiliation
      hasAnyCoupons: $hasAnyCoupons
    ) {
      edges {
        node {
          ...StudioSmallBase
        }
      }
    }
  }
  ${FRAGMENT_STUDIO_SMALL_BASE}
`

export const GET_STUDIOS_WITH_CATEGORIES = gql`
  query GetStudios($containsContract: Iterable, $affiliation: String) {
    studios(containsContract: $containsContract, affiliation: $affiliation) {
      edges {
        node {
          ...StudioMiddleBase
        }
      }
    }
  }
  ${FRAGMENT_STUDIO_MIDDLE_BASE}
`

export const GET_STUDIO_BY_SLUG = gql`
  query GetStudios($containsContract: Iterable, $slug: String) {
    studios(containsContract: $containsContract, slug: $slug) {
      edges {
        node {
          ...StudioBase
        }
      }
    }
  }
  ${FRAGMENT_STUDIO_BASE}
`

export const GET_STUDIO = gql`
  query Studio($id: ID!) {
    studio(id: $id) {
      ...StudioBase
    }
  }
  ${FRAGMENT_STUDIO_BASE}
`
