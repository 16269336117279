import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconBody.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconBody: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  const currentSize = props.size ?? '32'
  return (
    <Root
      width={currentSize}
      height={currentSize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'icon-body-root'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5223 15.4626C25.9314 15.0916 26.5639 15.1225 26.9349 15.5316L26.1941 16.2034C26.9349 15.5316 26.9355 15.5323 26.9361 15.533L26.9374 15.5344L26.9402 15.5375L26.9463 15.5444L26.9608 15.5611C26.9715 15.5736 26.9841 15.5888 26.9985 15.6067C27.0272 15.6424 27.0628 15.6891 27.1036 15.7471C27.1852 15.8632 27.2872 16.0244 27.3955 16.2342C27.6125 16.6549 27.8514 17.2649 28.0031 18.0893C28.307 19.742 28.2515 22.2054 27.0388 25.6764C26.8566 26.1978 26.2863 26.4728 25.7649 26.2907C25.2436 26.1085 24.9686 25.5382 25.1507 25.0168C26.2717 21.8083 26.2658 19.7001 26.036 18.4511C25.9208 17.8245 25.747 17.4012 25.6181 17.1512C25.5533 17.0256 25.499 16.9422 25.4675 16.8974C25.453 16.8768 25.4434 16.8644 25.4397 16.8597C25.0832 16.4503 25.1183 15.829 25.5223 15.4626Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89354 15.6895C7.22653 15.2607 7.15657 14.6423 6.73263 14.2992C6.30335 13.9517 5.67366 14.018 5.3262 14.4473L6.09771 15.0718C5.32619 14.4473 5.32556 14.4481 5.32492 14.4489L5.32359 14.4505L5.32078 14.4541L5.31453 14.462L5.29961 14.4813C5.28862 14.4958 5.27558 14.5135 5.26075 14.5345C5.23108 14.5764 5.19425 14.6314 5.15218 14.7002C5.06797 14.8378 4.96314 15.03 4.85287 15.2826C4.63193 15.7885 4.39179 16.5306 4.24927 17.5528C3.9643 19.5968 4.07226 22.7391 5.4749 27.341C5.63592 27.8693 6.19472 28.167 6.72301 28.006C7.2513 27.845 7.54903 27.2862 7.38801 26.7579C6.0499 22.3677 5.99388 19.5235 6.23011 17.829C6.34819 16.982 6.53995 16.4168 6.68576 16.0829C6.75885 15.9155 6.82111 15.8046 6.85815 15.744C6.87669 15.7137 6.88902 15.6959 6.89336 15.6897M6.89354 15.6895C6.89354 15.6895 6.89354 15.6895 6.89354 15.6895L6.89354 15.6895Z"
        fill={currentColor}
      />
      <path
        d="M23.374 4.6C23.9607 3.42667 24.4674 2 24.534 0H10.6274C10.3607 0.68 9.42737 3.52 10.7874 5.85333C11.094 6.4 11.6807 7.17333 11.7207 7.92C11.854 11.16 9.80071 13.1333 9.10737 16.8933C8.02737 22.8133 10.0274 29.2533 11.494 32H13.374C13.4807 31.32 13.6274 30.4667 13.7207 29.8133C14.694 22.9733 12.974 21.12 12.9607 21.1067C12.7074 20.8533 12.7074 20.4267 12.9607 20.1733C13.2007 19.9067 13.614 19.8933 13.8807 20.1333C13.974 20.2267 14.3607 20.6267 14.7207 21.6133C15.5474 19.24 16.7874 18.2933 16.8674 18.24C17.1607 18.0133 17.5874 18.08 17.8007 18.3733C18.014 18.6667 17.9607 19.0933 17.6674 19.3067C17.614 19.3467 15.5074 21.04 15.3607 25.72C15.3607 25.7467 15.3607 25.7733 15.3474 25.8C15.3607 26.96 15.2674 28.3467 15.0407 30C14.9607 30.5867 14.8274 31.36 14.734 32H20.494C21.8007 27.44 23.5607 23.6 23.7074 19.64C23.8674 15.52 20.5074 13.64 20.0274 10.6667C19.5474 7.69333 21.9207 7.52 23.374 4.6ZM14.8807 13.3067C14.8807 13.3067 14.854 13.4 14.854 13.4533C14.8407 13.8 14.5474 14.08 14.1874 14.08H14.1607C13.7874 14.0667 13.5074 13.7467 13.5207 13.3867C13.5207 13.3067 13.534 13.2133 13.5607 13.1067V12.9467C13.614 12.5733 13.9474 12.3067 14.3074 12.3467C14.6674 12.3867 14.9474 12.7067 14.9074 13.08L14.8807 13.3067Z"
        fill={currentColor}
      />
    </Root>
  )
}

export { IconBody }
