import React, { PropsWithChildren } from 'react'

import StudioFilter from '@components/organisms/StudioFilter'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokStudioFilter.styles'

interface Props extends PropsWithClassName {}

const StoryblokStudioFilter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-studio-filter-root'}
    >
      <StudioFilter />
    </Root>
  )
}

export { StoryblokStudioFilter }
