import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconInfo.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconInfo: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { color } = props
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      data-testid={'icon-info-root'}
    >
      <g id="Stroke">
        <g id="Group 168">
          <path
            id="Vector"
            d="M12.5 16.5C12.5 16.5 12.5 15.3978 12.5 15V11.25C12.5 11.0511 12.421 10.8603 12.2803 10.7197C12.1397 10.579 11.9489 10.5 11.75 10.5H11"
            stroke={color || 'black'}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M12.125 7.5C11.9179 7.5 11.75 7.33211 11.75 7.125C11.75 6.91789 11.9179 6.75 12.125 6.75"
            stroke={color || 'black'}
            stroke-width="1.5"
          />
          <path
            id="Vector_3"
            d="M12.125 7.5C12.3321 7.5 12.5 7.33211 12.5 7.125C12.5 6.91789 12.3321 6.75 12.125 6.75"
            stroke={color || 'black'}
            stroke-width="1.5"
          />
        </g>
        <path
          id="Vector_4"
          d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
          stroke={color || 'black'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </Root>
  )
}

export { IconInfo }
