import styled from 'styled-components'

import Button from '@components/atoms/Button'
import Row from '@components/atoms/Grid/Row'

export const Root = styled(Row)`
  align-items: stretch;
`

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 0;
`
