import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { HeroSubpageStoryblok } from '@definitions/types/storyblokTypes'

import {
  BackgroundImage,
  Desktop,
  Mobile,
  Root,
} from './StoryblokHeroSubpage.styles'

interface Props extends PropsWithClassName {
  blok: HeroSubpageStoryblok
}

const StoryblokHeroSubpage = (props: Props): React.ReactElement => {
  const { blok } = props
  const { image_mobile, image } = blok
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={props.className}
      data-testid={'storyblok-hero-subpage-root'}
    >
      <BackgroundImage>
        {image_mobile.filename && (
          <Mobile backgroundImage={image_mobile.filename} />
        )}
        {image.filename && <Desktop backgroundImage={image.filename} />}
      </BackgroundImage>
    </Root>
  )
}

export { StoryblokHeroSubpage }
