import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren, useMemo } from 'react'
import { AppTheme, useTheme } from 'styled-components'

import { BlockPreContent } from '@components/atoms/BlockPreContent/BlockPreContent'
import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import LinkButton from '@components/atoms/LinkButton'
import {
  Container,
  PostContent,
} from '@components/storyblok/molecules/StoryblokTextColContainer/StoryblokTextColContainer.styles'

import { getEnvironment } from '@helper/env'
import { PropsWithClassName } from '@helper/PropsWithClassName'
import { getUrlFromLink } from '@helper/url'

import { TextColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokTextColContainer.styles'

interface Props extends PropsWithClassName {
  blok: TextColContainerStoryblok
}

const StoryblokTextColContainer: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { textcols, headline, subline, button, buttonLink } = blok

  const colWidth = useMemo(
    () => 12 / ((textcols ? textcols?.length : null) ?? 1),
    [textcols]
  )

  const theme: any = useTheme()
  const url = getUrlFromLink(buttonLink, theme)

  // console.log(blok.buttonLink)
  // console.log(blok.buttonLink?.url)
  // console.log(blok.buttonLink?.cached_urlurl)
  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-text-col-container-root'}
      aria-label={headline?.toString()}
      title={headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} mdOffset={0} lgOffset={0}>
          <Container>
            <BlockPreContent headline={headline} subline={subline} />
            <Row>
              {textcols?.map((textCol, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={colWidth}
                  lg={colWidth}
                  mdOffset={0}
                  lgOffset={0}
                  key={index}
                >
                  <StoryblokComponent blok={textCol} />
                </Col>
              ))}
            </Row>
            {url && (
              <PostContent>
                <LinkButton
                  disabled={false}
                  modifier={ButtonModifier.MINIMAL}
                  loading={false}
                  label={button ?? 'Mehr erfahren'}
                  href={url}
                  icon={<IconArrowRight />}
                  iconAlign={IconAligns.RIGHT}
                >
                  {button ?? 'Mehr erfahren'}
                </LinkButton>
              </PostContent>
            )}
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokTextColContainer }
