import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Text from '@components/atoms/Text'
import { TextMetaStyles } from '@components/atoms/Text/Text.styles'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.big};
  position: relative;
  inset: 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 16 / 9;
  padding: 0.5rem;
`

export const Container = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const StyledUsercentricsBox = styled(Box)`
  max-width: 30rem;
  height: auto;
  margin: 0;
  padding: 2rem;
`

export const StyledText = styled(Text)`
  margin-bottom: 0;
`
