import useUserCentrics from '@hooks/useUserCentrics'
import { storyblokEditable } from '@storyblok/react'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { YouTubePlayer, YouTubeProps } from 'react-youtube'

import { BlockPreContent } from '@components/atoms/BlockPreContent/BlockPreContent'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconLargeSun from '@components/atoms/Icons/IconLargeSun'
import IconPlay from '@components/atoms/Icons/IconPlay'
import UsercentricsConsent from '@components/molecules/UsercentricsConsent'

import { UsercentricServices } from '@helper/enums/UsercentricServices'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { VideoWithOptionalTextStoryblok } from '@definitions/types/storyblokTypes'

import {
  Container,
  Root,
  StyledVideo,
  StyledYoutube,
  VideoButton,
  VideoContainer,
  VideoPlayIconContainer,
} from './StoryblokVideoWithOptionalText.styles'

interface Props extends PropsWithClassName {
  blok: VideoWithOptionalTextStoryblok
}

const youtubeOptions = {
  height: '390',
  width: '640',
  playerVars: {
    showinfo: 1,
    controls: 1,
    autohide: 1,
    modestbranding: 1,
  },
}

const parseYoutubeId = (url: string): string => {
  if (url.includes('/embed/')) {
    const urlParts = url.split('/embed/')
    return urlParts[1]
  }

  if (url.includes('?v=')) {
    const urlParts = url.split('?v=')
    return urlParts[1]
  }

  return url
}

const StoryblokVideoWithOptionalText: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { video, headline, subline, youtube, placeholderImage } = blok
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(false)
  const youtubePlayerRef = useRef<YouTubePlayer | null>(null)
  const { consentYoutube } = useUserCentrics()

  const onYoutubePlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    youtubePlayerRef.current = event.target
  }

  const videoPlayerRef = useRef<HTMLVideoElement | null>(null)

  const playVideo = useCallback(() => {
    setIsVideoPlaying(true)

    if (youtubePlayerRef.current) {
      youtubePlayerRef.current.playVideo()
    } else {
      videoPlayerRef.current?.play()
    }
  }, [])

  const stopVideo = useCallback(() => {
    if (isVideoPlaying) {
      setIsVideoPlaying(false)
      videoPlayerRef.current?.pause()
    }
  }, [isVideoPlaying])

  useEffect(() => {
    setTimeout(() => {
      setShowPlaceHolder(true)
    }, 200)
  }, [])

  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-video-with-optional-text-root'}
      aria-label={blok.headline?.toString()}
      title={blok.headline?.toString()}
    >
      <Row>
        <Col xs={12} sm={12} md={12} mdOffset={0} lg={12} lgOffset={0}>
          <Container>
            <BlockPreContent headline={headline} subline={subline} />
            <VideoContainer>
              {video && video.id && (
                <StyledVideo ref={videoPlayerRef} onClick={stopVideo}>
                  <source src={video?.filename ?? ''} />
                </StyledVideo>
              )}
              {youtube && consentYoutube && (
                <StyledYoutube
                  videoId={parseYoutubeId(youtube)}
                  opts={youtubeOptions}
                  onReady={onYoutubePlayerReady}
                  onPause={() => setIsVideoPlaying(false)}
                  iframeClassName={'youtubeIframeElement'}
                />
              )}
              {showPlaceHolder && youtube && !consentYoutube && (
                <UsercentricsConsent
                  placeholderImage={placeholderImage}
                  service={UsercentricServices.YouTube}
                  headline={
                    'Wir benötigen Ihre Zustimmung, um den YouTube Video-Service zu laden!'
                  }
                  text={`Wir verwenden einen Service eines Drittanbieters, um
                                            Videoinhalte einzubetten. Dieser Service kann Daten zu
                                            Ihren Aktivitäten sammeln. Bitte lesen Sie die Details
                                            durch und stimmen Sie der Nutzung des Service zu, um
                                            dieses Video anzusehen.`}
                />
              )}

              {!isVideoPlaying && (
                <VideoButton onClick={playVideo}>
                  <IconLargeSun />
                  <VideoPlayIconContainer>
                    <IconPlay />
                  </VideoPlayIconContainer>
                </VideoButton>
              )}
            </VideoContainer>
          </Container>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokVideoWithOptionalText }
