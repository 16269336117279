import { FieldError } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { InputStyles } from '@helper/mixins'

import InputLabel from '../InputLabel'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
`

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: ${(props) => props.theme.margin.small};
`
interface TextareaProps {
  error: boolean | FieldError
}
export const Textarea = styled.textarea<TextareaProps>`
  resize: none;
  ${(props) => InputStyles(false, props.error)}
`
