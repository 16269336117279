import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { PropsWithChildren } from 'react'

import Row from '@components/atoms/Grid/Row'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { TextOnImageColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { Root, StyledCol } from './StoryblokTextOnImageColContainer.styles'

interface Props extends PropsWithClassName {
  blok: TextOnImageColContainerStoryblok
}

const StoryblokTextOnImageColContainer: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { blok } = props
  const { textOnImageCols } = blok
  return (
    <Root
      {...storyblokEditable(blok)}
      className={props.className}
      data-testid={'storyblok-text-on-image-col-container-root'}
    >
      <Row>
        {textOnImageCols?.map((textOnImageCol, index) => (
          <StyledCol xs={12} sm={12} md={6} lg={6} key={index}>
            <StoryblokComponent blok={textOnImageCol} />
          </StyledCol>
        ))}
      </Row>
    </Root>
  )
}

export { StoryblokTextOnImageColContainer }
