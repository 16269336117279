import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './Blockqoute.styles'

interface Props extends PropsWithClassName {}

const Blockqoute: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children, className } = props
  return (
    <Root className={className} data-testid={'blockqoute-root'}>
      {children}
    </Root>
  )
}

export { Blockqoute }
