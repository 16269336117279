import { CtaLeftAlignStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokCtaLeftAlign } from './StoryblokCtaLeftAlign'

export default StoryblokCtaLeftAlign

export const dummyBlokCtaLeftAlignPrimaryStoryblok: CtaLeftAlignStoryblok = {
  _uid: '17091d45-1adf-44a6-b5d5-2efceed28fbe',
  link: {
    id: '50d1b16c-e98c-4255-a068-8fe147ec27db',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: 'sunpoint/home',
  },
  image: {
    id: 9757937,
    alt: '',
    name: '',
    focus: '',
    title: '',
    source: '',
    filename:
      'https://a.storyblok.com/f/235453/1920x445/0868c8320d/filiale.jpg',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {},
    is_external_url: false,
  },
  button: 'dassdsadd',
  content: {
    type: 'doc',
    content: [
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'dsadssadsdssd',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'ssaddssadsad',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'sdasadsda',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'dsasdadsa',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  headline: 'dsadsdsdds',
  component: 'cta-left-align',
  buttonStyle: 'primary',
  _editable:
    '<!--#storyblok#{"name": "cta-left-align", "space": "235453", "uid": "17091d45-1adf-44a6-b5d5-2efceed28fbe", "id": "322787899"}-->',
}

export const dummyBlokCtaLeftAlignSecondaryStoryblok: CtaLeftAlignStoryblok = {
  ...dummyBlokCtaLeftAlignPrimaryStoryblok,
  buttonStyle: 'secondary',
}
