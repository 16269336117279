import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconArrowRight.styles'

interface Props extends PropsWithClassName {
  color?: string
}

const IconArrowRight: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'

  return (
    <Root
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={props.className}
      data-testid={'icon-arrow-right-root'}
    >
      <path
        fill={currentColor}
        d="M4.75 11.747h14.5a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1 0-1.5Z"
      />
      <path
        fill={currentColor}
        d="m14.97 9.277 3.22 3.22-3.22 3.22a.75.75 0 1 0 1.06 1.06l3.75-3.75a.75.75 0 0 0 0-1.06l-3.75-3.75a.75.75 0 1 0-1.06 1.06Z"
      />
    </Root>
  )
}

export { IconArrowRight }
