import { TabbedContentEntryStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTabbedContentEntry } from './StoryblokTabbedContentEntry'

export const dummyTabbedContentEntryBlok: TabbedContentEntryStoryblok = {
  _uid: '8685e05a-12f6-4df9-94a1-0cdfa64045fd',
  image: {
    id: 1234,
    alt: 'null',
    name: '',
    focus: 'null',
    title: 'null',
    source: 'null',
    filename: '',
    copyright: 'null',
    fieldtype: 'asset',
    meta_data: {},
  },
  button: [],
  headline: 'Tabbed Content Entry',
  component: 'tabbed-content-entry',
  description: 'Non velit sunt qui sint fugiat qui Lorem.',
  _editable:
    '<!--#storyblok#{"name": "tabbed-content-entry", "space": "228071", "uid": "8685e05a-12f6-4df9-94a1-0cdfa64045fd", "id": "300939517"}-->',
}

export default StoryblokTabbedContentEntry
