import styled from 'styled-components'

import Headline from '@components/atoms/Headline'

export const Root = styled.div``

export const StyledHeadline = styled(Headline)`
  margin-bottom: 1.5rem;
  width: auto;
  font-weight: 400;
`

export const Container = styled.div``

export const Content = styled.div`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.paragraph.mobile.fontSize};
  line-height: ${(props) => props.theme.font.text.paragraph.mobile.lineHeight};
  font-weight: 300;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    margin-bottom: 2rem;
  }
`
