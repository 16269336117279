export enum RoutesSunpoint {
  SUNPOINT__AGB = '/sunpoint/agb',
  SUNPOINT__UEBERSUNPOINT__STUDIOFINDER = '/sunpoint/ueber-sunpoint/studiofinder',
  SUNPOINT__UEBERSUNPOINT__FRANCHISEPARTNERWERDEN = '/sunpoint/ueber-sunpoint/franchise-partner-werden',
  SUNPOINT__UEBERSUNPOINT__EXPANSIONSTANDORTFUERSUNPOINTANBIETEN = '/sunpoint/ueber-sunpoint/expansion-standort-fuer-sunpoint-anbieten',
  SUNPOINT__UEBERSUNPOINT__SUNPOINTBEAUTYTANNINGSTUDIOS = '/sunpoint/ueber-sunpoint/sunpoint-beauty-tanning-studios',
  SUNPOINT__CLUB = '/sunpoint/club/',
  SUNPOINT__UEBERSUNPOINT = '/sunpoint/ueber-sunpoint/',
  SUNPOINT__FRANCHISE = '/sunpoint/franchise/',
  SUNPOINT__BRAEUNE = '/sunpoint/braeune/',
  SUNPOINT__JOBS = '/sunpoint/jobs/',
  SUNPOINT__BODYFORMING = '/sunpoint/bodyforming/',
  SUNPOINT__KOSMETIK = '/sunpoint/kosmetik/',
  SUNPOINT__WIDERRUFSBELEHRUNG = '/sunpoint/widerrufsbelehrung',
  SUNPOINT__BRAEUNE__SOLARIUM = '/sunpoint/braeune/solarium',
  SUNPOINT__KOSMETIK__MONATSANGEBOTE = '/sunpoint/kosmetik/monatsangebote',
  SUNPOINT__JOBS__BEWERBUNGSTUDIOLEITERIN = '/sunpoint/jobs/bewerbung-studioleiterin',
  SUNPOINT__CLUB__SUNPOINTCLUB = '/sunpoint/club/sunpoint-club',
  SUNPOINT__COOKIEEINSTELLUNGEN = '/sunpoint/cookie-einstellungen',
  SUNPOINT__JOBS__BEWERBUNGSTUDIOMITARBEITERIN = '/sunpoint/jobs/bewerbung-studiomitarbeiterin',
  SUNPOINT__KOSMETIK__SOLARKOSMETIK = '/sunpoint/kosmetik/solarkosmetik',
  SUNPOINT__BODYFORMING__FARBLICHTTHERAPIE = '/sunpoint/bodyforming/farblichttherapie',
  SUNPOINT__BRAEUNE__COLLAGEN = '/sunpoint/braeune/collagen',
  SUNPOINT__CLUB__WELLMAXXCLUB = '/sunpoint/club/wellmaxx-club',
  SUNPOINT__DEV = '/sunpoint/dev',
  SUNPOINT__KOSMETIK__WIRKKOSMETIK = '/sunpoint/kosmetik/wirkkosmetik',
  SUNPOINT__CLUB__TANTASTICCLUB = '/sunpoint/club/tantastic-club',
  SUNPOINT__BRAEUNE__SPRAYTANNINGBRAEUNUNGSDUSCHE = '/sunpoint/braeune/spray-tanning-braeunungsdusche',
  SUNPOINT__JOBS__BEWERBUNGREINIGUNGSKRAFT = '/sunpoint/jobs/bewerbung-reinigungskraft',
  SUNPOINT__FORMULARE = '/sunpoint/formulare',
  SUNPOINT__KOSMETIK__BEAUTYTIPPS = '/sunpoint/kosmetik/beauty-tipps',
  SUNPOINT__CLUB__KOMBICLUB = '/sunpoint/club/kombi-club',
  SUNPOINT__BRAEUNE__HAUTTYPENTEST = '/sunpoint/braeune/hauttypen-test',
  SUNPOINT__CLUB__CLUBFAQ = '/sunpoint/club/club-faq',
  SUNPOINT__BRAEUNE__BRAEUNUNGSTIPPS = '/sunpoint/braeune/braeunungs-tipps',
  SUNPOINT__LIDLAKTION = '/sunpoint/lidl-aktion',
  SUNPOINT__BRAEUNE__SONNENLICHTUNDVITAMIND = '/sunpoint/braeune/sonnenlicht-und-vitamin-d',
  SUNPOINT__CLUB__CLUBMITGLIEDWERDEN = '/sunpoint/club/clubmitglied-werden',
  SUNPOINT__PRESSE = '/sunpoint/presse',
  SUNPOINT__NEWSLETTER = '/sunpoint/newsletter',
  SUNPOINT__KOOPERATIONENINFLUENCER = '/sunpoint/kooperationen-influencer',
  SUNPOINT__CLUBVERTRAGKUENDIGEN = '/sunpoint/clubvertrag-kuendigen',
  SUNPOINT__COUPONS = '/sunpoint/coupons',
  SUNPOINT__SUNPOINTCLUBSYSTEM = '/sunpoint/sunpoint-clubsystem',
  SUNPOINT__CLUBMITGLIEDWERDEN = '/sunpoint/clubmitglied-werden',
  SUNPOINT__HOME = '/sunpoint/home',
  SUNPOINT__KONTAKT = '/sunpoint/kontakt',
  SUNPOINT__DATENSCHUTZ = '/sunpoint/datenschutz',
  SUNPOINT__IMPRESSUM = '/sunpoint/impressum',
  SUNPOINT__RELEASEDCOMPONENTS = '/sunpoint/released-components',
  SUNPOINT__WELLMAXXWAVE = '/sunpoint/wellmaxx-wave',
}
