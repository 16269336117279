import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './LogoDevee.styles'

interface Props extends PropsWithClassName {}

const LogoDevee: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      width="46"
      height="39"
      viewBox="0 0 46 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      data-testid={'logo-devee-root'}
    >
      <g clipPath="url(#clip0_1722_119316)">
        <path
          d="M0.138 34.4107C0.299 33.9651 0.437 33.5194 0.575 33.0972C0.598 33.0033 0.621 32.8861 0.621 32.7922C0.644 30.2589 0.667 27.7021 0.667 25.1688C0.667 18.2725 0.667 11.3527 0.667 4.45642C0.667 3.58852 0.621 2.69716 0.575 1.82926C0.529 1.40704 0.391 1.05519 0.161 0.750251C0.092 0.656424 0.069 0.562596 0 0.445312C0.782 0.445312 1.495 0.445312 2.231 0.445312C2.461 0.445312 2.691 0.492226 2.921 0.468769C3.979 0.398399 4.853 0.844078 5.635 1.52432C6.739 2.48605 7.383 3.75272 7.797 5.16013C8.211 6.59099 8.487 8.04531 8.579 9.54655C8.717 11.728 8.832 13.933 8.901 16.1144C8.993 18.3428 8.993 20.5712 8.832 22.7996C8.671 25.1922 8.349 27.5379 7.682 29.8601C7.36 30.9861 6.9 32.0416 6.118 32.933C5.382 33.7774 4.462 34.2465 3.358 34.3169C2.277 34.3638 1.196 34.3638 0.138 34.4107ZM3.22 2.67371C3.22 2.81445 3.197 2.88482 3.197 2.97865C3.197 3.54161 3.197 4.10457 3.174 4.66753C3.174 7.01321 3.151 9.33544 3.151 11.6811C3.151 18.2021 3.151 24.7231 3.151 31.2441C3.151 31.5256 3.151 31.807 3.151 32.0885C3.151 32.2996 3.243 32.3935 3.45 32.3465C3.841 32.2762 4.14 32.0651 4.393 31.7836C4.945 31.2206 5.221 30.5169 5.428 29.7663C5.727 28.6873 5.888 27.5848 5.98 26.4589C6.187 23.8317 6.302 21.1811 6.302 18.5305C6.302 16.8416 6.256 15.1527 6.21 13.4873C6.164 11.3762 6.049 9.28852 5.681 7.20087C5.451 5.91074 5.198 4.59716 4.439 3.4947C4.186 3.04902 3.795 2.74408 3.22 2.67371Z"
          fill="#292929"
        />
        <path
          d="M18.9297 3.77639C20.1947 3.77639 21.3907 3.77639 22.6097 3.77639C22.6097 3.79984 22.6097 3.84676 22.5867 3.87021C22.1497 4.45663 22.1497 5.13688 22.2187 5.79367C22.4717 8.42083 22.7707 11.048 23.0237 13.6752C23.2767 16.2319 23.5527 18.7887 23.8057 21.3455C23.8977 22.2369 23.9897 23.1048 24.1277 24.0196C24.1507 23.8319 24.1967 23.6677 24.2197 23.4801C24.4267 21.6739 24.6107 19.8912 24.7947 18.085C25.0017 16.1147 25.2317 14.1677 25.4387 12.1974C25.6227 10.5789 25.8067 8.96034 25.9677 7.34182C26.0367 6.73194 26.0827 6.12207 26.1517 5.53565C26.1977 4.94923 26.1287 4.38626 25.7837 3.89367C25.7607 3.87021 25.7607 3.84676 25.7607 3.8233C25.7607 3.79984 25.7607 3.79984 25.7607 3.75293C26.7497 3.75293 27.7387 3.75293 28.7737 3.75293C28.6587 3.91713 28.5667 4.05787 28.4977 4.19861C28.1987 4.6912 28.1527 5.27762 28.0837 5.84058C27.7847 8.20972 27.4857 10.6023 27.2097 12.9714C26.9567 15.106 26.7267 17.264 26.4737 19.3986C26.2437 21.369 25.9907 23.3628 25.7607 25.3332C25.5307 27.327 25.3007 29.2974 25.0707 31.2912C24.8407 33.2616 24.6107 35.2319 24.3577 37.2023C24.3117 37.5072 24.2887 37.8356 24.2427 38.164C23.9667 38.164 23.7137 38.164 23.4377 38.164C23.3917 37.6949 23.3227 37.2258 23.2767 36.7566C23.0697 35.0443 22.8627 33.3085 22.6787 31.5961C22.4717 29.9073 22.2877 28.2184 22.0807 26.5295C21.8967 24.911 21.6897 23.269 21.5057 21.6505C21.3447 20.3369 21.2067 19.0468 21.0457 17.7332C20.8617 16.2554 20.6777 14.7542 20.5167 13.2764C20.3787 12.127 20.2637 10.9542 20.1257 9.80478C19.9187 8.13935 19.6887 6.47392 19.4357 4.80849C19.3897 4.55046 19.2287 4.29244 19.1137 4.03441C19.0447 3.9875 18.9987 3.91713 18.9297 3.77639Z"
          fill="#292929"
        />
        <path
          d="M29.8537 34.3171C29.9227 34.1764 30.0147 34.0356 30.0837 33.8949C30.3597 33.285 30.4057 32.6282 30.4057 31.9714C30.4057 23.2455 30.4057 14.4961 30.4057 5.77019C30.4057 5.1134 30.2677 4.50353 29.8767 3.98748C29.8307 3.94056 29.8077 3.89365 29.7617 3.79982C29.8767 3.79982 29.9457 3.77637 30.0377 3.77637C32.0847 3.77637 34.1317 3.77637 36.1787 3.77637C36.8687 3.77637 36.8687 3.77637 36.8687 4.48007C36.8687 5.08995 36.8687 5.69982 36.8687 6.33316C36.6847 6.21587 36.5467 6.07513 36.3627 6.00476C35.9947 5.86402 35.6037 5.69982 35.2357 5.67637C34.5917 5.62945 33.9247 5.65291 33.2577 5.65291C32.9587 5.65291 32.8437 5.77019 32.8207 6.07513C32.7977 6.3097 32.8207 6.54427 32.8207 6.77884C32.7977 9.0776 32.7977 11.3764 32.7747 13.6751C32.7747 13.769 32.7747 13.8862 32.7977 13.9801C32.8667 14.3554 32.9587 14.4492 33.3267 14.4257C33.8557 14.4023 34.3847 14.3554 34.9137 14.2616C35.0977 14.2381 35.2817 14.0739 35.4657 13.9801C35.5577 13.9332 35.6267 13.8862 35.7647 13.8159C35.7647 14.848 35.7647 15.8332 35.7647 16.8653C35.6957 16.8418 35.6037 16.8183 35.5577 16.7714C35.2127 16.4665 34.7987 16.3492 34.3617 16.3257C33.9477 16.3023 33.5337 16.3257 33.1197 16.3257C32.9357 16.3257 32.8667 16.4196 32.8667 16.6307C32.8667 18.4369 32.8437 20.2665 32.8207 22.0727C32.8207 25.2393 32.8207 28.3825 32.8207 31.5492C32.8207 31.6665 32.8207 31.7603 32.8207 31.8776C32.8207 32.1356 32.9587 32.2764 33.2117 32.2764C34.0627 32.2764 34.9367 32.2998 35.7877 32.2295C36.3397 32.1825 36.8457 31.9949 37.2137 31.5257C37.2367 31.5023 37.2597 31.5023 37.3057 31.4788C37.3057 32.3937 37.3057 33.3085 37.3057 34.2702C34.8447 34.3171 32.3607 34.3171 29.8537 34.3171Z"
          fill="#292929"
        />
        <path
          d="M38.3867 3.77637C38.5477 3.77637 38.6627 3.77637 38.7547 3.77637C40.7557 3.77637 42.7567 3.75292 44.7577 3.79983C45.6547 3.82329 45.4477 3.63563 45.4937 4.52699C45.5167 5.11341 45.4937 5.69983 45.4937 6.33316C45.3327 6.21588 45.1947 6.12205 45.0567 6.05168C44.6887 5.81711 44.2747 5.69983 43.8377 5.67637C43.2397 5.65292 42.6187 5.65292 42.0207 5.67637C41.5607 5.67637 41.4457 5.81711 41.4457 6.28625C41.4457 6.59119 41.4457 6.89613 41.4457 7.17761C41.4457 9.35909 41.4457 11.5406 41.4457 13.7221C41.4457 14.4023 41.5147 14.4727 42.1817 14.4727C42.7797 14.4727 43.4007 14.4727 43.9297 14.1443C44.0677 14.0504 44.2057 13.9566 44.4127 13.8393C44.4127 14.8949 44.4127 15.8801 44.4127 16.9122C44.3207 16.8887 44.2517 16.8653 44.1827 16.8183C43.7687 16.443 43.2397 16.3492 42.7107 16.3258C42.3887 16.3023 42.0667 16.3258 41.7447 16.3492C41.5147 16.3727 41.4227 16.49 41.4227 16.7245C41.4227 21.0406 41.4227 25.3566 41.4227 29.6727C41.4227 30.2591 41.3997 30.8455 41.4227 31.4554C41.4687 32.3467 41.4457 32.2764 42.2047 32.3233C43.0097 32.3937 43.8377 32.4171 44.6197 32.2295C45.0567 32.1122 45.5167 31.9949 45.7927 31.5961C45.8157 31.5727 45.8617 31.5492 45.9307 31.5258C45.9307 32.464 45.9307 33.3788 45.9307 34.3406C43.4237 34.3406 40.9397 34.3406 38.4327 34.3406C38.5017 34.2233 38.5937 34.0825 38.6627 33.9418C39.0077 33.285 38.9847 32.5344 39.0077 31.8307C39.0307 28.1011 39.0077 24.3949 39.0077 20.6653C39.0077 18.9764 39.0077 17.2875 38.9847 15.5751C38.9847 12.2912 38.9617 9.00724 38.9617 5.72329C38.9617 5.11341 38.8237 4.52699 38.4557 4.0344C38.4787 3.94057 38.4557 3.8702 38.3867 3.77637Z"
          fill="#292929"
        />
        <path
          d="M18.7901 31.5257C18.7901 32.464 18.7901 33.3788 18.7901 34.3405C16.2831 34.3405 13.7991 34.3405 11.3611 34.3405C11.4761 33.9652 11.6831 33.5665 11.7521 33.1677C11.8441 32.6282 11.8671 32.0652 11.8671 31.5023C11.8671 26.7875 11.8671 22.0726 11.8671 17.3578C11.8671 16.2084 11.8671 15.0591 11.8671 13.9097C11.8671 11.4467 11.8441 8.9603 11.8441 6.49734C11.8441 6.00474 11.8441 5.53561 11.7751 5.04301C11.7061 4.69116 11.5221 4.36277 11.3841 4.03437C11.3611 3.964 11.2921 3.89363 11.2461 3.7998C13.6151 3.7998 15.9611 3.7998 18.3301 3.7998C18.3301 4.66771 18.3301 5.48869 18.3301 6.35659C17.4561 5.51215 16.3751 5.65289 15.3401 5.65289C15.1331 5.65289 14.9261 5.67635 14.7191 5.67635C14.4891 5.67635 14.3741 5.79363 14.3281 6.00474C14.3051 6.12203 14.2821 6.26277 14.2821 6.40351C14.2821 8.86647 14.2821 11.306 14.2821 13.7689C14.2821 14.4257 14.3511 14.5196 15.0181 14.4961C15.6161 14.4961 16.2371 14.4961 16.7891 14.1677C16.9271 14.0739 17.0881 13.9801 17.2721 13.8628C17.2721 14.9183 17.2721 15.9035 17.2721 16.9121C17.2031 16.8887 17.1111 16.8887 17.0651 16.8418C16.6281 16.4196 16.0531 16.3492 15.5011 16.3492C15.2021 16.3257 14.8801 16.3726 14.5811 16.3961C14.4201 16.3961 14.3511 16.4899 14.3281 16.6307C14.3281 16.7949 14.3051 16.9591 14.3051 17.1233C14.3051 21.4862 14.3051 25.8492 14.3281 30.2356C14.3281 30.7986 14.3511 31.3381 14.3511 31.901C14.3511 32.1591 14.4661 32.2998 14.7421 32.3233C15.6851 32.3936 16.6051 32.464 17.5251 32.2529C17.9851 32.1591 18.3991 31.9949 18.7211 31.6196C18.6751 31.5492 18.7211 31.5492 18.7901 31.5257Z"
          fill="#292929"
        />
      </g>
      <defs>
        <clipPath id="clip0_1722_119316">
          <rect
            width="46"
            height="38"
            fill="white"
            transform="translate(0 0.328125)"
          />
        </clipPath>
      </defs>
    </Root>
  )
}

export { LogoDevee }
