import styled from 'styled-components'

import Button from '@components/atoms/Button'
import InputText from '@components/atoms/Form/InputText'
import Col from '@components/atoms/Grid/Col'
import Text from '@components/atoms/Text'

export const Root = styled.div``

export const ButtonCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  margin: 0;
`

export const StyledText = styled(Text)`
  margin: 0;
`

export const StyledInputText = styled(InputText)`
  margin-bottom: 1rem;
`

export const ButtonStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  @media (min-width: ${(props) => props.theme.device.mobile}) {
    align-items: flex-start;
  }
`
