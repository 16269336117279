import { GroupedCategory, useCategories } from '@hooks/useCategories'
import useStudioSearch from '@hooks/useStudioSearch'
import useUserCentrics from '@hooks/useUserCentrics'
import { useRouter } from 'next/router'
import React, {
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'

import { ButtonModifier, IconAligns } from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import { Col } from '@components/atoms/Grid/Col/Col'
import { Row } from '@components/atoms/Grid/Row/Row'
import IconCircleX from '@components/atoms/Icons/IconCircleX'
import Pill from '@components/atoms/Pill'
import Collapsible from '@components/molecules/Collapsible'
import GoogleMap from '@components/molecules/GoogleMap'
import StudioFinderSearchfield from '@components/molecules/StudioFinder/StudioFinderSearchfield'

import capitalizeFirstLetter from '@helper/capitalizeFirstLetter'
import { type PropsWithClassName } from '@helper/PropsWithClassName'

import { type Studio } from '@definitions/types/symfonyTypesd'

import {
  FilterContainer,
  FilterResetButton,
  Pills,
  Root,
  Tab,
  Tabs,
} from './StudioFilter.styles'

export enum FilterSunpoint {
  BODYFORMING = 'Bodyforming',
  BRAEUNE = 'Bräune',
  CLUB = 'Club',
  KOSMETIK = 'Kosmetik',
}
export enum FilterWellmaxx {
  BODYFORMING = 'Bodyforming',
}

interface ReedemableCouponsFormDataType {
  redeemableCoupons: boolean
}

interface Props extends PropsWithClassName {}

const StudioFilter: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const router = useRouter()
  const theme: any = useTheme()
  const { consentGoogleMaps } = useUserCentrics()
  const { studioArray, handleSearch } = useStudioSearch(null, true)
  const [activeParent, setActiveParent] = useState<GroupedCategory>()

  const [activeFilters, setActiveFilters] = useState<string[]>([])

  let affiliation: string = capitalizeFirstLetter(theme.key)

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
    groupedData: categoriesGroupedData,
  } = useCategories(true, [affiliation])

  const toggleFilter = (filterName: string) => {
    setActiveFilters((prevFilters) => {
      if (prevFilters.includes(filterName)) {
        // Remove the filter if it already exists
        return prevFilters.filter((f) => f !== filterName)
      } else {
        // Add the filter if it doesn't exist
        return [...prevFilters, filterName]
      }
    })
  }

  const resetFilter = () => {
    setActiveFilters([])
  }

  const handleStudioClick = (studio: Studio) => {
    router.push(`/${theme.key}/studio/${studio.slug}`)
  }

  const { register, watch } = useForm<ReedemableCouponsFormDataType>({
    defaultValues: {
      redeemableCoupons: false,
    },
  })
  const redeemableCoupons = watch('redeemableCoupons')

  const filteredStudios = useMemo(() => {
    if (studioArray.length === 0) {
      return []
    }

    if (activeFilters.length === 0) {
      return studioArray
    }

    return studioArray?.filter((studio) => {
      const list =
        [
          ...new Set(
            studio.categories?.edges?.map(({ node }: any) => node?.name)
          ),
        ].filter((name) => {
          if (!name) {
            return false
          }

          return activeFilters.includes(name)
        }) ?? []

      return list.length > activeFilters.length - 1
    })
  }, [activeFilters, studioArray])

  const order = ['Bräune', 'Bodyforming', 'Kosmetik', 'Club']

  const sortedGroupedData = categoriesGroupedData.sort(
    (a, b) => order.indexOf(a.parent.name) - order.indexOf(b.parent.name)
  )

  useEffect(() => {
    if (activeParent === undefined) {
      setActiveParent(sortedGroupedData[0])
    }
  }, [activeParent, setActiveParent, sortedGroupedData])

  return (
    <Root className={props.className} data-testid={'studio-filter-root'}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FilterContainer>
            {/*<InputCheckbox
              forId="ReedemableCoupons"
              label="Coupons einlösbar"
              register={register('redeemableCoupons')}
            />*/}
            <Collapsible
              title={`Weitere Filter anzeigen (${activeFilters.length})`}
              titleAlign="center"
            >
              <Tabs>
                {sortedGroupedData.map((cat: any) => {
                  return (
                    <Tab
                      key={cat.parent.id}
                      onClick={() => setActiveParent(cat)}
                      variant={cat.parent.name}
                      active={activeParent?.parent.name === cat.parent.name}
                    >
                      {cat.parent.name}
                    </Tab>
                  )
                })}
              </Tabs>
              <Pills>
                {activeParent &&
                  activeParent.children?.map((child) => {
                    if (child === null) {
                      return
                    }
                    return (
                      <Pill
                        onClick={() => toggleFilter(child.name)}
                        key={child.id}
                        active={activeFilters.includes(child.name)}
                      >
                        {child.name}
                      </Pill>
                    )
                  })}
              </Pills>
              <FilterResetButton
                color={theme.palette.default.primary}
                icon={<IconCircleX color={theme.palette.default.primary} />}
                iconAlign={IconAligns.RIGHT}
                onClick={() => resetFilter()}
                modifier={ButtonModifier.MINIMAL}
                loading={false}
                disabled={false}
              >
                Alle Filter zurücksetzen
              </FilterResetButton>
            </Collapsible>
          </FilterContainer>
          <StudioFinderSearchfield
            studios={filteredStudios}
            onChange={handleSearch}
            setStudio={handleStudioClick}
          />
          {consentGoogleMaps ? (
            <GoogleMap
              loading={false}
              error={undefined}
              studios={filteredStudios}
              setStudio={(studio) => handleStudioClick(studio)}
            />
          ) : null}
        </Col>
      </Row>
    </Root>
  )
}

export { StudioFilter }
