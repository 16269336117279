import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Root = styled.div`
  position: relative;
  width: 100%;
`

interface StyledSwiperProps {}

export const StyledSwiper = styled(Swiper)<StyledSwiperProps>`
  overflow: visible;
  position: relative;
  width: 100%;
  height: 550px;
  padding-bottom: 2.5rem;
  .swiper-pagination {
    bottom: 0;
    &-bullet {
      background: ${(props) => props.theme.palette.default.primary};
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.625rem;
      border-radius: 100%;
      opacity: 0.5;
      transition: all 0.6s ease;
      &-active {
        opacity: 1;
        transform: scale(1.2);
      }
      @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.3125rem;
      }
    }
  }
`

export const Prev = styled.div`
  position: absolute;
  top: calc(50% - 1.25rem);
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.default.grey};
  transition: background-color 0.3 ease-in-out;
  display: none;
  z-index: 99;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    display: flex;
    left: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.default.primary};
  }
  &::after {
    display: none;
  }
`
export const Next = styled.div`
  position: absolute;
  top: calc(50% - 1.25rem);
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.default.grey};
  transition: background-color 0.3s ease-in-out;
  display: none;
  z-index: 99;
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    display: flex;
    right: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.default.primary};
  }
  &::after {
    display: none;
  }
`

export const StyledSwiperSlide = styled(SwiperSlide)`
  position: relative;
  max-width: 48.0625rem;
  height: 31.25rem;
  border-radius: 1rem;
  overflow: hidden;
  height: 500px;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  overflow: visible;
  display: flex;
  justify-content: center;
  transform: scale(1);
  transform-origin: center center;
  &.swiper-slide-active {
    z-index: 30;
    opacity: 1;
    transform: scale(0.4);
  }
  &.swiper-slide-prev {
    z-index: 20;
    opacity: 0;
    cursor: pointer;
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      opacity: 0.4;
      transform: scale(0.4);
    }
  }
  &.swiper-slide-next {
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      opacity: 0.4;
      transform: scale(0.4);
    }
  }
`
