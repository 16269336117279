import styled from 'styled-components'

import Box from '@components/atoms/Box'
import IconLargeSun from '@components/atoms/Icons/IconLargeSun'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  position: relative;
  background: ${(props) => props.theme.palette.default.primary};
  padding-top: ${(props) => props.theme.padding.big};
  padding-bottom: ${(props) => props.theme.padding.big};
  margin-bottom: ${(props) => props.theme.margin.big};
`

export const StyledBox = styled(Box)`
  padding-top: 5rem;
  margin: 0;
`
export const Stoerer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 9.75rem;
  height: 10.125rem;
  border-radius: 100%;
  background: ${(props) => props.theme.palette.default.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: ${(props) => props.theme.margin.default};
  @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
    position: absolute;
    top: -7.6875rem;
    left: auto;
    right: 10%;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    position: absolute;
    top: -7.6875rem;
    right: auto;
    left: 30%;
    margin-bottom: 0;
  }
`

export const StyledText = styled(Text)`
  position: relative;
  z-index: 10;
  margin: 0;
`
export const StyledIconLargeSun = styled(IconLargeSun)`
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 1;
  path {
    fill: ${(props) => props.theme.palette.default.white};
  }
`

export const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: ${(props) => props.theme.margin.default};
  flex-wrap: wrap;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    margin-bottom: 0;
  }
  svg {
    margin: ${(props) => props.theme.margin.small};
    margin-top: 0;
    path {
      fill: ${(props) => props.theme.palette.default.greyDark};
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      path {
        fill: ${(props) => props.theme.palette.default.black};
      }
    }
  }
`
