import { yupResolver } from '@hookform/resolvers/yup'
import useSendMail from '@hooks/mails/useSendMail'
import Link from 'next/link'
import React, { PropsWithChildren, useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import InputCheckbox from '@components/atoms/Form/InputCheckbox'
import InputLabel from '@components/atoms/Form/InputLabel'
import InputText from '@components/atoms/Form/InputText'
import InputTextarea from '@components/atoms/Form/InputTextarea'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import { Text, TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'
import ThemeBasedLink from '@helper/ThemeBasedLink'

import { RoutesSunpoint } from '@definitions/routes/RoutesSunpoint'
import { RoutesWellmaxx } from '@definitions/routes/RoutesWellmaxx'

import { ButtonCol, Root, StyledButton } from './FormCooperation.styles'

export interface FormCooperationType {
  socialMedia: string
  contentIdeas: string
  email: string
  privacy: boolean
  sunpoint: boolean
  tantastic: boolean
  wellmaxxBodyforming: boolean
  sunmaxx: boolean
  wellmaxxCosmetics: boolean
}

interface Props extends PropsWithClassName {
  userData?: FormCooperationType
  handleSubmitForm: (data: FormCooperationType) => void
  formId: string
}

const FormCooperation: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formSend, setFormSend] = useState<boolean>(false)
  const { handleSubmitForm, userData, formId, dataTestId } = props

  const {
    isLoading: isMailSending,
    sendFormDataMail,
    result: resultSendMail,
  } = useSendMail()

  const { register, handleSubmit, formState } = useForm<FormCooperationType>({
    resolver: yupResolver(
      yup.object().shape({
        socialMedia: yup.string(),
        contentIdeas: yup.string(),
        email: yup
          .string()
          .trim()
          .email('Dies ist keine valide E-Mail-Adresse')
          .required('Dies ist ein Pflichtfeld.')
          .test(
            'is-valid-email',
            'Dies ist keine valide E-Mail-Adresse',
            (value) => {
              if (!value) return false
              return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
            }
          ),
        privacy: yup
          .boolean()
          .oneOf(
            [true],
            'Bitte lese und akzeptiere unsere Datenschutzerklärung um fortzufahren.'
          ),
        sunpoint: yup.boolean(),
        tantastic: yup.boolean(),
        sunmaxx: yup.boolean(),
        wellmaxxBodyforming: yup.boolean(),
        wellmaxxCosmetics: yup.boolean(),
      })
    ) as Resolver<FormCooperationType>,
    defaultValues: userData,
  })

  const onSubmit = async (data: FormCooperationType) => {
    setLoading(true)
    await sendFormDataMail('Kooperations-Anfrage', data, 'cooperation', [])
    await sendFormDataMail(
      'Kooperations-Anfrage',
      data,
      'cooperation-confirm',
      []
    )
    setLoading(false)
    setFormSend(true)
  }

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'form-cooperation-root'}
    >
      {!formSend && (
        <form
          onError={(error) => console.log(error)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={5}
                label="Bitte teile uns deine Social-Media-Namen mit!"
                forId={`${formId}-socialMedia`}
                register={register('socialMedia')}
                error={formState.errors.socialMedia ?? false}
                errorText={formState.errors?.socialMedia?.message}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputLabel>
                Für welche unserer Beauty-Marken interessierst du dich?
              </InputLabel>
              <InputCheckbox
                register={register('sunpoint')}
                forId={`${formId}-sunpoint`}
                error={formState.errors.sunpoint ?? false}
                errorText={formState.errors?.sunpoint?.message}
                label={'SUNPOINT'}
                name={'beautyBrands-sunpoint'}
              />
              <InputCheckbox
                register={register('tantastic')}
                forId={`${formId}-tantastic`}
                error={formState.errors.tantastic ?? false}
                errorText={formState.errors?.tantastic?.message}
                label={'TANTASTIC Bräunungsdusche'}
                name={'beautyBrands'}
              />
              <InputCheckbox
                register={register('wellmaxxBodyforming')}
                forId={`${formId}-wellmaxxBodyforming`}
                error={formState.errors.wellmaxxBodyforming ?? false}
                errorText={formState.errors?.wellmaxxBodyforming?.message}
                label={'WELLMAXX bodyforming'}
                name={'beautyBrands'}
              />
              <InputCheckbox
                register={register('sunmaxx')}
                forId={`${formId}-sunmaxx`}
                error={formState.errors.sunmaxx ?? false}
                errorText={formState.errors?.sunmaxx?.message}
                label={'SUNMAXX'}
                name={'beautyBrands'}
              />
              <InputCheckbox
                register={register('wellmaxxCosmetics')}
                forId={`${formId}-wellmaxxCosmetics`}
                error={formState.errors.wellmaxxCosmetics ?? false}
                errorText={formState.errors?.wellmaxxCosmetics?.message}
                label={'WELLMAXX cosmetics'}
                name={'beautyBrands'}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputTextarea
                rows={5}
                label="Was sind deine ersten Content-Ideen"
                forId={`${formId}-contentIdeas`}
                register={register('contentIdeas')}
                error={formState.errors.contentIdeas ?? false}
                errorText={formState.errors?.contentIdeas?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputText
                register={register('email')}
                forId={`${formId}-email`}
                label="E-Mailadresse"
                error={formState.errors.email ?? false}
                errorText={formState.errors?.email?.message}
                required
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <InputCheckbox
                register={register('privacy')}
                forId={`${formId}-privacy`}
                error={formState.errors.privacy ?? false}
                errorText={formState.errors?.privacy?.message}
                required
                label={
                  <>
                    Ich habe die{' '}
                    <ThemeBasedLink
                      linkSunpoint={RoutesSunpoint.SUNPOINT__DATENSCHUTZ}
                      linkWellmaxx={RoutesWellmaxx.WELLMAXX__DATENSCHUTZ}
                      text="Datenschutzerklärung"
                    />{' '}
                    zur Kenntnis genommen.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <ButtonCol xs={12} sm={12} md={12} lg={12}>
              <StyledButton
                modifier={ButtonModifier.PRIMARY}
                buttonType={ButtonTypes.SUBMIT}
                disabled={formState.isSubmitting}
                icon={<IconArrowRight />}
                iconAlign={IconAligns.RIGHT}
                loading={loading}
              >
                Absenden
              </StyledButton>
            </ButtonCol>
          </Row>
        </form>
      )}
      {formSend && (
        <>
          {resultSendMail && (
            <Text variant={TextVariants.lead}>
              <b>
                Vielen Dank für Deine Kontaktaufnahme.
                <br />
                Wir werden uns in Kürze bei Dir melden.
              </b>
            </Text>
          )}
          {!resultSendMail && (
            <Text variant={TextVariants.lead}>
              <b>Es ist ein Fehler aufgetreten</b>
            </Text>
          )}
        </>
      )}
    </Root>
  )
}

export { FormCooperation }
