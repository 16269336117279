import React from 'react'
import { Tooltip } from 'react-tooltip'
import slugify from 'slugify'

import {
  Headline,
  InfoButton,
  InfoSymbol,
  TooltipContent,
} from './InfoText.styles'

interface Props {
  title: string
  content: string
}

export function InfoText(props: Props) {
  const { title, content } = props
  const id = slugify(title)

  return (
    <InfoButton data-tooltip-id={id}>
      <InfoSymbol>i</InfoSymbol>
      <Tooltip id={id}>
        <TooltipContent>
          <Headline>{title}</Headline>
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </TooltipContent>
      </Tooltip>
    </InfoButton>
  )
}
