import styled, { css } from 'styled-components'

import { shouldUseWhiteText } from '@helper/color'
import { userSelectNone } from '@helper/mixins'

export const Root = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.device.smartphone}) {
    flex-wrap: nowrap;
  }
`

export const StudioFinderPaginationItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin: 0.625rem;
  text-align: center;
  width: 9.375rem;

  @media (min-width: ${(props) => props.theme.device.smartphone}) {
    flex: initial;
  }
`

interface StudioFinderPaginationItemBulletProps {
  active: boolean
  background: string
  clickable: boolean
}

export const getCursorStyles = (clickable: boolean, active: boolean) => {
  if (clickable && active) {
    return css`
      cursor: pointer;
    `
  }
  if (clickable && !active) {
    return css`
      cursor: not-allowed;
    `
  }
  if ((!clickable && active) || (!clickable && !active)) {
    return css`
      cursor: default;
    `
  }
}

export const StudioFinderPaginationItemBullet = styled.div<StudioFinderPaginationItemBulletProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.active ? props.background : props.theme.palette.default.grey};
  border-radius: 1.25rem;
  word-wrap: break-word;
  width: 3.4375rem;
  height: 2.5rem;
  margin-bottom: ${(props) => props.theme.margin.default};
  ${(props) => getCursorStyles(props.clickable, props.active)}
  color: ${(props) =>
    shouldUseWhiteText(
      props.active ? props.background : props.theme.palette.default.grey
    )
      ? 'white'
      : 'black'};
  svg {
    path,
    circle,
    rect {
      fill: ${(props) =>
        shouldUseWhiteText(
          props.active ? props.background : props.theme.palette.default.grey
        )
          ? 'white'
          : 'black'};
    }
  }
`
export const StudioFinderPaginationItemText = styled.div`
  ${() => userSelectNone()}
`
