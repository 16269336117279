import React, { MouseEventHandler, PropsWithChildren } from 'react'

import { Farben } from '@helper/enums/storyblok/Farben'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { BoxBackgrounds } from '../Box/Box'
import IconLoadingSpinner from '../Icons/IconLoadingSpinner'
import { IconLeft, IconRight, Root, Span } from './Button.styles'

export enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ButtonModifier {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SMALL = 'small',
  MINIMAL = 'minimal',
}

export enum IconAligns {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ButtonProps extends PropsWithClassName {
  disabled: boolean
  children: string | React.ReactNode
  buttonType?: ButtonTypes
  modifier: ButtonModifier
  loading: boolean
  icon?: JSX.Element
  iconAlign?: IconAligns
  onClick?: (e: any) => void
  background?: BoxBackgrounds | Farben | string
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = (
  props: PropsWithChildren<ButtonProps>
): React.ReactElement => {
  const {
    disabled,
    children,
    buttonType,
    dataTestId,
    modifier,
    icon,
    iconAlign,
    onClick,
    loading,
    background,
  } = props
  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'button-root'}
      disabled={disabled}
      type={buttonType}
      modifier={modifier}
      onClick={onClick as MouseEventHandler}
      background={background}
    >
      {loading && iconAlign === IconAligns.LEFT && (
        <IconLeft>
          <IconLoadingSpinner />
        </IconLeft>
      )}
      {icon && !loading && iconAlign === IconAligns.LEFT && (
        <IconLeft>{icon}</IconLeft>
      )}
      <Span background={background}>{children}</Span>
      {icon && !loading && iconAlign === IconAligns.RIGHT && (
        <IconRight>{icon}</IconRight>
      )}
      {loading && iconAlign === IconAligns.RIGHT && (
        <IconRight>
          <IconLoadingSpinner />
        </IconRight>
      )}
      {loading && iconAlign === undefined && (
        <IconRight>
          <IconLoadingSpinner />
        </IconRight>
      )}
    </Root>
  )
}

export { Button }
