import React, { PropsWithChildren, useState } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { StudioWithDistance } from '@definitions/customTypes/StudioWithDistance'
import { Studio } from '@definitions/types/symfonyTypesd'

import {
  Input,
  Root,
  Search,
  StyledIconMarker,
  StyledStudioFinderResultsList,
} from './StudioFinderSearchfield.styles'

interface Props extends PropsWithClassName {
  onChange: (term: string) => void
  setStudio: (studio: Studio) => void
  studios: StudioWithDistance[]
}

const StudioFinderSearchfield: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const defaultValue = ''

  const [search, setSearch] = useState<string>(defaultValue)
  const [resulstsVisible, setResultsVisible] = useState<boolean>(false)

  const { onChange, setStudio, studios } = props

  const handleChange = (query: string) => {
    onChange(query)
    setSearch(query)
    setResultsVisible(true)
  }
  const handleFocus = () => {
    setResultsVisible(true)
  }

  const open = studios.length > 0 && search.length >= 3 && resulstsVisible
  return (
    <Root
      className={props.className}
      data-testid={'studio-finder-searchfield-root'}
    >
      <Search open={open}>
        <StyledIconMarker />
        <Input
          onFocus={() => {
            handleFocus()
          }}
          onChange={(e) => handleChange(e.currentTarget.value)}
          defaultValue={defaultValue}
          type={'search'}
          id={'studio-finder-search'}
          placeholder="Stadt oder PLZ eingeben"
          autoComplete="off"
        />
      </Search>
      {open && (
        <StyledStudioFinderResultsList
          studios={studios}
          setStudio={(studio) => setStudio(studio)}
        />
      )}
    </Root>
  )
}

export { StudioFinderSearchfield }
