import styled from 'styled-components'

import Box from '@components/atoms/Box'
import Headline from '@components/atoms/Headline'
import LinkButton from '@components/atoms/LinkButton'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  display: flex;
  height: 100%;
  a {
    color: #292929;
  }
`

export const StyledBox = styled(Box)``

export const Content = styled.div<any>`
  color: ${(props) => props.fontColor};
`

export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
`

export const StyledText = styled(Text)`
  display: flex;
  width: 100%;
  align-self: center;
`

export const InnerContainerTop = styled.div``

export const InnerContainerBottom = styled.div`
  display: flex;
`

export const StyledImage = styled.img``

export const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 0;
`
