import { ContactFormStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokFranchiseForm } from './StoryblokFranchiseForm'

export default StoryblokFranchiseForm

export const dummyBlokContactFormStoryblok: ContactFormStoryblok = {
  _uid: 'eded1a5d-4551-4606-ab40-12f58d7905ef',
  subline: 'Die mit * gekennzeichneten sind Pflichtfelder',
  headline: 'Kontaktformular Franchise',
  subjects:
    'Fristgerecht zum nächstmöglichen Zeitpunkt\nSonderkündigung (Umzug/Krankheit)\nSpäter als fristgerechter, möglicher Zeitpunkt',
  component: 'contact-form',
  _editable:
    '<!--#storyblok#{"name": "contact-form", "space": "235453", "uid": "eded1a5d-4551-4606-ab40-12f58d7905ef", "id": "367795855"}-->',
}
