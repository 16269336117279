import { TextOnImageColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextOnImageColContainer } from './StoryblokTextOnImageColContainer'

export default StoryblokTextOnImageColContainer

export const dummyBlokTextOnImageColContainerStoryblok: TextOnImageColContainerStoryblok =
  {
    _uid: 'db075f70-2197-4926-8afd-e181e1124dd6',
    link: {
      id: 'a2b647ff-d542-4258-b5e3-3784f2fdbc62',
      url: '',
      linktype: 'story',
      fieldtype: 'multilink',
      cached_url: 'sunpoint/braeune/sonnenlicht-und-vitamin-d',
    },
    image: {
      id: 10944310,
      alt: 'test',
      name: '',
      focus: '',
      title: 'test',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/592x322/f0ae5912b2/image-2.png',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_external_url: false,
    },
    content: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
        },
      ],
    },
    headline: 'Gesundes Leben \nbraucht Sonne!',
    linkName: 'Jetzt informieren',
    component: 'text-on-image-col-container',
    logoImage: {
      id: 10947604,
      alt: 'test',
      name: '',
      focus: '',
      title: 'test',
      source: '',
      filename:
        'https://a.storyblok.com/f/235453/170x102/107a9f3fdc/sunpoint-vitamin-d-1-transparent.png',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_external_url: false,
    },
    rightAlign: true,
    _editable:
      '<!--#storyblok#{"name": "text-on-image-col", "space": "235453", "uid": "db075f70-2197-4926-8afd-e181e1124dd6", "id": "322564270"}-->',
  }
