import styled from 'styled-components'

import InputLabel from '../InputLabel'

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  width: 2.4375rem;
  height: 1.75rem;
  margin: 0;
  padding: 0;
  position: absolute;
  border: none;
  outline: none;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 20;
`

export const Switch = styled.div`
  min-width: 3rem;
  width: 3rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background: ${(props) => props.theme.palette.default.white};
  position: relative;
  margin-right: ${(props) => props.theme.margin.small};
  border: 0.0625rem solid ${(props) => props.theme.palette.default.primary};
  z-index: 10;
  position: relative;
  cursor: pointer;
  &::before {
    content: ' ';
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    background: ${(props) => props.theme.palette.default.primary};
    border-radius: 0.5rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`

export const StyledInputLabel = styled(InputLabel)`
  margin: 0;
`

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => props.theme.margin.default};

  ${Input}:checked + ${Switch}::before {
    left: 1.625rem;
  }
`
