import { HeroSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokHeroSection } from './StoryblokHeroSection'

export const dummyBlokHeroSectionStoryblok: HeroSectionStoryblok = {
  _uid: 'd0d0fc94-826a-4305-ab48-9224c5723a97',
  buttons: [
    {
      _uid: '0b50529a-2b9f-4368-b504-75a7bcf9d85b',
      link: {
        id: '',
        url: '',
        linktype: 'story',
        fieldtype: 'multilink',
        cached_url: '',
      },
      size: 'medium',
      label: 'Default',
      style: 'default',
      component: 'button',
      button_color: 'white',
      _editable:
        '<!--#storyblok#{"name": "button", "space": "228071", "uid": "0b50529a-2b9f-4368-b504-75a7bcf9d85b", "id": "300939517"}-->',
    },
    {
      _uid: 'a1bce6ab-ac88-4216-943b-27a899666b04',
      link: {
        id: '2c86c59d-c031-426e-8c50-6d9b311c0204',
        url: '',
        linktype: 'story',
        fieldtype: 'multilink',
        cached_url: 'error-404',
      },
      size: 'medium',
      label: 'Semi transparent',
      style: 'semi',
      component: 'button',
      button_color: 'primary',
      _editable:
        '<!--#storyblok#{"name": "button", "space": "228071", "uid": "a1bce6ab-ac88-4216-943b-27a899666b04", "id": "300939517"}-->',
    },
  ],
  headline: 'Next.js',
  component: 'hero-section',
  text_color: 'light',
  full_height: false,
  subheadline:
    'ein leistungsstarkes Framework für die Erstellung von schnellen, benutzerfreundlichen und SEO-optimierten statischen Webseiten und React-Webanwendungen',
  pattern_overlay: false,
  background_image: {
    id: 8895725,
    alt: 'next js wallpaper',
    name: '',
    focus: '',
    title: '',
    filename:
      'https://a.storyblok.com/f/228071/1920x1080/3b5be3d858/nextjs-wallpaper.png',
    copyright: '',
    fieldtype: 'asset',
    is_external_url: false,
  },
  background_video: {
    id: 1,
    alt: undefined,
    name: '',
    focus: undefined,
    title: undefined,
    filename: '',
    copyright: undefined,
    fieldtype: 'asset',
  },
  vertical_alignment: 'center',
  horizontal_alignment: 'left',
  _editable:
    '<!--#storyblok#{"name": "hero-section", "space": "228071", "uid": "d0d0fc94-826a-4305-ab48-9224c5723a97", "id": "300939517"}-->',
}

export default StoryblokHeroSection
