import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import 'storyblok-rich-text-react-renderer'

import { ButtonModifier } from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import { TextVariants } from '@components/atoms/Text/Text'
import StoryblokRichText from '@components/storyblok/atoms/StoryblokRichText'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  ButtonStoryblok,
  RichtextStoryblok,
  TextSectionStoryblok,
} from '@definitions/types/storyblokTypes'

import {
  RichText,
  Root,
  StyledHeadline,
  StyledLinkButton,
  StyledText,
} from './StoryblokTextSection.styles'

interface Props extends PropsWithClassName {
  blok: TextSectionStoryblok | RichtextStoryblok
}

const StoryblokTextSection = (props: Props): React.ReactElement => {
  const { blok } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok.key ? blok.key : blok._uid}
      className={props.className}
      data-testid={'storyblok-text-section-root'}
      paddingTop={blok.paddingTop ?? false}
      alignment={blok.alignment ?? 'center'}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledHeadline
            alignment={blok.alignment ?? 'center'}
            importance={HeadlineImportances.h2}
          >
            {blok.headline?.toString()}
          </StyledHeadline>
          <StyledText
            alignment={blok.alignment ?? 'center'}
            variant={TextVariants.lead}
          >
            {blok.lead?.toString()}
          </StyledText>
          <RichText alignment={blok.alignment ?? 'center'}>
            <StoryblokRichText content={blok.text} />
          </RichText>
          {blok.button &&
            blok.button.length > 0 &&
            blok.button.map((button: ButtonStoryblok, index: number) => (
              <StyledLinkButton
                alignment={blok.alignment ?? 'center'}
                key={button.label ?? index}
                disabled={false}
                loading={false}
                label={button.label ?? ''}
                href={button.link?.cached_url ?? ''}
                modifier={ButtonModifier.PRIMARY}
              >
                {button.label}
              </StyledLinkButton>
            ))}
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokTextSection }
