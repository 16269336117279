import Image from 'next/image'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'

export const StyledSwiperSlide = styled(SwiperSlide)`
  position: relative;
  max-width: 48.0625rem;
  height: 31.25rem;
  /* opacity: 0; */
  transition: all 0.3s ease-in-out;
  overflow: visible;
  display: flex;
  justify-content: center;
  &.swiper-slide-active {
    z-index: 30;
    opacity: 1;
  }
  &.swiper-slide-prev {
    z-index: 20;
    opacity: 0;
    cursor: pointer;
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      opacity: 0.4;
    }
  }
  &.swiper-slide-next {
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    @media screen and (min-width: ${(props) => props.theme.device.mobile}) {
      opacity: 0.4;
    }
  }
`

export const StyledImage = styled(Image)`
  border-radius: 1rem;
  max-width: 100%;
  height: auto;
  object-fit: cover;
`
