import { gql } from '@apollo/client'

export const CHECK_VOUCHER = gql`
  query VoucherCheck($code: String!, $contractId: String!) {
    voucherCheck(code: $code, contractId: $contractId) {
      code
      valid
      newContract {
        id
      }
    }
  }
`
