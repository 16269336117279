import { domToReact, type HTMLReactParserOptions } from 'html-react-parser'
import React from 'react'

import Headline from '@components/atoms/Headline'
import { HeadlineImportances } from '@components/atoms/Headline/Headline'
import IconCircleCheck from '@components/atoms/Icons/IconCircleCheck'
import ListItem from '@components/atoms/ListItem'
import OrderedList from '@components/atoms/OrderedList'
import { Text, TextVariants } from '@components/atoms/Text/Text'
import UnorderedList from '@components/atoms/UnorderedList'

export const parseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === 'h1') {
      return (
        <Headline importance={HeadlineImportances.h1}>
          {domToReact(domNode.children, parseOptions)}
        </Headline>
      )
    }
    if (domNode.attribs && domNode.name === 'h2') {
      return (
        <Headline importance={HeadlineImportances.h2}>
          {domToReact(domNode.children, parseOptions)}
        </Headline>
      )
    }
    if (domNode.attribs && domNode.name === 'h3') {
      return (
        <Headline importance={HeadlineImportances.h3}>
          {domToReact(domNode.children, parseOptions)}
        </Headline>
      )
    }
    if (domNode.attribs && domNode.name === 'h4') {
      return (
        <Headline importance={HeadlineImportances.h4}>
          {domToReact(domNode.children, parseOptions)}
        </Headline>
      )
    }
    if (domNode.attribs && domNode.name === 'h5') {
      return (
        <Headline importance={HeadlineImportances.h5}>
          {domToReact(domNode.children, parseOptions)}
        </Headline>
      )
    }
    if (domNode.attribs && domNode.name === 'p') {
      return (
        <Text variant={TextVariants.paragraph}>
          {domToReact(domNode.children, parseOptions)}
        </Text>
      )
    }
    if (domNode.attribs && domNode.name === 'ul') {
      return (
        <UnorderedList>
          {domToReact(domNode.children, parseOptions)}
        </UnorderedList>
      )
    }
    if (domNode.attribs && domNode.name === 'ol') {
      return (
        <OrderedList>{domToReact(domNode.children, parseOptions)}</OrderedList>
      )
    }
    if (domNode.attribs && domNode.name === 'li') {
      return (
        <ListItem icon={<IconCircleCheck active />}>
          {domToReact(domNode.children, parseOptions)}
        </ListItem>
      )
    }
    return domNode
  },
}
