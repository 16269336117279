import Link from 'next/link'
import styled from 'styled-components'

import { ButtonModifier } from '@components/atoms/Button/Button'
import { getButtonModifierStyles } from '@components/atoms/Button/Button.styles'

import { shouldUseWhiteText } from '@helper/color'
import { Farben } from '@helper/enums/storyblok/Farben'
import { userSelectNone } from '@helper/mixins'

import { BoxBackgrounds } from '../Box/Box'

interface BackgroundProps {
  background: BoxBackgrounds | Farben | string | undefined
}

export const Root = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.margin.default};
  margin-right: ${(props) => props.theme.margin.small};
`
interface RootProps {
  modifier: ButtonModifier
  disabled: boolean
  background: BoxBackgrounds | Farben | string | undefined
}
export const StyledLink = styled(Link)``

export const StyledA = styled.a<RootProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  border-radius: 1.5rem;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.primaryFamily};
  ${(props) => getButtonModifierStyles(props)}
  text-decoration: none;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  svg {
    width: auto;
    height: 1.5rem;
    &:hover {
    }
  }
`

export const IconLeft = styled.div<BackgroundProps>`
  margin-right: 0.3125rem;
`

export const IconRight = styled.div<BackgroundProps>`
  margin-left: 0.3125rem;
`
export const Span = styled.span<BackgroundProps>`
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  ${userSelectNone()}
`
