import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconArrowLeft.styles'

interface Props extends PropsWithClassName {}

const IconArrowLeft: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      data-testid={'icon-arrow-left-root'}
    >
      <path
        fill="#292929"
        d="M19.25 13.247H4.75a.75.75 0 0 1 0-1.5h14.5a.75.75 0 0 1 0 1.5Z"
      />
      <path
        fill="#292929"
        d="m9.03 15.717-3.22-3.22 3.22-3.22a.75.75 0 0 0-1.06-1.06l-3.75 3.75a.75.75 0 0 0 0 1.06l3.75 3.75a.75.75 0 0 0 1.06-1.06Z"
      />
    </Root>
  )
}

export { IconArrowLeft }
