import { apiPlugin, storyblokInit } from '@storyblok/react'

import StoryblokImageSliderSlide from '@components/atoms/StoryblokImageSliderSlide'
import StoryblokBulletList from '@components/storyblok/atoms/StoryblokBulletList'
import StoryblokBulletListItem from '@components/storyblok/atoms/StoryblokBulletListItem'
import StoryblokButton from '@components/storyblok/atoms/StoryblokButton'
import StoryblokTextSection from '@components/storyblok/atoms/StoryblokTextSection'
import StoryblokCancellationForm from '@components/storyblok/molecules/Forms/StoryblokCancellationForm'
import StoryblokContactForm from '@components/storyblok/molecules/Forms/StoryblokContactForm'
import StoryblokCooperationForm from '@components/storyblok/molecules/Forms/StoryblokCooperationForm'
import StoryblokFormSection from '@components/storyblok/molecules/Forms/StoryblokFormSection'
import StoryblokFranchiseForm from '@components/storyblok/molecules/Forms/StoryblokFranchiseForm'
import StoryblokJobApplication from '@components/storyblok/molecules/Forms/StoryblokJobApplication'
import StoryblokNewsletter from '@components/storyblok/molecules/Forms/StoryblokNewsletter'
import StoryblokOfferLocationForm from '@components/storyblok/molecules/Forms/StoryblokOfferLocationForm'
import StoryblokAccordion from '@components/storyblok/molecules/StoryblokAccordion'
import StoryblokBanner from '@components/storyblok/molecules/StoryblokBanner'
import StoryblokCtaCenterAlign from '@components/storyblok/molecules/StoryblokCtaCenterAlign'
import StoryblokCtaLeftAlign from '@components/storyblok/molecules/StoryblokCtaLeftAlign'
import StoryblokFeaturedArticleSection from '@components/storyblok/molecules/StoryblokFeaturedArticleSection'
import StoryblokGridCard from '@components/storyblok/molecules/StoryblokGridCard'
import StoryblokGridSection from '@components/storyblok/molecules/StoryblokGridSection'
import StoryblokHeroSection from '@components/storyblok/molecules/StoryblokHeroSection'
import StoryblokHeroSubpage from '@components/storyblok/molecules/StoryblokHeroSubpage'
import StoryblokImageSlider from '@components/storyblok/molecules/StoryblokImageSlider'
import StoryblokImageTextBlock from '@components/storyblok/molecules/StoryblokImageTextBlock'
import StoryblokImageTextCol from '@components/storyblok/molecules/StoryblokImageTextCol'
import StoryblokImageTextColContainer from '@components/storyblok/molecules/StoryblokImageTextColContainer'
import StoryblokImageTextSection from '@components/storyblok/molecules/StoryblokImageTextSection'
import StoryblokImageWithOptionalText from '@components/storyblok/molecules/StoryblokImageWithOptionalText'
import StoryblokJobPlugin from '@components/storyblok/molecules/StoryblokJobPlugin'
import StoryblokPersonalizedSection from '@components/storyblok/molecules/StoryblokPersonalizedSection'
import StoryblokSearchfield from '@components/storyblok/molecules/StoryblokSearchfield'
import StoryblokStudioTour from '@components/storyblok/molecules/StoryblokStudioTour'
import StoryblokTabbedContentEntry from '@components/storyblok/molecules/StoryblokTabbedContentEntry'
import StoryblokTabbedContentSection from '@components/storyblok/molecules/StoryblokTabbedContentSection'
import StoryblokTextCol from '@components/storyblok/molecules/StoryblokTextCol'
import StoryblokTextColContainer from '@components/storyblok/molecules/StoryblokTextColContainer'
import StoryblokTextIconCol from '@components/storyblok/molecules/StoryblokTextIconCol'
import StoryblokTextIconColContainer from '@components/storyblok/molecules/StoryblokTextIconColContainer'
import StoryblokTextOnImageCol from '@components/storyblok/molecules/StoryblokTextOnImageCol'
import StoryblokTextOnImageColContainer from '@components/storyblok/molecules/StoryblokTextOnImageColContainer'
import StoryblokVideoWithOptionalText from '@components/storyblok/molecules/StoryblokVideoWithOptionalText'
import StoryblokArticleOverviewPage from '@components/storyblok/organisms/StoryblokArticleOverviewPage'
import StoryblokSkinTypeTest from '@components/storyblok/organisms/StoryblokSkinTypeTest'
import { StoryblokStudioFilter } from '@components/storyblok/organisms/StoryblokStudioFilter/StoryblokStudioFilter'
import StoryblokStudioFinder from '@components/storyblok/organisms/StoryblokStudioFinder'
import StoryblokBannerReference from '@components/storyblok/StoryblokBannerReference'
import StoryblokPage from '@components/storyblok/StoryblokPage'

import { StoryblokHeroStartpage } from '../components/storyblok/molecules/StoryblokHeroStartpage/StoryblokHeroStartpage'

const initStoryblok = () => {
  storyblokInit({
    accessToken: process.env.storyblokPreviewToken,
    use: [apiPlugin],
    components: {
      'article-overview-page': StoryblokArticleOverviewPage,
      'tabbed-content-entry': StoryblokTabbedContentEntry,
      'tabbed-content-section': StoryblokTabbedContentSection,
      'featured-articles-section': StoryblokFeaturedArticleSection,
      'personalized-section': StoryblokPersonalizedSection,
      button: StoryblokButton,
      'hero-section': StoryblokHeroSection,
      'hero-subpage': StoryblokHeroSubpage,
      'hero-startpage': StoryblokHeroStartpage,
      'image-text-section': StoryblokImageTextSection,
      'image-text-col': StoryblokImageTextCol,
      'image-text-col-container': StoryblokImageTextColContainer,
      'image-with-optional-text': StoryblokImageWithOptionalText,
      'image-slider': StoryblokImageSlider,
      'text-section': StoryblokTextSection,
      'grid-card': StoryblokGridCard,
      'grid-section': StoryblokGridSection,
      banner: StoryblokBanner,
      'default-page': StoryblokPage,
      'banner-reference': StoryblokBannerReference,
      'form-section': StoryblokFormSection,
      bullet_list: StoryblokBulletList,
      list_item: StoryblokBulletListItem,
      'video-with-optional-text': StoryblokVideoWithOptionalText,
      'cta-left-align': StoryblokCtaLeftAlign,
      'cta-center-align': StoryblokCtaCenterAlign,
      'text-col': StoryblokTextCol,
      'text-col-container': StoryblokTextColContainer,
      'text-icon-col': StoryblokTextIconCol,
      'text-icon-col-container': StoryblokTextIconColContainer,
      'text-on-image-col': StoryblokTextOnImageCol,
      'text-on-image-col-container': StoryblokTextOnImageColContainer,
      accordion: StoryblokAccordion,
      'image-text-block': StoryblokImageTextBlock,
      'contact-form': StoryblokContactForm,
      'newsletter-formular': StoryblokNewsletter,
      'cancellation-form': StoryblokCancellationForm,
      'skin-type-test': StoryblokSkinTypeTest,
      'studio-filter': StoryblokStudioFilter,
      'studio-finder': StoryblokStudioFinder,
      'job-application': StoryblokJobApplication,
      'cooperation-form': StoryblokCooperationForm,
      'job-plugin': StoryblokJobPlugin,
      'offer-location-form': StoryblokOfferLocationForm,
      'franchise-form': StoryblokFranchiseForm,
      'studio-search-field': StoryblokSearchfield,
      'studio-tour': StoryblokStudioTour,
      'image-slider-slide': StoryblokImageSliderSlide,
    },
    apiOptions: {
      region: 'eu',
    },
  })
}

export default initStoryblok
