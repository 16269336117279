import { JobApplicationStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokJobApplication } from './StoryblokJobApplication'

export default StoryblokJobApplication

export const testBlok: JobApplicationStoryblok = {
  _uid: '842b50f8-55c1-4472-888f-6dbaec690c1a',
  jobName: 'Reinigungskraft',
  component: 'job-application',
  _editable:
    '<!--#storyblok#{"name": "job-application", "space": "235453", "uid": "842b50f8-55c1-4472-888f-6dbaec690c1a", "id": "382433939"}-->',
}
