import styled from 'styled-components'

import Box from '@components/atoms/Box'
import { Children } from '@components/atoms/Box/Box.styles'
import Button from '@components/atoms/Button'
import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'
import ListItem from '@components/atoms/ListItem'
import { Icon } from '@components/atoms/ListItem/ListItem.styles'

import { shouldUseWhiteText } from '@helper/color'

export const Root = styled(Col)`
  display: flex;
  flex: 1;
  align-items: stretch;
  margin-top: 3rem;
  margin-bottom: ${(props) => props.theme.margin.default};
  ul {
    margin: 0;
  }
  li {
    padding: 16px 6px 16px 2.2rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.default.grey};
    ${Icon} {
      top: 18px;
    }
  }
`

export const StyledBox = styled(Box)`
  padding: 0 !important;
  margin-bottom: 0;
  flex-direction: column;
  height: 100%;
  ${Children} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxHead = styled.div`
  min-height: 200px;
  background: ${(props) => props.theme.palette.default.primary};
  padding: 5rem 1.9375rem 1.5rem 1.9375rem;
  border-radius: 1rem 1rem 0 0;
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.primary)
      ? 'white'
      : 'black'};
`
export const BoxHeadHeadline = styled(Headline)`
  margin: 0;
`
interface BoxBodyProps {
  button: boolean
}

export const BoxBody = styled.div<BoxBodyProps>`
  background: ${(props) => props.theme.palette.default.white};
  padding: 2rem 1.9375rem ${(props) => (props.button ? '0' : '2rem')} 1.9375rem;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const BoxBodyHeadline = styled(Headline)`
  margin: 0;
`

export const Stoerer = styled.div`
  background: ${(props) => props.theme.palette.default.tertiary};
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.tertiary)
      ? 'white'
      : 'black'};
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -2.5625rem;
  right: 10%;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 3.125rem;
  margin: auto;
  text-align: center;
  b {
    font-size: x-large;
  }
`

export const StyledListItem = styled(ListItem)`
  padding: 16px 6px 16px 2.2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.default.grey};
  ${Icon} {
    top: 18px;
  }
  &:last-child {
    border-bottom: none;
  }
`

export const StyledButton = styled(Button)`
  margin: 0 auto;
  margin-bottom: 2rem;
  width: auto;
  display: flex;
`
