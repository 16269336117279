import { InputSelect } from './InputSelect'

export const DummyOptions = [
  {
    value: 'Elit',
    label: 'Elit consequat laboris eu aute consectetur.',
  },
  {
    value: 'Velit',
    label: 'Velit non ullamco dolore ea proident est amet.',
  },
  {
    value: 'Veniam',
    label: 'Veniam commodo dolor minim exercitation sint ex.',
  },
]
export default InputSelect
