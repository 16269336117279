import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import {
  Headline,
  HeadlineImportances,
} from '@components/atoms/Headline/Headline'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { FormSectionStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokFormSection.styles'

interface StoryblokFormSectionProps extends FormSectionStoryblok {}
interface Props extends PropsWithClassName {
  blok: StoryblokFormSectionProps
}

const StoryblokFormSection = (props: Props) => {
  const { blok } = props
  return (
    <Root
      {...storyblokEditable(blok)}
      key={blok._uid}
      data-testid="storyblok-form-section-root"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Headline importance={HeadlineImportances.h2}>
            {props.blok.headline}
          </Headline>
          <Text variant={TextVariants.paragraph}>{props.blok.lead}</Text>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Text variant={TextVariants.paragraph}>TODO: Render form</Text>
        </Col>
      </Row>
    </Root>
  )
}

export { StoryblokFormSection }
