import { Searchdestinations } from '@helper/enums/storyblok/Searchdestinations'

import { StudioSearchFieldStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokSearchfield } from './StoryblokSearchfield'

export const dummyStoryblokSearchfield: StudioSearchFieldStoryblok = {
  _uid: '2184f160-ee64-4200-9e86-925354f4129b',
  subline: 'Subline',
  headline: 'Headline',
  component: 'studio-search-field',
  searchLink: Searchdestinations.STUDIODETAIL,
  _editable:
    '<!--#storyblok#{"name": "studio-search-field", "space": "235453", "uid": "2184f160-ee64-4200-9e86-925354f4129b", "id": "424177377"}-->',
}

export default StoryblokSearchfield
