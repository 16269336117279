import { ImageTextColContainerStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokImageTextColContainer } from './StoryblokImageTextColContainer'

export default StoryblokImageTextColContainer

export const dummyBlokImageTextColContainerStoryblok: ImageTextColContainerStoryblok =
  {
    _uid: 'c30604c4-f2f2-4070-b8c0-e98d806f87da',
    subline: 'fddfsf',
    headline: 'dfsdffsf',
    component: 'image-text-col-container',
    imageTextCols: [
      {
        _uid: 'ce5baf00-f0b1-43d1-818a-d87cb2116a31',
        link: {
          id: '589e3df3-f7df-4728-989e-8cbdf37e6209',
          url: '',
          linktype: 'story',
          fieldtype: 'multilink',
          cached_url: 'sunpoint/braeune/solarium',
        },
        image: {
          id: 10414769,
          alt: 'Sonnenbank',
          name: '',
          focus: '',
          title: 'Sonnenbank',
          source: '',
          filename:
            'https://a.storyblok.com/f/235453/384x288/01074d2bf9/sonnenbank.png',
          copyright: '',
          fieldtype: 'asset',
          meta_data: {},
          is_external_url: false,
        },
        content: {
          type: 'doc',
          content: [
            {
              type: 'bullet_list',
              content: [
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'sadsdasdd',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'sdadsdd',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'sdadsddds',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        headline: 'ssdsadsdads',
        component: 'image-text-col',
        _editable:
          '<!--#storyblok#{"name": "image-text-col", "space": "235453", "uid": "ce5baf00-f0b1-43d1-818a-d87cb2116a31", "id": "322787899"}-->',
      },
      {
        _uid: 'dcb1f49c-1dee-463e-8553-0dfb026848d1',
        link: {
          id: '589e3df3-f7df-4728-989e-8cbdf37e6209',
          url: '',
          linktype: 'story',
          fieldtype: 'multilink',
          cached_url: 'sunpoint/braeune/solarium',
        },
        image: {
          id: 10414769,
          alt: 'Sonnenbank',
          name: '',
          focus: '',
          title: 'Sonnenbank',
          source: '',
          filename:
            'https://a.storyblok.com/f/235453/384x288/01074d2bf9/sonnenbank.png',
          copyright: '',
          fieldtype: 'asset',
          meta_data: {},
          is_external_url: false,
        },
        content: {
          type: 'doc',
          content: [
            {
              type: 'bullet_list',
              content: [
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'sdadsdd',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'dsadd',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'list_item',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'sdaasd',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        headline: 'sdasdadd',
        component: 'image-text-col',
        _editable:
          '<!--#storyblok#{"name": "image-text-col", "space": "235453", "uid": "dcb1f49c-1dee-463e-8553-0dfb026848d1", "id": "322787899"}-->',
      },
    ],
    _editable:
      '<!--#storyblok#{"name": "image-text-col-container", "space": "235453", "uid": "c30604c4-f2f2-4070-b8c0-e98d806f87da", "id": "322787899"}-->',
  }
