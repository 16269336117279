import React, { PropsWithChildren } from 'react'

import { TextVariants } from '@components/atoms/Text/Text'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './ErrorText.styles'

interface Props extends PropsWithClassName {}

const ErrorText: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { children } = props
  return (
    <Root
      variant={TextVariants.meta}
      className={props.className}
      dataTestId={'error-text-root'}
    >
      {children}
    </Root>
  )
}

export { ErrorText }
