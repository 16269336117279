import { ImageTextSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokImageTextSection } from './StoryblokImageTextSection'

export const dummyImageTextSectionBlok: ImageTextSectionStoryblok = {
  _uid: '5a836061-95a6-4f8a-b4ce-a151048d909c',
  text: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            text: 'Incididunt laborum eu magna voluptate sit. Aliqua laboris et irure irure nulla id. Labore aute reprehenderit id aliquip officia cillum sunt ut amet quis cupidatat nisi. Elit Lorem minim ullamco in aliquip id. Aute eiusmod sint occaecat elit aliquip quis qui aliqua duis eiusmod duis minim ullamco velit. Officia duis cupidatat commodo dolor aliqua consectetur ad ex ex consectetur. Aute amet Lorem sit qui.',
            type: 'text',
          },
        ],
      },
    ],
  },
  image: {
    id: 8907064,
    alt: '',
    name: '',
    focus: '',
    title: '',
    filename:
      'https://a.storyblok.com/f/228071/1920x1080/8f14388a3a/design_development.jpeg',
    copyright: '',
    fieldtype: 'asset',
    is_external_url: false,
  },
  button: [],
  headline: 'Do ipsum laborum eu esse esse excepteur esse ipsum.',
  component: 'image-text-section',
  image_layout: 'proportional',
  reverse_layout: false,
  background_color: 'white',
  _editable:
    '<!--#storyblok#{"name": "image-text-section", "space": "228071", "uid": "5a836061-95a6-4f8a-b4ce-a151048d909c", "id": "300939517"}-->',
}

export default StoryblokImageTextSection
