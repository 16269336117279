import { BoxBackgrounds } from '@components/atoms/Box/Box'

import { GridCardStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokGridCard } from './StoryblokGridCard'

export const dummyGridCardBlok: GridCardStoryblok = {
  _uid: '2ded3a15-933d-466b-a228-9e5fc65dcc9c',
  icon: {
    id: 1234,
    alt: '1234',
    name: '',
    focus: undefined,
    title: undefined,
    source: null,
    filename: '',
    copyright: undefined,
    fieldtype: 'asset',
    meta_data: {},
  },
  text: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
      },
    ],
  },
  label: '',
  button: [],
  component: 'grid-card',
  background_color: BoxBackgrounds.PRIMARY,
  _editable:
    '<!--#storyblok#{"name": "grid-card", "space": "235453", "uid": "2ded3a15-933d-466b-a228-9e5fc65dcc9c", "id": "322564270"}-->',
}

export default StoryblokGridCard
