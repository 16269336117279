import { PersonalizedSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokPersonalizedSection } from './StoryblokPersonalizedSection'

export const dummyPersonalizedSection: PersonalizedSectionStoryblok = {
  _uid: '5b4fd5e5-aa55-439c-9df8-fe47e84094c7',
  preview: 'new_visitor',
  component: 'personalized-section',
  new_visitor_blocks: [
    {
      _uid: '0ca25c03-8937-4abb-b492-11ef53af0d6a',
      lead: 'Have fun!',
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      button: [],
      headline: 'Hello Stranger!',
      alignment: 'center',
      component: 'text-section',
      background_color: 'white',
      overlap_preceding_hero: false,
      _editable:
        '<!--#storyblok#{"name": "text-section", "space": "228071", "uid": "0ca25c03-8937-4abb-b492-11ef53af0d6a", "id": "300939517"}-->',
    },
  ],
  returning_visitor_blocks: [
    {
      _uid: '8940f4c1-05fa-49a9-9098-193d6e63ab7f',
      lead: 'Schön dich wieder zu sehen',
      text: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      button: [],
      headline: 'Welcome Back',
      alignment: 'center',
      component: 'text-section',
      background_color: 'white',
      overlap_preceding_hero: false,
      _editable:
        '<!--#storyblok#{"name": "text-section", "space": "228071", "uid": "8940f4c1-05fa-49a9-9098-193d6e63ab7f", "id": "300939517"}-->',
    },
  ],
  _editable:
    '<!--#storyblok#{"name": "personalized-section", "space": "228071", "uid": "5b4fd5e5-aa55-439c-9df8-fe47e84094c7", "id": "300939517"}-->',
}

export default StoryblokPersonalizedSection
