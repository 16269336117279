import Link from 'next/link'
import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { IconChevron } from '../Icons/IconChevron/IconChevron'
import { Root, StyledLink } from './BreadCrumb.styles'

export interface BreadCrumbProps {
  text: string
  link: string
}

interface Props extends PropsWithClassName {
  elements: BreadCrumbProps[]
  color?: string
}

const BreadCrumb: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { elements, color } = props
  const currentColor = color || 'black'

  return (
    <Root className={props.className} data-testid={'bread-crumb-root'}>
      {elements.map((element, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <IconChevron color={currentColor} />}
            <StyledLink key={element.text + index} href={element.link}>
              {element.text}
            </StyledLink>
          </React.Fragment>
        )
      })}
    </Root>
  )
}

export { BreadCrumb }
