import styled from 'styled-components'

import Col from '@components/atoms/Grid/Col'
import Headline from '@components/atoms/Headline'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.margin.default};
`
export const StyledCol = styled(Col)`
  color: ${(props) => props.theme.palette.default.black};
`
export const StyledHeadline = styled(Headline)`
  display: flex;
  flex-direction: column;
  text-align: center;
`
export const StyledText = styled(Text)`
  display: flex;
  flex-direction: column;
  text-align: center;
`
