import React, { PropsWithChildren, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import IconBody from '@components/atoms/Icons/IconBody'
import IconCheckmark from '@components/atoms/Icons/IconCheckmark'
import IconSun from '@components/atoms/Icons/IconSun'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'
import { FormUserDataType } from '@components/molecules/Forms/FormUserData/FormUserData'
import { StepsType } from '@components/organisms/StudioFinder/StudioFinder'

import { getColorsBetweenColors } from '@helper/color'
import { useUserData } from '@helper/context/UserDataContext'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Contract, Studio } from '@definitions/types/symfonyTypesd'

import {
  Root,
  StudioFinderPaginationItem,
  StudioFinderPaginationItemBullet,
  StudioFinderPaginationItemText,
} from './StudioFinderPagination.styles'

interface Props extends PropsWithClassName {
  step: StepsType
  handlePaginationClick: (step: StepsType) => void
  contract?: Contract
  studio?: Studio
  formData?: FormUserDataType
}

const StudioFinderPagination: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { step, handlePaginationClick } = props
  const theme = useContext(ThemeContext)
  const { userData } = useUserData()
  let { formData, studio, contract } = userData
  const startColor = theme.palette.default.primary
  const endColor = theme.palette.default.tertiary
  const colors = getColorsBetweenColors(startColor, endColor, 3)
  const isSunpoint = theme.key === 'sunpoint'
  const isWellmaxx = theme.key === 'wellmaxx'

  if (contract === null && props.contract !== undefined) {
    contract = props.contract
  }
  if (studio === null && props.studio !== undefined) {
    studio = props.studio
  }
  if (formData === null && props.formData !== undefined) {
    formData = props.formData
  }

  return (
    <Root
      className={props.className}
      data-testid={'studio-finder-pagination-root'}
    >
      <StudioFinderPaginationItem>
        <StudioFinderPaginationItemBullet
          background={startColor}
          onClick={() => handlePaginationClick(1)}
          clickable={step !== 5}
          active={true}
        >
          {studio === null && <span>1</span>}
          {studio !== null && <IconCheckmark />}
        </StudioFinderPaginationItemBullet>
        <StudioFinderPaginationItemText>
          <Text variant={TextVariants.paragraph}>
            <b>Studio</b>
            <br />
            {studio?.title ?? ' '}
          </Text>
        </StudioFinderPaginationItemText>
      </StudioFinderPaginationItem>
      <StudioFinderPaginationItem>
        <StudioFinderPaginationItemBullet
          background={colors[0]}
          onClick={() => handlePaginationClick(2)}
          active={step === 2 || studio !== null}
          clickable={step !== 5}
        >
          {contract === null && <span>2</span>}
          {contract !== null && <IconCheckmark />}
        </StudioFinderPaginationItemBullet>
        <StudioFinderPaginationItemText>
          <Text variant={TextVariants.paragraph}>
            <b>Mitgliedschaft</b>
            <br />
            {contract?.title ?? ' '}
          </Text>
        </StudioFinderPaginationItemText>
      </StudioFinderPaginationItem>

      <StudioFinderPaginationItem>
        <StudioFinderPaginationItemBullet
          background={colors[1]}
          onClick={() => handlePaginationClick(3)}
          clickable={contract !== null && studio !== null}
          active={contract !== null && studio !== null}
        >
          {formData === null && <span>3</span>}
          {formData !== null && <IconCheckmark />}
        </StudioFinderPaginationItemBullet>
        <StudioFinderPaginationItemText>
          <Text variant={TextVariants.paragraph}>
            <b>Daten</b>
            <br />
            {formData?.vorname ?? ' '}
          </Text>
        </StudioFinderPaginationItemText>
      </StudioFinderPaginationItem>
      <StudioFinderPaginationItem>
        <StudioFinderPaginationItemBullet
          background={colors[2]}
          onClick={() => handlePaginationClick(4)}
          clickable={step !== 5}
          active={formData !== null && contract !== null && studio !== null}
        >
          {step <= 4 && <span>4</span>}
          {step > 4 && <IconCheckmark />}
        </StudioFinderPaginationItemBullet>
        <StudioFinderPaginationItemText>
          <Text variant={TextVariants.paragraph}>
            <b>Zahlung</b>
          </Text>
        </StudioFinderPaginationItemText>
      </StudioFinderPaginationItem>
      <StudioFinderPaginationItem>
        <StudioFinderPaginationItemBullet
          background={endColor}
          active={step === 5}
          clickable={step !== 5}
        >
          {isSunpoint ? <IconSun /> : isWellmaxx && <IconBody />}
        </StudioFinderPaginationItemBullet>
        <StudioFinderPaginationItemText>
          <Text variant={TextVariants.paragraph}>
            <b>{isSunpoint ? 'Sonnen' : isWellmaxx && 'Fertig'}</b>
          </Text>
        </StudioFinderPaginationItemText>
      </StudioFinderPaginationItem>
    </Root>
  )
}

export { StudioFinderPagination }
