import { ButtonStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokButton } from './StoryblokButton'

export const dummyButtonBlok: ButtonStoryblok = {
  _uid: '0b50529a-2b9f-4368-b504-75a7bcf9d85b',
  link: {
    id: '',
    url: '',
    linktype: 'story',
    fieldtype: 'multilink',
    cached_url: '',
  },
  size: 'medium',
  label: 'ButtonStoryblok',
  style: 'default',
  component: 'button',
  button_color: 'white',
  _editable:
    '<!--#storyblok#{"name": "button", "space": "228071", "uid": "0b50529a-2b9f-4368-b504-75a7bcf9d85b", "id": "300939517"}-->',
}

export default StoryblokButton
