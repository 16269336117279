import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { BannerStoryblok } from '@definitions/types/storyblokTypes'

import { Root } from './StoryblokBulletList.styles'

interface Props extends PropsWithClassName {
  children: any
}

const StoryblokBulletList = (props: Props) => {
  const { children } = props
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-bullet-list-root'}
    >
      StoryblokBulletList
      {children}
    </Root>
  )
}

export { StoryblokBulletList }
