import React, { PropsWithChildren } from 'react'

import StudioFinder from '@components/organisms/StudioFinder'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokStudioFinder.styles'

interface Props extends PropsWithClassName {}

const StoryblokStudioFinder: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-studio-finder-root'}
    >
      <StudioFinder />
    </Root>
  )
}

export { StoryblokStudioFinder }
