import { Interface } from 'readline'

import { RoutesSunpoint } from './RoutesSunpoint'
import { RoutesWellmaxx } from './RoutesWellmaxx'

export enum RoutesSunpointDefault {
  INDEX = '/sunpoint',
  STUDIOLIST = '/sunpoint/studio',
  INSTAGRAM = 'https://www.instagram.com/sunpoint_de/',
  YOUTUBE = 'https://www.youtube.com/channel/UCHhvv5ddZwmmhhLCAwDAJ-Q',
  FACEBOOK = 'https://www.facebook.com/SUNPOINT.Studios/',
}

export enum RoutesWellmaxxDefault {
  INDEX = '/wellmaxx',
  STUDIOLIST = '/wellmaxx/studio',
  INSTAGRAM = 'https://www.instagram.com/sunpoint_de/',
  YOUTUBE = 'https://www.youtube.com/channel/UCHhvv5ddZwmmhhLCAwDAJ-Q',
  FACEBOOK = 'https://www.facebook.com/SUNPOINT.Studios/',
}

export interface MetaLinks {
  agb: RoutesSunpoint | RoutesWellmaxx
  privacy: RoutesSunpoint | RoutesWellmaxx
  imprint: RoutesSunpoint | RoutesWellmaxx
  cookie: RoutesSunpoint | RoutesWellmaxx
  withdrawal: RoutesSunpoint | RoutesWellmaxx
}

export interface AllMetaLinksType {
  sunpoint: MetaLinks
  wellmaxx: MetaLinks
}

export const AllMetaLinks: AllMetaLinksType = {
  sunpoint: {
    agb: RoutesSunpoint.SUNPOINT__AGB,
    privacy: RoutesSunpoint.SUNPOINT__DATENSCHUTZ,
    imprint: RoutesSunpoint.SUNPOINT__IMPRESSUM,
    cookie: RoutesSunpoint.SUNPOINT__COOKIEEINSTELLUNGEN,
    withdrawal: RoutesSunpoint.SUNPOINT__WIDERRUFSBELEHRUNG,
  },
  wellmaxx: {
    agb: RoutesWellmaxx.WELLMAXX__AGB,
    privacy: RoutesWellmaxx.WELLMAXX__DATENSCHUTZ,
    imprint: RoutesWellmaxx.WELLMAXX__IMPRESSUM,
    cookie: RoutesWellmaxx.WELLMAXX__COOKIEEINSTELLUNGEN,
    withdrawal: RoutesWellmaxx.WELLMAXX__WIDERRUFSBELEHRUNG,
  },
}
