import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './StoryblokArticleOverviewPage.styles'

interface Props extends PropsWithClassName {}

const StoryblokArticleOverviewPage: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'storyblok-article-overview-page-root'}
    >
      StoryblokArticleOverviewPage
    </Root>
  )
}

export { StoryblokArticleOverviewPage }
