import styled from 'styled-components'

export const Root = styled.svg`
  @keyframes spinner_YpZS {
    0% {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0;
    }
    47.5% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -16;
    }
    95%,
    100% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -59;
    }
  }
`

export const Spinner = styled.g`
  transform-origin: center;
  animation: spinner 2s linear infinite;
  @keyframes spinner {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Circle = styled.circle`
  stroke-linecap: round;
  animation: spinnercircle 1.5s ease-in-out infinite;
  @keyframes spinnercircle {
    0% {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0;
    }
    47.5% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -16;
    }
    95%,
    100% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -59;
    }
  }
`
