import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Root } from './IconMarker.styles'

interface Props extends PropsWithClassName {
  color?: string
  size?: string
}

const IconMarker: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const currentColor = props.color ?? '#292929'
  const currentSize = props.size ?? '24'

  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      width={currentSize}
      height={currentSize}
      viewBox="0 0 24 24"
      fill="none"
      className={props.className}
      data-testid={'icon-marker-root'}
    >
      <path
        fill={currentColor}
        fillRule="evenodd"
        d="M19.25 8.25a8.25 8.25 0 0 0-16.5 0c0 2.965 3.594 9.945 7 15.08a1.5 1.5 0 0 0 2.5 0c3.406-5.135 7-12.115 7-15.08ZM8 8.25a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
        clipRule="evenodd"
      />
    </Root>
  )
}

export { IconMarker }
