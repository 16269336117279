import styled from 'styled-components'

import Headline from '@components/atoms/Headline'

import { Text } from '../Text/Text'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledHeadline = styled(Headline)`
  margin-bottom: 1rem;
  width: auto;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

export const StyledSubline = styled(Text)`
  margin-bottom: 0;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`
