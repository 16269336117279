import styled from 'styled-components'

import Headline from '@components/atoms/Headline'
import Text from '@components/atoms/Text'

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.margin.large};
`

export const StyledHeadline = styled(Headline)`
  text-align: center;
  padding-top: ${(props) => props.theme.padding.default};
  margin-bottom: ${(props) => props.theme.margin.large};
`

export const SearchWithAutocomplete = styled.div`
  position: relative;
`

export const StyledText = styled(Text)`
  margin-bottom: 0;
`

export const Map = styled.div``
export const Pagination = styled.div``
export const Steps = styled.div``
export const Step1 = styled.div``
export const Step2 = styled.div``
export const Step3 = styled.div``
export const Step4 = styled.div``
export const Step5 = styled.div``
