import { TextSectionStoryblok } from '@definitions/types/storyblokTypes'

import { StoryblokTextSection } from './StoryblokTextSection'

export const dummyTextSectionBlok: TextSectionStoryblok = {
  _uid: '54d49867-e2e1-4430-b510-73e731eac43e',
  lead: 'Next.js ist ein leistungsstarkes Framework für die Erstellung von schnellen, benutzerfreundlichen und SEO-optimierten statischen Webseiten und React-Webanwendungen',
  text: {
    type: 'doc',
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
        content: [
          {
            text: 'Headline One',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 2,
        },
        content: [
          {
            text: 'Headline Two',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 3,
        },
        content: [
          {
            text: 'Headline Three',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 4,
        },
        content: [
          {
            text: 'Headline Four',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 5,
        },
        content: [
          {
            text: 'Headline Five',
            type: 'text',
          },
        ],
      },
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'asdf',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'qwer',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'dfjlkg',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'blockquote',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Qoute of the day',
                type: 'text',
              },
            ],
          },
        ],
      },
      {
        type: 'code_block',
        attrs: {
          class: 'language-typescript',
        },
        content: [
          {
            text: "import Row from '@components/atoms/Row'\nimport Text from '@components/atoms/Text'\nimport UnorderedList from '@components/atoms/UnorderedList'\n\nimport { PropsWithClassName } from '@helper/PropsWithClassName'\n\nimport { Root } from './StoryblokTextSection.styles'\n\ninterface Props extends PropsWithClassName {\n  blok: TextSectionStoryblok\n}",
            type: 'text',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' ist ein React-',
            type: 'text',
          },
          {
            text: 'Framework',
            type: 'text',
            marks: [
              {
                type: 'subscript',
              },
            ],
          },
          {
            text: ', das die ',
            type: 'text',
          },
          {
            text: 'Erstellung',
            type: 'text',
            marks: [
              {
                type: 'superscript',
              },
            ],
          },
          {
            text: ' von ',
            type: 'text',
          },
          {
            text: 'superschnellen',
            type: 'text',
            marks: [
              {
                type: 'italic',
              },
            ],
          },
          {
            text: ', SEO-freundlichen und ',
            type: 'text',
          },
          {
            text: 'benutzerfreundlichen',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' statischen ',
            type: 'text',
          },
          {
            text: 'Webseiten',
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: '/home',
                  uuid: 'adcc9fa7-2a2b-4d23-b1bf-43dac356f67e',
                  anchor: null,
                  target: '_self',
                  linktype: 'story',
                },
              },
            ],
          },
          {
            text: ' und Webanwendungen ermöglicht. Es bietet hybrides statisches und Server-Rendering, TypeScript-Unterstützung, Smart Bundling, Route ',
            type: 'text',
          },
          {
            text: 'Prefetching',
            type: 'text',
            marks: [
              {
                type: 'underline',
              },
            ],
          },
          {
            text: ' und vieles mehr, ohne zusätzliche  ',
            type: 'text',
          },
          {
            type: 'emoji',
            attrs: {
              name: 'orangutan',
              emoji: '🦧',
              fallbackImage:
                'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a7.png',
            },
          },
          {
            text: ' Konfiguration. Eine der wichtigsten ',
            type: 'text',
          },
          {
            text: 'Funktionen',
            type: 'text',
            marks: [
              {
                type: 'textStyle',
                attrs: {
                  color: '#FF0000',
                },
              },
            ],
          },
          {
            text: ' von Next.js ist das Pre-Rendering, das die ',
            type: 'text',
          },
          {
            text: 'Seiten',
            type: 'text',
            marks: [
              {
                type: 'highlight',
                attrs: {
                  color: '#FF0000',
                },
              },
            ],
          },
          {
            text: ' im Voraus rendert und den HTML-Code  ',
            type: 'text',
          },
          {
            type: 'emoji',
            attrs: {
              name: 'star_struck',
              emoji: '🤩',
              fallbackImage:
                'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f929.png',
            },
          },
          {
            text: ' jeder Seite zusammen mit dem minimalen JavaScript generiert.',
            type: 'text',
          },
        ],
      },
      {
        type: 'ordered_list',
        attrs: {
          order: 1,
        },
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'opiu',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'xcfgvcbx',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'gfhjkfgh',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'image',
            attrs: {
              id: 9247443,
              alt: '',
              src: 'https://a.storyblok.com/f/228071/1920x1080/30bae51d35/design_development.webp',
              title: '',
              source: '',
              copyright: '',
              meta_data: {},
            },
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' vereinfacht die Erstellung von produktionsreifen Anwendungen mit seinen Funktionen, Werkzeugen und seiner Konfiguration. Zu den weiteren Vorteilen gehören die Internationalisierung, die es ermöglicht, Anwendungen leicht in verschiedene Sprachen zu übersetzen, und die ',
            type: 'text',
          },
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: '-Analytik, die ein analytisches Dashboard bietet, um Besucherdaten und Seiteneinblicke anzuzeigen. Das dateibasierte Routing-System und die einfache Integration von React-Komponenten in Next.js-Seiten tragen ebenfalls zur Beliebtheit des Frameworks bei.',
            type: 'text',
          },
        ],
      },
    ],
  },
  button: [],
  headline: 'Text Section Next JS',
  alignment: 'center',
  component: 'text-section',
  background_color: 'white',
  overlap_preceding_hero: false,
  _editable:
    '<!--#storyblok#{"name": "text-section", "space": "228071", "uid": "54d49867-e2e1-4430-b510-73e731eac43e", "id": "300939517"}-->',
}

export const dummyTextSectionBlokLeft: TextSectionStoryblok = {
  _uid: '54d49867-e2e1-4430-b510-73e731eac43e',
  lead: 'Next.js ist ein leistungsstarkes Framework für die Erstellung von schnellen, benutzerfreundlichen und SEO-optimierten statischen Webseiten und React-Webanwendungen',
  text: {
    type: 'doc',
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
        content: [
          {
            text: 'Headline One',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 2,
        },
        content: [
          {
            text: 'Headline Two',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 3,
        },
        content: [
          {
            text: 'Headline Three',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 4,
        },
        content: [
          {
            text: 'Headline Four',
            type: 'text',
          },
        ],
      },
      {
        type: 'horizontal_rule',
      },
      {
        type: 'heading',
        attrs: {
          level: 5,
        },
        content: [
          {
            text: 'Headline Five',
            type: 'text',
          },
        ],
      },
      {
        type: 'bullet_list',
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'asdf',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'qwer',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'dfjlkg',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'blockquote',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: 'Qoute of the day',
                type: 'text',
              },
            ],
          },
        ],
      },
      {
        type: 'code_block',
        attrs: {
          class: 'language-typescript',
        },
        content: [
          {
            text: "import Row from '@components/atoms/Row'\nimport Text from '@components/atoms/Text'\nimport UnorderedList from '@components/atoms/UnorderedList'\n\nimport { PropsWithClassName } from '@helper/PropsWithClassName'\n\nimport { Root } from './StoryblokTextSection.styles'\n\ninterface Props extends PropsWithClassName {\n  blok: TextSectionStoryblok\n}",
            type: 'text',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' ist ein React-',
            type: 'text',
          },
          {
            text: 'Framework',
            type: 'text',
            marks: [
              {
                type: 'subscript',
              },
            ],
          },
          {
            text: ', das die ',
            type: 'text',
          },
          {
            text: 'Erstellung',
            type: 'text',
            marks: [
              {
                type: 'superscript',
              },
            ],
          },
          {
            text: ' von ',
            type: 'text',
          },
          {
            text: 'superschnellen',
            type: 'text',
            marks: [
              {
                type: 'italic',
              },
            ],
          },
          {
            text: ', SEO-freundlichen und ',
            type: 'text',
          },
          {
            text: 'benutzerfreundlichen',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' statischen ',
            type: 'text',
          },
          {
            text: 'Webseiten',
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: '/home',
                  uuid: 'adcc9fa7-2a2b-4d23-b1bf-43dac356f67e',
                  anchor: null,
                  target: '_self',
                  linktype: 'story',
                },
              },
            ],
          },
          {
            text: ' und Webanwendungen ermöglicht. Es bietet hybrides statisches und Server-Rendering, TypeScript-Unterstützung, Smart Bundling, Route ',
            type: 'text',
          },
          {
            text: 'Prefetching',
            type: 'text',
            marks: [
              {
                type: 'underline',
              },
            ],
          },
          {
            text: ' und vieles mehr, ohne zusätzliche  ',
            type: 'text',
          },
          {
            type: 'emoji',
            attrs: {
              name: 'orangutan',
              emoji: '🦧',
              fallbackImage:
                'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f9a7.png',
            },
          },
          {
            text: ' Konfiguration. Eine der wichtigsten ',
            type: 'text',
          },
          {
            text: 'Funktionen',
            type: 'text',
            marks: [
              {
                type: 'textStyle',
                attrs: {
                  color: '#FF0000',
                },
              },
            ],
          },
          {
            text: ' von Next.js ist das Pre-Rendering, das die ',
            type: 'text',
          },
          {
            text: 'Seiten',
            type: 'text',
            marks: [
              {
                type: 'highlight',
                attrs: {
                  color: '#FF0000',
                },
              },
            ],
          },
          {
            text: ' im Voraus rendert und den HTML-Code  ',
            type: 'text',
          },
          {
            type: 'emoji',
            attrs: {
              name: 'star_struck',
              emoji: '🤩',
              fallbackImage:
                'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f929.png',
            },
          },
          {
            text: ' jeder Seite zusammen mit dem minimalen JavaScript generiert.',
            type: 'text',
          },
        ],
      },
      {
        type: 'ordered_list',
        attrs: {
          order: 1,
        },
        content: [
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'opiu',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'xcfgvcbx',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            type: 'list_item',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: 'gfhjkfgh',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'image',
            attrs: {
              id: 9247443,
              alt: '',
              src: 'https://a.storyblok.com/f/228071/1920x1080/30bae51d35/design_development.webp',
              title: '',
              source: '',
              copyright: '',
              meta_data: {},
            },
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: ' vereinfacht die Erstellung von produktionsreifen Anwendungen mit seinen Funktionen, Werkzeugen und seiner Konfiguration. Zu den weiteren Vorteilen gehören die Internationalisierung, die es ermöglicht, Anwendungen leicht in verschiedene Sprachen zu übersetzen, und die ',
            type: 'text',
          },
          {
            text: 'Next.js',
            type: 'text',
            marks: [
              {
                type: 'bold',
              },
            ],
          },
          {
            text: '-Analytik, die ein analytisches Dashboard bietet, um Besucherdaten und Seiteneinblicke anzuzeigen. Das dateibasierte Routing-System und die einfache Integration von React-Komponenten in Next.js-Seiten tragen ebenfalls zur Beliebtheit des Frameworks bei.',
            type: 'text',
          },
        ],
      },
    ],
  },
  button: [],
  headline: 'Text Section Next JS',
  alignment: 'left',
  component: 'text-section',
  background_color: 'white',
  overlap_preceding_hero: false,
  _editable:
    '<!--#storyblok#{"name": "text-section", "space": "228071", "uid": "54d49867-e2e1-4430-b510-73e731eac43e", "id": "300939517"}-->',
}

export default StoryblokTextSection
